<template>
    <div id="admin-agency-review" class="agency-review">

        <div class="row sm-p-2" v-show="isCurrentRoute('admin.agencies.locations.review')">
            <div class="col-12">
                <app-breadcrumb></app-breadcrumb>
            </div>

            <div class="col-12">
                <div class="rules">
                    <p class="mb-1"><i class="fas fa-info-circle static text-main"></i> Please read the following rules before committing any action</p>
                    <ul class="pl-4 mb-0">
                        <li>Only unverified agency or location can be approved or declined</li>
                        <li>All unverified locations will be approved automatically when an agency is approved</li>
                        <li>All duplicated locations will be removed when merging two agencies</li>
                    </ul>
                </div>
            </div>

            <div class="col-12 mt-3" v-if="loaded">
                <table class="table">

                    <thead>
                        <tr class="row">
                            <th class="col-10 col-sm-11 col-md-6">Agency</th>
                            <th class="col-2 col-sm-1 d-block d-md-none"></th>
                            <th class="col-md-6 d-none d-md-inline-block">Location</th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr class="bg-highlight row">
                            <td class="col-10 col-sm-11 col-md-6 col-lg-6">
                                <p class="fs-110 my-1">{{ organization.agency_name }}</p>
                            </td>

                            <td class="col-2 col-sm-1 d-block d-md-none text-center" @click.stop="expandRow($event)"><i class="fas fa-plus fs-110"></i></td>
                            <td class="col-md-6 col-lg-6 d-none d-md-inline-block">
                                <ul class="list-unstyled mb-0" v-if="organization.locations && organization.locations.length">
                                    <li v-for="(e, index) in unverifiedLocations">
                                        <div class="list-item icon-group">
                                            <i class="far fa-building"></i> {{ e.city }}, {{ e.country_name }}

                                            <i v-if="$can('decline', 'agency')" class="fas fa-trash text-red clickable" @click="declineLocation(e)"></i>
                                            <i v-if="$can('approve', 'agency')" class="fas fa-check text-main clickable" @click="approveLocation(e)"></i>
                                        </div>

                                    </li>
                                </ul>
                            </td>
                        </tr>

                        <tr class="row expand" style="display: none;">
                            <td class="col-12 col-sm-12 d-block d-md-none">
                                <h5 class="mb-0">{{ organization.agency_name }}</h5>

                                <ul class="list-unstyled tree" v-if="organization.locations && organization.locations.length">
                                    <li v-for="(e, index) in unverifiedLocations">
                                        <i class="far fa-building"></i> {{ e.city }}, {{ e.country_name }}
                                        <i v-if="$can('decline', 'agency')" class="fas fa-trash text-red clickable" @click="declineLocation(e)"></i>
                                        <i v-if="$can('approve', 'agency')" class="fas fa-check text-main clickable" @click="approveLocation(e)"></i>
                                    </li>
                                </ul>
                            </td>
                        </tr>

                        <tr class="row">
                            <td colspan="3" class="col-12"><i class="fas fa-info-circle text-main static pl-0"></i> Existing listing</td>
                        </tr>

                        <!-- verified locations -->
                        <template v-if="organization.verified">
                            <tr class="bg-light-white row mb-1">
                                <td class="col-10 col-sm-11 col-md-6"><p class="fs-110 my-1">{{ organization.agency_name }}</p></td>
                                <td class="col-2 col-sm-1 d-block d-md-none text-center" @click.stop="expandRow($event)"><i class="fas fa-plus fs-110"></i></td>
                                <td class="col-md-6 d-none d-md-inline-block">
                                    <ul class="list-unstyled mb-0" v-if="organization.locations && organization.locations.length">
                                        <li v-for="(i, idx) in  verifiedLocations">
                                            <div class="list-item">
                                                <i class="far fa-building"></i> {{ i.city }}, {{ i.country_name }}
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                            </tr>

                            <tr class="row expand" style="display: none;">
                                <td class="col-12 col-sm-12 d-block d-md-none">
                                    <ul class="list-unstyled tree" v-if="organization.locations && organization.locations.length">
                                        <li v-for="(i, idx) in verifiedLocations">
                                            <i class="far fa-building"></i> {{ i.city }}, {{ i.country_name }}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </template>

                    </tbody>
                </table>
            </div>
            <div v-else class="page-loading"></div>

        </div>
    </div>
</template>

<script>

    export default {
        props: {
            sdId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                organization: {
                    locations: []
                }
            }
        },

        inject: ['api'],

        components: {

        },

        created() {
            this.loadUnverifiedOrganization()
        },

        destroyed() {

        },

        computed: {
            verifiedLocations() {
                return this.organization.locations.filter(function(e){
                    return e.verified;
                });

            },

            unverifiedLocations() {
                return this.organization.locations.filter(function(e){
                    return !e.verified;
                });
            }
        },

        methods: {
            loadUnverifiedOrganization() {

                let self = this;

                axios.get(self.api + 'admin/organizations/' + self.sdId + '/unverified')
                    .then(function(response){
                        self.organization = response.data;
                        self.loaded = true;
                        self.$emit('page-loaded');
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            expandRow(e) {
                let expand = $(e.currentTarget).closest('tr').next('.expand');

                expand.toggle();

                if(expand.is(":visible")) {
                    $(e.currentTarget).find('.fa-plus').addClass('fa-minus').removeClass('fa-plus');
                }
                else {
                    $(e.currentTarget).find('.fa-minus').addClass('fa-plus').removeClass('fa-minus');
                }

            },

            declineLocation(location) {

                let self = this;

                let targetElement = event.currentTarget;

                if(!location.verified) {

                    self.handleConfirm({
                        text: 'Are you sure that you want to decline this location?'
                    }, function () {
                        $(targetElement).removeClass('fa-trash').addClass('fa-spin fa-spinner');
                        axios.delete(self.api + 'admin/organizations/' + self.organization.id + '/locations/' + location.location_id + '/decline')
                            .then(function(response){
                                let index = self.arraySearch(self.organization.locations, location.city_id, 'city_id', true);
                                self.organization.locations.splice(index, 1);
                                self.$emit('load-unverified-organizations');
                                self.handleNotice('This location is declined');
                                $(targetElement).addClass('fa-trash').removeClass('fa-spin fa-spinner');
                            })
                            .catch(function(error){
                                $(targetElement).addClass('fa-trash').removeClass('fa-spin fa-spinner');
                                self.handleError(error);
                            });
                    });
                }
                else {
                    self.handleError({message: 'Verified Location is not allowed to decline'});
                }
            },

            approveLocation(location) {

                let self = this;

                if(location.verified) {
                    self.handleNotice('The location has been approved');
                    return true;
                }

                let targetElement = event.currentTarget;

                self.handleConfirm({
                    text: 'Are you sure that you want to approve this location?'
                }, function () {

                    $(targetElement).removeClass('fa-check').addClass('fa-spin fa-spinner');

                    axios.post(self.api + 'admin/organizations/' + self.organization.id + '/locations/' + location.location_id + '/approve')
                        .then(function(response){
                            location.verified = true;
                            self.$emit('load-unverified-organizations');
                            self.handleNotice('This location is approved');
                            $(targetElement).addClass('fa-check').removeClass('fa-spin fa-spinner');
                        })
                        .catch(function(error){
                            $(targetElement).addClass('fa-check').removeClass('fa-spin fa-spinner');
                            self.handleError(error);
                        });
                });

            }
        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">


</style>