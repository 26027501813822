<template>
    <div class="agent-home">
        <app-page-banner page="agent-home" title="Welcome" :subtitle="'Welcome to ' + appName()"></app-page-banner>

        <div v-if="!loaded" class="page-loading"></div>
        <div v-else :class="{'fixed-content-width': $root.fixedContentWidth }">
            <div class="row">
                <div class="col-12 col-sm-8">
                    <h5 class="text-main mb-0">Latest news</h5>
                    <app-search-bar placeholder="Search News" :search-handler="search" :clear-handler="clear" ref="keywords"></app-search-bar>
                    
                    <table class="table table-hover mt-2" v-if="latestNews.length">
                        <tbody>
                            <tr v-for="(f, index) in latestNews" :key="index" class="border-top">
                                <td class="thumbnail d-none d-sm-block border-0">
                                    <img v-if="f.image" :src="f.image" class="img-responsive" onerror="this.style.display='none'"/>
                                </td>
                                <td class="position-relative">

                                    <app-sticky-flag v-if="isSticky(f.flag)"></app-sticky-flag>

                                    <p class="mb-0" v-if="!f.title"><a class="link wr-bk" :href="f.url" target="_blank" data-id="News Article">{{ f.url }}</a></p>
                                    <small class="posted">Posted on {{ f.created_at | convertDate('DD MMMM YYYY') }}</small>
                                    <div class="d-block d-sm-none">
                                        <img v-if="f.image" :src="f.image" onerror="this.style.display='none'" class="thumbnail img-responsive my-2 "/>
                                    </div>
                                    <div class="wrap-link-details d-flex">
                                        <span class="left-border"></span>
                                        <div class="w-100">
                                            <p class="link-site mb-1" v-if="f.favicon"><img class="link-site-image" :src="f.favicon" alt="">{{ f.site_name }}</p>
                                            <a class="link-title mb-0 link" :href="f.url" target="_blank" data-id="News Article">{{ f.title }}</a>
                                            <p class="link-description mb-0">{{ f.description }}</p>
                                        </div>
                                    </div>
                                    <p class="wr-bk mb-0 text-bold" v-html="f.content"></p>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <app-reminder :ready="loaded" :new-line="false" message="There is no news at the moment" :result="latestNews"></app-reminder>

                </div>

                <div class="col-12 col-sm-4">
                    <router-link tag="div" class="featured-course mb-2 clickable"  v-for="(c, index) in courses.data" :key="index" :to="{name: 'agent.courses'}">

                        <img class="img-fluid" :src="c.image" :alt="c.name" :title="c.name" >

                    </router-link>

                    <app-video-prompt
                            v-if="auth().prompt_video"
                            :video="featuredVideo"
                            :open="true"
                            ref="featuredVideoModal"
                    ></app-video-prompt>
                    
                    <app-video-player
                            v-else
                            :source="featuredVideo.source"
                            :poster="featuredVideo.poster"
                            :name="featuredVideo.name"
                    ></app-video-player>
                    <!--<div class="featured-video">
                        <app-video-player :source="featuredVideo" name="Feature Proof Yourself in New Zealand"></app-video-player>
                        <a class="border" href="https://enz.govt.nz/news-and-research/ed-news/enz-launches-future-proof-campaign/" target="_blank">
                            <img class="img-fluid" src="/images/agent/home/Future-proof-campaign.jpg" alt="Future-proof-campaign">
                        </a>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {

        props: {

        },

        data() {
            return {
                latestNews: [],
                courses: {},
                featuredVideo: {
                    'name': 'Feature Proof Yourself in New Zealand',
                    'poster': '/images/agent/prompted-video-cover-image.jpg',
                    'source': '/prompt.mp4', //"https://s3-ap-southeast-2.amazonaws.com/enz/prompt.mp4",
                    'autoplay': true,
                    'playsinline': true
                }
            }
        },

        inject: ['api', 'auth', 'appName'],

        components: {

        },

        created() {
            this.loadLatestNews();
            this.loadFeaturedCourses();
        },

        computed: {

        },

        methods: {

            loadLatestNews() {

                let self = this;

                axios.get(self.api + 'agent/feeds')
                    .then(function(response) {
                        self.latestNews = response.data;
                        self.loaded = true;
                    })
                    .catch(function(error) {
                        self.handleError(error);
                    })
            },

            loadFeaturedCourses(params = {}) {

                let self = this;

                axios.get(this.api + 'agent/courses', {params: params})
                    .then(function(response){
                        self.courses = response.data;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            search(keywords) {
                this.$router.push({name: 'agent.feeds', query: {q: keywords}});
            },

            clear() {

            }
        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">
    .latest-news {
        margin: 10px 0;
        border-bottom: 1px solid var(--light-grey-color);
    }
    #video-modal {
        .modal-dialog {
            max-width: 800px !important;
        }
    }
</style>