<template>
    <div class="staff-team" v-if="$can('view', 'team')">

        <div class="row sm-p-2">

            <!--<div class="col-12">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                    <span aria-hidden="true"><i class="fas fa-times"></i></span>
                </button>
            </div>-->

            <div class="col-12">
                <router-link class="jba-btn" :to="{name: 'admin.staff.teams.create'}" v-if="$can('create', 'team')">Create Teams</router-link>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                    <span aria-hidden="true"><i class="fas fa-times"></i></span>
                </button>
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-xl-3" v-for="(t, index) in teams" :key="index">

                <!--<ul class="list-group clickable py-2">
                    <router-link class="list-group-item" :to="{name: 'admin.staff.teams.update', params: {id: t.id}}">
                        {{ t.name }}
                        <span class="pull-right badge badge-primary badge-pill bg-main fs-100">{{ t.member_count }}</span>
                    </router-link>
                </ul>-->
                <router-link class="card" :to="{name: 'admin.staff.teams.update', params: {id: t.id}}">
                    <div><i class="fa fa-trash fs-130 close" @click.prevent="onDelete(index)"></i></div>
                    <div class="card-body border-bottom">
                        <div class="card-title mb-0">{{ t.name }}</div>
                    </div>
                    <div class="card-footer bg-transparent border-success">
                        <small><i class="fas fa-users static"></i> <b class="fs-110">{{ t.member_count }}</b></small>
                    </div>


                </router-link>
            </div>

            <app-reminder :ready="loaded" message="There is no team at the moment" :new-line="true" :result="teams"></app-reminder>

        </div>
        
        <router-view @load-teams="loadTeams"></router-view>
    </div>
</template>

<script>
    import { getTeams, deleteTeam } from '@/api/teams';

    export default {
        name: 'AdminStaffTeam',

        props: {

        },

        data() {
            return {
                teams: [],
            }
        },

        created(){
            this.loadTeams();
        },

        methods: {

            loadTeams() {
                let self = this;
                getTeams()
                    .then(function(response){
                        self.teams = response.data;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    })
                    .finally(function(){
                        self.loaded = true;
                    });
            },

            close() {
                this.$router.push({name: 'admin.staff'});
            },

            onDelete(index) {

                if(index < this.teams.length) {

                    let self = this;

                    self.handleConfirm({
                        text: 'Are you sure that you want to delete this team?'
                    }, function(){

                        deleteTeam(self.teams[index].id)
                            .then(function(response){
                                self.teams.splice(index, 1);
                            })
                            .catch(function (error) {
                                self.handleError(error);
                            })
                    })
                }
            }
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss"  scoped>

    .list-group-item {

        .badge {
            position: absolute;
            right: 12px;
            top: 12px;
        }
    }

    button {
        &.close {

            padding: 7px 11px;
            color: var(--light-black-color);
            border-radius: 100%;

            &:hover {
                background: var(--light-grey-color);
                transition: background 200ms ease-in;
            }
        }
    }
</style>