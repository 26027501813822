<template>
    <div class="report-sheet" >
        <div class="row" v-if="reportData.current_page">
            <div class="col-12" v-show="pageCount > 1">
                <paginate :page-count="pageCount"
                          :page-range="5"
                          :click-handler="navigate"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :container-class="'pagination float-right'"
                          :page-class="'page-item'"
                          v-model="page"
                ></paginate>
            </div>

            <div class="col-12">

                <table class="table table-hover">
                    <thead>
                        <tr class="row">
                            <th v-for="(h, idx) in getReportLayout(report.slug)" :key="idx" :class="h.col">{{ h.name }}</th>
                            <th class="col-2 d-block d-sm-none"></th>
                        </tr>
                    </thead>

                    <tbody>
                        <template v-for="(row, index) in reportData.data">
                            <tr class="row">
                                <td v-for="(c, idx) in getReportLayout(report.slug)" :key="idx" :class="c.col" v-html="typeof c.callback === 'function' ? c.callback(row, c.field) : row[c.field]"></td>
                                <td class="col-2 d-block d-sm-none text-center" @click.stop="expandRow($event)"><i class="fas fa-plus"></i></td>
                            </tr>

                            <tr :key="'tr'+index" class="row expand" style="display: none;">
                                <td class="col-12 d-block d-sm-none" colspan="2">
                                    <ul class="list-unstyled">
                                        <li v-for="(l, ix) in getReportLayout(report.slug)" :key="ix">{{ l.name }}: <p v-html="typeof l.callback === 'function' ? l.callback(row, l.field) : row[l.field]"></p></li>
                                    </ul>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>

            </div>

            <app-reminder :ready="true" message="No data is found" :new-line="true" :result="reportData.data"></app-reminder>

            <div class="col-12" v-show="pageCount > 1">
                <paginate :page-count="pageCount"
                          :page-range="5"
                          :click-handler="navigate"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :container-class="'pagination float-right'"
                          :page-class="'page-item'"
                          v-model="page"
                ></paginate>
            </div>
        </div>

        <div v-else-if="loading" class="page-loading"></div>
    </div>

</template>

<script>
    export default {
        props: {
            report: {
                type: Object,
                default: function () {
                    return {};
                }
            },
            reportData: {
                type: Object,
                default: function() {
                    return {};
                }
            },
            loading: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loaded: false,
                page: 1
            }
        },

        inject: ['api'],

        components: {

        },

        created() {

        },

        destroyed() {

        },

        computed: {
            pageCount(){
                return this.reportData.per_page ? Math.ceil(this.reportData.total / this.reportData.per_page) : 0;
            }
        },

        watch: {

        },

        methods: {

            navigate(page) {
                this.$emit('navigate', page);
            },

            getReportLayout(report, field = null) {

                let self = this;

                let layout = {
                    'group-summary': [
                        {
                            name: 'Name',
                            field: 'name',
                            col: 'col-10 col-sm-3 col-md-2 col-lg-2 col-xl-2'
                        },
                        {
                            name: 'Agents',
                            field: 'agent_count',
                            col: 'col-sm-5 col-md-3 col-lg-2 col-xl-2 d-none d-sm-inline-block'
                        },
                        {
                            name: 'Countries assigned',
                            field: 'country_names',
                            col: 'col-sm-4 col-md-4 col-lg-3 col-xl-2 d-none d-sm-inline-block',
                            callback: self.multipleBadges
                        },
                        {
                            name: 'Agencies assigned',
                            field: 'organization_names',
                            col: 'col-md-3 col-lg-3 col-xl-2 d-none d-md-inline-block',
                            callback: self.multipleBadges
                        },
                        {
                            name: 'Sector related',
                            field: 'education_sector_names',
                            col: 'col-lg-2 col-xl-2 d-none d-lg-inline-block',
                            callback: self.multipleBadges
                        },
                        {
                            name: 'News feeds',
                            field: 'news_feed_count',
                            col: 'col-xl-2 d-none d-xl-inline-block'
                        }
                    ],
                    'module-completion': [
                        {
                            name: 'Module',
                            field: 'module_name',
                            col: 'col-10 col-sm-3 col-md-2 col-lg-2 col-xl-2'
                        },
                        {
                            name: 'Agent ID',
                            field: 'agent_id',
                            col: 'col-xl-1 d-none d-xl-inline-block',
                        },
                        {
                            name: 'Agent',
                            field: 'full_name',
                            col: 'col-sm-5 col-md-3 col-lg-2 col-xl-2 d-none d-sm-inline-block',
                        },
                        {
                            name: 'Agency',
                            field: 'organization_name',
                            col: 'col-sm-4 col-md-4 col-lg-3 col-xl-2 d-none d-sm-inline-block',
                        },
                        {
                            name: 'Country',
                            field: 'country_name',
                            col: 'col-md-3 col-lg-3 col-xl-2 d-none d-md-inline-block'
                        },
                        {
                            name: 'Score',
                            field: 'score',
                            col: 'col-lg-2 col-xl-1 d-none d-lg-inline-block',
                            callback: self.attachBadge
                        },
                        {
                            name: 'Started at',
                            field: 'started_at',
                            col: 'col-xl-1 d-none d-xl-inline-block',
                            callback: function(row, field){

                                if(!row[field]) {
                                    return '';
                                }
                                return self.$options.filters.convertDate(row[field], 'DD MMM YYYY');
                            }
                        },
                        {
                            name: 'Completed at',
                            field: 'completed_at',
                            col: 'col-xl-1 d-none d-xl-inline-block',
                            callback: function(row, field){

                                if(!row[field]) {
                                    return '';
                                }
                                return self.$options.filters.convertDate(row[field], 'DD MMM YYYY');
                            }
                        }
                    ],
                    'agent-summary': [
                        {
                            name: 'Name',
                            field: 'full_name',
                            col: 'col-10 col-sm-3 col-md-2 col-lg-2 col-xl-2',
                        },
                        {
                            name: 'Agency',
                            field: 'organization_name',
                            col: 'col-sm-5 col-md-3 col-lg-2 col-xl-2 d-none d-sm-inline-block',
                        },
                        {
                            name: 'Interests',
                            field: 'interests',
                            col: 'col-sm-4 col-md-4 col-lg-3 col-xl-2 d-none d-sm-inline-block',
                            callback: self.multipleBadges
                        },
                        {
                            name: 'Groups',
                            field: 'groups',
                            col: 'col-md-3 col-lg-3 col-xl-2 d-none d-md-inline-block',
                            callback: self.multipleBadges
                        },
                        {
                            name: 'Country',
                            field: 'country_name',
                            col: 'col-lg-2 col-xl-1 d-none d-lg-inline-block',
                        },
                        {
                            name: 'Email',
                            field: 'email',
                            col: 'col-xl-2 d-none d-xl-inline-block ',
                        },
                        {
                            name: 'Registered at',
                            field: 'created_at',
                            col: 'col-xl-1 d-none d-xl-inline-block',
                            callback: function(row, field){

                                if(!row[field]) {
                                    return '';
                                }
                                return self.$options.filters.convertDate(row[field], 'DD MMM YYYY');
                            }
                        }
                    ],
                    'active-agent': [

                        {
                            name: 'Name',
                            field: 'full_name',
                            col: 'col-10 col-sm-4'
                        },
                        {
                            name: 'Country',
                            field: 'country_name',
                            col: 'col-sm-4 d-none d-sm-inline-block'
                        },
                        {
                            name: 'Logged at',
                            field: 'logged_at',
                            col: 'col-sm-4 d-none d-sm-inline-block',
                            callback: function(row, field){

                                if(!row[field]) {
                                    return '';
                                }
                                return self.$options.filters.convertDate(row[field], 'DD MMM YYYY HH:mm');
                            }
                        }

                    ],
                    'active-agent-summary': [
                        {
                            name: 'Period',
                            field: 'label',
                            col: 'col-10 col-sm-3'
                        },
                        {
                            name: 'Total Active Agents',
                            field: 'user_count',
                            col: 'col-sm-3'
                        },
                        {
                            name: 'Total Login Count',
                            field: 'login_count',
                            col: 'col-sm-3'
                        },
                        {
                            name: 'Action',
                            field: 'label',
                            col: 'col-sm-3',
                            callback: function(row, field){
                                return '<a href="' + self.api + 'admin/reports/' + self.report.id + '/download?' + $.param({range: row[field]}) + '">Download</a>';
                            }
                        }
                    ]
                };

                if(field) {
                    return layout[report][field];
                }

                return layout[report];
            },

            multipleBadges(row, field, delimiter = ',') {

                let str = row[field];

                if(!str) {
                    return '';
                }
                let arr = [];
                str.split(delimiter).forEach(function(e){
                    arr.push('<span class="badge text-white bg-gray">' + e + '</span>');
                });

                return arr.join(' ');
            },

            attachBadge(row, field, badgeField = 'badge') {

                let score = row[field];
                let badge = row[badgeField];

                if(badge) {
                    return score + ' <img src="' + badge + '" class="pass-icon" />'
                }

                return score;
            },

            expandRow(e) {
                let expand = $(e.currentTarget).closest('tr').next('.expand');

                expand.toggle();

                if(expand.is(":visible")) {
                    $(e.currentTarget).find('.fa-plus').addClass('fa-minus').removeClass('fa-plus');
                }
                else {
                    $(e.currentTarget).find('.fa-minus').addClass('fa-plus').removeClass('fa-minus');
                }

            }

        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">
    .report-sheet {
        td {
            overflow-x: hidden;
            .badge {
                font-size: 1em;
                font-weight: 100;
                margin-bottom: 3px;
            }

            .pass-icon {
                width: 20px;
                vertical-align: top;
            }
        }
    }
</style>
