<template>

    <transition name="transition-model">

        <div class="modal fade show" data-backdrop="static" data-keyboard="false" id="admin-team-modal" tabindex="-1" role="dialog" aria-labelledby="Show Agent">
            <div class="modal-dialog" role="document" @click.stop="function(){}">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>{{ modeText }} team</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </div>

                    <div class="modal-body">

                        <form novalidate>
                            <div class="form-group">
                                <label for="team_name">Name</label>
                                <input class="form-control" type="text" name="name" id="team_name"
                                       v-validate="{required: true, max:50}"
                                       data-v-name="team name"
                                       :readonly="!can(['create', 'update'])"
                                       :class="{'is-invalid': errors.has('name')}"
                                       v-model="form.name" />
                                <small v-show="errors.has('name')" class="text-danger">{{ errors.first('name') }}</small>
                            </div>

                            <div class="form-group mb-0">
                                <label for="members">Team members</label>
                                <app-multiple-select-box v-if="can(['create', 'update'])"
                                        name="members"
                                        track_by="id"
                                        label="full_name"
                                        data-vv-name="members"
                                        v-validate="{required: true}"
                                        :value="form.members"
                                        @select="form.members = $event"
                                        :multiple="true"
                                        :options="users"
                                        :internal-search="false"
                                        :class="{'is-invalid': errors.has('members')}">
                                </app-multiple-select-box>
                                <small v-show="errors.has('members')" class="text-danger">{{ errors.first('members') }}</small>
                                <div class="selected-members">
                                    <span class="badge badge-primary" v-for="(m, k) in form.members" :key="k">
                                        {{ m.full_name }} <i class="fa fa-times clickable" v-if="can(['create', 'update'])" @click="deleteMember(k)"></i>
                                    </span>

                                    <p v-if="deleteTip" class="alert alert-info my-2">Please click 'Save' to commit your change</p>
                                </div>

                            </div>

                        </form>
                    </div>

                    <div class="modal-footer">
                        <button class="jba-btn reverse pull-left" @click.stop="onDelete" v-if="isEditMode && can('delete')">
                            Delete
                        </button>
                        <button class="jba-btn" @click.stop="onSubmit" :disabled="submitting" v-if="can(['create', 'update'])">
                            <i v-if="submitting" class="fas fa-spinner fa-spin"></i> {{ modeText }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import { getOneTeam, saveTeam, deleteTeam } from '@/api/teams';
    import { getAllStaff } from '@/api/staff';
    export default {
        name: 'AdminStaffTeamSave',

        props: {

        },

        data() {
            return {
                submitting: false,
                deleteTip: false,
                form: {
                    members: []
                },
                users: [

                ]
            }
        },

        created() {

            let self = this;

            self.loadOneTeam();

            getAllStaff().then(function(response){

                    self.users = response.data;
                })
                .catch(function(error){
                    self.handleError(error);
                });
        },

        computed: {
            isEditMode() {
                return this.$route.params.id && this.form.id;
            },

            modeText() {
                return this.isEditMode ? 'Save' : 'Create';
            },

        },

        methods: {
            loadOneTeam() {

                let self = this;

                if(!self.$route.params.id) {
                    return true;
                }

                getOneTeam(self.$route.params.id)
                    .then(function(response){
                        self.form = response.data;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    })
                    .finally(function(){
                        self.loaded = true;
                    });
            },

            close() {
                this.toggleModal('hide');
                this.$router.push({name: 'admin.staff.teams'});
            },

            toggleModal(status) {
                $(this.$el).modal(status);
            },

            onDelete() {
                let self = this;

                self.handleConfirm({
                    text: 'Are you sure that you want to delete this team?'
                }, function(){

                    deleteTeam(self.$route.params.id)
                        .then(function(response){
                            self.$emit('load-teams');
                            self.close();
                            return true;
                        })
                        .catch(function (error) {
                            self.handleError(error);
                        })
                })
            },

            onSubmit() {

                let self = this;

                this.$validator.validateAll().then(function() {

                    if(!self.errors.any()) {

                        let data = {
                            name: self.form.name,
                            members: [],
                        };

                        self.form.members.forEach(function(o){
                            data.members.push(o.id);
                        });

                        self.submitting = true;

                        saveTeam(data, self.$route.params.id)
                            .then(function(response) {
                                self.$emit('load-teams');
                                self.close();
                                return true;
                            })
                            .catch(function(error){
                                self.handleError(error);
                            })
                            .finally(function(){
                                self.submitting = false;
                            });
                    }
                });
            },

            deleteMember(index) {
                if(index < this.form.members.length) {
                    this.form.members.splice(index, 1);
                    this.deleteTip = true;
                }
            }
        },

        mounted() {
            this.toggleModal('show');
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss"  scoped>

    .badge {
        margin: 3px;
        padding: 6px;
        i {
            margin-left: 2px;
            padding: 3px 5px;
            background: var(--white-color);
            color: var(--enz-silver-color);
            border-radius: 100%;
        }
    }
</style>