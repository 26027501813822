<template>
    <form>
        <div v-if="!loaded" class="page-loading"></div>
        <!--
            set NEW AGENCY form
        -->
        <div v-else-if="!agent.organization_id && !setExistingOrg" class="form-content">
            <h5>Register your agency</h5>
            <p class="py-2 border-b border-main text-main">Your agency details</p>

            <div class="form-group">
                <label for="agency_name">Name *</label>
                <input v-model="organization.name" class="form-control" name="name" type="text" required>
            </div>
            <div class="form-group">
                <label for="contact_person">Contact Person *</label>
                <input v-model="organization.contact_person" class="form-control" name="contact_person" type="text" required>
            </div>
            <div class="form-group">
                <label for="contact_email">Contact Email Address *</label>
                <input v-model="organization.contact_email" class="form-control" name="contact_email" type="email">
            </div>
            <div class="form-group">
                <label for="website">Website</label>
                <input v-model="organization.website" class="form-control" name="website" type="text" placeholder="http(s)://yourdomain.com">
            </div>
            <div class="form-group">
                <label for="main_phone">Phone</label>
                <input v-model="organization.main_phone" class="form-control" name="main_phone" type="tel">
            </div>
            <div class="form-group d-flex">
                <button @click.prevent="submitNewOrg" class="jba-btn submit-agency">
                    <i v-if="submitting" class="fas fa-spinner fa-spin"></i>
                    Submit
                </button>
            </div>
            <div class="text-center">
                <span class="mr-1">Is your agency already registered? <a class="link" @click="toggleOrgForm">Click here</a></span>
            </div>
        </div>
        <div v-else-if="!agent.organization_id && setExistingOrg" class="form-content">
            <h5>Select your agency</h5>
            <p class="py-2 border-b border-main text-main">Your agency details</p>

            <div class="form-group">
                <label for="existing_orgs">Which agency do you work for?</label>

                <app-multiple-select-box
                        id="organization"
                        name="organization"
                        data-vv-name="organization"
                        v-validate="{required: true}"
                        data-vv-validate-on="select"
                        placeholder="Select agency"
                        :disabled="submitting"
                        :multiple="false"
                        :options="organizationList"
                        :internal-search="false"
                        :value="agent.organization_id"
                        @select="attachExistingOrg($event)"
                        :class="{'is-invalid': errors.has('organization')}"
                        :markup="false">
                </app-multiple-select-box>
                <small v-show="errors.has('organization')" class="text-danger">{{ errors.first('organization') }}</small>

            </div>

            <div class="text-center">
                <span class="mr-1">
                    Don't see your agency?<a class="link" @click="toggleOrgForm"> Click here </a> to register your agency.
                </span>
            </div>
        </div>
        <!--
            set OFFICE form
        -->
        <div v-else class="form-content">
            <h5 class="border-b border-main">Register your office location</h5>
            <!--
                choose existing office
            -->
            <div class="form-group" v-if="setExistingOffice">
                <app-multiple-select-box
                        id="office"
                        name="office"
                        label="city"
                        v-validate="{required: false}"
                        data-vv-validate-on="select"
                        placeholder="Select office..."
                        :multiple="false"
                        :options="offices"
                        :internal-search="false"
                        :value="office"
                        data-vv-as="office"
                        @select="office = $event"
                        :class="{'is-invalid': errors.has('office')}"
                        :markup="false">
                </app-multiple-select-box>
                <small v-show="errors.has('office')" class="text-danger">{{ errors.first('office') }}</small>
            </div>
            <!--
                create new office
            -->
            <div class="row office-detail" v-else>
                <div class="form-group">
                    <small v-if="offices.length == 0" class="d-block pb-2 text-main">* No offices found for your agency, please register a new office location</small>
                    <app-country-region-city
                        ref="countryRegionCity"
                        id="country-region-city"
                        name="city"
                        label="city"
                        v-validate="{required: false}"
                        data-vv-validate-on="select"
                        data-vv-as="city"
                        @select="office.city_id = $event"
                        :class="{'is-invalid': errors.has('city')}"
                    ></app-country-region-city>
                    <small v-show="errors.has('city')" class="text-danger">{{ errors.first('city') }}</small>
                </div>

                <div class="form-group">
                    <label class="custom-control overflow-checkbox d-inline-block">
                        <input id="head_office"
                            type="checkbox"
                            class="overflow-control-input"
                            v-model="office.head_office">
                        <span class="overflow-control-indicator"></span>
                        <span class="overflow-control-description">Head office</span>
                    </label>
                </div>
            </div>
            <div class="form-group d-flex">
                <button :disabled="!office.city_id" @click.prevent="submitAgentWithOffice" class="jba-btn submit-agency">
                    <i v-if="submitting" class="fas fa-spinner fa-spin"></i>
                    Submit
                </button>
            </div>

            <div class="text-center">
                <span v-if="offices.length > 0 && setExistingOffice" class="mr-1">
                    Don't see your office? <a class="link" @click="toggleOfficeForm"> Click here </a> to register a new office.
                </span>
                <span v-if="offices.length > 0 && !setExistingOffice" class="mr-1">
                    Is your office already registered?<a class="link" @click="toggleOfficeForm"> Click here</a>.
                </span>
                <p><a class="link" @click="handleBackFromSetOffice">Back</a></p>
            </div>
        </div>
    </form>
</template>
<script>
import {
    loadOrgOffices,
    createOrganization,
    saveAgentOffice
} from '@/api/organizations';
import {
    updateProfile,
    getUserProfile
} from '@/api/agents';

export default {
    props: {},
    inject: ['api', 'auth', 'languages', 'countries', 'providerTypes', 'organizations'],
    data () {
        return {
            agent: {},
            offices: [],
            organization: {
                name: null,
                contact_email: null,
                contact_person: null,
                main_phone: null,
                website: null,
            },
            organizationList: [],
            office: {
                // 'id' => $d->locationId,
                // 'organization_id' => $d->agencyId,
                // 'city_id' => $d->cityId,
                // 'city' => $d->city,
                // 'country_id' => $country ? $country->id : null,
                // 'status' => (isset($d->status) ? $d->status : OrganizationOffice::STATUS_CONFIRMED),
            },
            loaded: false,
            submitting: false,
            setExistingOrg: false,
            setExistingOffice: false,
        }
    },
    async mounted () {
        this.organizationList = this.organizations();
        const res = await getUserProfile();
        if(res.data.organization_id) {
            const officeResp = await loadOrgOffices(res.data.organization_id);
            this.offices = officeResp.data;
            if(this.offices.length > 0) {
                this.setExistingOffice = true;
            }
        }
        this.loaded = true;
        this.agent = res.data;
        if(this.agent.interests && this.agent.interests.length > 0) {
            this.agent.interests = this.agent.interests.map(i => i.id);
        }
    },
    computed: {
    },
    methods: {
        toggleOfficeForm() {
            this.office = {};
            this.setExistingOffice = !this.setExistingOffice;
        },
        toggleOrgForm() {
            this.setExistingOrg = !this.setExistingOrg;
        },
        async submitNewOrg() {
            let error = this.validateNewOrg();
            if (error) return this.handleError({message: error});
            this.submitting = true;
            try {
                const res = await createOrganization(this.organization);
                this.agent.organization_id = res.data.id;
                this.submitting = false;
                return;
            } catch (err) {
                this.submitting = false;
                this.handleError(err);
                console.error(err)
            }
        },
        async attachExistingOrg(org) {
            if(!org) return;
            this.submitting = true;
            try {
                const res = await loadOrgOffices(org.id);
                this.offices = res.data;
                this.submitting = false;
                if(this.offices.length > 0) {
                    this.setExistingOffice = true;
                }
                this.agent.organization_id = org.id;
                return;
            } catch (err) {
                this.submitting = false;
                this.handleError(err);
                console.error(err)
            }
        },
        async submitAgentWithOffice() { // end of flow
            const data = this.agent;
            data.office_id = this.office.id;
            data.city_id = this.office.city_id;
            data.head_office = this.office.head_office;
            data.notification = 0;
            data.newsletter = this.agent.prompt & 4; // bitwise
            this.submitting = true;
            try {
                const res = await updateProfile(data);
                this.submitting = false;
                window.location.replace('/');
                return;
            } catch (err) {
                this.submitting = false;
                this.handleError(err);
                console.error(err)
            }
        },
        handleBackFromSetOffice() {
            this.offices = [];
            this.setExistingOffice = false;
            this.agent.organization_id = null;
        },
        validateNewOrg() {
            let d = this.organization;
            if(!d.name) return 'Agency "name" is required';
            if(!d.contact_person) return 'Agency "contact person" is required';
            if(d.main_phone && d.main_phone.toString().length < 8) return 'Agency phone must be 8 digits';
            return;
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .form-group {
        width: 100%;
    }
    p {
        color: var(--white-color);
    }
    a.link {
        color: var(--link-blue-color) !important;
    }
    a.link:hover {
        cursor:pointer;
        text-decoration:underline !important;
    }
</style>