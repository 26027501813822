<template>

    <div class="avatar">
        <!--<div class="personal-image" :style="{ backgroundImage : 'url(' + profile.avatar+ ')'}" @click="imageUploadShow(profile.avatar)">
            <span class="edit-icon"><i class="far fa-plus-square"></i></span>
        </div>-->

        <a v-if="updatable" @click="showCroppie()">
            <img class="image-fluid image-circle" :src="avatar" />
        </a>
        <img v-else class="image-fluid image-circle" :src="avatar" />

        <span v-for="(r, index) in roles" :key="index" class="badge badge-warning p-2 ml-2">{{ r.name }}</span>
        <h3 class="full-name just-above" v-if="firstName && lastName">{{ firstName }} {{ lastName }}</h3>
        <!--h5 class="recognized-date d-none d-sm-block" v-if="recognizedAt">Recognized since {{ recognizedAt | convertDate('DD MMM YYYY') }}</h5-->
        <div class="agent-badge">
            <img v-if="recognizedBadge" :src="recognizedBadge"/>
            <img v-if="immigrationAdviser" :src="immigrationAdviserBadge"/>
        </div>

        <div id="avatar-upload-modal" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="Upload Avatar" @click="close">
            <div class="modal-dialog" role="document" @click.stop="function(){}">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title">{{ title }}</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </div>

                    <div class="modal-body text-center" :class="{uploading: uploading}">
                        <div id="upload-image" v-show="avatar"></div>
                    </div>

                    <div class="modal-footer text-center">
                        <input type="file" name="file" id="upload" class="d-none" value="" ref="file" accept="image/*" @change="chooseImage">
                        <label v-show="!newImage" for="upload" class="jba-btn">Choose a file</label>

                        <button v-show="newImage" type="button" class="jba-btn" @click="uploadAvatar()">Update</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    window.EXIF = require('exif-js');
    import Croppie from 'croppie/croppie';

    export default {

        props: {
            avatar: {
                type: String,
                default: ''
            },

            title: {
                type: String,
                default: 'Update your avatar'
            },

            recognizedAt: {
                type: String,
                default: null
            },

            recognizedBadge: {
                type: String,
                default: null
            },

            immigrationAdviserBadge: {
                type: String,
                default: null
            },

            immigrationAdviser: {
                default: false
            },

            firstName: {
                type: String,
                default: null
            },

            lastName: {
                type: String,
                default: null
            },
            roles: {
                type: Array,
                default: function(){
                    return [];
                }
            },
            updatable: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                newImage: false,
                modal: false,
                croppie: null,
                uploading: false
            }
        },

        inject: ['api'],

        components: {

        },

        created() {

        },

        beforeDestroy() {
            this.destroyCroppie();
        },

        computed: {

        },

        methods: {
            showCroppie() {

                this.modal.modal('show');
                this.setupCroppie();
            },

            uploadAvatar(){

                let self = this;

                if(self.croppie) {

                    self.croppie.result({
                        type: 'blob',
                        size: 'viewport'
                    }).then(function(response) {

                        self.newImage = response;

                        let image = new FormData;

                        image.append('avatar', self.newImage);

                        self.uploading = true;
                        axios.post(self.api + 'profile/avatar',
                            image,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }
                        ).then(function(response){

                            self.$root.$emit('avatar-updated', response.data.avatar + '?' + Math.random().toString(36).substring(7));
                            self.uploading = false;
                            self.newImage = null;
                            self.destroyCroppie();
                            self.close();
                        }).catch(function(error) {
                            self.uploading = false;
                            self.handleError(error);
                        })
                    })
                }
            },

            close() {
                this.destroyCroppie();
                this.modal.modal('hide');
            },

            chooseImage(e) {
                let files = e.target.files || e.dataTransfer.files;

                if(files.length) {
                    this.createImage(files[0]);
                }
            },

            createImage(file) {
                let self = this;
                let reader = new FileReader();

                reader.onload = (e) => {
                    self.newImage = e.target.result;
                    self.croppie.bind({
                        url: self.newImage
                    });
                };

                reader.readAsDataURL(file);
            },

            setupCroppie() {

                this.newImage = false;

                if(!this.croppie) {

                    let el = document.getElementById('upload-image');

                    this.croppie = new Croppie(el, {
                        viewport: {width: 200, height: 200, type: 'circle'},
                        boundary: {width: 220, height: 220},
                        enableExif: true
                    });

                    this.croppie.bind({
                        url: this.avatar
                    })
                }
            },

            destroyCroppie() {
                if(this.croppie) {
                    this.croppie.destroy();
                    this.croppie = null;
                }
            }
        },

        mounted() {
            this.modal = $('#avatar-upload-modal');

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">
    .avatar {
        .modal {
            .modal-footer {
                display: block;
            }
        }
    }

</style>