<template>

    <transition name="transition-modal">

        <div class="modal fade show" data-backdrop="static" data-keyboard="false" id="create-document-modal" tabindex="-1" role="dialog" aria-labelledby="Create Document">

            <div v-if="!cropping" class="modal-dialog" role="document" @click.stop="{}">
                <div v-if="!loaded" class="page-loading"></div>
                <div v-else class="modal-content">
                    <div class="modal-header">
                        <h3>{{ operation }} document</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <form novalidate enctype="multipart/form-data">

                            <div class="form-group">
                                <label for="document_name">Name <span>*</span></label>
                                <input id="document_name" name="name" type="text" class="form-control"
                                       :class="{'is-invalid': errors.has('name')}"
                                       v-model="form.name"
                                       v-validate="{required: true}"/>
                                <small v-show="errors.has('name')" class="text-danger">{{ errors.first('name') }}</small>
                            </div>

                            <div class="form-group">
                                <label for="document_description">Description <span>*</span></label>

                                <textarea type="classic" id="document_description" name="description" class="form-control"
                                          :class="{'is-invalid': errors.has('description')}"
                                          v-model="form.description"
                                          v-validate="{required: true}"></textarea>

                                <small v-show="errors.has('description')" class="text-danger">{{ errors.first('description') }}</small>
                            </div>

                            <div class="form-group">
                                <label for="document_groups" class="">Groups <span>*</span></label>

                                <app-multiple-select-box
                                        id="document_groups"
                                        name="groups"
                                        data-vv-name="groups"
                                        v-validate="{required: true}"
                                        data-vv-validate-on="select"
                                        placeholder="Select groups"
                                        :multiple="true"
                                        :options="$parent.groups"
                                        :internal-search="false"
                                        :value="form.groups"
                                        @select="form.groups = $event"
                                        :class="{'is-invalid': errors.has('groups')}"
                                        :markup="false">
                                </app-multiple-select-box>

                                <small v-show="errors.has('groups')" class="text-danger">{{ errors.first('groups') }}</small>
                            </div>

                            <div class="form-group text-right" v-if="(form.id && $can('update', 'document')) || (!form.id && $can('create', 'document'))">
                                <button @click.prevent="submit" class="jba-btn" :disabled="errors.any() || submitting"><i v-if="submitting" class="fas fa-spinner fa-spin"></i> {{ operation }}</button>
                            </div>

                            <div class="form-group" v-if="form.id && $can('upload', 'attachment')">
                                <label for="cover_image" class="mr-10">
                                    Cover Image
                                </label>
                                <i v-if="savedCoverImg" @click="confirmRemoveCoverImg" class="fas fa-trash close"></i>
                                <div class="icon-upload" :class="{'existing-img': savedCoverImg}" :style="coverImgStyle">
                                    <div v-if="savedCoverImg" class="edit-prompt">
                                        <i class="fas fa-camera" style="margin-right:5px;"></i>
                                        <small> Update Cover Image</small>
                                    </div>
                                    <input @change="handleImgFile" type="file" name="cover_image" id="cover_image" accept="image/*"/>
                                    <i v-if="!savedCoverImg" class="fas fa-camera"></i>
                                    <p v-if="!savedCoverImg">Upload Cover Image</p>
                                </div>
                                <small v-show="errors.has('cover_image')" class="text-danger">{{ errors.first('cover_image') }}</small>
                            </div>

                            <div class="form-group" v-if="form.id && $can('upload', 'attachment')">
                                <label for="document_attachments" class="">Attachments</label>
                                <app-file-uploader :save-handler="saveAttachments" upload-field-name="attachments[]" ref="fileUploader"></app-file-uploader>

                                <small v-show="errors.has('attachments')" class="text-danger">{{ errors.first('attachments') }}</small>
                            </div>

                            <div class="form-group" v-if="attachments.length">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Attachment</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(a, idx) in attachments" :key="idx">
                                            <td>{{ a.name }}</td>
                                            <td class="py-1">
                                                <button type="button" class="close" aria-label="Close" @click="removeAttachment(a.id, idx)" v-if="$can('delete', 'attachment')">
                                                    <i class="fas fa-trash"></i>
                                                </button>
                                                <button type="button" class="close">
                                                    <a target="_blank" :href="a.path"><i class="fas fa-download"></i></a>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div v-show="cropping" class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Upload Cover Photo</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div id="upload-cover-image"></div>
                    </div>
                    <div class="modal-footer">
                        <button class="jba-btn reverse" @click="destroyCroppie">
                            Back
                        </button>
                        <button class="jba-btn" @click="uploadCoverImg">
                            <i v-if="uploadingImg" class="fas fa-spinner fa-spin"></i>
                            Upload
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </transition>

</template>

<script>
    window.EXIF = require('exif-js');
    import Croppie from 'croppie/croppie';
    import {
        getAdminDocument,
        createAdminDocument,
        saveDocumentAttachments,
        saveDocumentCoverImg,
        updateAdminDocument,
        removeDocumentAttachment,
        deleteDocumentCoverImg
    } from '@/api/documents'

    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props:{
            id: {
                type: [String, Number],
                default: null
            }
        },

        data(){
            return {
                form: {
                    id: false
                },

                submitting: false,
                documentUpdated: false,
                attachments: [],
                savedCoverImg: false,
                pendingCoverImg: false,
                cropping: false,
                croppie: false,
                uploadingImg: false
            }
        },

        inject: ['api'],

        components: {

        },

        created() {

            this.loadDocument();
        },

        beforeDestroy() {
            if(this.documentUpdated) {
                this.$emit('load-documents');
            }
        },

        computed: {
            operation() {
                return this.form.id ? 'Update' : 'Create';
            },
            coverImgStyle() {
                if(this.savedCoverImg) {
                    return { background: `url(${this.savedCoverImg}) center no-repeat` };
                } else {
                    return {};
                }
            }
        },

        watch: {

        },

        mounted() {
            this.toggleModal('show');
        },

        methods: {

            loadDocument() {

                let self = this;

                if(self.id) {

                    getAdminDocument(self.id)
                        .then(function (response) {
                            self.loaded = true;
                            self.form = {
                                id: response.data.id,
                                name: response.data.name,
                                description: response.data.description,
                                groups: []
                            };

                            response.data.groups.forEach(function(o){
                                self.form.groups.push({
                                    id: o.id,
                                    name: o.name
                                });
                            });

                            self.attachments = response.data.attachments;
                            self.savedCoverImg = response.data.image;

                            return true;
                        })
                        .catch(function (error) {
                            self.handleError(error);
                        });
                }
                else {
                    self.loaded = true;
                }
            },

            /*loadGroups() {
                let self = this;

                self.loadGroupList()
                    .then(function(response){
                        self.groups = response.data;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    })
            },*/

            close() {
                this.toggleModal('hide');
                this.$router.push({name: 'admin.documents'});
            },

            toggleModal(status) {
                $(this.$el).modal(status);
            },

            handleImgFile (e) {
                let files = e.target.files || e.dataTransfer.files;
                if(!files.length) return;

                let latestFile = files[files.length - 1];
                if(!latestFile.type.startsWith('image/')) {
                    this.handleError({ message: 'Document cover image must be an image file type.' });
                    return;
                }

                this.readImgFile(latestFile);
            },
            readImgFile(imgFile) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.pendingCoverImg = e.target.result;
                    this.setupCroppie();
                };
                reader.readAsDataURL(imgFile);
                return;
            },
            setupCroppie() {
                this.cropping = true;
                if(this.croppie) return;

                let el = document.getElementById('upload-cover-image');
                this.croppie = new Croppie(el, {
                    viewport: {width: 400, height: 200, type: 'square'},
                    boundary: {width: 450, height: 400},
                    enableExif: true,
                });
                this.croppie.bind({
                    url: this.pendingCoverImg
                });
            },
            async uploadCoverImg() {
                this.uploadingImg = true;
                try {
                    const imgBlob = await this.croppie.result({
                        type: 'blob',
                        size: 'viewport'
                    });
                    let data = new FormData();
                    data.append('image', imgBlob);
                    const res = await saveDocumentCoverImg(this.form.id, data);
                    this.uploadingImg = false;
                    // add random query param so vue diff knows to update the model
                    this.savedCoverImg = `${res.data.image}?${Math.random().toString(36).substring(7)}`;
                    this.destroyCroppie();
                    this.handleNotice('Cover image saved');
                    this.$emit('doc-img-updated', {
                        id: this.form.id,
                        image: this.savedCoverImg
                    });
                } catch (err) {
                    this.uploadingImg = false;
                    if(err.response.status == 422) {
                        this.handleError({message: err.response.data.message});
                    } else {
                        this.handleError(err);
                    }
                }
            },
            destroyCroppie() {
                if(this.croppie) {
                    this.croppie.destroy();
                    this.croppie = null;
                    this.cropping = false;
                    this.pendingCoverImg = false;
                }
            },

            saveAttachments(formData) {

                let self = this;

                if(!self.form.id) {
                    return false;
                }

                return new Promise(function(resolve, reject){

                    saveDocumentAttachments(self.form.id, formData)
                        .then(function(response) {
                            response.data.forEach(function(o){
                                self.attachments.push(o);
                            });
                            self.documentUpdated = true;
                            resolve(response);
                        })
                        .catch(function(error){
                            self.handleError(error);
                            reject(error);
                        })
                });
            },


            submit() {
                let self = this;

                self.$validator.validateAll().then(function() {

                    if(!self.errors.any()) {

                        let data = {
                            name: self.form.name,
                            description: self.form.description,
                            groups: []
                        };


                        self.form.groups.forEach(function(o){
                            data.groups.push(o.id);
                        });

                        self.submitting = true;

                        if(self.form.id) {
                            self.update(data);
                        }
                        else {

                            createAdminDocument(data)
                                .then(function (response) {
                                    self.submitting = false;
                                    self.form.id = response.data.id;
                                    self.documentUpdated = true;
                                    return true;
                                })
                                .catch(function (error) {
                                    self.submitting = false;
                                    self.handleError(error);
                                });
                        }
                    }
                });
            },

            update(data) {
                let self = this;

                if(self.form.id) {

                    self.submitting = true;

                    updateAdminDocument(self.form.id, data)
                        .then(function (response) {
                            self.submitting = false;
                            self.documentUpdated = true;
                            self.handleNotice('Document is updated');
                            return true;
                        })
                        .catch(function (error) {
                            self.submitting = false;
                            self.handleError(error);
                        });
                }
            },

            removeAttachment(id, index) {
                let self = this;
                self.handleConfirm({
                    text: 'Are you sure that you want to delete this attachment ?'
                }, function () {
                    removeDocumentAttachment(self.form.id, id)
                        .then(function (response) {
                            self.attachments.splice(index, 1);
                            self.documentUpdated = true;
                        })
                        .catch(function (error) {
                            self.handleError(error)
                        })
                }.bind(this, id));
            },
            confirmRemoveCoverImg() {
                if(!this.savedCoverImg) return;
                this.handleConfirm({
                    text: 'Delete cover image?'
                }, async () => {
                    try {
                        await deleteDocumentCoverImg(this.form.id);
                        this.handleNotice('Image deleted!');
                        this.savedCoverImg = null;
                        this.$emit('doc-img-updated', {
                            id: this.form.id,
                            image: null
                        });
                    } catch (err) {
                        if(err.response.status == 422) {
                            this.handleError({message: err.response.data.message});
                        } else {
                            this.handleError(err);
                        }
                    }
                });
            }
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss"  scoped>
    .icon-upload {
        &:hover {
            > .edit-prompt {
                display: inline;
            }
            outline: none;
            background-color: var(--light-grey-color);
            color: var(--main-color);
            > p {
                color: var(--main-color);
            }
        }
        height: 200px;
        outline: 2px dashed grey;
        outline-offset: -10px;
        background: var(--light-white-color);
        text-align:center;
        position:relative;

        > input[type=file] {
            cursor: pointer;
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 200px;
            left: 0;
        }
        > i {
            width: 100%;
            margin-top: 65px;
        }
        > p {
            font-size: 1.2em;
        }
    }
    .existing-img {
        outline-offset: 0px;
        &:hover {
            outline: 2px dashed grey;
        }
    }
    .edit-prompt {
        display: none;
        background: black;
        position: absolute;
        border: 2px solid lightgray;
        border-radius: 3px;
        padding: 2px 9px;
        color: #fff;
        top: 8px;
        left: 8px;
        opacity: 0.8;
    }
    .fa-trash.close {
        cursor: pointer;
        padding: 9px;
        float: none;
        border-radius:100%;
        font-size:1.2rem;
    }
</style>