<template>
    <div id="document">
        <app-page-banner page="document" title="Documents" subtitle="Upload information and resources for quick access"></app-page-banner>

        <div v-if="!loaded" class="page-loading"></div>
        <div class="row sm-p-2" v-else>


            <div class="col-12" v-if="$can('create', 'document')">
                <router-link :to="{name: 'admin.documents.save'}" class="jba-btn btn-info">Create new document</router-link>
            </div>

            <div class="col-12 col-sm-4">
                <h5 class="text-main mb-0">Document list </h5>
            </div>

            <div class="col-12 col-sm-8">
                <app-search-bar placeholder="Search Documents" :search-handler="search" :clear-handler="clear" ref="keywords" :search-in-group="groups"></app-search-bar>
            </div>

            <div class="col-12" v-show="pageCount > 1">
                <paginate :page-count="pageCount"
                          :page-range="5"
                          :click-handler="navigate"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :container-class="'pagination float-right'"
                          :page-class="'page-item'"
                          v-model="page"
                ></paginate>
            </div>

            <div class="col-12 col-sm-6 col-md-4" v-for="(m, index) in documents.data" :key="index" v-if="documents.total">

                <router-link tag="div" class="card clickable p-2" :to="{name: 'admin.documents.save', params:{id: m.id}}" :title="m.name">

                    <i class="fas fa-trash clickable close" @click.stop="remove(m)" aria-label="Close" v-if="$can('delete', 'document')"></i>
                    <h6 class="pr-4 mb-0 py-1 text-truncate">{{ m.name }}</h6>
                    <div class="dropdown-divider mb-2"></div>
                    <img :src="m.image" alt="document cover image">
                    <div class="dropdown-divider mb-2"></div>
                    <div class="text-grey foot">
                        <div class="pr-5">
                            <p class="m-0"><small>attachments: <b>{{ m.attachment_count }}</b></small></p>
                            <p class="m-0"><small>size: <b>{{ m.attachment_size | bytesToSize }}</b></small></p>
                        </div>
                    </div>
                </router-link>

            </div>

            <app-reminder :ready="loaded" message="There is no document at the moment" :new-line="true" :result="documents.data"></app-reminder>

            <div class="col-12" v-show="pageCount > 1">
                <paginate :page-count="pageCount"
                          :page-range="5"
                          :click-handler="navigate"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :container-class="'pagination float-right'"
                          :page-class="'page-item'"
                          v-model="page"
                ></paginate>
            </div>

        </div>

        <router-view @load-documents="loadDocuments" @doc-img-updated="updateDocImage"></router-view>
    </div>
</template>

<script>
    export default {
        props: {

        },
        data() {
            return {
                groups: [],
                documents: [],
                page: 1,
                loaded: false
            }
        },

        inject: ['api'],

        components: {

        },

        created() {
            this.loadDocuments();
        },

        destroyed() {

        },

        computed: {
            pageCount(){
                return Math.ceil(this.documents.total / this.documents.per_page);
            }
        },

        watch: {

        },

        methods: {
            search(params) {
                this.loadDocuments(params);
            },

            navigate(page) {

                let params = this.$refs.keywords.value;
                params.page = page;

                this.loadDocuments(params);
            },

            loadDocuments(params = {}) {

                let self = this;

                axios.get(this.api + 'admin/documents', {params: params})
                    .then(function(response){
                        self.documents = response.data;
                        self.page = self.documents.current_page;
                        self.loaded = true;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            remove(doc) {

                let self = this;
                self.handleConfirm({
                    text: 'Are you sure that you want to delete this document?'
                }, function () {
                    axios.delete(self.api + 'admin/documents/' + doc.id)
                        .then(function (response) {
                            self.navigate(self.goToPage(self.documents.current_page, self.documents.data.length - 1));
                        })
                        .catch(function (error) {
                            self.handleError(error)
                        })
                });

            },

            clear() {
                this.navigate(1);
            },
            updateDocImage (document) {
                this.documents.data = this.documents.data.map(d => {
                    if(d.id == document.id) {
                        d.image = document.image;
                    }
                    return d;
                });
            }
        },

        mounted() {
            let self = this;

            self.loadGroupList().then(function (response) {
                self.groups = response.data;
            }, function (error) {
                self.handleError(error);
            });
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .reset-group {
        padding: 3px 12px;
        line-height: 1em;
        min-width: inherit;
        font-size: 0.6em;
    }
    .doc-cover-img {
        object-fit:cover;
        height: 200px;
    }
    .default-doc-image {
        font-size: 100px;
        color: grey;
        margin:auto;
    }
</style>