<template>
    <!--<div class="notification" ref="inbox" v-show="inbox.length > 0">
        <a class="dropdown-toggle"
           data-toggle="dropdown"
           aria-expanded="false">
            <i class="fas fa-bell"><em class="badge">{{ inbox.length }}</em></i>
        </a>
        <ul class="dropdown-menu dropdown-menu-right list-group">
            <li v-for="(i, index) in inbox" class="list-group-item" :key="index" @click="showMessage(index, i.id)">
                <span v-html="i.content"></span>
            </li>
        </ul>
    </div>-->

    <router-link tag="div" :to="{name:'agent.messages'}" class="notification" v-show="inbox.length > 0" ref="inbox">
        <i class="fas fa-bell clickable"><em class="badge" :class="{'no-count': !count}">{{ count ? inbox.length : '' }}</em></i>
    </router-link>


        <!--<button class="inbox tsmall" ref="inbox" @click="inboxToggle()">

                <i class="fas fa-bell"></i>

            <span class="notification" v-show="inbox.total > 0">
                <i class="fas fa-circle"></i>
            </span>
            <div v-show="inboxOpen&&inbox.total>0">
                <div class="triangle"></div>
                <div class="inbox-message-wrap">
                    <router-link v-for="(i, index) in inbox.data" class="db w-100 inbox-message" :key="index" :to="{name:'agent.messages'}" >
                        <span v-html="i.content"></span>
                    </router-link>
                </div>
            </div>
        </button>-->

</template>
<script>
    export default {
        props: {
            count: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                inbox:[],
                inboxOpen: false,
                url: '/agent/messages/'
            }
        },

        inject: ['api', 'auth'],

        created() {
            this.loadUnreadMessages().then(response => {
                this.inbox = response.data;
                this.$root.$emit('unread-message', this.inbox)
            }, error => {
                this.handleError(error);
            })
        },

        destroyed() {
        },

        methods: {

        },
        mounted() {
            let self = this;

            self.$root.$on('update-unread-message', data => {
                self.inbox = data;
            });
            
        }
    }
</script>
