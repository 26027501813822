<template>

    <div name="video-modal">

        <div class="modal fade show" id="video-modal" tabindex="-1" role="dialog" aria-labelledby="Show Course" @click.stop="function(){}">
            <div class="modal-dialog" role="document" @click.stop="function(){}">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="jba-btn" data-dismiss="modal" aria-label="Close" v-if="!videoEnded" @click.stop="onPlayerEnded()">Watch later</button>
                        <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>-->
                    </div>

                    <div class="modal-body">

                        <div class="prompt-content text-justify" v-if="videoEnded">
                            <p>Welcome to the Education New Zealand AgentLab, created for education agents,
                                your place for everything you need to know to promote New Zealand as a leading
                                education destination. </p>

                            <p>As well as exciting and modern training modules,
                                the AgentLab will be where we communicate with you on our latest campaigns and promotions,
                                provide you with important fact sheets and information, send you personal messages and updates,
                                and hold webinars.</p>

                            <p>Everything within the AgentLab is relevant and personalised to you based on the information
                                you have given us, and can be accessed from your computer, phone or tablet.</p>

                            <p class="text-center">
                                <!--button class="jba-btn reverse" @click="skip()">Watch later</button-->
                                <button class="jba-btn" @click="tour()">Tour</button>
                            </p>
                        </div>

                        <app-video-player v-else
                                          ref="promptVideo"
                                          :source="video.source"
                                          :name="video.name"
                                          :playsinline="video.playsinline"
                                          :on-player-ended="onPlayerEnded"
                                          :autoplay="video.autoplay"></app-video-player>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {

        props:{

            video: {
                type: Object,
                required: true
            },

            open: {
                type: Boolean,
                default: false
            }

            /*onPlayerEnded: {
                type: Function,
                default: function(){}
            }*/
        },

        data(){
            return {
                loaded: false,
                videoEnded: false
            }
        },

        inject: ['api', 'auth'],

        created() {

        },

        methods: {

            onPlayerEnded(player, id) {

                let self = this;

                axios.put(this.api + 'agent/prompt/video')
                    .then(function(response){
                        self.videoEnded = true;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            close() {
                if(this.$refs.promptVideo) {
                    this.$refs.promptVideo.player.pause();
                }
                else {
                    this.auth().prompt_video = false;
                }

                this.toggleModal('hide');  				
            },

            toggleModal(status) {
                if(status == 'hide') {
                    $(this.$el).find('.modal').modal(status);
                }
                else {
                    $(this.$el).find('.modal').modal({
                        backdrop: 'static',
                        keyboard: false
                    })
                }
            },

            show() {
                this.toggleModal('show');
            },

            skip() {
                this.auth().prompt = 0;
                this.close();
            },

            tour() {
                this.$root.$emit('start-tour');
                this.close();

            }

        },

        computed: {

        },

        mounted() {
            if(this.open) {
                this.show();
            }
        }
    }

</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .modal-header {
        text-align: right;
        display: block;
        padding-bottom: 0;
    }
</style>