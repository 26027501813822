<template>
    <div class="agent-feed">
        <app-page-banner page="agent-feed" title="News" subtitle="The latest New Zealand education news"></app-page-banner>

        <div v-if="!loaded" class="page-loading"></div>
        <div v-else :class="{'fixed-content-width': $root.fixedContentWidth }">
            <div class="row">

                <div class="col-12">
                    <app-search-bar placeholder="Search News" :search-handler="search" :query="$route.query.q" :clear-handler="clear" ref="keywords"></app-search-bar>
                </div>

                <div class="col-12" v-show="pageCount > 1">
                    <paginate :page-count="pageCount"
                              :page-range="5"
                              :click-handler="navigate"
                              :prev-text="'<'"
                              :next-text="'>'"
                              :container-class="'pagination float-right'"
                              :page-class="'page-item'"
                              v-model="page"
                    ></paginate>
                </div>

                <div class="col-12 mt-3">

                    <table class="table table-hover" v-if="feeds.total">
                        <tbody>
                            <tr v-for="(f, index) in feeds.data" :key="index">
                                <td class="thumbnail d-none d-sm-block">
                                    <img v-if="f.image" :src="f.image" class="img-responsive" onerror="this.style.display='none'"/>
                                </td>
                                <!--td :colspan="f.image ? 1 : 2"-->
                                <td class="position-relative">

                                    <app-sticky-flag v-if="isSticky(f.flag)"></app-sticky-flag>

                                    <p class="mb-0" v-if="!f.title"><a class="link wr-bk" :href="f.url" target="_blank" data-id="News Article">{{ f.url }}</a></p>
                                    <small class="posted">Posted on {{ f.created_at | convertDate('DD MMMM YYYY') }}</small>
                                    <div class="d-block d-sm-none">
                                        <img v-if="f.image" :src="f.image" class="thumbnail img-responsive my-2" onerror="this.style.display='none'" />
                                    </div>

                                    <div class="wrap-link-details d-flex">
                                        <span class="left-border"></span>
                                        <div class="w-100">
                                            <p class="link-site mb-1" v-if="f.favicon"><img class="link-site-image" :src="f.favicon" alt="">{{ f.site_name }}</p>
                                            <a class="link-title mb-0 link" :href="f.url" target="_blank" data-id="News Article">{{ f.title }}</a>
                                            <p class="link-description mb-0">{{ f.description }}</p>
                                        </div>
                                    </div>
                                    <p class="wr-bk mb-0 text-bold" v-html="f.content"></p>
                                </td>
                            </tr> 
                        </tbody>
                    </table>
                    <app-reminder :ready="loaded" message="There is currently no news to display" :result="feeds.data"></app-reminder>

                </div>

                <div class="col-12" v-show="pageCount > 1">
                    <paginate :page-count="pageCount"
                              :page-range="5"
                              :click-handler="navigate"
                              :prev-text="'<'"
                              :next-text="'>'"
                              :container-class="'pagination float-right'"
                              :page-class="'page-item'"
                              v-model="page"
                    ></paginate>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {

        props: {

        },

        data() {
            return {
                feeds: {},
                page: 1
            }
        },

        inject: ['api'],

        components: {

        },

        created() {
            this.loadFeeds();
        },

        computed: {
            pageCount(){
                return Math.ceil(this.feeds.total / this.feeds.per_page);
            }
        },

        methods: {
            search(keywords) {

                this.loadFeeds({q: keywords});
            },

            navigate(page) {

                let params = {page: page};

                if(this.$refs.keywords.value) {
                    params.q = this.$refs.keywords.value;
                }

                this.loadFeeds(params);
            },

            loadFeeds(params = {}) {

                let self = this;

                //{q: self.$route.query.q || ''}

                if(!params.q) {
                    params.q = self.$route.query.q || '';
                }

                axios.get(this.api + 'agent/feeds', {params: params})
                    .then(function(response){
                        self.feeds = response.data;
                        self.page = self.feeds.current_page;
                        self.loaded = true;
                    })
                    .catch(function(error){

                        self.handleError(error);
                    });
            },

            clear() {
                this.navigate(1);
            }
        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>