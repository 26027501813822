<template>
    <!--<div class="row">
        <div class="header-image" :class="page">
            <div class="header-title row ml-3 mr-3 ml-md-5 align-items-center">
                <h1 class="text-uppercase text-white mb-2 mr-5" v-text="title"></h1>
            </div>
        </div>
    </div>-->


        <div class="row">
            <div class="col-12 mx-0 px-0">
                <div class="header-image d-none d-md-block" :class="page">
                    <div class="header-title row ml-3 mr-3 ml-md-5 align-items-center">
                        <h1 class="text-uppercase text-white mb-2 mr-5" v-text="title"></h1>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="page-header mt-2">
                    <h1 class="text-uppercase d-block d-md-none">{{ title }}</h1>
                    <p v-if="subtitle" class="subtitle"><i class="fas fa-info-circle text-main static"></i> <span v-html="subtitle"></span></p>
                </div>
            </div>

            <!--<div class="col-12" v-if="isAgencyLocationMissing()">
                <div class="alert alert-danger my-2">
                    <router-link v-if="!auth().organization_id" :to="{name: 'agent.profile'}">Please click here to update your organisation detail</router-link>
                    <router-link v-else-if="!auth().office_id" :to="{name: 'agent.profile'}">Please update your office detail in profile page</router-link>
                </div>
            </div>-->
        </div>



</template>

<script>
    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props:{
            page: {
                type: String,
                default: 'home'
            },
            title: {
                type: String,
                required: true
            },
            subtitle: {
                type: String,
                default: null
            }
        },

        data(){
            return {

            }
        },
        created() {

        },

        inject: ['auth'],

        methods: {

        },
        computed: {
            imageClass() {
                return this.page + '-header-image';
            }
        },
        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>