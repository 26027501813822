<template>
  <div class="admin-faq p-3">
    <div v-if="!loaded" class="page-loading"></div>
    <div class="row sm-p-2" v-else>
      <div class="col-12">
        <h3 class="text-main">Education Agent FAQs</h3>
      </div>
      <div class="col-12 col-md-8 col-lg-4" v-if="$can('create', 'faq')">
        <form novalidate class="create-role" @submit.prevent.stop="onClickSubmit">
          <div class="input-group">
            <input
              class="form-control"
              type="text"
              name="title"
              v-model.trim="faq.title"
              v-validate="{required: true, min: 3, max: 100, regex: /^[A-Za-z0-9 ]+$/}" />
            <button class="jba-btn input-group-append rounded-left-0" type="button" aria-haspopup="true" aria-expanded="false" @click="onClickSubmit">Add new section</button>
          </div>
          <small v-show="errors.has('title')" class="text-danger">{{ errors.first('title') }}</small>
        </form>
      </div>
    </div>
    <div class="col-12">
      <div id="accordion">
        <admin-faq-item
          v-on:save-section="onSaveSection"
          v-on:delete-section="onDeleteSection"
          v-on:move-up-section="onMoveUpSection"
          v-on:move-down-section="onMoveDownSection"
          v-for="faq in faqs"
          :key="faq.id"
          :faq="faq" />
      </div>
    </div>
  </div>
</template>

<script>
  import AdminFaqItem from '@/components/admin/AdminFaqItem'
  import { list, create, update, remove, move } from '@/api/faqs'
  import { UP, DOWN }  from '@/constants'

  export default {
    name: 'admin-faq',

    props: {
    },

    data () {
      return {
        faq: {
          title: null
        },
        faqs: [],
        loaded: false
      }
    },

    inject: ['api', 'auth', 'appName'],

    components: {
      AdminFaqItem
    },

    methods: {
      onClickSubmit () {
        let self = this
        create(self.faq).then(response => {
          self.loadFaqs()
          self.faq = {
            title: null
          }
          self.$validator.reset();
        })
      },
      loadFaqs() {
        list().then(response => {
          this.faqs = response.data
          this.loaded = true
        }).catch(function(error){
          self.handleError(error);
        })
      },
      onSaveSection (faq) {
        let self = this
        update(faq).then((response) => {
          self.loadFaqs()
          self.handleNotice('Section updated')
        }).catch(function(error){
          this.handleError(error);
        })
      },
      onMoveUpSection (faq) {
        console.log(UP)
        move(faq, UP).then(response => {
          this.sort(response.data, UP)
        }).catch((error) => {
          this.handleError(error);
        })
      },
      onMoveDownSection (faq) {
        move(faq, DOWN).then(response => {
          this.sort(response.data, DOWN)
        }).catch((error) => {
          this.handleError(error);
        })
      },
      onDeleteSection (faq) {
        let self = this
        self.handleConfirm({
          text: 'Are you sure you want this section?'
        },
        function () {
          remove(faq).then((response) => {
            var list = [...self.faqs]
            var index = list.findIndex(f => f.id === faq.id)
            list.splice(index, 1)
            self.faqs = [...list]
          }).catch((error) => {
            self.handleError(error);
          })
        })
      },
      sort (faq, direction) {
        var list = [...this.faqs]
        var currentIndex = list.findIndex(f => f.id === faq.id)

        var swapIndex = currentIndex + (direction === DOWN ? +1 : -1)

        if (swapIndex < 0 || swapIndex > (list.length - 1)) {
          return
        }

        var swapItem = list[swapIndex]

        list[swapIndex] = faq
        list[currentIndex] = swapItem

        this.faqs = [...list]
      }
    },

    mounted () {
      this.loadFaqs()
    }
  }
</script>
