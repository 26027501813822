<template>

    <div id="agency-pending">
        <app-page-banner page="agency" title="Agencies" subtitle="Approve new agencies and offices"></app-page-banner>

        <div v-show="isCurrentRoute('admin.agencies.pending')">

            <div v-if="!loaded" class="page-loading"></div>
            <div class="row sm-p-2" v-else>


                <div class="col-12">

                    <ul class="nav nav-tabs">
                        <li class="nav-item mr-1">
                            <router-link class="nav-link" :to="{name: 'admin.agencies'}"><span>Agencies List</span></router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name: 'admin.agencies.pending'}"><span>Unverified</span></router-link>
                        </li>
                    </ul>
                </div>

                <div class="col-12 col-sm-4">
                    <h5 class="text-main mb-0">Unverified Agencies</h5>
                </div>

                <div class="col-12 col-sm-8">
                    <app-search-bar placeholder="Search Agencies" :search-handler="search" :clear-handler="clear" ref="keywords"></app-search-bar>
                </div>

                <div class="col-12 mt-3">
                    <table class="table" v-if="organizations.length">
                        <thead>
                        <tr class="row">
                            <th class="col-10 col-sm-6 col-md-6 col-lg-6">Agency</th>
                            <th class="col-2 d-block d-sm-none"></th>
                            <th class="col-sm-3 col-md-3 col-lg-3 d-none d-sm-inline-block">Unverified Locations</th>
                            <th class="col-sm-3 col-md-3 col-lg-3 d-none d-sm-inline-block"></th>
                        </tr>
                        </thead>

                        <tbody>
                        <template  v-for="(e, index) in organizations">
                            <tr class="row">
                                <td class="col-10 col-sm-6 col-md-6 col-lg-6">
                                    <!--app-single-field-update
                                            component-class="editable-text mb-0"
                                            :component-value="e.agency_name"
                                            :component-params="e.id"
                                            :component-handler="updateAgencyName"
                                    ></app-single-field-update-->
                                    <p class="fs-110 my-1">{{ e.agency_name }}</p>
                                </td>
                                <td class="col-2 d-block d-sm-none text-center" @click.stop="expandRow($event)"><i class="fas fa-plus"></i></td>
                                <td class="col-sm-3 col-md-3 col-lg-3 d-none d-sm-inline-block">
                                    <p class="my-1 fs-120 text-bold">{{ e.total_unverified_locations }}</p><!--i class="fas fa-external-link-alt text-main" :class="{hidden: !e.total_unverified_locations}"></i-->
                                </td>
                                <td class="col-sm-3 col-md-3 col-lg-3 d-none d-sm-inline-block">
                                    <p class="my-1 text-right">
                                        <router-link v-if="!e.verified" tag="button" class="jba-btn btn-xs" :to="{name: 'admin.agencies.pending.review', params: {sdId: e.id}}">
                                            Review
                                        </router-link>
                                        <router-link v-else tag="button" class="jba-btn btn-xs" :to="{name: 'admin.agencies.locations.review', params: {sdId: e.id}}">
                                            Review Location
                                        </router-link>
                                    </p>
                                </td>
                            </tr>

                            <tr :key="'tr'+index" class="row expand" style="display: none;">
                                <td class="col-12 d-block d-sm-none" colspan="2">
                                    <ul class="list-unstyled">
                                        <li><p class="my-1 fs-120 text-bold">Unverified Locations: {{ e.total_unverified_locations }}</p><!--i class="fas fa-external-link-alt" :class="{hidden: !e.total_unverified_locations}"></i--></li>
                                        <li>
                                            <p class="my-1 text-right">
                                                <router-link v-if="!e.verified" tag="button" class="jba-btn btn-xs" :to="{name: 'admin.agencies.pending.review', params: {sdId: e.id}}">
                                                    Review
                                                </router-link>
                                                <router-link v-else tag="button" class="jba-btn btn-xs" :to="{name: 'admin.agencies.locations.review', params: {sdId: e.id}}">
                                                    Review Location
                                                </router-link>
                                            </p>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>

                    <app-reminder :ready="loaded" message="There is no agency at the moment" :result="organizations"></app-reminder>
                </div>

            </div>
        </div>
        <router-view @load-unverified-organizations="loadUnverifiedOrganizations"></router-view>
    </div>

</template>

<script>

    export default {
        props: {

        },
        data() {
            return {
                organizations: [],
                submitting: false,
                loaded: false
            }
        },

        inject: ['api'],

        components: {

        },

        created() {
            this.loadUnverifiedOrganizations();
        },

        destroyed() {

        },

        computed: {

        },

        methods: {
            search(params) {
                this.loadUnverifiedOrganizations({q: params});
            },
            /*
            navigate(page) {

                let params = {page: page};
                params.q = this.$refs.keywords.value;

                this.loadOrganizations(params);
            },

            clear() {
                this.navigate(1);
            },
            */

            clear() {
                this.loadUnverifiedOrganizations();
            },

            loadUnverifiedOrganizations(params = {}) {

                let self = this;

                axios.get(this.api + 'admin/organizations/unverified', {params: params})
                    .then(function(response){
                        self.organizations = response.data;
                    //    self.page = self.organizations.current_page;
                        self.loaded = true;
                        self.$emit('page-loaded');
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            expandRow(e) {
                let expand = $(e.currentTarget).closest('tr').next('.expand');

                expand.toggle();

                if(expand.is(":visible")) {
                    $(e.currentTarget).find('.fa-plus').addClass('fa-minus').removeClass('fa-plus');
                }
                else {
                    $(e.currentTarget).find('.fa-minus').addClass('fa-plus').removeClass('fa-minus');
                }

            }
        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">


</style>