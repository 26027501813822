<template>

    <transition name="transition-modal">

        <div class="modal fade show" data-backdrop="static" data-keyboard="false" id="create-message-modal" tabindex="-1" role="dialog" aria-labelledby="Create Message">
            <div class="modal-dialog" role="document" @click.stop="function(){}">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Create message</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <form novalidate>

                            <div class="form-group">
                                <label for="broadcast_subject">Subject <span>*</span></label>
                                <input id="broadcast_subject" name="subject" type="text" class="form-control"
                                       :class="{'is-invalid': errors.has('subject')}"
                                       v-model="form.subject"
                                       v-validate="{required: true, min:3}"/>
                                <small v-show="errors.has('subject')" class="text-danger">{{ errors.first('subject') }}</small>
                            </div>

                            <div class="form-group">
                                <label for="broadcast_message">Message <span>*</span></label>

                                <editor type="classic" id="broadcast_message" name="message"
                                        :class="{'is-invalid': errors.has('message')}"
                                        :config="editorConfig"
                                        v-model="form.content"
                                        @blur="onBlur($event)"
                                        v-validate="{required_no_tag: true, min_no_tag: 10}"></editor>

                                <small v-show="errors.has('message') && !initialLoad" class="text-danger">{{ errors.first('message') }}</small>
                            </div>

                            <div class="form-group" v-show="!form.countries.length && !form.provider_types.length">
                                <label for="broadcast_groups" class="">Groups</label>

                                <app-multiple-select-box
                                        id="broadcast_groups"
                                        name="groups"
                                        data-vv-name="groups"
                                        v-validate="{required: false}"
                                        :value="form.groups"
                                        @select="form.groups = $event"
                                        :multiple="true"
                                        :options="groups"
                                        placeholder="Select groups"
                                        :internal-search="false"
                                        :markup="false"
                                        :class="{'is-invalid': errors.has('groups')}">
                                </app-multiple-select-box>

                                <small v-show="errors.has('groups')" class="text-danger">{{ errors.first('groups') }}</small>
                            </div>

                            <div v-show="!form.groups.length">
                                <div class="form-group">
                                    <label for="broadcast_countries" class="">Countries</label>

                                    <app-multiple-select-box
                                            id="broadcast_countries"
                                            name="countries"
                                            data-vv-name="countries"
                                            v-validate="{required: false}"
                                            :value="form.countries"
                                            @select="form.countries = $event"
                                            placeholder="Select countries"
                                            :multiple="true"
                                            :options="countries()"
                                            :internal-search="false"
                                            :markup="false"
                                            :class="{'is-invalid': errors.has('countries')}">
                                    </app-multiple-select-box>
                                    <small v-show="errors.has('countries')" class="text-danger">{{ errors.first('countries') }}</small>
                                </div>

                                <div class="form-group">
                                    <label for="broadcast_types" class="">Provider types</label>

                                    <app-multiple-select-box
                                            id="broadcast_types"
                                            name="provider_types"
                                            data-vv-name="provider_types"
                                            v-validate="{required: false}"
                                            :value="form.provider_types"
                                            @select="form.provider_types = $event"
                                            placeholder="Select provider types"
                                            :multiple="true"
                                            :options="providerTypes()"
                                            :searchable="false"
                                            :markup="false"
                                            :class="{'is-invalid': errors.has('provider_types')}">
                                    </app-multiple-select-box>
                                    <small v-show="errors.has('types')" class="text-danger">{{ errors.first('provider_types') }}</small>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="teams" class="">CC notification to Teams</label>

                                <app-multiple-select-box
                                        id="teams"
                                        name="teams"
                                        data-vv-name="teams"
                                        v-validate="{required: false}"
                                        :value="form.teams"
                                        @select="form.teams = $event"
                                        placeholder="Select teams"
                                        :multiple="true"
                                        :options="teams"
                                        :searchable="false"
                                        :markup="false"
                                        :class="{'is-invalid': errors.has('teams')}">
                                </app-multiple-select-box>
                                <small v-show="errors.has('teams')" class="text-danger">{{ errors.first('teams') }}</small>

                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <!--<button @click.stop="close" class="jba-btn reverse">Cancel</button>-->
                        <button @click.stop="submit" class="jba-btn" :disabled="errors.any() || submitting"><i v-if="submitting" class="fas fa-spinner fa-spin"></i> Send</button>
                    </div>
                </div>
            </div>
        </div>

    </transition>

</template>

<script>
    import { getTeams } from '@/api/teams';
    import { getGroupList } from '@/api/groups';
    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props:{

        },

        data(){
            return {
                editorConfig: {
                    toolbar: [
                        ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript']
                    ],
                    height: 200
                },
                form: {
                    countries: [],
                    provider_types: [],
                    groups: [],
                    teams: []
                },
                submitting: false,
                initialLoad: true,
                keywords: null,
                groups: [],
                teams: []
            }
        },

        inject: ['api', 'countries', 'providerTypes'],

        components: {

        },

        created() {
            this.loadGroups();
            this.loadTeams();
        },

        destroyed() {

        },

        computed: {

        },

        watch: {
            'form.content': function(val, oldVal) {
                // should use better solution to avert displaying error on CK Editor when page is loaded
                if(val && oldVal) {
                    this.initialLoad = false;
                }
            }
        },

        mounted() {
            this.toggleModal('show');
        },

        methods: {
            loadGroups() {

                let self = this;

                getGroupList()
                    .then(function(response){
                        self.groups = response.data;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            loadTeams() {
                let self = this;

                getTeams()
                    .then(function(response){
                        self.teams = response.data;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            close() {
                this.toggleModal('hide');
                this.$router.push({name: 'admin.broadcasts'});
            },

            toggleModal(status) {
                $(this.$el).modal(status);
            },

            onBlur(editor) {

            },

            onFocus(editor) {

            },

            submit() {
                let self = this;
                self.initialLoad = false;
                this.$validator.validateAll().then(function() {

                    if(!self.errors.any()) {

                        let data = {
                            countries: [],
                            provider_types: [],
                            groups: [],
                            teams: [],
                            subject: self.form.subject,
                            content: self.form.content
                        };

                        self.form.countries.forEach(function(o){
                            data.countries.push(o.id);
                        });

                        self.form.provider_types.forEach(function(o){
                            data.provider_types.push(o.id);
                        });

                        self.form.groups.forEach(function(o){
                            data.groups.push(o.id);
                        });

                        self.form.teams.forEach(function(o){
                            data.teams.push(o.id);
                        });

                        self.submitting = true;

                        axios.post(self.api + 'admin/broadcasts', data)
                            .then(function(response) {
                                self.submitting = false;
                                self.$emit('load-broadcasts');
                                self.close();
                                return true;
                            })
                            .catch(function(error){
                                self.submitting = false;
                                self.handleError(error);
                            });
                    }
                });
            }
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss"  scoped>

    .list-group{
        &:first-child .list-group-control:first-child {
            border-top-left-radius: .25rem;
            border-top-right-radius: .25rem;
        }

        &:last-child .list-group-control:last-child {
            border-bottom-left-radius: .25rem;
            border-bottom-right-radius: .25rem;
        }

        &.list-group-flush{
            &:last-child{
                .list-group-control:last-child {
                    border-bottom: 0;
                    margin-bottom: 0;
                }
            }

            &:first-child{
                .list-group-control:first-child {
                    border-top: 0;
                }
            }

            .list-group-control {
                border-right: 0;
                border-left: 0;
                border-radius: 0;
            }
        }

        .list-group-control{
            position: relative;
            display: block;
            margin-bottom: -1px;
            background-color: #fff;
            border: 1px solid rgba(0,0,0,.125);

            .custom-control{
                margin: 0;
                padding: .75rem 1.25rem;
                padding-left: 3rem;
                display: block;

                .custom-control-indicator, .material-control-indicator, .fill-control-indicator, .overflow-control-indicator{
                    top: 1rem;
                    left: 1.25rem;
                }
            }
        }
    }


</style>