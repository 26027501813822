import AdminEvent from '../components/admin/AdminEvent.vue';
import AdminEventSave from '../components/admin/AdminEventSave.vue';
import AdminEventShow from '../components/admin/AdminEventShow.vue';
import AdminVideo from '../components/admin/AdminVideo.vue';
import AdminVideoSave from '../components/admin/AdminVideoSave.vue';

import AdminFeed from '../components/admin/AdminFeed.vue';
import AdminFeedCreate from '../components/admin/AdminFeedCreate.vue';
import AdminBroadcast from '../components/admin/AdminBroadcast.vue';
import AdminBroadcastCreate from '../components/admin/AdminBroadcastCreate.vue';
import AdminDocument from '../components/admin/AdminDocument.vue';
import AdminDocumentSave from '../components/admin/AdminDocumentSave.vue';
import AdminGroup from '../components/admin/AdminGroup.vue';
import AdminGroupEdit from '../components/admin/AdminGroupEdit.vue';
import AdminReport from '../components/admin/AdminReport.vue';
import AdminAgent from '../components/admin/AdminAgent.vue';
import AdminAgentShow from '../components/admin/AdminAgentShow.vue';
import AdminStaff from '../components/admin/AdminStaff.vue';
import AdminStaffTeam from '../components/admin/AdminStaffTeam.vue';
import AdminStaffTeamSave from '../components/admin/AdminStaffTeamSave.vue';
import AdminStaffCreate from '../components/admin/AdminStaffCreate.vue';
import AdminStaffShow from '../components/admin/AdminStaffShow.vue';
import AdminAgentStatistic from '../components/admin/AdminAgentStatistic.vue';
import AdminCourse from '../components/admin/AdminCourse.vue';
import CoursePlay from '../components/course/Play.vue';
import AdminDashboard from '../components/admin/AdminDashboard.vue';
import AdminAgency from '../components/admin/AdminAgency.vue';
import AdminAgencyPending from '../components/admin/AdminAgencyPending.vue';
import AdminAgencyPendingReview from '../components/admin/AdminAgencyPendingReview.vue';
import AdminAgencyPendingReviewMerge from '../components/admin/AdminAgencyPendingReviewMerge.vue';
import AdminAgencyLocationReview from '../components/admin/AdminAgencyLocationReview.vue';

import AdminAcl from '../components/admin/AdminAcl.vue';
import AdminAclRoleRename from '../components/admin/AdminAclRoleRename.vue';
import AdminAclRoleDelete from '../components/admin/AdminAclRoleDelete.vue';

import AdminProfile from '../components/admin/AdminProfile.vue';
import AdminFaq from '../components/admin/AdminFaq.vue';


export default [

    {
        name: 'admin.feeds',
        path: '/feeds',
        component: AdminFeed,
        meta: {permission: ['view@news-feed']},
        props: true,
        children: [
            {
                name: 'admin.feeds.create',
                path: 'create',
                component: AdminFeedCreate,
                meta: {permission: ['create@news-feed']},
                props: true,
                children: [

                ]
            }
        ]
    },

    {
        name: 'admin.broadcasts',
        path: '/feeds/broadcasts',
        component: AdminBroadcast,
        meta: {permission: ['view@message']},
        props: true,
        children: [
            {
                name: 'admin.broadcasts.create',
                path: '/feeds/broadcasts/create',
                component: AdminBroadcastCreate,
                meta: {permission: ['create@message']},
                props: true,
                children: [

                ]
            }
        ]
    },

    {
        name: 'admin.groups',
        path: '/groups',
        component: AdminGroup,
        meta: {breadcrumb: 'Groups List', permission: ['view@group']},
        props: true,
        children: [
            {
                name: 'admin.groups.create',
                path: 'create',
                component: AdminGroupEdit,
                meta: {breadcrumb: 'Group', permission: ['create@group']},
                props: true,
                children: [

                ]
            },

            {
                name: 'admin.groups.show',
                path: ':id(\\d+)',
                component: AdminGroupEdit,
                meta: {breadcrumb: 'Group', permission: ['view@group']},
                props: true,
                children: [

                ]
            }
        ]
    },

    {
        name: 'admin.documents',
        path: '/documents',
        component: AdminDocument,
        meta: {permission: ['view@document']},
        props: true,
        children: [
            {
                name: 'admin.documents.save',
                path: 'save/:id(\\d+)?',
                component: AdminDocumentSave,
                meta: {permission: ['view@document', 'create@document', 'update@document']},
                props: true,
                children: [

                ]
            }
        ]
    },

    {
        name: 'admin.agents',
        path: '/users',
        component: AdminAgent,
        meta: {permission: ['view@agent']},
        props: true,
        children: [
            {
                name: 'admin.agents.show',
                path: ':id(\\d+)',
                component: AdminAgentShow,
                meta: {permission: ['view@agent']},
                props: true
            }
        ]
    },

    {
        name: 'admin.staff',
        path: '/users/staff',
        component: AdminStaff,
        meta: {permission: ['view@staff']},
        props: true,
        children: [
            {
                name: 'admin.staff.show',
                path: ':id(\\d+)',
                component: AdminStaffShow,
                meta: {permission: ['view@staff']},
                props: true,
                children: [

                ]
            },
            {
                name: 'admin.staff.create',
                path: 'create',
                component: AdminStaffCreate,
                meta: {permission: ['create@staff']},
                props: true,
                children: [

                ]
            },
            {
                name: 'admin.staff.teams',
                path: 'teams',
                component: AdminStaffTeam,
                meta: { permission: ['view@team']},
                props: true,

                children: [
                    {
                        name: 'admin.staff.teams.create',
                        path: 'create',
                        component: AdminStaffTeamSave,
                        meta: { permission: ['create@team']},
                        props: true,
                    },
                    {
                        name: 'admin.staff.teams.update',
                        path: ':id(\\d+)',
                        component: AdminStaffTeamSave,
                        meta: { permission: ['update@team']},
                        props: true,
                    }
                ]
            }
        ]
    },

    {
        name: 'admin.agents.statistic',
        path: '/agents/statistic',
        component: AdminAgentStatistic,
        meta: {permission: ['view@agent']},
        props: true
    },

    {
        name: 'admin.events',
        path: '/events/:view(calendar)?',
        component: AdminEvent,
        meta: {breadcrumb: 'Events', permission: ['view@webinar']},
        props: true,
        children: [
            {
                name: 'admin.events.create',
                path: 'create',
                component: AdminEventSave,
                meta: {permission: ['create@webinar']},
                props: true
            },
            {
                name: 'admin.events.show',
                path: ':id(\\d+)',
                component: AdminEventShow,
                meta: {permission: ['view@webinar']},
                props: true
            },
            {
                name: 'admin.events.edit',
                path: ':id(\\d+)/edit',
                component: AdminEventSave,
                meta: {permission: ['update@webinar']},
                props: true
            }
        ]
    },

    {
        name: 'admin.videos',
        path: '/events/share',
        component: AdminVideo,
        meta: {permission: ['create@webinar']},
        props: true,
        children: [
            {
                name: 'admin.videos.create',
                path: 'create',
                component: AdminVideoSave,
                meta: {permission: ['update@webinar']},
                props: true
            },
            {
                name: 'admin.videos.edit',
                path: ':id(\\d+)/edit',
                component: AdminVideoSave,
                meta: {permission: ['update@webinar']},
                props: true
            },
        ]
    },

    {
        name: 'admin.courses',
        path: '/courses',
        component: AdminCourse,
        meta: {permission: ['view@course']},
        props: true,
        children: [

        ]
    },

    {
        name: 'admin.dashboard',
        path: '/dashboard',
        component: AdminDashboard,
        meta: {permission: ['view@dashboard']},
        children: [

        ]
    },

    {
        name: 'admin.reports',
        path: '/dashboard/reports/:id(\\d+)?',
        component: AdminReport,
        meta: {permission: ['view@report']},
        props: true,
        children: []
    },

    /*{
        name: 'admin.agencies',
        path: '/agencies',
        component: AdminAgency,
        meta: {permission: ['view@agency']},
        props: true,
        children: [

        ]
    },*/

    {
        name: 'admin.acl',
        path: '/acl',
        component: AdminAcl,
        meta: {permission: ['view@role']},
        props: true,
        children: [
            {
                name: 'admin.acl.roles.rename',
                path: 'roles/:id(\\d+)',
                component: AdminAclRoleRename,
                meta: {permission: ['update@role']},
                props: true,
                children: [

                ]
            },

            {
                name: 'admin.acl.roles.delete',
                path: 'roles/:id(\\d+)/remove',
                component: AdminAclRoleDelete,
                meta: {permission: ['delete@role']},
                props: true,
                children: [

                ]
            }
        ]
    },

    {
        name: 'admin.agencies.pending',
        meta: {breadcrumb: 'Unverified List', permission: ['view@agency']},
        path: '/agencies/pending',
        component: AdminAgencyPending,
        props: true,
        children: [
            {
                name: 'admin.agencies.pending.review',
                meta: {breadcrumb: 'Review', permission: ['view@agency']},
                path: ':sdId(\\d+)/review',
                component: AdminAgencyPendingReview,
                props: true,
                children: [
                    {
                        name: 'admin.agencies.pending.review.merge',
                        meta: {breadcrumb: 'Merge', permission: ['merge@agency']},
                        path: ':index(\\d+)/merge',
                        component: AdminAgencyPendingReviewMerge,
                        props: true
                    }
                ]
            },
            {
                name: 'admin.agencies.locations.review',
                meta: {breadcrumb: 'Location Review', permission: ['view@agency']},
                path: ':sdId(\\d+)/locations/review',
                component: AdminAgencyLocationReview,
                props: true,
                children: [

                ]
            }

        ]
    },

    {
        name: 'admin.profile',
        path: '/profile',
        component: AdminProfile,
        meta: {permission: null},
        props: true
    },

    {
        name: 'admin.faq',
        path: '/faq',
        component: AdminFaq,
        meta: {permission: null},
        props: true
    },

    {
        name: 'course.play',
        path: 'courses/:id/:sub?/:subId?/play',
        component: CoursePlay,
        meta: {permission: ['view@course']},
        props: true
    },

    {
        path: '/*',
        redirect: '/dashboard'
    },


]
