<template>

    <transition name="transition-modal">
        <div class="modal fade show" data-backdrop="static" data-keyboard="false" id="delete-role-modal" tabindex="-1" role="dialog" aria-labelledby="Rename Role">
            <div class="modal-dialog" role="document" @click.stop="function(){}">
                <div class="modal-content">
                    <div class="modal-header pb-0">
                        <h5>Remove <i class="text-bold fs-110">{{ role.name }}</i> role</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </div>
                    <div class="modal-body pt-1">
                        <form novalidate v-if="!role.readonly">
                            <div class="form-group">
                                <label for="replace">Or replaced by</label>
                                <app-multiple-select-box
                                        id="replace"
                                        name="replace"
                                        data-vv-name="replaced role"
                                        v-validate="{required: false}"
                                        :value="replace"
                                        @select="replace = $event"
                                        :multiple="false"
                                        :options="roles"
                                        :internal-search="false"
                                        :class="{'is-invalid': errors.has('roles')}">
                                </app-multiple-select-box>
                                <small>Please leave it empty if you want to delete this role</small>
                            </div>

                            <div class="text-right">
                                <button @click.stop="submit" class="jba-btn" :disabled="errors.any() || submitting"><i v-if="submitting" class="fas fa-spinner fa-spin"></i> Remove</button>
                            </div>
                        </form>
                        <p v-else>A readonly role is not allowed to update!</p>
                    </div>

                </div>
            </div>
        </div>
    </transition>

</template>

<script>

    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props:{
            id: {
                type: [Number, String],
                default: null
            },
            roles: {
                type: Array,
                default: function(){
                    return [];
                }
            }
        },

        data(){
            return {
                role: {},
                name: null,
                replace: null,
                submitting: false,
            }
        },

        inject: ['api'],

        components: {

        },

        created() {
            this.loadRole();
        },

        destroyed() {

        },

        computed: {

        },

        mounted() {
            this.toggleModal('show');
        },

        methods: {
            loadRole() {
                let self = this;

                axios.get(self.api + 'admin/acl/roles/' + self.id + '/permissions')
                    .then(function(response){
                        self.role = response.data;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    })
            },

            close() {
                this.toggleModal('hide');
                this.$router.push({name: 'admin.acl'});
            },

            toggleModal(status) {
                $(this.$el).modal(status);
            },

            submit() {

                let self = this;

                self.$validator.validateAll().then(function(){

                    if(!self.errors.any()) {

                        self.submitting = true;

                        let url = self.api + 'admin/acl/roles/' + self.id;

                        if(self.replace) {
                            url += '/' + self.replace.id;
                        }

                        axios.delete(url)
                            .then(function(response) {
                                self.submitting = false;
                                self.$emit('load-acl');
                                self.close();
                                return true;
                            })
                            .catch(function(error){
                                self.submitting = false;
                                self.handleError(error);
                            });
                    }
                });
            }
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" >


</style>