import VeeValidate from 'vee-validate';

VeeValidate.Validator.extend('required_no_tag', {
    getMessage: field => 'The ' + field + ' field is required',
    validate(value) {
        let replaced = value.replace(/<.*?>/g, '').replace('&nbsp;', '');
        return !!replaced.length;
    }
});

VeeValidate.Validator.extend('min_no_tag', {
    getMessage(field, args) {
        return 'The ' + field + ' field must be at least ' + args[0] + ' characters';
    },
    validate(value, args) {

        let replaced = value.replace(/<.*?>/g, '').replace('&nbsp;', '');
        if(!replaced.length) {
            return true;
        }
        else {
            return replaced.length >= args[0];
        }
    }
});
