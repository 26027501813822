<template>
    <div id="single-group">
        <div class="row sm-p-2">

            <div class="col-12">
                <!--router-link :to="{name: 'admin.groups'}" class="jba-btn btn-info">Group List</router-link-->
                <app-breadcrumb></app-breadcrumb>
            </div>

            <div class="col-12" style="padding-bottom:0px !important;">
                <div class="border-b border-main d-flex">
                    <h5 class="text-main">Group information</h5>
                </div>
                <div class="form-group">
                    <label for="group_name">Name <span>*</span></label>
                    <input id="group_name" class="form-control" name="name" type="text"
                           v-model="group.name"
                           :class="{'is-invalid': errors.has('name')}"
                           v-validate="{required:true}"/>
                    <small v-show="errors.has('name')" class="text-danger">{{ errors.first('name') }}</small>
                </div>

                <div class="form-group">
                    <label for="group_description">Description</label>
                    <textarea id="group_description" class="form-control"
                              v-model="group.description"></textarea>
                </div>

                <div v-if="group.id && $can('update', 'group')" class="row">
                    <div class="form-group col-12 col-md-9">
                        <div v-show="!group.filters.closed_group">
                            <p>Groups created normally via the Groups page are open groups. Any new agents that register to AgentLab will automatically be added to the group by the system if they fit the scope criteria of the group.</p>
                        </div>
                        <div v-show="group.filters.closed_group">
                            <p>IMPORTANT NOTE: All groups created from Webinar Attendees are closed groups. This means that only agents who had attended the webinar are part of this group. Admin can add agents to the group by individual accounts only and CAN NOT bulk upload to the group via the scope fields.</p>
                        </div>
                    </div>
                    <div class="form-group text-right col-12 col-md-3">
                        <button @click.stop="updateGroupInfo" class="jba-btn"><i v-show="updating" class="fas fa-spinner fa-spin"></i> Update Info</button>
                    </div>
                </div>

            </div>

            <div class="col-12">
                <h5 class="border-b border-main text-main">Add Agents</h5>
            </div>

            <div class="col-12" style="padding-top:0px !important;">
                <button class="jba-btn"
                        :class="{'reverse': addType === 'SINGLE'}"
                        @click="handleAddSingleTabClick">
                    <i v-if="loadingAll" class="fas fa-spinner fa-spin"></i>
                    <i v-else class="fas fa-plus"></i>
                    Add Single
                </button>
                <button class="jba-btn"
                        v-if="!group.filters.closed_group"
                        :class="{'reverse': addType === 'SCOPE'}"
                        @click="handleAddBulkTabClick()">
                    <i class="fas fa-plus"></i> Add Bulk
                </button>
            </div>

            <div v-show="loaded && addType === 'SINGLE'" class="col-12 form-group add-single">
                <small class="col-12 mb-0">Please type the name or email address of the Agent you want to add.</small>
                <div class="row">
                    <div class="col-12 col-sm-7 d-flex">
                        <input id="agent-query" class="form-control agent-query" type="text" @input="debounceInputQuery">
                        <button class="jba-btn reset-btn" @click="resetQuery">Clear</button>
                    </div>
                    <div class="col-12 col-sm-5" style="padding-top:0 !important;padding-bottom:0 !important;line-height: 0.85rem;">
                        <app-reminder :ready="true" :message="queryMessage" :newLine="false"></app-reminder>
                    </div>
                </div>
                <div class="col-12 col-sm-7" style="margin-top:-1rem;">
                    <ul class="agent-query-list" v-if="displayList.length > 0">
                        <li class="add-single-agent" v-for="(a, i) in displayList" :key="i" @click="confirmAddSingleAgent(a)">
                            {{ a.email }}
                            <i class="fas fa-user-plus"></i>
                        </li>
                        <li v-if="agentQueryList.length > 10 && seeAll === false" class="add-single-agent" @click="seeAll = true">
                            See all results
                            <i class="fas fa-plus" style="display:inline"></i>
                        </li>
                        <li v-if="seeAll" class="add-single-agent" @click="seeAll = false">
                            See fewer results
                            <i class="fas fa-minus" style="display:inline"></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div v-show="loaded && addType === 'SCOPE' && !Boolean(group.filters.closed_group)" class="col-12">
                <div class="form-group">
                    <app-advanced-search-bar id="group_scope" ref="advancedSearch" placeholder="Search Agents"
                                             v-if="(!group.id && $can('create', 'group')) || (group.id && $can('update', 'group'))"
                                             :search-button-text="group.id ? 'Update Group' : 'Create Group'"
                                             :filter="group.filters"
                                             :search-handler="setGroupWithScope"
                                             :clear-handler="clear"
                                             :submitting="scoping"></app-advanced-search-bar>
                </div>
            </div>

            <div class="col-12" v-if="loaded && group.id && (tableType === 'MANUAL' || tableType === 'BULK')">
                <h5 class="border-b border-main text-main">Group Agents</h5>
                <ul class="nav nav-tabs">
                    <li class="nav-item mr-1">
                        <a @click="tableType = 'MANUAL', addType = 'SINGLE'"
                           class="nav-link"
                           :class="{'router-link-exact-active': tableType === 'MANUAL'}">
                            Added Individually
                        </a>
                    </li>
                    <li class="nav-item" v-if="!group.filters.closed_group">
                        <a @click="tableType = 'BULK', addType = 'SCOPE'"
                           class="nav-link"
                           :class="{'router-link-exact-active': tableType === 'BULK'}">
                            Added Bulk
                        </a>
                    </li>
                </ul>
                <admin-group-agents-table v-bind="tableProps" />
            </div>
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash';
    import AdminGroupAgentsTable from '@/components/admin/AdminGroupAgentsTable.vue'
    import { getAllAgentsAdmin, getAgentsAdmin } from '@/api/agents';
    import {
        addSingleAgentToGroup,
        createGroup,
        getGroup,
        getGroupAgents,
        getGroupAgentsAddedByManually,
        getAllAgentsNotInGroup,
        toggleGroupAgent,
        toggleGroupAgentAddedByManually,
        updateGroup,
        updateGroupNameAndDescription,
        createGroupWithSingleAgent
    } from '@/api/groups';

    export default {
        props: {
            id: {
                type: [Number, String],
                default: null
            },
        },
        data() {
            return {
                loaded: false,
                updating: false,
                scoping: false,
                group: {
                    agents: {},
                    filters: {},
                    agents_added_by_manually: {}
                },
                tableType: '',
                addType: '',
                agentQuery: '',
                loadingAll: false,
                allAgents: [],
                seeAll: false,
            }
        },
        inject: ['api', 'countries', 'providerTypes', 'appName'],
        components: {
            'admin-group-agents-table': AdminGroupAgentsTable
        },
        created() {
            this.loadGroup();
        },
        destroyed() {

        },
        computed: {
            agentQueryList() {
                // reset seeAll on query change
                this.seeAll = false;
                if(!this.allAgents.length || this.agentQuery.length < 1) {
                    return [];
                } else {
                    return this.allAgents.filter(a => {
                        let q = this.agentQuery.toLowerCase();
                        let email = a.email.toLowerCase();
                        let name = a.full_name.toLowerCase();
                        return (email.includes(q) || name.includes(q));
                    });
                }
            },
            displayList() {
                return this.seeAll ? this.agentQueryList : this.agentQueryList.slice(0, 10);
            },
            queryMessage() {
                if(this.agentQueryList.length === 1) {
                    return '1 agent found';
                } else {
                    return this.agentQueryList.length + ' agents found';
                }
            },
            tableProps() {
                if(!this.loaded) return {};
                if(this.tableType === 'MANUAL') {
                    return {
                        handleSearch: this.queryManualAgents,
                        agentList: this.group.agents_added_by_manually.data,
                        totalAgents: this.group.agents_added_by_manually.total,
                        navigate: this.navigateManual,
                        toggleAgent: this.toggleManualAgentRemoved,
                        perPage: this.group.agents_added_by_manually.per_page,
                        tableMessage: 'There are no individual agents in this group',
                        ready: true,
                    }
                } else if(this.tableType === 'BULK') {
                    return {
                        handleSearch: this.queryBulkAgents,
                        agentList: this.group.agents.data,
                        totalAgents: this.group.agents.total,
                        navigate: this.navigate,
                        toggleAgent: this.toggleAgent,
                        perPage: this.group.agents.per_page,
                        tableMessage: 'There are no bulk agents in this group',
                        ready: true,
                    }
                } else {
                    return {};
                }
            }
        },

        watch: {
        },

        methods: {
            async setGroupWithScope(filterParams) {
                await this.$validator.validateAll();
                if(this.errors.any()) return;

                // merge filter params with group-info.
                // if setting group filter, group must be open
                let data = Object.assign({
                    description: this.group.description,
                    name: this.group.name,
                    closed_group: 0
                }, filterParams);

                this.scoping = true;
                try {
                    // cant create empty group
                    const scopeResult = await getAgentsAdmin(filterParams);
                    if(!scopeResult.data.data.length) {
                        this.scoping = false;
                        this.handleError({
                            message: 'The selected criteria returned no agents. A valid group must contain at least one agent'
                        });
                        return;
                    }
                    if(this.group.id) {
                        const res = await updateGroup(this.group.id, data);
                        this.scoping = false;
                        this.handleNotice('Group updated!');
                        this.group = res.data;
                        this.$emit('load-groups');
                        this.loadGroup();
                    } else {
                        const res = await createGroup(data);
                        this.scoping = false;
                        this.handleNotice('Group created!');
                        this.$emit('load-groups');
                        this.group = res.data;
                        this.tableType = 'BULK';
                        this.$router.push({name: 'admin.groups.show', params: {id: res.data.id}});
                    }
                } catch (err) {
                    this.scoping = false;
                    this.handleError(err);
                }
            },
            async loadGroup() {
                if(!this.id) {
                    this.loaded = true;
                    return;
                }

                try {
                    const res = await getGroup(this.id);

                    if(this.tableType === '') {
                        this.tableType = res.data.agents_added_by_manually.total > 0 ? 'MANUAL' : 'BULK';
                    }

                    if(res.data.filters.closed_group) {
                        // jvb todo: get only selected bulk users in a closed group
                        // hide unselected scoped agents in closed groups
                        // res.data.agents.data = res.data.agents.data.filter(a => a.selected);
                    }
                    this.group = res.data;
                    this.loaded = true;
                    if(this.$refs.advancedSearch) {
                        this.$refs.advancedSearch.initSearch(this.group.filters);
                    }
                } catch (err) {
                    this.handleError(err);
                }
            },
            resetQuery () {
                this.agentQuery = '';
                document.getElementById('agent-query').value = '';
            },
            debounceInputQuery: debounce(function (e) {
                this.agentQuery = e.target.value;
                return;
            }, 175),
            handleAddBulkTabClick () {
                this.addType === 'SCOPE' ? this.addType = '' : this.addType = 'SCOPE';
                this.tableType = 'BULK';
                this.resetQuery();
            },
            async handleAddSingleTabClick () {
                this.resetQuery();
                if(this.addType === 'SINGLE') {
                    this.addType = '';
                    return;
                }
                this.loadingAll = true;
                try {
                    let res;
                    if(this.group.id) {
                        res = await getAllAgentsNotInGroup(this.group.id);
                    } else {
                        res = await getAllAgentsAdmin();
                    }
                    this.allAgents = res.data;
                    this.loadingAll = false;
                    this.addType = 'SINGLE';
                    this.tableType = 'MANUAL';
                    setTimeout(() => {
                        document.getElementById('agent-query').focus();
                    }, 10);
                    return;
                } catch (err) {
                    this.loadingAll = false;
                    this.handleError(err);
                }
            },
            async confirmAddSingleAgent(agent) {
                const swalContent = document.createElement('div');
                swalContent.innerHTML = `<img src="${agent.avatar}" class="img-fluid img-circle" />`
                    + `<p><b>${agent.full_name}</b></p>`
                    + `<p>${agent.email}</p>`;
                const swalButtons = {
                    cancel: 'Cancel',
                    confirm: this.group.id ? 'Confirm' : 'Create Group'
                };
                const answer = await swal({
                    title: 'Add Agent',
                    content: swalContent,
                    buttons: swalButtons
                });

                if(!answer) {
                    // this.resetQuery(); maintain single agent search on cancel add
                    return;
                }
                try {
                    if(this.group.id) {
                        const res = await toggleGroupAgentAddedByManually(this.group.id, agent.id);
                        this.handleNotice('Agent added!');
                        this.resetQuery();
                        agent.selected = 1;
                        this.group.agents_added_by_manually.data.push(agent);
                        this.group.agents_added_by_manually.total++;
                        this.group.agents.data = this.group.agents.data.filter(a => a.id !== agent.id);
                        this.group.agents.total--;
                        this.allAgents = this.allAgents.filter(a => a.id !== agent.id);
                        this.$emit('load-groups');
                    } else {
                        let data = {
                            name: this.group.name,
                            description: this.group.description,
                            agent_id: agent.id,
                            closed_group: false,
                            group_created_by_manually: true
                        };
                        const res = await createGroupWithSingleAgent(data);
                        this.handleNotice('Group created!');
                        this.resetQuery();
                        this.allAgents = this.allAgents.filter(a => a.id !== agent.id);
                        this.group = res.data;
                        this.tableType = 'MANUAL';
                        this.$emit('load-groups');
                        this.$router.push({name: 'admin.groups.show', params: {id: res.data.id}});
                    }
                } catch (err) {
                    this.handleError(err);
                }
            },
            async updateGroupInfo () {
                if(!this.group.id) return;
                await this.$validator.validateAll();
                if(this.errors.any()) return;
                this.updating = true;
                try {
                    let data = {
                        name: this.group.name,
                        description: this.group.description
                    };
                    const res = await updateGroupNameAndDescription(this.group.id, data);
                    this.updating = false;
                    this.handleNotice('Group updated!');
                    this.$emit('load-groups');
                } catch (err) {
                    this.updating = false;
                    this.handleError(err);
                }
            },
            // todo: remove duplicate functions...
            async toggleAgent(index) {
                this.resetQuery();
                if(!this.group.id) return;
                if(index >= this.group.agents.data.length || index < 0) return;
                let agent = this.group.agents.data[index];

                try {
                    const response = await toggleGroupAgent(this.group.id, agent.id);
                    let notice;
                    if(agent.selected) {
                        notice = 'Agent added!';
                        this.allAgents.filter(a => a.id != agent.id);
                    } else {
                        notice = 'Agent removed!';
                        this.allAgents.push(agent)
                    }
                    this.handleNotice(notice);
                    this.$emit('load-groups');
                } catch (err) {
                    this.handleError(err);
                }
            },
            async toggleManualAgentRemoved(index) {
                if(!this.group.id) return;
                this.resetQuery();
                if(index >= this.group.agents_added_by_manually.data.length || index < 0) return;
                let agent = this.group.agents_added_by_manually.data[index];
                try {
                    await toggleGroupAgentAddedByManually(this.group.id, agent.id);
                    this.handleNotice('Agent removed!');
                    this.group.agents_added_by_manually.data = this.group.agents_added_by_manually.data.filter(a => a.id !== agent.id);
                    this.group.agents_added_by_manually.total--;
                    this.group.agents.data.push(agent);
                    this.group.agents.total++;
                    this.allAgents.push(agent);
                    this.$emit('load-groups');
                } catch (err) {
                    this.handleError(err);
                }
            },
            // async queryBulkAgents (query) {
            //     if(!this.group.id) return;
            //     try {
            //         let params = { q: query };
            //         const res = await getGroupAgents(this.group.id, params)
            //         this.group.agents = res.data;
            //     } catch (err) {
            //         this.handleError(err);
            //     }
            // },
            // async queryManualAgents (query) {
            //     if(!this.group.id) return;
            //     try {
            //         let params = { q: query };
            //         const res = await getGroupAgentsAddedByManually(this.group.id, params)
            //         this.group.agents_added_by_manually = res.data;
            //     } catch (err) {
            //         this.handleError(err);
            //     }
            // },
            async navigate(page) {
                if(!this.group.id) return;
                try {
                    let params = { page: page };
                    const response = await getGroupAgents(this.group.id, params);
                    this.group.agents = response.data;
                    this.page = this.group.agents.current_page;
                    return;
                } catch (err) {
                    this.handleError(err);
                }
            },
            async navigateManual(page) {
                if(!this.group.id) return;
                try {
                    let params = { page: page };
                    const res = await getGroupAgentsAddedByManually(this.group.id, params)
                    this.group.agents_added_by_manually = res.data;
                    return;
                } catch (err) {
                    this.handleError(err);
                }
            },
            async clear() {
                const r = await getGroup(this.id);
                this.$refs.advancedSearch.initSearch(r.data.filters);
            },
        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .add-single {
        border: 1px solid var(--light-white-color);
        background: var(--bg-light-color);
    }
    .agent-query-list {
        list-style: none;
        border: 1.5px solid var(--main-color);
        border-radius: 3px;
        box-shadow: var(--box-shadow-color) 2px 2px 5px;
    }
    .add-single-agent {
        cursor: pointer;
        padding: 5px 10px;
        border-radius: 3px;
        border-top: solid 1px var(--light-grey-color);
        > i {
            float: right;
            display: none;
            margin-top: 4px;
        }
    }
    .add-single-agent:hover {
        background: var(--light-grey-color);
        > i {
            display: inline;
        }
    }
    .reset-btn {
        height: 37.5px;
        border-radius: 0 0.25rem 0.25rem 0;
    }
    .agent-query {
        border-radius: 0.25rem 0 0 0.25rem;
    }
    .nav-link {
        cursor:pointer;
    }
    .router-link-exact-active {
        color: var(--main-color) !important;
    }
    // TOGGLE: https://www.w3schools.com/howto/howto_css_switch.asp
    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: var(--main-color);
    }

    input:focus + .slider {
        box-shadow: 0 0 1px var(--main-color);
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
    .grey-btn {
        color: grey;
        border-color: grey;
        background-color: #fff;
    }
</style>