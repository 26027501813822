<template>
    <div class="privacy-policy">
        <div class="row fixed-content-width">
            <div class="col-12">
                <h2 class="mt-4">AgentLab - Privacy Policy</h2>
                <p class="mb-5">This website is owned and maintained by Education New Zealand, a Crown Entity funded by the New Zealand Government.</p>

                <h4>Copyright</h4>
                <p class="mb-1">Unless otherwise indicated, copyright in all material available on this site are owned by Education New Zealand.
                    Except as set out in these terms you may reproduce the material on this site free of charge without further
                    permission, as long as you:</p>

                <ul class="ml-5">
                    <li>Reproduce the material accurately;</li>
                    <li>Do not use the material in a derogatory manner or a misleading context;</li>
                    <li>Acknowledge the source and copyright status of the material. </li>
                </ul>

                <p class="mb-3">Unless we give you written permission you may not reproduce, adapt, copy, distribute or incorporate in
                    any other work, in whole or in part, any trade mark appearing on this site, including any Education
                    New Zealand logos or trade marks that appear on this site (including the 'Education New Zealand' logo
                    and the 'New Zealand Educated' logo).</p>

                <h4>Linked Sites</h4>

                <p class="mb-3">This website may contain links to third party websites that are not under the control of ENZ.
                    ENZ does not endorse, and is not responsible in any way for any information, content or material
                    that is available on third party websites, or the privacy practices of any such third party and its website. </p>

                <h4>ENZ Digital Privacy Policy</h4>

                <p class="mb-1">We are committed to complying with the Privacy Act 1993 (New Zealand) and the EU General Data Protection Regulation 2016/679
                    (<strong>GDPR</strong>) when collecting, using or dealing with any of your personal information. 
                    <br/>Like many organisations we collect information about our users for the purposes of:</p>
                <ul class="ml-5 mb-1">
                    <li>Providing you with services that we offer.</li>
                    <li>Improving our digital platforms, marketing material and strategies, and to provide you with a better experience when you use our digital
                        platforms and when we provide you our services. </li>
                    <li>Giving you a personalised user experience. </li>
                    <li>Conducting research and other statistical and data analysis associated with our functions</li>
                </ul>
                <p class="mb-3">We collect information about you when you communicate with us, register an ENZ account,
                    visit or use this site or any other online platform, service, application, website or messaging service that we
                    subscribe to or use (each being a <strong>Digital Platform</strong>). </p>

                <h6 class="mt-2">What personal information do we collect about you? </h6>
                <p class="mb-1">If you register an ENZ account we may collect personal information about you including your name, email address,
                    nationality, country of residence, address, telephone, company name, company website, what education providers
                    you are working with (if any) and immigration advisor license</p>
                <p class="mb-1">We may also collect information relevant to our Digital Platforms, our marketing strategies and our services,
                    including information on: </p>
                <ul class="ml-5 mb-1">
                    <li>IP addresses </li>
                    <li>Search terms</li>
                    <li>Pages accessed and links clicked</li>
                    <li>Dates and times of visits </li>
                    <li>The immediate previous site visited </li>
                    <li>Operating systems (eg Windows, Mac,etc) </li>
                    <li>Web browsers</li>
                    <li>Internet activity, whether on this website or any other </li>
                </ul>
                <p class="mb-3">Where we use this information for statistical purposes, all users are anonymous and not personally identifiable from the information. </p>

                <h6 class="mt-2">How do we use your personal information? </h6>
                <p class="mb-1">Personal information collected through any Digital Platform or the 'Register', 'Contact Us' and any other form on this website
                    will be collected by Education New Zealand and will be used to provide you with any service that you have requested,
                    for our legitimate interests, or in accordance with your consent, including for the purposes of: </p>

                <ul class="ml-5">
                    <li>Providing your personal information to Education New Zealand's agents, peak body groups, education providers and institutions to facilitate communications between you and Education New Zealand, and/or its agents, education providers and institutions.</li>
                    <li>Providing you with Education New Zealand's communications, and marketing and promotional information and materials about Education New Zealand, its programmes and activities, and its agents, peak body groups, education providers and institutions (unless you have opted out of such service).</li>
                    <li>For research, surveys, and other statistical or analytical purposes in connection with our functions.</li>
                    <li>Meeting any of our legal or other regulatory obligations. </li>
                </ul>

                <h6 class="mt-2">Use of cookies</h6>
                <p class="mb-1">A cookie is a small amount of data that is sent to your browser from a web server and stored on your computer's hard drive, then sent back to the server by your browser
                    each time you access certain sections of our Digital Platforms. </p>
                <p class="mb-1">Like many organisations, Education New Zealand uses cookies to store and track information about you when you are on our Digital Platforms
                    so that we can provide personalised services that better match your needs and interests. We also use cookies, including Google Analytics
                    for the Advertising Features Remarketing with Google Analytics and Google Analytics Demographics and Interest Reporting and DoubleClick
                    cookies, to collect information on how you use the website. </p>

                <p class="mb-1">Third parties including Google Analytics use this information to perform statistical analysis of user behaviour and demographics.
                    We may use this analysis to understand the key characteristics of visitors to any Digital Platform on an anonymous and aggregate
                    level which assists us to improve and personalise your experience with Education New Zealand. We also use the information provided
                    by third parties including the Google Analytics Remarketing service to better target our online advertising including based on your
                    previous visits to any Digital Platform, and to present you with relevant advertising on third party websites. Education New Zealand
                    may disclose the information collected by cookies, in an aggregate (not personal) form only, to Google and third parties including
                    advertisers or potential advertisers. </p>

                <p class="mb-1">If you do not want information to be collected by cookies there is a simple procedure in web browsers which allows you to manually disable the cookie function.
                    If you want to know how to do this, please look at the help menu on your browser. Additionally, you can modify your privacy settings to choose how your personal
                    information is managed by Google. For more information please <a href="https://policies.google.com/privacy" target="_blank">see here</a>.</p>

                <p class="mb-3">Education New Zealand uses a number of Digital Platforms that are operated by third party platform providers to interact with you, including social media platforms
                    (for example Facebook). When you visit one of our social media pages, the platform providers may also collect your information (this is outside Education New Zealand’s control).
                    Collection and use of this information will be governed by the terms and conditions of that platform, including its privacy policy. Please refer to the relevant websites for
                    more information</p>

                <h6 class="mt-2">Munchkin Cookie</h6>
                <p class="mb-1">We embed a Munchkin Javascript snippet on our site. When visitors come to a site, we place a cookie in the visitor's web browser (if there wasn't already one) and send messages
                    to our servers about that visitor's web activity. It is a first party cookie and is only visible for us. </p>
                <p class="mb-1">Munchkin automatically gathers the following information:</p>
                <ul class="ml-5 mb-1">
                    <li>Page Visits</li>
                    <li>Link Clicks</li>
                    <li>IP Address</li>
                    <li>Referrer</li>
                    <li>Our cookie ID</li>
                </ul>
                <p class="mb-3">If you prefer that we do not place this cookie on your web browser you may opt out by clicking the Do Not Track (DNT)
                    feature in your browser. This prevents tracking for your particular browser and device.</p>

                <h6 class="mt-2">Who will we share your personal information with and why</h6>
                <p>We may share information that we collect about you in accordance with, and for the purposes set out in these terms, and we may share information about you with: </p>
                <ul class="ml-5 mb-1">
                    <li>Education New Zealand's agents, education providers and institutions if you have completed any forms or submitted any enquiries connected to that third party and consented to such disclosure. </li>
                    <li>Our service providers that we have engaged to provide a service to us in relation to our functions, this website or any service that we have agreed to provide you including NetValue, SpeakData,
                        OMD, and JBA. For example, we may share information about you with a service provider that we have engaged to provide technology services or customer support services. </li>
                    <li>Any other organisation or person that you have authorised us to disclose your information to. </li>
                </ul>
                <p class="mb-1">Information we share with our agents, education providers and institutions will be processed, stored and secured by the relevant organisation in accordance with their
                    own privacy policy. These agents, education providers and institutions are located either within the EU (agents) or New Zealand (education providers or institutions).
                    Before you consent to us disclosing your personal information to our agents, education providers or institutions we recommend that you review their privacy policies
                    and agree to the terms on which they will process your personal information. </p>

                <p class="mb-3">Any personal information that we disclose to our third party service providers to enable them to perform a service for us will be processed in accordance with
                    our obligations under the Privacy Act 1993 and the principles of the GDPR. Where any personal information is to be transferred outside of the EU or New Zealand for
                    the purpose of processing, we will ensure that appropriate technical and organisational measures are in place to ensure the third party service provider protects the
                    data to the same standard as required under the GDPR (e.g. the information will be transferred to a country with adequate privacy laws, to an organisation who is
                    certified under the US/EU Privacy Shield, or we will enter into standard contractual clauses (as approved by the European Commission) with those service providers. 
                    We may disclose information about you if, and to the extent that, we are required to do so by law, or at the request of a government agency that has authority to
                    compel us to disclose information about you. </p>

                <h6 class="mt-2">Security</h6>
                <p class="mb-3">We have in place reasonable security safeguards to protect your personal information from loss, unauthorised access, use, modification, disclosure or
                    other misuse.  However, given the nature of the internet, we do not make any warranties in relation to the security of any information you disclose or transmit
                    to us and we are not responsible for the theft, destruction, or inadvertent disclosure of your personal information where our security measures have been breached.</p>

                <h6 class="mt-2">Retention of personal information</h6>
                <p class="mb-3">We only keep your personal information for as long as required to provide you with the services you have requested, for the purposes outlined in this privacy policy,
                    and for any legal purposes for which we are required to keep the information (i.e. audit purposes). </p>

                <h6 class="mt-2">Your acceptance of these terms </h6>
                <p class="mb-1">By registering an ENZ account, submitting information to Education New Zealand (including the ‘Register’ or by completing or submitting an enquiry form,
                    'Contact Us' or/and any other form on this website) or by using, or communicating with us through any other Digital Platform, you agree to the collection,
                    use and disclosure of your information as described above. </p>

                <p class="mb-1">If you do not agree to the terms of this policy, you will not be able to register an ENZ account, Education New Zealand will not accept your form or enquiry,
                    and your information will not be processed or referred on to Education New Zealand’s agents, education providers and institutions. </p>

                <p class="mb-1">Where you have given your consent to specific purposes of processing, you may withdraw your consent at any time by any of the following means: </p>
                <ul class="ml-5 mb-3">
                    <li>To stop receiving marketing and promotional information and materials about Education New Zealand, its programmes and activities, you can click the
                        unsubscribe facility in any of the electronic communications that we send you.</li>
                    <li>To opt out of the use of cookies or other profiling/analytic tools, you can change the settings on your device. </li>
                    <li>To close your ENZ Account you can contact us at the details set out below and we will do this for you. </li>
                </ul>

                <h6 class="mt-2">Your rights</h6>
                <p class="mb-1">You have the right to view and change any personal information Education New Zealand holds about you at any time.</p>
                <p class="mb-1">You have the right to object to the processing of your information, request that we delete your personal information,
                    and/or that we transfer your personal information to another organisation. </p>
                <p class="mb-1">Information will be held by Education New Zealand. You may request access to, correction or deletion of your personal information held by Education New Zealand
                    by writing to our Data Protection Officer at Level 5 Lambton House, 160 Lambton Quay. PO Box 12041, Wellington 6144, New Zealand , or by contacting Angela Meredith on
                    +64 4 913 9597 or <a href="mailto:angela.meredith@educationnz.govt.nz. ">angela.meredith@educationnz.govt.nz</a>.</p>
                <p class="mb-3">If there is a reason under the Privacy Act, GDPR or other applicable law for us not to comply with your request, we will notify you in writing setting out the
                    reasons for the refusal (except to the extent it would be unreasonable or unlawful to do so) and advise you of the mechanisms available to you to complain about our response. 
                    For more information about your rights or to make a complaint about our privacy practices you may contact either the Office of the Privacy Commissioner (New Zealand)
                    or your local supervisory authority. </p>

                <h4>Additional Terms relating to Registration of an ENZ Account </h4>
                <p class="mb-1">You can choose to create an ENZ Account to collate and store information relating to your student application. </p>
                <p class="mb-1">It is your responsibility to maintain the confidentiality and security of any information that may be used to access your ENZ Account, including your password. </p>
                <p class="mb-1">ENZ reserves the right at its discretion to suspend, modify or terminate access to your ENZ Account at any time with or without notice to you. </p>
                <p class="mb-1">Any information that you rely on through use of an ENZ Account is entirely at your own risk. You are solely responsible for the actions you take in
                    reliance of the information available on or accessed through your ENZ Account.</p>
                <p class="mb-5">ENZ Accounts are provided on an "as is" and "as available" basis. ENZ does warrant that ENZ Accounts will be free from errors, timely or secure. </p>
            </div>

        </div>
    </div>
</template>

<script>

    export default {
        name: 'AgentPrivacy',

        data() {
            return {

            }
        },

        inject: [],

        components: {
        },

        created() {

        },

        computed: {

        },

        methods: {

        },

        mounted() {
            this.loaded = true;
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>