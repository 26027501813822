<template>
    <div id="event">
        <!-- <app-page-banner page="event" title="Webinar" :subtitle="screenShareExtension"></app-page-banner> -->

        <div class="row sm-p-2">

            <div class="col-12">
                <ul class="nav nav-tabs">
                    <li class="nav-item mr-1" v-if="$can('view', 'webinar')">
                        <router-link class="nav-link" :class="{active: isRoutePattern('^admin\.events')}" :to="{name: 'admin.events'}"><span>Scheduled</span></router-link>
                    </li>
                    <li class="nav-item" v-if="$can('create', 'webinar')">
                        <router-link class="nav-link" :class="{active: isRoutePattern('^admin\.videos')}" :to="{name: 'admin.videos'}"><span>Recorded</span></router-link>
                    </li>
                </ul>
            </div>

            <div class="col-12">
                <router-link :to="{name: 'admin.events.create', params: {view: view}}" class="jba-btn btn-info d-none d-sm-inline-block" v-if="$can('create', 'webinar')">Schedule webinar</router-link>
                <router-link :to="{name: 'admin.events'}" class="float-right view-icon"><i class="far fa-list-alt" title="List View"></i></router-link>
                <router-link :to="{name: 'admin.events', params: {view: 'calendar'}}" class="float-right view-icon" title="Calendar View"><i class="fas fa-calendar-alt"></i></router-link>
                <!--<router-link :to="{name: 'admin.videos'}" class="float-right view-icon" v-if="$can('create', 'webinar')" title="Recorded Webinar"><i class="fas fa-share-alt-square"></i></router-link>-->
            </div>

            <div class="col-12 d-block d-sm-none">
                <router-link :to="{name: 'admin.events.create', params: {view: view}}" class="jba-btn btn-info" v-if="$can('create', 'webinar')">Schedule webinar</router-link>
            </div>
        </div>

        <!--<div class="row">
            <div class="col-12">
                <app-reminder always reminder-class="alert-info" message="The following date time is displayed as New Zealand Time">
                    <template v-slot:prepend-icon>
                        <i class="fas fa-info-circle"></i> 
                    </template>
                </app-reminder>
            </div>
        </div>-->

        <div v-if="!loaded" class="page-loading"></div>

        <div class="row" v-else-if="loaded && calendarView">
            <div class="col-12">

                <full-calendar
                    :events="calendarData"
                    defaultView="month"
                    @event-selected="eventSelected"
                ></full-calendar>
            </div>
        </div>

        <div class="row" v-else-if="loaded && !calendarView">

            <div class="col-12 col-sm-4">
                <h5 class="text-main mb-0">Event list</h5>
            </div>

            <div class="col-12 col-sm-8">
                <app-search-bar placeholder="Search webinars" :search-handler="search" :clear-handler="clear" ref="keywords"></app-search-bar>
            </div>

            <div class="col-12" v-show="pageCount > 1">
                <paginate :page-count="pageCount"
                          :page-range="5"
                          :click-handler="navigate"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :container-class="'pagination float-right'"
                          :page-class="'page-item'"
                          v-model="page"
                ></paginate>
            </div>

            <div class="col-12 mt-3">
                <table class="table table-hover" v-if="events.total">
                    <thead>
                        <tr class="row">
                            <th class="col-10 col-sm-3 col-md-2 col-lg-2">Title</th>
                            <th class="col-2 d-block d-sm-none"></th>
                            <th class="col-sm-5 col-md-2 col-lg-3 d-none d-sm-inline-block">Description</th>
                            <th class="col-md-1 col-lg-1 d-none d-md-inline-block">Date</th>
                            <th class="col-md-1 col-lg-1 d-none d-md-inline-block">Local Time</th>
                            <th class="col-md-2 col-lg-2 d-none d-md-inline-block">New Zealand Time</th>
                            <th class="col-md-1 col-lg-1 d-none d-md-inline-block">Duration</th>
                            <th class="col-sm-4 col-md-3 col-lg-2 d-none d-sm-inline-block text-right">Status</th>
                        </tr>
                    </thead>

                    <tbody>
                        <template  v-for="(e, index) in events.data">
                            <!--<router-link :key="'router-link'+index" tag="tr" :to="{name: e.editable ? 'admin.events.edit' : 'admin.events.show', params: {id: e.id, view: this.view}}" class="clickable row">-->
                            <tr :key="index" class="row">
                                <td class="col-10 col-sm-3 col-md-2 col-lg-2"><p class="m-0 text-truncate">{{ e.name }}</p></td>
                                <td class="col-2 d-block d-sm-none text-center" @click.stop="expandRow($event)"><i class="fas fa-plus"></i></td>
                                <td class="col-sm-5 col-md-2 col-lg-3 d-none d-sm-inline-block overflow-hidden">{{ e.description }}</td>
                                <td class="col-md-1 col-lg-1 d-none d-md-inline-block">{{ e.started_at | convertDate('DD MMMM, YYYY') }}</td>
                                <td class="col-md-1 col-lg-1 d-none d-md-inline-block">{{ e.started_at | convertDate('HH:mm') }}</td>
                                <td class="col-md-2 col-lg-2 d-none d-md-inline-block">{{ e.started_at | convertDate('HH:mm', 'Pacific/Auckland') }}</td>
                                <td class="col-md-1 col-lg-1 d-none d-md-inline-block">{{ e.duration }} minutes</td>
                                <td class="col-sm-4 col-md-3 col-lg-2 d-none d-sm-inline-block text-right">
                                    <b v-if="e.cancelled">Cancelled</b>
                                    <router-link :to="{name: 'admin.events.show', params: {id: e.id, view: view}}"
                                    class="jba-btn btn-xs live-btn"
                                    v-else-if="canStartEvent(e)">
                                        Ready
                                    </router-link>
                                    <router-link class="jba-btn btn-xs" v-else
                                    :to="{name: 'admin.events.show', params: {id: e.id, view: view}}">
                                        View
                                    </router-link>

                                </td>
                            </tr>
                            <!--</router-link>-->

                            <tr :key="'tr'+index" class="row expand" style="display: none;">
                                <td class="col-12 d-block d-sm-none" colspan="2">
                                    <ul class="list-unstyled">
                                        <li>Description: <p>{{ e.description }}</p></li>
                                        <li>Date: {{ e.started_at | convertDate('DD MMMM, YYYY') }}</li>
                                        <li>Time: {{ e.started_at | convertDate('HH:MM') }}</li>
                                        <li>Duration: {{ e.duration }} minutes</li>
                                        <li>Recording: {{ e.archived ? 'Enabled' : 'Disabled' }}</li>
                                        <li>
                                            <router-link class="jba-btn btn-xs" v-if="canUpdateEvent(e)"
                                            :to="{name: 'admin.events.edit', params: {id: e.id, view: view}}">
                                                Update
                                            </router-link>
                                            <router-link :to="{name: 'admin.events.show', params: {id: e.id, view: view}}"
                                            class="jba-btn btn-xs live-btn"
                                            v-else-if="canStartEvent(e)">
                                                Ready
                                            </router-link>
                                            <router-link class="jba-btn btn-xs" v-else
                                            :to="{name: 'admin.events.show', params: {id: e.id, view: view}}">
                                                View
                                            </router-link>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>

                <app-reminder :ready="loaded" :message="message" :result="events.data"></app-reminder>
            </div>

            <div class="col-12" v-show="pageCount > 1">
                <paginate :page-count="pageCount"
                          :page-range="5"
                          :click-handler="navigate"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :container-class="'pagination float-right'"
                          :page-class="'page-item'"
                          v-model="page"
                ></paginate>
            </div>
        </div>

        <router-view @load-event="navigate(page)"></router-view>
    </div>


</template>

<script>

    import { FullCalendar } from 'vue-full-calendar';
    import 'fullcalendar/dist/fullcalendar.css';
    import { getAdminEvents } from '@/api/events'

    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props:{
            view: {
                type: String,
                default: null
            }
        },

        data(){
            return {
                groups: [],
                events: {
                    data: []
                },
                page: 1,
                submitting: false,
                loaded: false,
                message: 'There is no event at the moment'
            }
        },
        inject: ['api'],

        components: {
            FullCalendar: FullCalendar
        },

        created() {
            // fetch some data for your component on initialization, especially if you need that data during server-side rendering.
            // you will be able to access reactive data and events are active. Templates and Virtual DOM have not yet been mounted or rendered.

            this.loadEvents();
            const SEC = 10;
            this.eventCountdown = setInterval(() => {
                this.events.data = this.events.data.map(e => {
                    if(e.endSeconds && e.endSeconds - SEC > 0) { // tick end seconds
                        e.endSeconds -= SEC;
                    } else {
                        e.endSeconds = null;
                    }
                    if(e.startSeconds && e.startSeconds - SEC > 0) { // tick start seconds
                        e.startSeconds -= SEC;
                    } else {
                        e.startSeconds = null;
                    }
                    return e;
                });
            }, SEC * 1000);
        },
        destroyed() {
            clearInterval(this.eventCountdown);
        },

        watch: {
            'view': function(val, oldVal){
                this.loadEvents();
            }
        },
        methods: {
            canUpdateEvent(e) { // is before event start and can edit
                return e.startSeconds && e.endSeconds && e.editable && this.$can('update', 'webinar');
            },
            canStartEvent(e) { // is within 10min of event start or during event
                const within10min = e.startSeconds <= 10 * 60;
                return e.endSeconds && (e.valid || within10min) && this.$can('create', 'webinar');
            },
            expandRow(e) {
                let expand = $(e.currentTarget).closest('tr').next('.expand');

                expand.toggle();

                if(expand.is(":visible")) {
                    $(e.currentTarget).find('.fa-plus').addClass('fa-minus').removeClass('fa-plus');
                }
                else {
                    $(e.currentTarget).find('.fa-minus').addClass('fa-plus').removeClass('fa-minus');
                }

            },

            loadEvents(params = {}) {

                let self = this;

                if(self.view == 'calendar') {
                    params.item_per_page = 0;
                }

                getAdminEvents(params)
                .then(function(response){

                    if(self.view !== 'calendar') {
                        self.events = response.data;
                        self.page = self.events.current_page;
                    }
                    else {
                        self.events.data = response.data;
                    }

                    self.loaded = true;
                })
                .catch(function(error){
                    self.loaded = true;
                    self.handleError(error);
                })
            },

            eventSelected(e){

                if(this.$can('update', 'webinar')) {

                    let event;

                    $.each(this.events.data, function (k, v) {
                        if (v.id === e.id) {
                            event = v;
                            return true;
                        }
                    });

                    if (event) {
                        let name = event.editable ? 'admin.events.edit' : 'admin.events.show';
                        this.$router.push({name: name, params: {id: e.id, view: this.view}});
                    }
                }
            },

            navigate(page) {

                let params = {page: page};

                if(!this.calendarView && this.$refs.keywords.value) {
                    params.q = this.$refs.keywords.value;
                }

                this.loadEvents(params);
            },

            search(keywords) {

                this.loadEvents({q: keywords});

            },

            clear() {
                this.navigate(1);
            }
        },
        computed: {

            calendarView() {
                return this.view === 'calendar';
            },

            // screenShareExtension() {
            //     if(this.isBrowser('chrome')) {
            //         return "View and schedule webinars. Please note: To use the share screen function please install <a target='_blank' class='link' href='https://chrome.google.com/webstore/detail/enz-screen-sharing/hcdjakbbhphfaaaoohedjjgmimkembli'>the extension</a> for Chrome";
            //     }
            //     else {
            //         return 'Sharing screen function is only available in Chrome browser.';
            //     }
            // },
            calendarData() {

                let data = [];

                let self = this;

                $.each(self.events.data, function(k, v){

                    let color, textColor;

                    if(v.valid) {
                        color = 'green';
                        textColor = 'white';
                    }
                    else if(v.closed_at) {
                        color = 'grey';
                        textColor = 'black';
                    }
                    else if(moment().utc().diff(v.started_at) < 0) {
                        color = '#3a87ad'; // future event
                        textColor = 'white';
                    }
                    else {
                    //    color = '#22a2a2';
                        color = '#8c8c8c';
                        textColor = 'white';
                    }

                    data.push({
                        id: v.id,
                        title: v.name,
                        start: self.$options.filters.convertDate(v.started_at, 'YYYY-MM-DD HH:mm:ss'),
                        end: self.$options.filters.convertDate(v.end_at, 'YYYY-MM-DD HH:mm:ss'),
                        color: color,
                        textColor: textColor
                    });
                });

                return data;
            },

            pageCount(){
                return Math.ceil(this.events.total / this.events.per_page);
            }
        },
        mounted() {
            // Use this hook if you need to access or modify the DOM of your component immediately before or after the initial render.
            // you will have full access to the reactive component, templates, and rendered DOM (via. this.$el)
            // often to integrate non-Vue libraries.

            let self = this;

            self.loadGroupList().then(function (response) {
                response.data.forEach(function(g){
                    self.groups.push({
                        'id': g.id,
                        'name': g.name + ' (' + g.agent_count + ')'
                    });
                });

            }, function (error) {
                self.handleError(error);
            });

        }
    }
</script>
<style rel="stylesheet/scss" lang="scss"  scoped>
    .live-btn {
        background-color: var(--enz-red-color);
        border: unset;
    }
    .live-btn:not([disabled]):hover {
        background-color: var(--enz-red-hover-color);
        border: unset;
    }

    .view-icon {
        width: 50px;
        text-align: center;

        i {
            color: var(--main-color);
            background: inherit;
            font-size: 2em;
        }

        &.router-link-exact-active {
            i {
                color: var(--gray);
            }
        }
    }
</style>