<template>
    <div id="agent-event">
        <app-page-banner page="agent-event" title="Webinars" subtitle="Any scheduled webinars that you have been invited to will appear here."></app-page-banner>


        <div :class="{'fixed-content-width': $root.fixedContentWidth }">

            <div class="row sm-p-2">
                <div class="col-12">

                    <ul class="nav nav-tabs">
                        <li class="nav-item mr-1">
                            <router-link class="nav-link" :to="{name: 'agent.events'}"><span>Scheduled</span></router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name: 'agent.videos'}"><span>Recorded</span></router-link>
                        </li>
                    </ul>
                </div>

            </div>

            <div v-if="!loaded" class="page-loading"></div>

            <div v-else class="row">

                <div class="col-sm-12">
                    <app-search-bar placeholder="Search webinars" :search-handler="search" :clear-handler="clear" ref="keywords"></app-search-bar>
                </div>

                <div class="col-12" v-show="pageCount > 1">
                    <paginate :page-count="pageCount"
                              :page-range="5"
                              :click-handler="navigate"
                              :prev-text="'<'"
                              :next-text="'>'"
                              :container-class="'pagination float-right'"
                              :page-class="'page-item'"
                              v-model="page"
                    ></paginate>
                </div>

                <div class="col-12 my-2" v-if="events.total">

                    <table class="table table-hover">
                        <tbody>
                            <tr v-for="(e, index) in eventList" :key="index">

                                <td>
                                    <div class="wrap-link-details d-flex">
                                        <span class="left-border"></span>
                                        <div class="w-100">
                                            <h5 class="mb-0">{{ e.name }}</h5>
                                            <p class="mb-1">{{ e.description }}</p>
                                            <p v-if="!e.closed_at" class="mb-1">
                                                Start time: {{ e.started_at | convertDate('hh:mm A on DD MMMM, YYYY') }}

                                                <span class="badge badge-pill fs-90 py-1 px-3 font-weight-normal" :class="{'badge-primary': e.isSoon, 'badge-secondary': !e.isSoon}">{{getStatus(e)}}</span>
                                            </p>
                                            <p v-if="!e.closed_at" class="mb-1">
                                                New Zealand Time: {{ e.started_at | convertUTCToNZDate('hh:mm A on DD MMMM, YYYY') }}
                                            </p>

                                            <p v-else class="mb-1">Cancelled date: {{ e.closed_at | convertDate }}</p>

                                            <div v-if="e.password">
                                                <p class="mb-1" for="copy-content">Zoom password</p>
                                                <div class="copy-to-clipboard alert alert-info fs-120 p-0">
                                                    <input type="text" :id="'copy-content-' + e.id" readonly class="form-control rounded-0 pr-5" :value="e.password" />
                                                    <i id="copy-to-clipboard" class="fas clickable fs-110 copy-to-clipboard" :class="{'fa-copy': !copied[e.id], 'fa-clipboard-check': copied[e.id]}" @click.stop.prevent="copyPassword(e.id)"></i>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <!--<a v-if="eventIsValid(e)" class="jba-btn my-2" :href="e.zoom_url" target="_blank">Join Zoom Webinar</a>-->
                                    <button v-if="eventIsValid(e)" class="jba-btn my-2" @click="handleJoinWebinar(e)">
                                        Join Zoom Webinar
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                <app-reminder :ready="loaded" message="You currently have no webinars scheduled." :new-line="true" :result="eventList"></app-reminder>

                <div class="col-12" v-show="pageCount > 1">
                    <paginate :page-count="pageCount"
                              :page-range="5"
                              :click-handler="navigate"
                              :prev-text="'<'"
                              :next-text="'>'"
                              :container-class="'pagination float-right'"
                              :page-class="'page-item'"
                              v-model="page"
                    ></paginate>
                </div>

            </div>
        </div>
        <router-view @loadEvent="loadEvents"></router-view>
    </div>
</template>

<script>
    import 'slick-carousel/slick/slick.css';
    import {
        saveEventAttendee,
        getAgentEvents,
    } from '@/api/events';

    export default {

        props: {

        },

        data() {
            return {
                events: {},
                eventList: [],
                page: 1,
                slickOptions: {
                    slidesToShow: 2,
                    prevArrow: '<button class="slick-prev"></button>',
                    nextArrow: '<button class="slick-next"></button>',
                    responsive: [{
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 2,
                            infinite: true
                        }

                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1
                        }
                    }]
                },
                copied: []
            }
        },

        inject: ['api','auth'],

        components: {

        },

        created() {
            this.loadEvents();
            const SEC = 10;
            this.eventCountdown = setInterval(() => {
                this.eventList = this.eventList.map(e => {
                    if(e.endSeconds && e.endSeconds - SEC > 0) { // tick end seconds
                        e.endSeconds -= SEC;
                    } else {
                        e.endSeconds = null;
                    }
                    if(e.startSeconds && e.startSeconds - SEC > 0) { // tick start seconds
                        e.startSeconds -= SEC;
                    } else {
                        e.startSeconds = null;
                        e.isSoon = false;
                    }
                    return e;
                });
            }, SEC * 1000);
        },
        destroyed() {
            clearInterval(this.eventCountdown);
        },


        computed: {
            pageCount(){
                return Math.ceil(this.events.total / this.events.per_page);
            },
        },

        methods: {
            eventIsValid(e) {
                return e.endSeconds && !e.startSeconds;
            },
            setEventList () {
                // sort events
                const valid = [];
                const soon = [];
                const rest = [];
                for(let event of this.events.data) {
                    let e = this.calcEventIsSoon(event);
                    if(e.valid) {
                        valid.push(e);
                    } else if(e.isSoon) {
                        soon.push(e);
                    } else {
                        rest.push(e);
                    }
                }

                this.eventList = valid.concat(soon.reverse(), rest);
            },
            getSoonText (e) {

                if(e.startSeconds) {
                    // turn start seconds to hrs + mins
                    let txt = '';
                    const hrs = Math.floor(e.startSeconds / 3600);
                    const mins = Math.round(e.startSeconds % 3600 / 60);
                    if(hrs > 0) txt += `${hrs}hrs `;
                    if(mins > 0) txt += `${mins}mins`;
                    if(!txt.length) return 'less than 1 minute';
                    return txt;
                }
            },
            getStatus(e) {

                if(e.isSoon) {
                    return 'Begins in ' + this.getSoonText(e);
                }

                if(e.closed_at) {
                    return 'Cancelled';
                }

                if(e.startSeconds) {
                    return 'Not Ready';
                }

                if(!e.startSeconds && e.endSeconds) {
                    return 'In progress';
                }

                if(!e.endSeconds) {
                    return 'Ended';
                }

                /*if(e.startSeconds && e.startSeconds > 0) return 'Not Ready';
                if(e.closed_at) return 'Cancelled';
                if(!e.endSeconds && !e.startSeconds) return 'Ended';*/
                return 'Disabled'; //default case..
            },
            // event is less than 24hrs from starting
            calcEventIsSoon (e) {

                let dayOfEvent = moment.utc(e.started_at).subtract(1, 'days');
                let startDate = moment.utc(e.started_at);
                let now = moment();
                e.isSoon = now.isBetween(dayOfEvent, startDate);
                return e;
            },

            handleJoinWebinar(e) {
                if(!e.endSeconds) {
                    return;
                }

                saveEventAttendee(e.id, this.auth().id)
                    .then(res => {
                        //this.confirmJoinWebinar(res.data.zoom_url);
                        window.location.replace(res.data.zoom_url);
                    })
                    .catch(err => {
                        this.handleError(err);
                    });
            },

            onPlayerPlay(player, id){
                axios.get(this.api + 'events/archives/' + id + '/play')
                    .then(function(response) {

                    })
                    .catch(function(error) {

                    })
            },

            loadEvents(){

                let self = this;

                getAgentEvents()
                    .then(function(response) {
                        self.events = response.data;
                        self.loaded = true;
                        self.setEventList();
                    })
                    .catch(function(error){
                        self.loaded = true;
                        self.handleError(error);
                    });
            },

            search(keywords) {
                let self = this;
                let params = { q: keywords };

                getAgentEvents(params)
                    .then(function(response){
                        self.events = response.data;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            clear() {
                this.navigate(1);
            },

            navigate(page) {
                let self = this;

                let params = {
                    page: page,
                    q: this.$refs.keywords.value || ''
                };

                getAgentEvents(params)
                    .then(function(response){
                        self.events = response.data;
                        self.page = self.events.current_page;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            copyPassword(id) {
                let copySource = document.querySelector('#copy-content-' + id);
                copySource.setAttribute('type', 'text');
                copySource.select();

                try {
                    let successful = document.execCommand('copy');
                    if(successful) {
                        this.copied[id] = true;
                    }
                    else {
                        this.handleError('Unable to copy, please press "Ctrl" + "C" to copy manually');
                    }
                } catch (err) {
                    this.handleError(err);
                }

            }
        },
    }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .evt-btn {
        font-size: 20px;
        margin: auto;
        cursor: pointer;
        &:disabled {
            background-color: grey;
            opacity: 0.6;
        }
    }
    .live {
        background-color: var(--enz-red-color);
        border: unset;
    }
    .live:not([disabled]):hover {
        background-color: var(--enz-red-hover-color);
        border: unset;
    }
    .card {
        flex-direction: row;
        box-shadow: var(--box-shadow-color) 2px 2px 5px;
    }
    .copy-to-clipboard {
        position: relative;
        max-width: 500px;

        input {
            background: transparent;
            border: none;
        }
        i {
            position: absolute;
            top: 0;
            right: 0;
        }

    }
</style>