<template>
</template>

<script>
    import { logout } from '@/api/auth';
    export default {
        name: "AutoLogout",

        props: {
            lifetime: {
                type: Number,
                required: true
            }
        },

        data: function(){
            return {
                events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],

                warningAdvance: 30,
                warningTimer: null,
                //logoutTimer: null,
                warningZone: false
            }
        },

        inject: ['auth'],

        mounted() {

            if(this.lifetime) {
                this.events.forEach(function (event) {
                    window.addEventListener(event, this.resetTimer);
                }, this);

                this.setTimers();
            }
        },

        destroyed() {
            if(this.lifetime) {
                this.events.forEach(function (event) {
                    window.removeEventListener(event, this.resetTimer);
                }, this);

                this.resetTimer();
            }
        },

        methods: {
            setTimers: function() {

                if(this.lifetime <= this.warningAdvance) {
                    this.handleError({message: 'The lifetime of AutoLogout must be greater than ' + this.warningAdvance});
                }
                else {
                    this.warningTimer = setTimeout(this.warningMessage, (this.lifetime - this.warningAdvance) * 1000);
                    //this.logoutTimer = setTimeout(this.logoutUser, this.lifetime * 1000);

                    this.warningZone = false;
                }
            },

            warningMessage: function() {
                let self = this;
                self.warningZone = true;

                let timerInterval;

                const starting = moment().unix();
                const swalContent = document.createElement('div');

                swalContent.innerHTML = `<p style="text-align:left;margin-bottom: 1em;">Your session will time out in <b></b> seconds.</p> `
                    + `<p style="text-align:left;margin-bottom: 1em;">Once timed out you will be logged off and you will lose any unconfirmed details for your security. To keep the session click Continue.</p>`;

                timerInterval = setInterval(() => {
                    const b = swalContent.querySelector('b');
                    const timerLeft = self.warningAdvance + starting - moment().unix();
                    if (b && (timerLeft >= 0)) {
                        b.textContent = timerLeft;
                    }
                }, 1000);

                swal({
                    title: '',
                    content: swalContent,
                    timer: self.warningAdvance * 1000,
                    button: 'Continue',
                    timerProgressBar: true,
                }).then((result) => {
                    timerInterval = null;
                    if(result || (moment().unix() < starting + self.warningAdvance)) {
                        clearInterval(timerInterval);
                        self.warningZone = false;
                        self.resetTimer();
                    }
                    else {
                        self.logoutUser();
                    }

                });
            },

            logoutUser: function() {
                if(this.isAdmin()) {
                    logout()
                        .then(function (response) {
                            window.location.replace('/login');
                        })
                        .catch(function (error) {
                            window.location.replace('/login');
                        });
                }
                else {
                    window.location.replace('/auth0/logout');
                }
            },

            resetTimer: function() {
                if(!this.warningZone) {
                    clearTimeout(this.warningTimer);
                    //clearTimeout(this.logoutTimer);

                    this.setTimers();
                }
            }
        }
    }
</script>