import request from '@/api/request';

export function parseZoomInvite (zoomInviteStr) {
  return request({
    url: `admin/events/parse/webinar/invitation`,
    method: 'POST',
    data: { zoomInviteStr: zoomInviteStr }
  });
}

export function createGroupFromEventAttendees (eventId, data) {
  return request({
    url: `admin/events/${eventId}/create-group-from-attendees`,
    method: 'POST',
    data: data
  });
}

export function saveEventAttendee (eventId, userId) {
  return request({
    url: `events/${eventId}/attended/${userId}`,
    method: 'GET'
  });
}

export function getEventById (eventId) {
  return request({
    url: `admin/events/${eventId}`,
    method: 'GET'
  });
}

export function cancelEvent (eventId) {
  return request({
    url: `admin/events/${eventId}/close`,
    method: 'PUT'
  });
}

export function updateEvent (eventId, data = {}) {
  return request({
    url: `admin/events/${eventId}`,
    method: 'PUT',
    data: data
  });
}

export function createEvent (data = {}) {
  return request({
    url: `admin/events`,
    method: 'POST',
    data: data
  });
}

export function getAgentEvents (params = {}) {
  return request({
    url: `agent/events`,
    method: 'GET',
    params: params
  });
}

export function getAdminEvents (params = {}) {
  return request({
    url: 'admin/events',
    method: 'GET',
    params: params
  })
}
