import request from '@/api/request';

export function getTeams() {
    return request({
        url: `admin/teams`,
        method: 'GET'
    });
}

export function getOneTeam(teamId) {
    return request({
        url: `admin/teams/${teamId}`,
        method: 'GET'
    });
}

export function saveTeam(payload, teamId = null) {
    if(teamId) {
        return request({
            url: `admin/teams/${teamId}`,
            method: 'PUT',
            data: payload
        });
    }
    else {
        return request({
            url: `admin/teams`,
            method: 'POST',
            data: payload
        });
    }
}

export function deleteTeam(teamId) {
    return request({
        url: `admin/teams/${teamId}`,
        method: 'DELETE'
    });
}