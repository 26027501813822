import Vue from 'vue';
import VueRouter from 'vue-router';
import { abilitiesPlugin } from '@casl/vue';
import { Can } from '@casl/vue';
import ability from '../ability.js';
import admin from './admin';
import agent from './agent';
import limitedAgentRoutes from './limitedAgentRoutes';

Vue.use(VueRouter);
Vue.component('Can', Can);

export default class Router {

    constructor(el = '#app', type = 'agent') {
        this.el = el;
        this.type = type;
    }

    generate() {

        let router;

        let limitedAgent = $('#app').find('.wrapper').data('limited-agent');
        if(limitedAgent) {
            router = new VueRouter({
                routes: limitedAgentRoutes
            });
            return router;
        }

        switch(this.type) {

            case 'admin': {
                router = new VueRouter({
                    routes: admin
                });

                ability.update(JSON.parse(atob( $('#app').find('.wrapper').data('ability') )));

                Vue.use(abilitiesPlugin, ability);

                router.beforeEach((to, from, next) => {

                    if(to.meta.permission) {

                        for(let i = 0; i < to.meta.permission.length; i++) {

                            let perm = to.meta.permission[i].split('@');

                            if (ability.can(perm[0], perm[1])) {
                                next();
                                return;
                            }

                        }

                        next('/profile');
                        return;
                    }

                    next();
                });

                break;
            }
            case 'agent': {
                router = new VueRouter({
                    routes: agent
                });

                break;
            }
            default: {
                //console.error('Invalid vue route type');
            }
        }

        return router;
    }

}