<template>
    <div class="agent-profile">
        <app-page-banner page="agent-profile" title="My Profile" ></app-page-banner>

        <div v-if="!loaded" class="page-loading"></div>
        <div v-else :class="{'fixed-content-width': $root.fixedContentWidth }">
            <div class="row">
                <div class="col-12">

                    <ul class="nav nav-tabs mb-2">
                        <li class="nav-item mr-1">
                            <router-link class="nav-link" :to="{name: 'agent.profile'}"><span>Profile</span></router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name: 'agent.setting'}"><span>Settings</span></router-link>
                        </li>
                    </ul>
                </div>

                <div class="col-12">

                    <app-reminder :always="true" reminder-class="alert-warning" message="The following data is read only in AgentLab, please update your profile in MaiENZ, and the updated content will take effect after you logout.">
                        <template slot="prepend-icon"><i class="fa fa-info-circle mr-1"></i> </template>
                    </app-reminder>

                    <div class="card mb-3">
                        <app-avatar-update
                                class="mb-3"
                                :avatar="profile.avatar"
                                :first-name="profile.first_name"
                                :last-name="profile.last_name"
                                :immigration-adviser-badge="profile.immigration_adviser_badge"
                                :immigration-adviser="profile.immigration_adviser"
                                :recognized-badge="profile.recognized_badge"
                                :recognized-at="profile.recognized_at"
                                :updatable="false"
                        ></app-avatar-update>

                        <div class="row">
                            <div class="col-12" v-if="courseBadge.length">
                                <div class="course-badge">
                                    <h5 class="border-b mt-3 border-main text-main">Course badge</h5>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item text-center m-2"  v-for="(c, index) in courseBadge" :key="index" :title="c.name">
                                            <img class="img-fluid" :src="c.status.badge"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-12">
                                <h5 class="border-b mt-3 border-main text-main">Personal Information</h5>
                            </div>

                            <div class="col-12 col-sm-6">
                                <fieldset>
                                    <legend>First name</legend>
                                    <p class="mb-0">{{ profile.first_name }}</p>
                                </fieldset>
                            </div>

                            <div class="col-12 col-sm-6">
                                <fieldset>
                                    <legend>Last name</legend>
                                    <p class="mb-0">{{ profile.last_name }}</p>
                                </fieldset>
                            </div>

                            <div class="col-12 col-sm-6">
                                <fieldset>
                                    <legend>Email address</legend>
                                    <p class="mb-0">{{ profile.email }}</p>
                                </fieldset>
                            </div>

                            <div class="col-12 col-sm-6">
                                <fieldset>
                                    <legend>Which agency do you work for?</legend>
                                    <p class="mb-0">{{ profile.organization.name }}</p>
                                </fieldset>
                            </div>

                            <div class="col-12 col-sm-6">
                                <fieldset>
                                    <legend>Education sectors I am interested in</legend>
                                    <p class="mb-0">
                                        <span class="badge badge-primary m-1" v-for="(i, index) in profile.interests" :key="index">{{ i.name }}</span>
                                    </p>
                                </fieldset>
                            </div>

                            <div class="col-12 col-sm-6">
                                <fieldset>
                                    <legend>Country I am located in</legend>
                                    <p class="mb-0">{{ profile.country.name }}</p>
                                </fieldset>
                            </div>

                            <div class="col-12 col-sm-6">
                                <fieldset>
                                    <legend>Immigration adviser license</legend>
                                    <p class="mb-0">{{ profile.immigration_license }}</p>
                                </fieldset>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getProfile } from '@/api/auth';
    import { putNotificationSetting } from '@/api/agents';
    export default {
        name: 'AgentProfileShow',

        props: {

        },

        data() {
            return {
                profile: {
                    avatar: '',
                    newsletter: 0,
                    notification: 0,
                    course_modules: [],
                    organization: {},
                    country: {},
                    office: {}
                },

                sectors: [],
                submitting: false,
                loadingOffice: false,
                currentType: null,
                offices: []
            }
        },

        inject: ['api', 'auth', 'languages', 'countries', 'providerTypes', 'organizations'],

        created() {
            this.loadProfile();
        },

        watch: {

        },

        computed: {

            courseBadge() {
                let self = this;
                return self.profile.course_modules.filter(function (module) {
                    return !!module.status.badge;
                })
            }

        },

        methods: {

            loadProfile() {

                let self = this;

                getProfile()
                    .then(function(response) {

                        self.initModel(response.data);
                        self.loaded = true;
                    })
                    .catch(function(error) {
                        self.handleError(error);
                    })
            },

            /*updateNotification(val) {
                putNotificationSetting({notification: val})
                    .then(response => {

                    })
                    .catch(error => {
                        this.handleError(error);
                    });
            },*/

            initModel(data) {

                let self = this;

                $.each(data, function(k, v){

                    switch(k) {
                        case 'email':
                        case 'first_name':
                        case 'last_name':
                        case 'avatar':
                        case 'course_modules':
                        case 'immigration_adviser':
                        case 'immigration_license':
                        case 'immigration_adviser_badge':
                        case 'recognized_badge': {
                            self.profile[k] = v;
                            break;
                        }
                        case 'office': {
                            self.profile.office = v || {};
                            break;
                        }
                        case 'country': {
                            self.profile.country = v || {};
                            break;
                        }
                        case 'organization': {
                            self.profile.organization = v || {};
                            self.offices = self.profile.organization.offices || [];

                            break;
                        }
                        case 'interests': {
                            if(v.length) {
                                self.profile.interests = v;
                            }
                            break;
                        }
                        case 'prompt': {
                            self.profile.newsletter = v & 4;
                            self.profile.notification = v & 16;
                            break;
                        }

                        default: {

                            break;
                        }
                    }
                });
            }
        },

        mounted() {

            let self = this;
            self.$root.$on('avatar-updated', function(data) {
                self.profile.avatar = data;
            });

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">
    fieldset {
        padding: 0 9px 9px 9px;
        border: 1px solid var(--enz-silver-color);
        border-radius: 3px;
        margin: 10px 0;
        min-height: 58px;
        legend {
            font-size: 0.9em;
            padding: 0 3px;
            width: auto;
            margin: 0;
        }
    }
</style>