<template>
    <div class="ribbon" :class="componentClass">
        <span>{{ text }}</span>
    </div>
</template>

<script>
    export default {
        props: {
            text: {
                type: String,
                default: 'Stick'
            },
            componentClass: {
                type: String,
                default: 'main'
            }

        },
    }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>