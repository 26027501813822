<template>

    <div class="course-module">
        <div class="row">
            <div class="col-12 col-sm-8">
                <h4>{{ course.name }}</h4>
            </div>
            <div class="col-12 col-sm-4 text-right">
                <button class="jba-btn" @click="startCourse()" v-text="course.checkpoint ? 'RESUME COURSE' : 'START COURSE'"></button>
                <!--<router-link v-elseif="course.checkpoint" :to="{name: 'course.play', params: {id: course.id}, query: {checkpoint: course.checkpoint, token: auth().api_token}}" class="jba-btn">RESUME COURSE</router-link>
                <router-link v-else :to="{name: 'course.play', params: {id: course.id}, query: {token: auth().api_token}}" class="jba-btn">START COURSE</router-link>-->
            </div>

        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="(m, index) in course.modules" :key="index">

                <div v-if="m.game_module > 0" class="card clickable" @click="startModule(m.game_module)">

                    <ul class="list-inline" :class="showModuleStatus(m).replace(' ', '-')">
                        <li class="list-inline-item">{{ showModuleStatus(m).toUpperCase() }}</li>
                        <li class="list-inline-item">{{ showModuleScore(m) }}</li>
                    </ul>

                    <img class="img-responsive" :src="m.image" :alt="m.name">
                    <div class="card-body p-3">
                        <h6 class="card-title text-truncate">
                            {{ m.name }}
                            <img v-if="m.module_pass" :src="m.badge" class="module-badge pull-right"/>
                            <img v-else :src="m.gray_badge" class="module-badge pull-right"/>
                        </h6>
                        <p class="card-text" v-if="m.description" v-html="m.description"></p>
                    </div>
                </div>

                <div v-else class="card">
                    <ul class="list-inline" :class="showModuleStatus(m).replace(' ', '-')">
                        <li class="list-inline-item">COMING SOON</li>
                        <li class="list-inline-item">{{ showModuleScore(m) }}</li>
                    </ul>

                    <img class="img-responsive" :src="m.image" :alt="m.name">
                    <div class="card-body p-3">
                        <h6 class="card-title text-truncate">
                            {{ m.name }}
                            <img v-if="m.module_pass" :src="m.badge" class="module-badge pull-right"/>
                            <img v-else :src="m.gray_badge" class="module-badge pull-right"/>
                        </h6>
                        <p class="card-text" v-if="m.description" v-html="m.description"></p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    export default {

        props:{

            course: {
                type: Object,
                required: true
            }
        },

        data(){
            return {
                //    course: {},
                loaded: false
            }
        },

        inject: ['api', 'auth'],

        created() {
        },

        methods: {

            startCourse() {

                let self = this;

                if(!self.isMobileDevice()) {

                    let query = {token: self.auth().api_token};

                    if(self.course.checkpoint) {
                        query.checkpoint = self.course.checkpoint;
                    }

                    self.$router.push({name: 'course.play', params: {id: self.course.id}, query: query});

                }
                else {
                    self.startCourseOnMobile();
                }
            },

            startModule(game_module) {

                let self = this;

                if(!self.isMobileDevice()) {
                    self.$router.push({
                        name: 'course.play',
                        params: {id: self.course.id, sub: 'modules', subId: game_module},
                        query: {checkpoint: self.course.checkpoint, token: self.auth().api_token}
                    });
                }
                else {
                    self.startCourseOnMobile();
                }
            },

            startCourseOnMobile() {

                let self = this;

                if(self.isMobileDevice()) {
                    /*let text = 'Open this page in "' + (self.isIOS() ? 'App Store' : 'Google Play') + '"?';
                    self.handleConfirm({
                        text: ' ',
                        warning: text,
                        type: 'info'
                    }, function () {
                        let destination = self.isIOS() ? self.course.ios_download : self.course.android_download;
                        window.location.replace(destination);
                    });*/

                    let destination = self.isIOS() ? self.course.ios_download : self.course.android_download;
                    window.location.replace(destination);
                }
                else {
                    self.startCourse();
                }
            },

            showModuleStatus(module) {
                switch(module.module_status) {
                    case 2: {
                        return 'in progress';
                    }
                    case 4: {

                        return module.module_pass ? 'passed' : 'failed';
                    }
                    default: {
                        return 'new';
                    }
                }
            },

            showModuleScore(module) {
                return module.module_score ? module.module_score + '%' : 'TO PASS ' + module.pass_score + '%';
            }

        },

        computed: {

        },

        mounted() {

        }
    }

</script>