import request from '@/api/request';

export function list() {
  return request({
    url: `admin/faqs`,
    method: 'GET'
  });
}


export function agentList() {
  return request({
    url: `agent/faqs`,
    method: 'GET'
  });
}

export function create(faq) {
  return request({
    url: `admin/faqs`,
    method: 'POST',
    data: faq
  });
}

export function update(faq) {
  return request({
    url: `admin/faqs/${faq.id}`,
    method: 'PUT',
    data: faq
  });
}

export function move(faq, direction) {
  return request({
    url: `admin/faqs/${faq.id}/move/${direction}`,
    method: 'PUT'
  });
}

export function remove(faq) {
  return request({
    url: `admin/faqs/${faq.id}`,
    method: 'DELETE'
  });
}
