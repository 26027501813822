import request from '@/api/request';

export function agentLogout() {
    return request({
        url: `auth0/logout`,
        baseURL: '/',
        method: 'GET'
    });
}

export function logout() {
    return request({
        url: `logout`,
        baseURL: '/',
        method: 'POST'
    });
}

export function getProfile() {
    return request({
        url: `profile`,
        method: 'GET'
    });
}

