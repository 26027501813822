<template>

    <nav aria-label="breadcrumb" v-if="$route.matched">
        <ol class="breadcrumb">
            <li class="breadcrumb-item" v-for="(e, index) in $route.matched" :class="{active: e.name == $router.currentRoute.name}">
                <router-link class="list-inline-item" :to="{name: e.name}">{{ e.meta.breadcrumb }}</router-link>
            </li>
        </ol>
    </nav>
</template>

<script>
    export default {
        props: {

        },
        data() {
            return {

            }
        },

        components: {

        },

        created() {

        },

        destroyed() {

        },

        computed: {

        },

        methods: {

        },

        mounted() {

        }
    }
</script>