<template>
  <div class="card border-top border-bottom-0 border-right-0 border-left-0">
    <div class="card-header bg-white border-0 px-0">
      <div class="container-fluid px-0">
        <div class="row align-items-center">
          <div class="col-sm-12 col-md-11 col-lg-11 px-0">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-4 px-sm-2 pl-md-0 pl-lg-2 text-center text-md-left">
                <span class="heading-input text-main xlarge-font mb-0" v-if="!edit">{{data.title}}</span>
                <form novalidate class="update-section" @submit.prevent.stop="onClickSave" v-else>
                  <div class="input-group">
                    <input
                      type="text"
                      name="title"
                      v-validate="{required: true, min: 3, max: 100, regex: /^[A-Za-z0-9_\- ]+$/}"
                      class="heading-input form-control text-main xlarge-font mb-2 mb-md-0"
                      v-model.trim="data.title">
                  </div>
                  <small v-show="errors.has('title')" class="text-danger">{{ errors.first('title') }}</small>
                </form>
              </div>
              <div class="col-12 col-md-6 px-2 d-flex align-items-center justify-content-center justify-md-content-left" v-if="$can('update', 'faq')">
                <button class="jba-btn mx-1" @click="onClickEdit" v-if="!edit">Edit</button>
                <button class="jba-btn reverse mx-1" @click="onClickSave" v-else>Save</button>
                <button class="jba-btn txlarge wauto mx-1" @click="onClickUp"><i class="fas fa-angle-up"></i></button>
                <button class="jba-btn txlarge wauto mx-1" @click="onClickDown"><i class="fas fa-angle-down rotate-icon"></i></button>
                <button class="jba-btn btn-danger mx-1" @click="onClickDelete" v-if="$can('delete', 'faq')">Delete</button>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-1 col-lg-1 align-content-center pr-md-0">
            <a
              ref="toggle"
              class="text-main toggle text-center"
              data-toggle="collapse"
              data-parent="#accordion"
              :href="'#collapse-'+data.id"
              aria-expanded="true"
              :aria-controls="'#collapse-'+data.id"
              @click="toggle = !toggle">
              <i class="fas" :class="{'fa-angle-down': !toggle, 'fa-angle-up': toggle}"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div :id="'collapse-' + data.id" class="collapse row" role="tabpanel" :aria-labelledby="'header-' + data.id" data-parent="#accordion">
      <div class="card-body p-0 faq-body">
        <div v-if="!loaded" class="page-loading"></div>
        <div class="container-fluid px-2 py-3" v-else>
          <div class="row">
            <div class="col-12 px-0" v-if="edit">
              <editor
                :id="'editor-' + faq.id"
                type="classic"
                name="content"
                class="mb-2"
                :config="config"
                v-validate="{required_no_tag: true, min_no_tag: 10}"
                v-model="data.content"></editor>
                <small v-show="errors.has('content')" class="text-danger">{{ errors.first('content') }}</small>
            </div>
            <div class="col-12 px-0" v-else-if="!edit && data.content !== null" v-html="data.content"></div>
            <div class="col-12 px-0" v-else>
              <p class=""><em>Content Yet to be added</em></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  name: 'admin-faq-item',

  props: {
    faq: {
      type: Object
    }
  },

  inject: ['api', 'auth', 'appName'],

  data () {
    return {
      data: {},
      config: {
        toolbar: [
          ['Format'],
          ['Bold', 'Italic', 'Link', 'NumberedList', 'BulletedList', 'Blockquote', 'FontSize', 'TextColor']
        ],
        colorButton_colors: '22A2A2,800000,8B4513,2F4F4F,008080,000080,4B0082,696969,' +
                            'B22222,A52A2A,DAA520,006400,40E0D0,0000CD,800080,808080,' +
                            'F00,FF8C00,FFD700,008000,0FF,00F,EE82EE,A9A9A9',
        height: 200
      },
      toggle: false,
      edit: false,
      loaded: true
    }
  },

  components: {
  },

  watch: {
    faq (faq) {
      this.data = faq
      this.loaded = true
    }
  },

  methods: {
    onClickSave () {
      let self = this
      this.$validator.validateAll().then(function() {
        if(!self.errors.any()) {
          self.$emit('save-section', self.data)
          self.edit = false
        }
      })
    },
    onClickDelete () {
      this.$emit('delete-section', this.data)
    },
    onClickUp () {
      this.loaded = false
      this.$emit('move-up-section', this.data)
    },
    onClickDown () {
      this.loaded = false
      this.$emit('move-down-section', this.data)
    },
    onClickEdit () {
      this.edit = !this.edit
      if (!this.toggle) {
        this.$refs.toggle.click()
      }
    }
  },

  mounted () {
    this.data = this.faq
  }
}
</script>

<style lang="scss" scoped>
.heading-input {
  font-size: 2em !important;
  border-radius: 0;
}
.card {
  border-radius: 0;
}
.fa-angle-down:before {
  vertical-align: middle;
}
.wauto {
  min-width: auto !important;
}
a.toggle {
  font-size: 40px;
  display: block;

  i {
    display: block;
  }

  .fas:hover,
  .fas:active,
  .fas:visited {
    color: var(--main-color);
    background-color: #fff;
  }
}
</style>
