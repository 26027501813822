<template>
    <div>
        <v-tour name="appTour" :steps="vTourSteps" :callbacks="vTourCallbacks" :options="vTourOptions"></v-tour>
        <app-tour-complete ref="tourComplete"></app-tour-complete>
    </div>

</template>

<script>

    export default {

        props:{

        },

        data(){
            return {
                tourStep: 0,
            }
        },

        inject: ['auth'],

        created() {
        },

        methods: {

        },

        computed: {
            vTourSteps: function(e){

                let placement = this.isSmallScreen() ? 'top' : 'left';

                return [
                    {
                        target: '#agent-home-page',
                        content: 'Features within the hub include the Home page where you will see a snippet of the latest news from New Zealand along with campaign updates from Education New Zealand.',
                        params: {
                            placement: placement
                        }
                    },

                    {
                        target: '#agent-course-page',
                        content: 'On the Courses page you’ll find engaging, modern learning modules about everything from the New Zealand education system through to our commitment to student wellbeing.',

                        params: {
                            placement: placement
                        }
                    },
                    {
                        target: '#agent-news-page',
                        content: 'The Newsfeed will give you the latest relevant education news direct from New Zealand.',

                        params: {
                            placement: placement
                        }
                    },
                    {
                        target: '#agent-document-page',
                        content: 'The Documents page is where you will find more detailed information about the New Zealand Education system that you can refer to.',

                        params: {
                            placement: placement
                        }
                    },
                    {
                        target: '#agent-event-page',
                        content: 'Within the Webinar section you will be able to connect with people from the New Zealand education industry about areas of interest to you.',

                        params: {
                            placement: placement
                        }
                    },
                    {
                        target: '#agent-message-page',
                        content: 'Message is where you will receive updates and notifications directly from Education New Zealand.',

                        params: {
                            placement: placement
                        }
                    }
                ]
            },

            vTourOptions: function() {
                return {
                    labels: {
                        buttonSkip: 'End tour',
                        buttonPrevious: 'Previous',
                        buttonNext: 'Next',
                        buttonStop: 'Finish'
                    }
                };
            },

            vTourCallbacks: function() {
                let self = this;
                return {
                    'onStop': function(){
                        if(self.tourStep >= self.vTourSteps.length - 1) {
                            self.$refs.tourComplete.show();
                            /*self.updatePrompt('tour')
                                .then(function (response) {
                                    self.auth().prompt_tour = false;
                                }, function (error) {
                                    self.handleError(error);
                                });*/
                        }

                    },
                    'onNextStep': function(currentStep) {
                        if(self.tourStep < currentStep + 1) {
                            self.tourStep = currentStep + 1;
                        }
                    }
                }
            }
        },

        mounted() {
        }
    }

</script>

<style rel="stylesheet/scss" lang="scss">
    /*.v-step__buttons {
        .v-step__button {
            &:nth-child(1) {
                display: none;
            }
        }
    }*/

    .v-step {
        margin-top: 95px;
    }
</style>