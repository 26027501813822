import request from '@/api/request';

export function getAdminDocument (docId) {
    return request({
        url: `admin/documents/${docId}`,
        method: 'GET'
    });
}

export function saveDocumentAttachments (docId, data = {}) {
    return request({
        url: `admin/documents/${docId}/attachments`,
        method: 'POST',
        data: data,
    },
    {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

export function updateAdminDocument (docId, data = {}) {
    return request({
        url: `admin/documents/${docId}`,
        method: 'PUT',
        data: data
    });
}

export function createAdminDocument (data = {}) {
    return request({
        url: `admin/documents`,
        method: 'POST',
        data: data
    });
}

export function removeDocumentAttachment (docId, attachmentId) {
    return request({
        url: `admin/documents/${docId}/attachments/${attachmentId}`,
        method: 'DELETE',
    });
}

export function saveDocumentCoverImg (docId, formData) {
    return request({
        url: `admin/documents/${docId}/image`,
        method: 'POST',
        data: formData,
    },
    {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

export function deleteDocumentCoverImg (docId) {
    return request({
        url: `admin/documents/${docId}/image`,
        method: 'DELETE'
    });
}