<template>

    <transition name="transition-modal">

        <div class="modal fade show" data-backdrop="static" data-keyboard="false" id="create-webinar-modal" tabindex="-1" role="dialog" aria-labelledby="Create Webinar">
            <div class="modal-dialog" role="document" @click.stop="function(){}">
                <div v-if="!loaded" class="page-loading"></div>
                <!-- first screen: past zoom invite -->
                <div v-else-if="!event.id && !next" class="modal-content">
                    <div class="modal-header">
                        <h3>Schedule webinar</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>

                    </div>
                    <div class="modal-body">
                        <form novalidate>
                            <div class="form-group">
                                <div class="zoom-invite-label-container">
                                    <label class="zoom-invite-label-text" for="zoom_invitation">Paste Zoom Webinar Invitation</label>
                                    <p class="help-button" @click="showHelp = !showHelp">
                                        <i class="fas fa-info-circle static text-main pointer"></i>
                                        {{showHelp ? 'hide' : 'show' }} help
                                    </p>
                                </div>
                                <div class="invite-help" v-if="showHelp">
                                    <p class="infotext">
                                        <b>1.</b> Schedule a Webinar on the Zoom platform:
                                        <a target="_blank" href="https://zoom.us/webinar/schedule">link</a>
                                    </p>
                                    <p>
                                        <small><i>*Must select the "Auckland, Wellington" Time Zone option</i></small><br>
                                        <small><i>*Must not select checkboxes for "Registration Required" or "Require Password"</i></small>
                                    </p>
                                    <img src="/images/admin/enz-zoom-webinar-create.png" alt="Image shows Zoom's create webinar screen">
                                    <p class="infotext">
                                        <b>2.</b> Go to your created Zoom Webinar and click "Copy the invitation"
                                    </p>
                                    <img src="/images/admin/enz-zoom-invite1.png" alt="Image shows an example of Zooms copy webinar invitation screen">
                                    <p class="infotext">
                                        <b>3.</b> Click "Copy Attendee Invitation"
                                    </p>
                                    <img src="/images/admin/enz-zoom-invite2.png" alt="Image shows which button to click on Zoom to copy the webinar invitation">
                                    <p class="infotext">
                                        <b>4.</b> On Agent Lab, right-click, and select "paste" to insert your Zoom Webinar invitation into the text-box provided
                                    </p>
                                    <img src="/images/admin/enz-zoom-invite3.png" alt="Image displays a user pasting a Zoom Webinar invitation into AgentLabs 'Schedule Webinar' textarea">
                                </div>
                                <textarea v-else id="zoom_invitation" name="description" class="form-control"
                                          v-model="zoomInviteStr"
                                          rows="15"></textarea>
                                <small v-if="!showHelp">* Leave blank to fill webinar details manually</small>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                      <button v-if="showHelp === true" @click.stop="showHelp = false" class="jba-btn">
                        Hide Help
                      </button>
                      <button v-else  @click.stop="handleNextClick" class="jba-btn">
                        Next
                      </button>
                    </div>
                </div>
                <!-- second screen: confirm details -->
                <div v-else class="modal-content">
                    <div class="modal-header">
                        <h3>Schedule webinar</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>

                    </div>
                    <div class="modal-body">
                        <form novalidate>
                            <div class="form-group">
                                <label for="event_name">Name <span>*</span></label>
                                <input id="event_name" name="name" type="text" class="form-control"
                                       :class="{'is-invalid': errors.has('name')}"
                                       v-model="event.name"
                                       v-validate="{required: true, min: 3}"/>
                                <small v-show="errors.has('name')" class="text-danger">{{ errors.first('name') }}</small>
                            </div>

                            <div class="form-group">
                                <label for="event_description">Description <span>*</span></label>
                                <textarea id="event_description" name="description" class="form-control"
                                          :class="{'is-invalid': errors.has('description')}"
                                          v-model="event.description"
                                          v-validate="{required: true, min: 10}"></textarea>
                                <small v-show="errors.has('description')" class="text-danger">{{ errors.first('description') }}</small>
                            </div>

                            <div class="form-group">
                                <label for="event_started_at">Start date (local time)<span>*</span></label>
                                <date-picker id="event_started_at" name="start" readonly
                                             :class="{'is-invalid': errors.has('start')}"
                                             v-model="event.started_at"
                                             :config="pickerOptions"
                                             v-validate="{required: true}"></date-picker>
                                <small v-if="event.started_at" class="d-block">New Zealand Time: {{ event.started_at | convertToNZDate(pickerOptions.format) }}</small>
                                <!--<small v-if="event.started_at" class="d-block">UTC: {{ event.started_at | convertToUTCDate(pickerOptions.format) }}</small>-->
                                <small v-show="errors.has('start')" class="text-danger">{{ errors.first('start') }}</small>
                            </div>

                            <div class="form-group">
                                <label for="event_duration">Duration (minute) <span>*</span></label>
                                <input id="event_duration" name="duration" type="number" class="form-control"
                                       :class="{'is-invalid': errors.has('duration')}"
                                       v-model="event.duration"
                                       v-validate="{required: true, regex: /^([0-9]+)$/, min_value: 10, max_value: 120}" />
                                <small v-show="errors.has('duration')" class="text-danger">{{ errors.first('duration') }}</small>
                            </div>


                            <div class="form-group">
                                <label for="event_groups">Assign to groups <span>*</span></label>

                                <!--<v-select label="name" data-vv-name="groups"
                                          :class="{'is-invalid': errors.has('groups')}"
                                          :searchable=true
                                          v-model="event.groups"
                                          :options="groups" multiple
                                          v-validate="{required: true}"
                                ></v-select>-->

                                <app-multiple-select-box
                                        id="event_groups"
                                        name="groups"
                                        data-vv-name="groups"
                                        v-validate="{required: true}"
                                        data-vv-validate-on="select"
                                        placeholder="Select groups"
                                        :multiple="true"
                                        :options="$parent.groups"
                                        :internal-search="false"
                                        :value="event.groups"
                                        @select="event.groups = $event"
                                        :class="{'is-invalid': errors.has('groups')}"
                                        :markup="false">
                                </app-multiple-select-box>
                                <small v-show="errors.has('groups')" class="text-danger">{{ errors.first('groups') }}</small>

                            </div>

                            <div class="form-group">
                                <label for="event_zoom_url">Zoom webinar link <span>*</span></label>
                                <input id="event_zoom_url" name="zoom link" type="url" class="form-control"
                                       :class="{'is-invalid': errors.has('zoom link')}"
                                       v-model="event.zoom_url"
                                       v-validate="{required: true}"/>
                                <small v-show="errors.has('zoom link')" class="text-danger">{{ errors.first('zoom link') }}</small>
                            </div>

                            <div class="form-group password-field">
                                <label for="event_zoom_password">Zoom password</label>
                                <input id="event_zoom_password" name="zoom password" :type="showPassword ? 'text' : 'password'" class="form-control"
                                       :class="{'is-invalid': errors.has('zoom password')}"
                                       v-model="event.password"
                                       v-validate="{required: false}" />
                                <i class="fas static" :class="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword}" @click="showPassword = !showPassword"></i>
                                <small v-show="errors.has('zoom password')" class="text-danger">{{ errors.first('zoom password') }}</small>
                            </div>

                            <div class="form-group">
                                <label for="teams" class="">CC notification to Teams</label>

                                <app-multiple-select-box
                                        id="teams"
                                        name="teams"
                                        data-vv-name="teams"
                                        v-validate="{required: false}"
                                        :value="event.teams"
                                        @select="event.teams = $event"
                                        placeholder="Select teams"
                                        :multiple="true"
                                        :options="teams"
                                        :searchable="false"
                                        :markup="false"
                                        :class="{'is-invalid': errors.has('teams')}">
                                </app-multiple-select-box>
                                <small v-show="errors.has('teams')" class="text-danger">{{ errors.first('teams') }}</small>
                            </div>

                        </form>
                    </div>
                    <div class="modal-footer">
                        <button v-if="!event.id" @click.stop="handleBack" class="jba-btn reverse pull-left">Back</button>
                        <button v-if="event.id && $can('cancel', 'webinar')" @click.stop="cancel" class="jba-btn btn-danger" :disabled="cancelling"><i v-if="cancelling" class="fas fa-spinner fa-spin"></i> Cancel Webinar</button>
                        <button @click.stop="submit" class="jba-btn" :disabled="errors.any() || submitting"><i v-if="submitting" class="fas fa-spinner fa-spin"></i> {{ event.id ? 'Update' : 'Create' }}</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>

</template>

<script>
    import {
        parseZoomInvite,
        getEventById,
        cancelEvent,
        updateEvent,
        createEvent,
    } from '@/api/events';
    import { getTeams } from '@/api/teams';
    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props:{
            view: {
                type: String,
                default: null
            },
            id: {
                type: [Number, String],
                default: null
            }
        },

        data(){
            return {
                event: {
                    teams: []
                },
                submitting: false,
                cancelling: false,
                pickerOptions: {},
                next: false,
                zoomInviteStr: '',
                showHelp: false,
                showPassword: false,
                teams: []
            }
        },

        inject: ['api'],

        components: {

        },

        created() {
            this.loadTeams();
            this.loadEvent();
            this.pickerOptions = {
                format: 'YYYY-MM-DD hh:mm:ss A',
                useCurrent: false,
                minDate: moment(),
                ignoreReadonly: true,
                icons: this.datePickerIcons
            };
        },

        computed: {
            eventType() {
                return 'Webinar';
            },

            timezone() {
                return moment.tz.guess();
            }
        },

        mounted() {
            this.toggleModal('show');
            const body = document.querySelector('body')
            setTimeout(() => {
                body.className = 'modal-open';
            }, 50);
        },

        methods: {

            handleBack(e) {
                this.next = false;
                this.event = {};
            },

            handleNextClick () {
                // go next if no string to parse
                if(this.zoomInviteStr.length <= 0) {
                    this.next = true;
                    return;
                }

                if(this.zoomInviteStr.toLowerCase().includes('zoom meeting')) {
                    const message = 'Sorry we couldn\'t parse your Webinar invitation.\n\n'
                    + 'The provided invitation is for a Zoom Meeting, please schedule a Zoom Webinar and try again.';
                    this.handleError({ message: message });
                    return;
                }

                this.submitting = true;
                parseZoomInvite(this.zoomInviteStr)
                .then(res => {
                    this.submitting = false;
                    this.next = true;
                    this.event.name = res.data.topic;
                    this.event.description = res.data.topic;
                    this.event.zoom_url = res.data.link;
                    this.event.password= res.data.password;
                    this.event.started_at = this.$options.filters.convertDate(res.data.start, this.pickerOptions.format);
                    /*
                     // only set date if date is future
                    let startDate = moment(res.data.start, this.pickerOptions.format);
                    if(startDate.isAfter(this.pickerOptions.minDate)) {
                        this.event.started_at = res.data.start;
                    }*/
                })
                .catch(err => {
                    this.submitting = false;
                    if(err.response.status == 422) {
                        this.handleError({message: err.response.data.message});
                    } else {
                        this.handleError(err);
                    }
                });
            },

            loadEvent() {

                let self = this;

                if(!self.id) {
                    self.loaded = true;
                    return;
                }

                getEventById(self.id)
                    .then(function(response){

                    self.pickerOptions.minDate = moment();
                    self.event = response.data;
                    if(self.event.started_at) {
                        self.event.started_at = self.$options.filters.convertDate(self.event.started_at, 'YYYY-MM-DD hh:mm:ss A');
                    }

                    self.loaded = true;
                })
                .catch(function(error){
                    self.handleError(error);
                });
            },

            close() {
                this.toggleModal('hide');
                this.$router.push({name: 'admin.events', params: {view: this.view}});
            },

            cancel() {

                let self = this;

                self.handleConfirm({
                    text: 'Are you sure that you want to cancel this webinar event?',
                    warning: 'An email will notify all subscribers'
                }, function () {
                    self.cancelling = true;
                    cancelEvent(self.event.id)
                        .then(function (response){
                            self.cancelling = false;
                            self.$emit('load-event');
                            self.close();
                        })
                        .catch(function(error){
                            self.cancelling = false;
                            self.handleError(error);
                        })

                });

            },

            toggleModal(status) {
                $(this.$el).modal(status);
            },

            submit() {
                let self = this;

                this.$validator.validateAll().then(function () {
                    if (!self.errors.any()) {
                        const dateUTC = moment(self.event.started_at, self.pickerOptions.format).utc().valueOf() / 1000;
                        let data = {
                            name: self.event.name,
                            description: self.event.description,
                            started_at: dateUTC,
                            duration: self.event.duration,
                            archived: !!self.event.archived,
                            password: self.event.password,
                            groups: [],
                            zoom_url: self.event.zoom_url,
                            teams: []
                        };

                        for (let i = 0; i < self.event.groups.length; i++) {
                            data.groups.push(self.event.groups[i].id);
                        }

                        for (let i = 0; i < self.event.teams.length; i++) {
                            data.teams.push(self.event.teams[i].id);
                        }

                        self.submitting = true;

                        if(self.event.id) {
                            //update
                            updateEvent(self.event.id, data)
                                .then(function (response){
                                    self.submitting = false;
                                    self.$emit('load-event');
                                    self.close();
                                    self.handleNotice('Webinar updated');
                                    return true;
                                })
                                .catch(function(error){
                                    self.submitting = false;
                                    self.handleError(error);
                                })
                        }
                        else {

                            createEvent(data)
                                .then(function (response) {
                                    self.submitting = false;
                                    self.$emit('load-event');
                                    self.close();
                                    self.handleNotice('Webinar created');
                                    return true;
                                })
                                .catch(function (error) {
                                    self.submitting = false;
                                    self.handleError(error);
                                });
                        }

                    }
                });
            },

            loadTeams(){

                let self = this;

                getTeams()
                    .then(function(response){
                        self.teams = response.data;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    })
            }
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

    .modal-content {
        overflow-y: auto;
        max-height: 90vh;
    }

    .pointer {
        cursor: pointer;
    }
    img {
        max-width: 100%;
        border: 1px solid var(--main-color);
        border-radius: 3px;
    }
    .infotext {
        margin-bottom: 0px;
        margin-top: 15px
    }
    .zoom-invite-label-container {
        display: flex;
    }
    .zoom-invite-label-text {
        margin: 0 auto 0 0;
    }
    .help-button:hover {
        text-decoration: underline;
    }
    .help-button {
        cursor: pointer;
        line-height: unset;
    }
    b {
        font-weight: bold;
    }
    a {
        text-decoration: underline;
        color: var(--link-blue-color);
    }

    .password-field {
        position: relative;

        .fas {
            position: absolute;
            top: 44px;
            right: 10px;
        }
    }
</style>