<template>

    <div id="feed">
        <app-page-banner page="feed" title="Communications" subtitle="Upload news articles"></app-page-banner>

        <div v-if="!loaded" class="page-loading"></div>
        <div class="row sm-p-2" v-else>
            <!--<div class="col-12">
                <div class="alert alert-success alert-dismissible fade show mt-4" role="alert">
                    <p class="text-center titalic tsmall mb-0">Select news to create and send.</p>
                    <button type="button" class="close py-2" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>-->

            <div class="col-12">

                <ul class="nav nav-tabs">
                    <li class="nav-item mr-1" v-if="$can('view', 'news-feed')">
                        <router-link class="nav-link" :to="{name: 'admin.feeds'}" :class="{'router-link-exact-active': isRoutePattern('^admin\.feeds')}"><span>News feed</span></router-link>
                    </li>
                    <li class="nav-item" v-if="$can('view', 'message')">
                        <router-link class="nav-link" :to="{name: 'admin.broadcasts'}"><span>Message</span></router-link>
                    </li>
                </ul>
            </div>

            <!-- List Feed -->
            <div class="col-12" v-if="$can('create', 'news-feed')">
                <router-link :to="{name: 'admin.feeds.create'}" class="jba-btn btn-info">Create</router-link>
            </div>

            <div class="col-sm-12 col-md-4">
                <h5 class="text-main mb-0">News list</h5>
            </div>

            <div class="col-sm-12 col-md-8">
                <app-search-bar placeholder="Search News" :search-handler="search" :clear-handler="clear" ref="keywords"></app-search-bar>
            </div>

            <div class="col-12" v-show="pageCount > 1">
                <paginate :page-count="pageCount"
                          :page-range="5"
                          :click-handler="navigate"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :container-class="'pagination float-right'"
                          :page-class="'page-item'"
                          v-model="page"
                ></paginate>
            </div>

            <div class="col-12 mt-3">
                <table class="table table-hover" v-if="feeds.total">
                    <tbody>
                        <tr v-for="(f, index) in feeds.data" :key="index">
                            <td class="overlap p-0">

                                <app-sticky-flag v-if="isSticky(f.flag)"></app-sticky-flag>

                                <div class="panel">
                                    <button type="button" aria-label="Close" @click="remove(f.id)" v-if="$can('delete', 'news-feed')">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                    <button type="button" aria-label="Sticky" @click="sticky(f.id)" v-if="$can('create', 'news-feed')" :title="isSticky(f.flag) ? 'No sticky' : 'Sticky'">
                                        <i class="fas fa-thumbtack" :class="{sticky: !isSticky(f.flag)}"></i>
                                    </button>
                                </div>

                                <div class="row sm-p-2">
                                    <div class="col-sm-3">
                                        <img v-if="f.image" :src="f.image" class="img-responsive" onerror="this.style.display='none'"/>
                                        <!--img :src="f.image" class="img-responsive" onerror="this.src='/images/login.jpg'"/-->
                                    </div>
                                    <div class="col-sm-9">
                                        <p class="mb-0" v-if="!f.title"><a class="link" :href="f.url" target="_blank">{{ f.url }}</a></p>

                                        <small class="posted">posted at {{ f.created_at | convertDate }}</small>
                                        <div class="wrap-link-details d-flex">
                                            <span class="left-border"></span>
                                            <div class="w-100">
                                                <p class="link-site mb-1" v-if="f.favicon"><img class="link-site-image" :src="f.favicon" alt="">{{ f.site_name }}</p>
                                                <a class="link-title my-2 mr-5 link d-block" :href="f.url" target="_blank">{{ f.title }}</a>
                                                <p class="link-description mb-0">{{ f.description }}</p>
                                                <div v-if="f.feed_scopes" v-html="showFeedScopes(f.scopes)"></div>

                                            </div>
                                        </div>
                                        <p class="wr-bk mb-0 text-bold mt-2" v-html="f.content"></p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <app-reminder :ready="loaded" message="There is no news at this moment." :result="feeds.data"></app-reminder>
            </div>

            <div class="col-12" v-show="pageCount > 1">
                <paginate :page-count="pageCount"
                          :page-range="5"
                          :click-handler="navigate"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :container-class="'pagination float-right'"
                          :page-class="'page-item'"
                          v-model="page"
                ></paginate>
            </div>

        </div>


        <router-view @load-feeds="loadFeeds"></router-view>
    </div>

</template>

<script>

    export default {
        props: {

        },
        data() {
            return {
                submitting: false,
                feeds: {},
                page: 1
            }
        },

        inject: ['api', 'countries', 'providerTypes'],

        components: {

        },

        created() {
            this.loadFeeds();
        },

        destroyed() {

        },

        computed: {
            pageCount(){
                return Math.ceil(this.feeds.total / this.feeds.per_page);
            },

        },

        watch: {

        },

        methods: {

            search(keywords) {

                this.loadFeeds({q: keywords});
            },

            navigate(page) {

                let params = {page: page};

                if(this.$refs.keywords.value) {
                    params.q = this.$refs.keywords.value;
                }

                this.loadFeeds(params);
            },

            loadFeeds(params = {}) {

                let self = this;

                axios.get(this.api + 'admin/feeds', {params: params})
                    .then(function(response){
                        self.feeds = response.data;
                        self.page = self.feeds.current_page;
                        self.loaded = true;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            showFeedScopes(scopes) {

                let str = '';

                if(scopes && scopes.groups && scopes.groups.length) {
                    str += '<p class="mb-1"><span class="badge badge-primary">' + scopes.groups.join('</span> <span class="badge badge-primary">') + '</span></p>';
                }

                if(scopes && scopes.countries && scopes.countries.length) {
                    str += '<p class="mb-1"><span class="badge badge-warning">' + scopes.countries.join('</span> <span class="badge badge-warning">') + '</span></p>';
                }

                if(scopes && scopes.provider_types && scopes.provider_types.length) {
                    str += '<p class="mb-1"><span class="badge badge-success">' + scopes.provider_types.join('</span> <span class="badge badge-success">') + '</span></p>';
                }

                return str;
            },

            remove(id) {

                let self = this;
                self.handleConfirm({
                    text: 'Are you sure that you want to delete this news?'
                }, function () {
                    axios.delete(self.api + 'admin/feeds/' + id)
                        .then(function (response) {
                            self.navigate( self.goToPage(self.feeds.current_page, self.feeds.data.length - 1));
                        })
                        .catch(function (error) {
                            self.handleError(error)
                        })
                }.bind(this, id));

            },

            sticky(id) {

                let self = this;

                self.handleConfirm({
                    text: 'Are you sure that you want to update the sticky option of this news?',
                    warning: ' '
                }, function () {
                    axios.put(self.api + 'admin/feeds/' + id + '/sticky')
                        .then(function (response) {
                        //    self.navigate( self.goToPage(self.feeds.current_page, self.feeds.data.length - 1));
                            self.arraySearch(self.feeds.data, id).flag = response.data;
                        })
                        .catch(function (error) {
                            self.handleError(error)
                        })
                }.bind(this, id));
            },

            clear() {
                this.navigate(1);
            }

        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>