<template>

    <transition name="transition-model">

        <div class="modal fade show" data-backdrop="static" data-keyboard="false" id="show-event-modal" tabindex="-1" role="dialog" aria-labelledby="Show Event">
            <div class="modal-dialog" role="document" @click.stop="function(){}">
                <div v-if="!showCreateGroupModal" class="modal-content">
                    <div class="modal-header">
                        <h3 class="text-truncate">{{ event.name }}</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </div>

                    <div v-if="event.is_loaded" class="modal-body">
                        <p>{{ event.description }}</p>

                        <div v-if="!event.closed_at">
                            <div>
                                <p>Date: {{ event.started_at | convertDate('DD MMM YYYY') }}</p>
                                <p>Time: {{ event.started_at | convertDate('HH:mm') }}</p>
                                <p>Duration: {{ event.duration }} minutes </p>
                                <p>Number of Agents: {{ event.agent_count }}</p>
                                <p>Group(s): {{ join(event.groups) }}</p>
                                <p class="mb-0">Attending:</p>
                                <ul class="list-unstyled">
                                    <li v-for="(f, index) in event.feedback" :key="index">{{ f.attending }}: {{ f.count }}</li>
                                </ul>
                                <p v-if="!event.editable">Total Attendees: <b>{{event.webinar_attendees.attendees_count}}</b></p>
                                <p v-if="!isExpired && event.password" class="copy-to-clipboard">
                                    Zoom Password:
                                    <input type="text" readonly id="copy-content" :value="event.password" />
                                    <i id="copy-to-clipboard" class="fas clickable" :class="{'fa-copy': !copied, 'fa-clipboard-check': copied}" @click.stop.prevent="copyPassword()"></i>
                                </p>
                            </div>

                            <!--<div v-if="!isExpired && event.password" class="copy-to-clipboard alert alert-info fs-120 position-relative p-0">
                                <input type="text" id="copy-content" readonly class="form-control rounded-0 pr-5" :value="event.password" />
                                <i id="copy-to-clipboard" class="fas clickable" :class="{'fa-copy': !copied, 'fa-clipboard-check': copied}" @click.stop.prevent="copyPassword()"></i>
                            </div>-->
                        </div>
                        <div v-else>
                            <p>Closed at: {{ event.closed_at | convertDate }}</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            v-if="!isExpired"
                            @click.stop="joinEvent(event)"
                            :disabled="!event.valid" class="jba-btn float-left"
                            :class="{disabled: !event.valid}"
                        >
                            {{ joinOrStart }}
                            <!--<i id="zoom-password" class="fas fa-lock static"
                               data-toggle="tooltip"
                               data-placement="top"
                               :title="event.password"
                               @click.stop="joinEvent(event)"></i>-->
                        </button>
                        <!--<button v-if="event.deletable" class="jba-btn" @click="deleteEvent">Delete</button>-->
                        <button class="jba-btn" v-if="event.editable && $can('update', 'webinar')" @click="toUpdate">Edit</button>
                        <button v-if="showCreateGroupBtn" @click.stop="showCreateGroupModal = true;" class="jba-btn">Create New Group</button>
                        <button v-if="showCancel" @click.stop="cancel" class="jba-btn btn-danger" :disabled="cancelling"><i v-if="cancelling" class="fas fa-spinner fa-spin"></i> Cancel Webinar</button>


                    </div>
                </div>
                <div v-else class="modal-content">
                    <div class="modal-header">
                        <h3>Create New Group</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="group_name">Group Name *</label>
                            <input class="form-control"
                                type="text"
                                v-model="newGroup.name"
                                name="group name"
                                :class="{'is-invalid': errors.has('group name')}"
                                v-validate="{required:true}"/>
                            <small v-show="errors.has('group name')" class="text-danger">{{ errors.first('group name') }}</small>
                        </div>
                        <div class="form-group">
                            <label for="group_description">Group Description</label>
                            <textarea id="group_description" class="form-control"
                                    v-model="newGroup.description"></textarea>
                        </div>
                        <label for="none">Agents</label>
                        <div class="scroll-table">
                            <table class="table">
                                <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(attendee,index) in event.webinar_attendees.attendees" :key="index">
                                    <td>{{ attendee.agent.full_name }}</td>
                                    <td>{{ attendee.agent.email }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="jba-btn" @click.stop="handleBack">Back</button>
                        <button class="jba-btn" @click.stop="createNewGroup" :disabled="submitting">
                            <i v-show="submitting" class="fas fa-spinner fa-spin"></i> Create
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>

</template>

<script>
    import {
        getEventById,
        cancelEvent,
        saveEventAttendee,
        createGroupFromEventAttendees
    } from '@/api/events'

    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props:{
            id: {
                type: [Number, String],
                required: true
            },
            view: {
                type: String,
                default: null
            }
        },

        data(){
            return {
                cancelling: false,
                event: {
                    is_loaded: false
                },
                showCreateGroupModal: false,
                newGroup: {},
                submitting: false,
                copied: false
            }
        },

        inject: ['api', 'auth'],

        created() {
            this.loadEvent();
        },

        methods: {

            handleBack() {
                this.showCreateGroupModal = false;
                this.newGroup = {};
                return;
            },

            async createNewGroup() {
                await this.$validator.validateAll();
                if (this.errors.any()) return;

                this.submitting = true;
                try {
                    const res = await createGroupFromEventAttendees(this.id, this.newGroup);
                    this.submitting = false;
                    this.handleNotice('Group created!');
                    this.close();
                    this.$router.push({name: 'admin.groups.show', params: {id: res.data.id}});
                } catch(err) {
                    this.submitting = false;
                    if(err.response.status == 422) {
                        this.handleError({message: err.response.data.message});
                    }else if(err.response.status == 400) {
                        this.handleError({message: err.response.data.errors});
                    } else {
                        this.handleError(err);
                    }
                }
            },

            loadEvent() {
                let self = this;
                getEventById(self.id)
                    .then(function(response) {
                        self.event = response.data;
                        self.newGroup.name = self.event.name;
                        self.newGroup.description = self.event.description;
                        self.event.is_loaded = true;
                        return true;
                    })
                    .catch(function(error) {
                        self.handleError(error);
                    });
            },


            close() {
                this.toggleModal('hide');
                this.$router.push({name: 'admin.events', params: {view: this.view}});
            },

            toUpdate() {
                this.toggleModal('hide');
                this.$router.push({name: 'admin.events.edit', params: {id: this.event.id}});

            },

            joinEvent(e) {
                if(!e.valid) {
                    return false;
                }
                else if(this.auth().id !== this.event.user_id) {
                    //window.location.href = this.event.zoom_url;

                    window.open(this.event.zoom_url);
                }
                else {
                    // prompt admin zoom sign in
                    const content = document.createElement('div');
                    content.className = 'swal-content';
                    content.innerHTML = '<p>You must sign in to your Zoom Admin account and start the webinar from the Zoom platform.</p>'
                        + '<button style="margin-bottom:10px" class="swal-button swal-button--confirm">'
                        + '<a target="_blank" href="https://zoom.us/signin" rel="noreferrer">Sign in to Zoom</a>'
                        + '</button>';

                    return swal({
                        title: 'Start Zoom Webinar',
                        icon: 'warning',
                        button: false,
                        content: content
                    });
                }
            },

            toggleModal(status) {
                $(this.$el).modal(status);
            },

            cancel() {

                let self = this;

                self.handleConfirm({
                    text: 'Are you sure that you want to cancel this webinar event?',
                    warning: 'An email will notify all subscribers'
                }, function () {
                    self.cancelling = true;
                    cancelEvent(self.event.id)
                        .then(function (response){
                            self.cancelling = false;
                            self.$emit('load-event');
                            self.close();
                        })
                        .catch(function(error){
                            self.cancelling = false;
                            self.handleError(error);
                        })

                });

            },

            createGroup () {
                this.toggleModal('hide');
                this.$router.push({name: 'admin.groups.create', params: {eventId: this.event.id}});
            },

            copyPassword() {
                let copySource = document.querySelector('#copy-content');
                copySource.setAttribute('type', 'text');
                copySource.select();

                try {
                    let successful = document.execCommand('copy');
                    if(successful) {
                        this.copied = true;
                    }
                    else {
                        this.handleError('Unable to copy, please press "Ctrl" + "C" to copy manually');
                    }
                } catch (err) {
                    this.handleError(err);
                }

            }
        },

        computed: {
            showCreateGroupBtn() {
                // compare dates as UTC
                const hasEnded = moment.utc().isAfter(moment.utc(this.event.end_at));
                return hasEnded  && this.event.webinar_attendees.attendees.length > 0;
            },
            joinOrStart() {
                return this.event.user_id === this.auth().id ? 'Start' : 'Join';
            },

            showCancel () {
                return !this.event.cancelled && this.$can('cancel', 'webinar') && this.event.started_at > Date.now();
            },

            isExpired() {
                return !this.event.end_at || moment().utc().isAfter(moment.utc(this.event.end_at));
            }
        },

        mounted() {
            this.toggleModal('show');
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss"  scoped>
    /*.modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;

        .modal-wrapper {
            display: table-cell;
            vertical-align: middle;

            .modal-container {
                max-width: 600px;
                width: 100%;
                margin: 0px auto;
                padding: 10px;
                background-color: #fff;
                border-radius: 2px;
                box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
                transition: all .3s ease;
                font-family: Helvetica, Arial, sans-serif;

                .modal-header h3 {
                    margin-top: 0;
                    color: #42b983;
                }

                .modal-body {

                }
            }
        }
    }*/

    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

    /*.admin-event-show-enter {
        opacity: 0;
    }

    .admin-event-show-leave-active {
        opacity: 0;
    }

    .admin-event-show-enter .modal-container,
    .admin-event-show-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }*/
    .scroll-table {
        height: 45vh;
        overflow-y: auto;
        border: 1.5px solid var(--main-color);
    }

    .copy-to-clipboard {
        input {
            border: none;
            border-bottom: 1px solid;
            padding-right: 40px;
        }
        i {
            margin-left: -30px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 100%;
        }
    }
</style>