<template>

    <div id="agency">
        <app-page-banner page="agency" title="Agencies" subtitle="Search, view and approve registered agencies"></app-page-banner>

        <div v-if="!loaded" class="page-loading"></div>
        <div class="row sm-p-2" v-else>

            <div class="col-12">

                <ul class="nav nav-tabs">
                    <li class="nav-item mr-1">
                        <router-link class="nav-link" :to="{name: 'admin.agencies'}"><span>Agencies List</span></router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{name: 'admin.agencies.pending'}"><span>Unverified</span></router-link>
                    </li>
                </ul>
            </div>

            <div class="col-12 col-sm-4">
                <h5 class="text-main mb-0">Agency list </h5>
            </div>

            <div class="col-12 col-sm-8">
                <app-search-bar placeholder="Search Agencies" :search-handler="search" :clear-handler="clear" ref="keywords"></app-search-bar>
            </div>

            <div class="col-12" v-show="pageCount > 1">
                <paginate :page-count="pageCount"
                          :page-range="5"
                          :click-handler="navigate"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :container-class="'pagination float-right'"
                          :page-class="'page-item'"
                          v-model="page"
                ></paginate>
            </div>

            <div class="col-12 mt-3">
                <table class="table table-hover" v-if="organizations.total">
                    <thead>
                    <tr class="row">
                        <th class="col-10 col-sm-6 col-md-6 col-lg-6">Name</th>
                        <th class="col-2 d-block d-sm-none"></th>
                        <th class="col-sm-3 col-md-3 col-lg-3 d-none d-sm-inline-block">Agent Count</th>
                        <th class="col-sm-3 col-md-3 col-lg-3 d-none d-sm-inline-block">Status</th>
                    </tr>
                    </thead>

                    <tbody>
                    <template  v-for="(e, index) in organizations.data">
                        <tr class="row">
                            <td class="col-10 col-sm-6 col-md-6 col-lg-6">{{ e.name }}</td>
                            <td class="col-2 d-block d-sm-none text-center" @click.stop="expandRow($event)"><i class="fas fa-plus"></i></td>
                            <td class="col-sm-3 col-md-3 col-lg-3 d-none d-sm-inline-block">{{ e.agent_count }}</td>
                            <td class="col-sm-3 col-md-3 col-lg-3 d-none d-sm-inline-block">{{ e.status_string }}</td>
                        </tr>

                        <tr :key="'tr'+index" class="row expand" style="display: none;">
                            <td class="col-12 d-block d-sm-none" colspan="2">
                                <ul class="list-unstyled">
                                    <li>Agent Count: <b>{{ e.agent_count }}</b></li>
                                    <li>Status: {{ e.status_string }}</li>
                                </ul>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>

                <app-reminder :ready="loaded" message="There is no agency at the moment" :result="organizations.data"></app-reminder>
            </div>

            <div class="col-12" v-show="pageCount > 1">
                <paginate :page-count="pageCount"
                          :page-range="5"
                          :click-handler="navigate"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :container-class="'pagination float-right'"
                          :page-class="'page-item'"
                          v-model="page"
                ></paginate>
            </div>

        </div>
    </div>

</template>

<script>

    export default {
        props: {

        },
        data() {
            return {
                organizations: {},
                submitting: false,
                page: 1,
            }
        },

        inject: ['api'],

        components: {

        },

        created() {
            this.loadOrganizations();
        },

        destroyed() {

        },

        computed: {
            pageCount(){
                return Math.ceil(this.organizations.total / this.organizations.per_page);
            }
        },

        methods: {
            search(params) {
                this.loadOrganizations({q: params});
            },

            navigate(page) {

                let params = {page: page};
                params.q = this.$refs.keywords.value;

                this.loadOrganizations(params);
            },

            clear() {
                this.navigate(1);
            },

            loadOrganizations(params = {}) {

                let self = this;

                axios.get(this.api + 'admin/organizations', {params: params})
                    .then(function(response){
                        self.organizations = response.data;
                        self.page = self.organizations.current_page;
                        self.loaded = true;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            expandRow(e) {
                let expand = $(e.currentTarget).closest('tr').next('.expand');

                expand.toggle();

                if(expand.is(":visible")) {
                    $(e.currentTarget).find('.fa-plus').addClass('fa-minus').removeClass('fa-plus');
                }
                else {
                    $(e.currentTarget).find('.fa-minus').addClass('fa-plus').removeClass('fa-minus');
                }

            }
        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">


</style>