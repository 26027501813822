<template>
    <div id="world-map" class="border p-2 jvectormap jvectormap-mill"></div>
</template>

<script>

    window.jQuery = require('jquery');
    require('jvectormap');
    require('../../../lib/jvectormap/jquery-jvectormap-world-mill.js');
    import 'jvectormap/jquery-jvectormap.css';

    export default {
        props: {
            ready: {
                type: Boolean,
                default: false
            },
            source: {
                type: Object,
                default: function(){
                    return {};
                }
            },
            height: {
                type: [Number, String],
                default: false
            }
        },
        data() {
            return {
                radio: 1.75,
                minHeight: 200
            }
        },
        watch: {

        },
        methods: {

            drawMap(){

                let self = this;

                let map = $('#world-map');

                if(!self.height) {
                    let height = map.width() / self.radio;

                    self.height = height < self.minHeight ? self.minHeight : height;
                }

                map.height(self.height).vectorMap({
                    map: 'world_mill',
                    backgroundColor: '#fff',
                    regionStyle: {
                        initial: {
                            fill: '#c4c4c4',
                            "fill-opacity": 0.9,
                            stroke: 'none',
                            "stroke-width": 0,
                            "stroke-opacity": 0
                        },
                        hover: {
                            "fill-opacity": 1
                        }
                    },
                    series: {
                        regions: [{
                            values: self.source,
                            scale: ['#85a8b6', '#4d7686'],
                            normalizeFunction: 'polynomial'
                        }]
                    },
                    onRegionTipShow: function (e, el, code) {

                        let label = self.source[code] || 0;

                        label = label > 1 ? label + ' agents' : label + ' agent';

                        el.html(el.html() + ' (' + label + ')');
                    }
                });
            }
        },
        mounted() {
            this.drawMap();
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>