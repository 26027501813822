<template>
    <form novalidate class="search-by-keyword">
        <div class="input-group">
            <i class="fas fa-search"></i>
            <input class="form-control" type="text" name="search" id="search-messages"
                   v-model="keywords"
                   :placeholder="placeholder"
                   @change="handleSearch"
                   @keydown.enter.prevent="handleSearch"
                   v-validate="{min: 3}" />
            <i class="fas fa-times" :class="{'text-white': searchInGroup }" v-show="keywords.length || selectedGroup.id" title="clear search" @click="handleClear"></i>

            <div id="group-dropdown" class="input-group-append" v-if="searchInGroup.length">
                <button class="btn dropdown-toggle text-truncate" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ selectedGroupName }}</button>
                <div class="dropdown-menu">
                    <a v-for="(g, index) in searchInGroup" :key="index" @click="selectGroup(g)" class="dropdown-item">{{ g.name }}</a>
                </div>
            </div>
        </div>
        <small v-show="errors.has('search')" class="text-danger">{{ errors.first('search') }}</small>
    </form>
</template>

<script>
    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props:{
            searchHandler: {
                type: Function,
                required: true
            },
            clearHandler: {
                type: Function,
                required: true
            },
            placeholder: {
                type: String,
                default: 'Search by keywords'
            },
            minKeywordLength: {
                type: Number,
                default: 2
            },
            query: {
                type: String,
                default: ''
            },
            searchInGroup: {
                type: Array,
                default: function(){
                    return [];
                }
            }
        },

        data(){
            return {
                keywords: this.query || '',
                selectedGroup: {}
            }
        },

        inject: ['api'],

        computed: {
            value() {

                if(this.searchInGroup.length) {
                    return {
                        q: (this.keywords.length > this.minKeywordLength ? this.keywords : null),
                        group: this.selectedGroup.id
                    };
                }
                else {
                    if (this.keywords.length > this.minKeywordLength) {
                        return this.keywords;
                    }
                    return null;
                }
            },

            selectedGroupName() {
                return this.selectedGroup.name || 'In all groups';
            }
        },

        methods: {
            handleSearch() {
                let self = this;

                if(!self.value) {
                    return self.handleClear();
                }

                self.$validator.validateAll().then(function() {
                    if (!self.errors || !self.errors.any()) {
                        if (self.value) {
                            self.$emit('search-by-value', self.value);
                            self.searchHandler(self.value);
                        }
                    }
                });
            },

            handleClear() {
                this.keywords = '';
                if(this.searchInGroup) {
                    this.selectedGroup = {};
                }
                this.clearHandler();
            },

            selectGroup(group) {
                this.selectedGroup = group;
                this.handleSearch();
            }
        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" >
    .search-by-keyword {
        *position: relative;
        .input-group {

            .fa-search {
                padding: 9px;
                position: absolute;
                font-size: 1.3em;
                color: var(--enz-silver-color);
                z-index: 10;
                &:hover {
                    background: inherit !important;
                }
            }
            .fa-times {
                position: absolute;
                padding: 9px;
                font-size: 1.3em;
                top: 0;
                right: 0;
                cursor: pointer;

                z-index: 10;

                &:before {
                    color: var(--enz-silver-color);
                }

                &.text-white {
                    &:before {
                        /*color: inherit;*/
                    }
                }
            }

            input {
                padding-left: 40px;
                padding-right: 40px;

                &::-ms-clear {
                    display: none;
                }
            }

            .input-group-append {
                button {
                    padding-right: 35px;
                    background: var(--main-color);
                    color: var(--white-color);
                    max-width: 200px;
                    &:after {
                        content: none;
                    }
                }
                .dropdown-menu {
                    width: 100%;
                    left: -9px !important;
                    .dropdown-item {
                        cursor: pointer;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }
            }
        }


    }
</style>