<template>

    <transition name="transition-model">

        <div class="staff modal fade show" id="show-staff-modal" tabindex="-1" role="dialog" aria-labelledby="Show Staff" @click="close">
            <div class="modal-dialog" role="document" @click.stop="function(){}">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Staff profile</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </div>

                    <div class="modal-body">

                        <div class="text-center avatar">
                            <img :src="staff.avatar" class="img-fluid img-circle"/>
                        </div>

                        <h5 class="text-center my-2">{{ staff.first_name }} {{ staff.last_name }}</h5>

                        <table class="table table-hover mb-0">
                            <thead>
                                <tr>
                                    <td class="border-top-0 py-0">
                                        <app-single-field-update
                                                ref="singleFirstNameUpdate"
                                                component-class="editable-text mb-0"
                                                :component-value="staff.first_name"
                                                :component-params="staff.id"
                                                :component-handler="updateFirstName"
                                                :editable="$can('update', 'staff')"
                                        ></app-single-field-update>
                                    </td>

                                </tr>

                                <tr>
                                    <td class="border-top-0 py-0">
                                        <app-single-field-update
                                                ref="singleLastNameUpdate"
                                                component-class="editable-text mb-0"
                                                :component-value="staff.last_name"
                                                :component-params="staff.id"
                                                :component-handler="updateLastName"
                                                :editable="$can('update', 'staff')"
                                        ></app-single-field-update>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="border-top-0 py-0">
                                        <app-single-field-update
                                                ref="singleEmailUpdate"
                                                component-class="editable-text mb-0"
                                                :component-value="staff.email"
                                                :component-params="staff.id"
                                                :component-handler="updateEmail"
                                                :editable="false"
                                        ></app-single-field-update>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="border-top-0 py-1" v-if="$can('update', 'staff') && !isEqual()">
                                        <app-multiple-select-box
                                                name="role"
                                                data-vv-name="role"
                                                v-validate="{required: false}"
                                                :value="role"
                                                @select="updateRole($event)"
                                                :multiple="false"
                                                :options="roles"
                                                :internal-search="false"
                                                :class="{'is-invalid': errors.has('role'), 'autocomplete-loader': submitting}">
                                        </app-multiple-select-box>
                                    </td>
                                    <td v-else><span class="badge badge-warning fs-100 p-2">{{ role.name }}</span></td>
                                </tr>

                                <!--<tr v-if="$can('delete', 'staff') || $can('restore', 'staff')">
                                    <td class="border-top-0 py-1 text-right">
                                        <button class="jba-btn btn-danger" @click="restore()" v-show="staff.deleted_at" v-if="$can('restore', 'staff')">Activate</button>
                                        <button class="jba-btn btn-danger" @click="remove()" v-show="!staff.deleted_at" v-if="$can('delete', 'staff')">Deactivate</button>
                                    </td>
                                </tr>-->
                            </thead>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </transition>

</template>

<script>
    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props:{
            id: {
                type: [Number, String],
                required: true
            },
            staff: {
                type: Object,
                default: function(){
                    return {};
                }
            }
        },

        data(){
            return {
                submitting: false,
                role: this.staff.roles[0] || {},
                roles: []
            }
        },

        inject: ['api', 'auth'],

        created() {
            this.loadRoles();
        },

        methods: {

            loadRoles() {

                let self = this;

                axios.get(self.api + 'admin/staff/roles')
                    .then(function(response){
                        self.roles = response.data;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            close() {
                this.toggleModal('hide');
                this.$router.go(-1);
            },

            toggleModal(status) {
                $(this.$el).modal(status);
            },

            updateFirstName(firstName, staffId) {

                let self = this;

                axios.put(self.api + 'admin/staff/' + staffId, {first_name: firstName})
                    .then(function(response){
                    //    self.handleNotice('The staff first name is updated');
                        self.$refs.singleFirstNameUpdate.resetStatus();
                        self.staff.first_name = firstName;
                        self.$emit('load-staff');
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            updateLastName(lastName, staffId) {
                let self = this;

                axios.put(self.api + 'admin/staff/' + staffId + '', {last_name: lastName})
                    .then(function(response){
                    //    self.handleNotice('The staff last name is updated');
                        self.$refs.singleLastNameUpdate.resetStatus();
                        self.staff.last_name = lastName;
                        self.$emit('load-staff');
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            updateEmail(email, staffId) {

                let self = this;

                axios.put(self.api + 'admin/staff/' + staffId + '', {email: email})
                    .then(function(response){
                    //    self.handleNotice('The staff email is updated');
                        self.$refs.singleEmailUpdate.resetStatus();
                        self.$emit('load-staff');
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            updateRole(role) {

                if(!role) {
                    return;
                }

                let self = this;
                self.submitting = true;
                axios.put(self.api + 'admin/staff/' + self.id + '/roles/' + role.id)
                    .then(function(response){
                        self.$emit('load-staff');
                        self.submitting = false;

                        if(role.name == 'Agent') {
                            self.close();
                        }

                    })
                    .catch(function(error){
                        self.handleError(error);
                        self.submitting = false;
                    });
            },

            remove() {
                let self = this;

                self.handleConfirm({
                    text: 'Are you sure that you want to delete this user?',
                    warning: ''
                }, function () {
                    axios.delete(self.api + 'admin/staff/' + self.id)
                        .then(function(response){
                            self.$emit('load-staff');
                            self.close();
                        })
                        .catch(function(error){
                            self.handleError(error);
                        });
                });
            },
             restore() {
                 let self = this;

                 self.handleConfirm({
                     text: 'Are you sure that you want to restore this user?'
                 }, function () {
                     axios.put(self.api + 'admin/staff/' + self.id + '/restore')
                         .then(function(response){
                             self.$emit('load-staff');
                             self.close();
                         })
                         .catch(function(error){
                             self.handleError(error);
                         });
                 });
             }
        },

        computed: {

        },

        mounted() {
            this.toggleModal('show');
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" >
    

</style>