<template>
    <transition name="transition-modal">

        <div class="modal fade show" data-backdrop="static" data-keyboard="false" id="agent-office-modal" tabindex="-1" role="dialog" aria-labelledby="agent-office">
            <div class="modal-dialog" role="document" @click.stop="{}">
                <div v-if="!loaded" class="page-loading"></div>
                <div v-else class="modal-content">
                    <div class="modal-header">
                        <h3>Office Location</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </div>

                    <div class="modal-body">

                        <div class="form-group">
                            <label for="organization">Agency </label>
                            <input id="organization" class="form-control" :value="organization.name" :readonly="true">
                        </div>

                        <app-country-region-city
                            ref="countryRegionCity"
                            id="country-region-city"
                            name="city"
                            label="city"
                            v-validate="{required: false}"
                            data-vv-validate-on="select"
                            data-vv-as="city"
                            @select="agent.city_id = $event"
                            :class="{'is-invalid': errors.has('city')}">
                        </app-country-region-city>

                    <div class="form-group">
                        <label class="custom-control overflow-checkbox d-inline-block">
                            <input id="head_office"
                                type="checkbox"
                                class="overflow-control-input"
                                v-model="agent.head_office">
                            <span class="overflow-control-indicator"></span>
                            <span class="overflow-control-description">Head office</span>
                        </label>
                    </div>

                    </div>
                    <div class="modal-footer">
                        <button @click.stop="registerNewOffice" class="jba-btn" :disabled="errors.any() || submitting"><i v-if="submitting" class="fas fa-spinner fa-spin"></i> Save</button>
                        <button @click.stop="close" class="jba-btn reverse">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import { createOffice } from '@/api/agents';
    export default {
        props:{
            organization: {
                type: Object,
                default: () => ({})
            }
        },
        data(){
            return {
                agent: {
                    organization_id: this.organization.id
                },
                loaded: false,
                submitting: false
            }
        },
        inject: ['api', 'auth', 'countries', 'organizations'],
        components: {},
        beforeDestroy() {},
        watch: {},
        mounted() {

            this.toggleModal('show');
            this.loaded = true;
            /*getUserProfile()
                .then(function(response){
                    self.agent = response.data;
                    self.agent.newsletter = self.agent.prompt & 4;
                    if(self.agent.interests && self.agent.interests.length > 0) {
                        self.agent.interests = self.agent.interests.map(i => i.id);
                    }
                    self.loaded = true;
                })
                .catch(function(error){
                    self.handleError(error);
                });*/

        },
        methods: {
            registerNewOffice() {

                let self = this;

                self.$validator.validateAll().then(function() {
                    self.submitting = true;

                    createOffice(self.agent)
                        .then(function(response){
                            self.$emit('load-profile');
                            self.submitting = false;
                            self.close();
                        })
                        .catch(function(error) {
                            self.handleError(error);
                            self.submitting = false;
                        });
                });
                /*this.submitting = true;

                try {
                    delete this.agent.office_id; // ignore current office to create a new one
                    this.agent.organization_id = this.org.id;
                    const res = await updateProfile(this.agent);
                    this.submitting = false;
                    this.$emit('load-profile');
                    this.close();

                } catch (err) {
                    this.submitting = false;
                    this.handleError(err);
                }*/
            },
            close() {
                this.toggleModal('hide');
                this.$router.push({name: 'agent.profile'});
            },
            toggleModal(status) {
                $(this.$el).modal(status);
            }
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>