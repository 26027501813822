<template>
    <label :class="componentClass" v-if="editable">
        <span class="position-relative" :class="{'d-inline-block': (!editing) && value, 'd-block pr-0': editing}" >
            <!--<i class="position-absolute clickable" :class="{'fas fa-check': saved, 'fas fa-save': editing, 'fas fa-pen': !(saved && editing)}" @click="handler"></i>-->
            <i class="position-absolute clickable fas fa-pen" v-if="status == 0" @click="status = 1"></i>
            <i class="position-absolute clickable fas fa-save" v-else-if="status == 1" @click="handler"></i>
            <em v-if="!editing">{{ value }}</em>
            <input class="form-control bg-transparent" v-else v-model="value" />
        </span>
    </label>
    <label v-else> {{ value }}</label>
</template>

<script>

    export default {
        props: {
            componentClass: {
                type: String,
                default: ''
            },
            componentHandler: {
                type: Function,
                required: true
            },
            componentParams: {
                default: null
            },
            componentValue: {
                type: String,
                default: ''
            },
            editable: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                value: this.componentValue,
                status: 0
            }
        },

        components: {

        },

        created() {

        },

        destroyed() {

        },

        watch: {

        },

        computed: {
            editing() {
                return this.status == 1;
            }
        },

        methods: {
            handler() {

                if(this.editing) {
                    this.componentHandler(this.value, this.componentParams);
                }
                else {
                    this.status = 1;

                }
            },

            resetStatus() {
                this.status = 0;
            }
        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

    span {
        &.position-relative {
            min-width: 300px;

            &.d-inline-block {
                border-bottom: 1px solid var(--main-color);

                em {
                    display: block;
                    width: 100%;
                    height: calc(1.5em + 0.75rem + 2px);
                    padding: 0.375rem 0.75rem;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                }
            }
            .position-absolute {
                &.fas {
                    top: 5px;
                    right: 5px;
                }
            }
        }
    }

</style>