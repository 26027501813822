<template>
    <multi-select :label="label"
                  :track-by="trackBy"
                  :data-vv-name="name"
                  :placeholder="isEmpty ? placeholder : ''"
                  :allow-empty="allowEmpty"
                  v-model="selected"
                  :multiple="multiple"
                  :searchable="searchable"
                  :options="lists"
                  :disabled="disabled"
                  :close-on-select="!multiple"
                  :clear-on-select="!multiple"
                  :internal-search="internalSearch"
                  :group-values="groupValues"
                  :group-label="groupLabel"
                  :group-select="groupSelect"
                  @search-change="startWith">
        <template slot="selection" slot-scope="{ values, search }" v-if="markup && values.length > 1">
            <span class="multiselect__single" v-if="values.length > 1">{{ values.length }} options selected</span>
        </template>
    </multi-select>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                required: true
            },
            label: {
                type: String,
                default: 'name'
            },
            trackBy: {
                type: String,
                default: 'id'
            },
            placeholder: {
                type: String,
                default: 'All'
            },
            allowEmpty: {
                type: Boolean,
                default: true
            },
            disabled: {
                type: Boolean,
                default: false
            },
            options: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            internalSearch: {
                type: Boolean,
                default: true
            },
            value: {
                type: [Array, Object],
                default: function() {
                    return []
                }
            },
            multiple: {
                type: Boolean,
                required: false
            },
            searchable: {
                type: Boolean,
                default: true
            },
            searchKey: {
                type: String,
                default: 'name'
            },
            markup: {
                type: Boolean,
                default: true
            },
            groupValues: {
                type: String,
                default: ''
            },
            groupLabel: {
                type: String,
                default: 'name'
            },
            groupSelect: {
                type: Boolean,
                default: false
            }

        },
        data() {
            return {
                lists: this.options,
                selected: this.value
            }
        },

        components: {

        },

        created() {

        },

        destroyed() {

        },

        watch: {
            'options': function(val, oldVal) {
                this.lists = val;
            },
            'value': function(val) {
                this.selected = val;
            },
            'selected': function(val) {
                this.$emit('select', val);
            }
        },

        computed: {
            isEmpty() {
                if($.isArray(this.selected)) {
                    return !this.selected.length;
                }
                else if($.isPlainObject(this.selected)) {
                    return $.isEmptyObject(this.selected);
                }
                else {
                    return !this.selected;
                }
            }
        },

        methods: {
            startWith(query = ''){
                let self = this;
                self.lists = [];
                if(!query) {
                    self.lists = self.options;
                    return true;
                }

                $.each(self.options, function (idx, item) {
                    if (item[self.label].toUpperCase().indexOf(query.toUpperCase()) == 0) {
                        self.lists.push(item);
                    }
                });
            }
        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" >

</style>