<template>

    <div id="agent-event">
        <app-page-banner page="agent-event" title="Webinars" subtitle="Any scheduled webinars that you have been invited to will appear here."></app-page-banner>


        <div :class="{'fixed-content-width': $root.fixedContentWidth }">

            <div class="row sm-p-2">
                <div class="col-12">

                    <ul class="nav nav-tabs">
                        <li class="nav-item mr-1">
                            <router-link class="nav-link" :to="{name: 'agent.events'}"><span>Scheduled</span></router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name: 'agent.videos'}"><span>Recorded</span></router-link>
                        </li>
                    </ul>
                </div>

            </div>

            <div v-if="!loaded" class="page-loading"></div>

            <div v-else class="row">

                <div class="col-sm-12">
                    <app-search-bar placeholder="Search webinars" :search-handler="search" :clear-handler="clear" ref="keywords"></app-search-bar>
                </div>

                <div class="col-12" v-show="pageCount > 1">
                    <paginate :page-count="pageCount"
                              :page-range="5"
                              :click-handler="navigate"
                              :prev-text="'<'"
                              :next-text="'>'"
                              :container-class="'pagination float-right'"
                              :page-class="'page-item'"
                              v-model="page"
                    ></paginate>
                </div>


                <div class="col-12 my-2" v-if="videos.total">

                    <table class="table table-hover">
                        <tbody>
                        <tr v-for="(e, index) in videos.data" :key="index">
                            <!--<td class="thumbnail d-none d-sm-block">
                                <img v-if="e.image" :src="e.image" class="img-responsive" onerror="this.style.display='none'"/>
                            </td>-->

                            <td>
                                <div class="wrap-link-details d-flex">
                                    <span class="left-border"></span>
                                    <div class="w-100">
                                        <h5 class="mb-0"><a class="link" :href="'/agent/videos/' + e.id" target="_blank">{{ e.name }}</a></h5>
                                        <p class="mb-1">{{ e.description }}</p>
                                        <p class="mb-1">
                                            <span class="badge badge-pill px-3 py-1 fs-90 font-weight-normal" :class="{'badge-success': e.view_count >= 50, 'badge-warning': e.view_count < 50}">
                                                {{ e.view_count }} {{ e.view_count > 1 ? ' views' : 'view' }}
                                            </span>
                                        </p>
                                        <small class="posted d-block">Posted on {{ e.created_at | convertDate('DD MMMM YYYY') }}</small>
                                    </div>
                                </div>

                                <!--<a class="jba-btn btn-xs my-2" :href="'/agent/videos/' + e.id" target="_blank">Watch</a>-->

                                <!--<div class="d-block d-sm-none">
                                    <img v-if="e.image" :src="e.image" class="thumbnail img-responsive my-2" onerror="this.style.display='none'" />
                                </div>-->

                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <!--<table class="table table-hover">
                        <thead>
                            <tr class="row">
                                <th class="col-10 col-sm-3 col-md-3 col-lg-2">Name</th>
                                <th class="col-2 d-block d-sm-none"></th>
                                <th class="col-sm-6 col-md-7 col-lg-8 d-none d-sm-inline-block">Description</th>
                                <th class="col-sm-3 col-md-2 col-lg-2 d-none d-sm-inline-block text-right"></th>
                            </tr>
                        </thead>

                        <tbody>

                        <template  v-for="(e, index) in videos.data">

                            <tr :key="index" class="row">
                                <td class="col-10 col-sm-3 col-md-3 col-lg-2">{{ e.name }}</td>
                                <td class="col-2 d-block d-sm-none text-center" @click.stop="expandRow($event)"><i class="fas fa-plus"></i></td>
                                <td class="col-sm-6 col-md-7 col-lg-8 d-none d-sm-inline-block">{{ e.description }}</td>
                                <td class="col-sm-3 col-md-2 col-lg-2 d-none d-sm-inline-block text-right">
                                    <a :href="'/agent/videos/' + e.id" class="jba-btn btn-xs" target="_blank">Watch</a>
                                </td>
                            </tr>


                            <tr :key="'tr'+index" class="row expand" style="display: none;">
                                <td class="col-12 d-block d-sm-none" colspan="2">
                                    <ul class="list-unstyled">
                                        <li class="mb-1">Description: <span>{{ e.description }}</span></li>

                                        <li class="mb-1">
                                            <a :href="'/agent/videos/' + e.id" class="jba-btn btn-xs" target="_blank">Watch</a>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>-->

                </div>

                <app-reminder :ready="loaded" :message="message" :new-line="true" :result="videos.data"></app-reminder>


                <div class="col-12" v-show="pageCount > 1">
                    <paginate :page-count="pageCount"
                              :page-range="5"
                              :click-handler="navigate"
                              :prev-text="'<'"
                              :next-text="'>'"
                              :container-class="'pagination float-right'"
                              :page-class="'page-item'"
                              v-model="page"
                    ></paginate>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getVideosForAgent } from '@/api/videos'
    export default {

        props: {

        },

        data(){
            return {
                videos: {},
                page: 1,
                message: 'You currently have no recorded webinars.'
            }
        },

        inject: ['api'],

        created() {
            this.loadVideos();
        },

        destroyed() {

        },

        watch: {

        },

        computed: {
            pageCount(){
                return Math.ceil(this.videos.total / this.videos.per_page);
            }
        },

        methods: {

            loadVideos(params = {}) {

                let self = this;

                getVideosForAgent(params)
                    .then(function(response){

                        self.videos = response.data;
                        self.loaded = true;
                    })
                    .catch(function(error){
                        self.handleError(error);
                        self.loaded = true;
                    })
            },

            navigate(page) {

                let params = {page: page};

                if(this.$refs.keywords.value) {
                    params.q = this.$refs.keywords.value;
                }

                this.loadVideos(params);
            },

            search(keywords) {
                this.loadVideos({q: keywords});

            },

            clear() {
                this.navigate(1);
            }
        },

        mounted() {

        }
    }
</script>