
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

//window.$ = require('jquery');
import 'select2';

import Vue from 'vue';
import VeeValidate from 'vee-validate';
//import adminRoutes from './routes/admin';
//import agentRoutes from './routes/agent';

import VueCkeditor from 'vue-ckeditor2';
//import VueRouter from 'vue-router';
import swal from 'sweetalert';

import VuejsPaginate from 'vuejs-paginate';
import VueSlick from 'vue-slick';
import VueTour from 'vue-tour';
import Scrollspy from 'vue2-scrollspy';
//import { abilitiesPlugin } from '@casl/vue';
//import { Can } from '@casl/vue';
import VueMultiSelect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import DatePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

//import ability from './ability.js';
import './Mixin';
import './VeeValidateRules';
import './VueFilters';
import 'vue-tour/dist/vue-tour.css';
import cssVars from 'css-vars-ponyfill';
import AppRouter from './routes/router';
//partials
Vue.component('AppInboxNotification', require('./components/partials/AppInboxNotification.vue').default);
Vue.component('AppPageBanner', require('./components/partials/AppPageBanner.vue').default);
Vue.component('AppTopNavBar', require('./components/partials/AppTopNavBar.vue').default);
Vue.component('AppSideBar', require('./components/partials/AppSideBar.vue').default);
Vue.component('AppFootBar', require('./components/partials/AppFootBar.vue').default);
Vue.component('AppVideoPlayer', require('./components/partials/AppVideoPlayer.vue').default);
Vue.component('AppSearchBar', require('./components/partials/AppSearchBar.vue').default);
Vue.component('AppAvatarUpdate', require('./components/partials/AppAvatarUpdate.vue').default);
Vue.component('AppAdvancedSearchBar', require('./components/partials/AppAdvancedSearchBar.vue').default);
Vue.component('AppFileUploader', require('./components/partials/AppFileUploader.vue').default);
Vue.component('AppReminder', require('./components/partials/AppReminder.vue').default);
Vue.component('AppVideoPrompt', require('./components/partials/AppVideoPrompt.vue').default);
Vue.component('AppTour', require('./components/partials/AppTour.vue').default);
Vue.component('AppTourComplete', require('./components/partials/AppTourComplete.vue').default);
Vue.component('AppMultipleSelectBox', require('./components/partials/AppMultipleSelectBox.vue').default);
Vue.component('CourseShow', require('./components/course/Show.vue').default);
Vue.component('AppCourseModule', require('./components/partials/AppCourseModule.vue').default);
Vue.component('AppCountryRegionCity', require('./components/partials/AppCountryRegionCity.vue').default);
Vue.component('AppSingleFieldUpdate', require('./components/partials/AppSingleFieldUpdate.vue').default);
Vue.component('AppBreadcrumb', require('./components/partials/AppBreadCrumb.vue').default);
Vue.component('AppStickyFlag', require('./components/partials/AppStickyFlag.vue').default);
Vue.component('AppAutoLogout', require('./components/partials/AppAutoLogout.vue').default);
Vue.component('paginate', VuejsPaginate);
Vue.component('MultiSelect', VueMultiSelect);
Vue.component('slick', VueSlick);
Vue.component('editor', VueCkeditor);

Vue.use(VeeValidate);
Vue.use(VueTour);
Vue.use(DatePicker);
Vue.use(Scrollspy);

let root = '#app';

let appRouter = new AppRouter(root, window.location.pathname.split('/')[1].toLowerCase());


cssVars({});

const app = new Vue({
	el: root,
	router: appRouter.generate(),
	data:{
        api: '/api/',
        auth: null,
        countries: null,
        languages: null,
        providerTypes: null,
        organizations: null,
        appName: null,
        maiEnz: null,
        unreadMessages: [],
        fixedContentWidth: true
	},

    watch:{
        $route (to, from){
            if(this.isMobileDevice() && this.$refs.sidebar.active) {
                this.$refs.sidebar.toggleSidebar();
            }
        }
    },

	methods:{

        loadAuth: function() {
            if(!this.auth && $(this.$el).find('.wrapper').length) {
                this.auth = JSON.parse(atob($(this.$el).find('.wrapper').data('auth')));
            //    this.$ability.update(this.auth.permissions);
            }

            return this.auth;
        },

		loadCountries: function() {
			if(!this.countries) {
				this.countries = $(this.$el).find('.wrapper').data('countries');
			}

			return this.countries;
		},

        loadLanguages: function() {
            if(!this.languages) {
                this.languages = $(this.$el).find('.wrapper').data('languages');
            }

            return this.languages;
        },

        loadProviderTypes: function() {
            if(!this.providerTypes) {
                this.providerTypes = $(this.$el).find('.wrapper').data('types');
            }

            return this.providerTypes;
        },

        loadOrganizations: function() {
            if(!this.organizations) {
                this.organizations = $(this.$el).find('.wrapper').data('organizations');
            }

            return this.organizations;
        },

        loadAppName: function() {
            if(!this.appName) {
                this.appName = $(this.$el).find('.wrapper').data('app-name');
            }

            return this.appName;
        },

        loadMaiEnz: function() {
            if(!this.maiEnz) {
                this.maiEnz = $(this.$el).find('.wrapper').data('maienz');
            }

            return this.maiEnz;
        }
	},
	computed: {

	},

	created: function() {
        this.loadAuth();
	},

	provide() {
        let self = this;
		return {
			api: this.api,
            appName: function(){ return self.loadAppName();},
            countries: function(){ return self.loadCountries(); },
            languages: function(){ return self.loadLanguages(); },
            providerTypes: function(){ return self.loadProviderTypes(); },
            organizations: function(){ return self.loadOrganizations(); },
            auth: function(){ return self.loadAuth(); },
            maiEnz: function(){ return self.loadMaiEnz(); }

		}
	},
	mounted() {

        let self = this;

        self.$root.$on('start-tour', function(data) {
            self.$refs.promptTour.$tours.appTour.start();
        });
	}

});


