import AgentEvent from '../components/agent/AgentEvent.vue';
import AgentVideo from '../components/agent/AgentVideo.vue';
import AgentHome from '../components/agent/AgentHome.vue';
import AgentDocument from '../components/agent/AgentDocument.vue';
import AgentDocumentShow from '../components/agent/AgentDocumentShow.vue';
import AgentSetting from '../components/agent/AgentSetting.vue';
import AgentProfileShow from '../components/agent/AgentProfileShow.vue';
import AgentMessage from '../components/agent/AgentMessage.vue';
import AgentMessageShow from '../components/agent/AgentMessageShow.vue';
import AgentCourse from '../components/agent/AgentCourse.vue';
import CoursePlay from '../components/course/Play.vue';
import AgentFeed from '../components/agent/AgentFeed.vue';
import AgentOrganization from '../components/agent/AgentOrganization.vue';
import AgentOffice from '../components/agent/AgentOffice.vue';
import AgentFaq from '../components/agent/AgentFaq.vue';
import AgentPrivacy from '../components/agent/AgentPrivacy.vue';
import AgentTerms from '../components/agent/AgentTerms.vue';

export default [
    {
        name: 'agent.home',
        path: '/home',
        component: AgentHome,
        props: true,
        children: [

        ]
    },

    {
        name: 'agent.setting',
        path: '/setting',
        component: AgentSetting,
        props: true,
        children: []
    },

    {
        name: 'agent.profile',
        path: '/profile',
        component: AgentProfileShow,
        props: true,
        children: [
            {
                name: 'agent.profile.organization',
                path: 'organization',
                component: AgentOrganization,
                props: true
            },
            {
                name: 'agent.profile.office',
                path: 'office',
                component: AgentOffice,
                props: true
            }
        ]
    },

    {
        name: 'agent.feeds',
        path: '/feed',
        component: AgentFeed,
        props: true,
        children: [

        ]
    },

    {
        name: 'agent.courses',
        path: '/courses',
        component: AgentCourse,
        props: true,
        children: [

        ]
    },

    {
        name: 'agent.documents',
        path: '/documents',
        component: AgentDocument,
        props: true,
        children: [
            {
                name: 'agent.documents.show',
                path: ':id(\\d+)',
                component: AgentDocumentShow,
                props: true
            }
        ]
    },

    {
        name: 'agent.messages',
        path: '/messages',
        component: AgentMessage,
        props: true,
        children: [
            {
                name: 'agent.messages.show',
                path: ':id(\\d+)/:unread?',
                component: AgentMessageShow,
                props: true
            }
        ]
    },

    {
        name: 'agent.events',
        path: '/events',
        component: AgentEvent,
        props: true,
        children: [

        ]
    },

    {
        name: 'agent.videos',
        path: '/events/share',
        component: AgentVideo,
        props: true,
        children: [

        ]
    },

    {
        name: 'course.play',
        path: 'courses/:id/:sub?/:subId?/play',
        component: CoursePlay,
        props: true
    },

    {
        name: 'agent.faq',
        path: '/faq',
        component: AgentFaq,
        props: true
    },

    {
        name: 'agent.privacy',
        path: '/privacy',
        component: AgentPrivacy,
        props: true
    },

    {
        name: 'agent.terms',
        path: '/terms',
        component: AgentTerms,
        props: true
    },

    {
        path: '/*',
        redirect: '/home'
    },


]
