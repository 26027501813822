<template>
    <div class="agent-profile">
        <app-page-banner page="agent-profile" title="My Setting" ></app-page-banner>

        <div v-if="!loaded" class="page-loading"></div>
        <div v-else :class="{'fixed-content-width': $root.fixedContentWidth }">
            <div class="row">
                <div class="col-12">

                    <ul class="nav nav-tabs mb-2">
                        <li class="nav-item mr-1">
                            <router-link class="nav-link" :to="{name: 'agent.profile'}"><span>Profile</span></router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name: 'agent.setting'}"><span>Settings</span></router-link>
                        </li>
                    </ul>
                </div>

                <div class="col-12">

                    <app-reminder :always="true" reminder-class="alert-info" message="Note: The following settings will only affect notifications from AgentLab.">
                        <template slot="prepend-icon"><i class="fa fa-info-circle mr-1"></i> </template>
                    </app-reminder>

                    <div class="form-group">
                        <label class="custom-control overflow-checkbox">
                            <input id="notification" type="checkbox" class="overflow-control-input"
                                   v-model="setting.stop_notification"
                                   @change="setNotification">
                            <span class="overflow-control-indicator"></span>
                            <span class="overflow-control-description">I don't want to receive notifications from AgentLab</span>
                        </label>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { getSettings, updateNotification } from '@/api/agents';
    export default {
        name: 'AgentSetting',

        props: {

        },

        data() {
            return {
                setting: {}
            }
        },

        inject: [],

        created() {
            let self = this;
            getSettings()
                .then(function(response){
                    self.setting = response.data;
                })
                .catch(function(error){
                    self.handleError(error);
                })
                .finally(function(){
                    self.loaded = true;
                })
        },

        watch: {

        },

        computed: {

        },

        methods: {
            setNotification() {

                let self = this;
                updateNotification({stop: self.setting.stop_notification})
                    .then(function(response){

                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            }

        },

        mounted() {


        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>