<template>

    <transition name="transition-model">

        <div class="staff modal fade show" id="create-staff-modal" tabindex="-1" role="dialog" aria-labelledby="Create Staff" @click="close">
            <div class="modal-dialog" role="document" @click.stop="function(){}">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Create staff</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </div>

                    <div class="modal-body pb-0">

                        <form novalidate>
                            <div class="form-group">
                                <label for="first_name">First name</label>
                                <input class="form-control" type="text" name="staff_first_name" id="first_name"
                                       v-validate="{required: true, max:20}"
                                       data-v-name="first name"
                                       v-model="form.first_name" />
                            </div>

                            <div class="form-group">
                                <label for="last_name">Last name</label>
                                <input class="form-control" type="text" name="staff_last_name" id="last_name"
                                       v-validate="{required: true, max:20}"
                                       data-v-name="last name"
                                       v-model="form.last_name" />
                            </div>

                            <div class="form-group">
                                <label for="email">Email</label>
                                <input class="form-control" type="email" name="staff_email" id="email"
                                       v-validate="{required: true, email: true}"
                                       data-v-name="email"
                                       v-model="form.email" />
                            </div>

                            <div class="form-group">
                                <label for="role">Role</label>
                                <app-multiple-select-box
                                        name="role"
                                        data-vv-name="role"
                                        v-validate="{required: true}"
                                        :value="form.role"
                                        @select="form.role = $event"
                                        :multiple="false"
                                        :options="roles"
                                        :internal-search="false"
                                        :class="{'is-invalid': errors.has('role')}">
                                </app-multiple-select-box>
                            </div>

                        </form>
                    </div>

                    <div class="modal-footer pt-0">
                        <button @click.stop="submit" class="jba-btn" :disabled="errors.any() || submitting"><i v-if="submitting" class="fas fa-spinner fa-spin"></i> Create</button>
                    </div>

                </div>
            </div>
        </div>
    </transition>

</template>

<script>
    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props:{

        },

        data(){
            return {
                submitting: false,
                role: {},
                form: {
                    first_name: null,
                    last_name: null,
                    email: null,
                    role: {}
                },
                roles: []
            }
        },

        inject: ['api'],

        created() {
            this.loadRoles();
        },

        methods: {

            loadRoles() {

                let self = this;

                axios.get(self.api + 'admin/staff/roles')
                    .then(function(response){
                        self.roles = response.data;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            close() {
                this.toggleModal('hide');
                this.$router.go(-1);
            },

            toggleModal(status) {
                $(this.$el).modal(status);
            },

            submit() {

                let self = this;

                this.$validator.validateAll().then(function() {

                    if(!self.errors.any()) {

                        let data = {
                            first_name: self.form.first_name,
                            last_name: self.form.last_name,
                            email: self.form.email,
                            roles: [self.form.role.id]
                        };

                        self.submitting = true;

                        axios.post(self.api + 'admin/staff', data)
                            .then(function(response) {
                                self.submitting = false;
                                self.$emit('load-staff');
                                self.close();
                                return true;
                            })
                            .catch(function(error){
                                self.submitting = false;
                                self.handleError(error);
                            });
                    }
                });
            }
        },

        computed: {

        },

        mounted() {
            this.toggleModal('show');
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" >

    .staff {
        .modal-header {
            border-bottom: none;
        }
        .modal-body {
            .list-inline-item {
                img {
                    /*height: 60px;*/
                }
            }
        }
        .modal-footer {
            border-top: none;
        }
        .avatar {
            position: relative;
            overflow: hidden;
            img {
                width: 105px;
                border: 5px solid var(--light-white-color);
                border-radius: 100%;
            }
            &:before {
                background: var(--main-color);
                content: '';
                width: 50%;
                height: 4px;
                position: absolute;
                left: -60px;
                top: 50%;
                margin-top: -2px;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    background: #22a2a2;
                }
            }

            &:after {
                background: var(--main-color);
                content: '';
                width: 50%;
                height: 4px;
                position: absolute;
                right: -60px;
                top: 50%;
                margin-top: -2px;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    background: #22a2a2;
                }
            }
        }
    }

</style>