import request from '@/api/request';

export function createOrganization (data = {}) {
    return request({
      url: `agent/organization`,
      method: 'POST',
      data: data
    });
}

export function loadOrgOffices (orgId) {
    return request({
      url: `organizations/${orgId}/offices`,
      method: 'GET',
    });
  }
  
export function loadRegionsByCountry (countryIso) {
  return request({
    url: `countries/${countryIso}/regions`,
    method: 'GET',
  });
}

export function loadCitiesByCountryAndRegion (countryIso, regionIso) {
  return request({
    url: `countries/${countryIso}/regions/${regionIso}/cities`,
    method: 'GET',
  });
}