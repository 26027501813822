<template>

    <nav id="sidebar" :class="{active: active}">
        <!--<div class="sidebar-header d-none d-md-block">
            <div class="hamburger hamburger&#45;&#45;collapse-r" :class="{'is-active': !active}" @click="toggleSidebar">
                <div class="hamburger-box">
                    <div class="hamburger-inner"></div>
                </div>
            </div>
            &lt;!&ndash;<a href="/">
                <img :src="logo" class="img-fluid logo" alt="enz logo">
            </a>&ndash;&gt;

        </div>

        <div class="sidebar-header d-block d-md-none">
            <div class="hamburger hamburger&#45;&#45;collapse-r is-active" @click="toggleSidebar">
                <div class="hamburger-box">
                    <div class="hamburger-inner"></div>
                </div>
            </div>
        </div>-->

        <ul class="list-unstyled components position-relative">
            <li v-if="auth().prompt_tour" class="tour badge badge-danger" @click.stop="tour()">
                <span><i class="fas fa-info-circle static"></i> Tour</span>
            </li>

            <li :id="m.id" v-for="(m, index) in sideBarMenu" :key="index" v-if="!m.permission || $can(m.permission.action, m.permission.object)">

                <a v-if="m.sub_menu_id"
                   data-toggle="collapse" aria-expanded="false" class="dropdown-toggle" :href="'#' + m.sub_menu_id">

                    <img :src="m.image"/>
                    <span class="text-uppercase">{{ m.name }}</span>
                </a>

                <router-link v-else :to="{name: m.route}" class="position-relative">
                    <li v-if="(m.id == 'agent-message-page') && unread.length" class="tour badge badge-danger fs-120">
                        <span><i class="fas fa-envelope static"></i> {{ unread.length }}</span>
                    </li>
                    <img :src="m.image"/>
                    <span class="text-uppercase">{{ m.name }}</span>
                </router-link>

                <ul v-if="m.sub_menu_id" class="collapse list-unstyled" :id="m.sub_menu_id">
                    <li v-for="(sm, i) in m.children" :key="i">
                        <router-link :key="i" :to="{name: sm.route}">{{ sm.name }}</router-link>
                    </li>
                </ul>
            </li>

        </ul>
    </nav>

</template>

<script>

    export default {
        props: {
            logo: {
                type: String,
                default: '/images/enz-logo.png'
            }

        },
        data() {
            return {
                active: false,
                unread: [],
                menu: {
                    admin: [

                    ],

                    agent: [

                    ]
                }
            }
        },

        inject: ['api', 'auth'],

        components: {

        },

        created() {

        },

        destroyed() {

        },

        computed: {
            sideBarMenu: {
                get: function(){
                    if(this.isAgent()) {
                        this.generateAgentMenu();
                    }
                    else if (this.isAdmin()) {
                        this.generateAdminMenu();
                    }
                    else {
                        this.handleError({message: 'Invalid menu type!'});
                        return;
                    }

                    return this.menu[this.auth().type];
                }
            }
        },

        methods: {
            toggleSidebar() {
                this.active = !this.active;
                this.$root.$emit('click-side-hamburger');

            },

            tour() {
                this.$root.$emit('start-tour');
            },

            generateAdminMenu() {
                this.menu.admin = [
                    {
                        id: 'admin-group-page',
                        name: 'Groups',
                        image: this.icons.admin.groups,
                        route: 'admin.groups',
                        permission: {action: 'view', object: 'group'}
                    },

                    {
                        id: 'admin-document-page',
                        name: 'Documents',
                        image: this.icons.admin.documents,
                        route: 'admin.documents',
                        permission: {action: 'view', object: 'document'}
                    },

                    {
                        id: 'admin-event-page',
                        name: 'Webinars',
                        image: this.icons.admin.webinars,
                        route: 'admin.events',
                        permission: {action: 'view', object: 'webinar'}
                    },

                    /*{
                        id: 'admin-agency-page',
                        name: 'Agencies',
                        image: this.icons.admin.agencies,
                        route: 'admin.agencies',
                        permission: {action: 'view', object: 'agency'}
                    },*/

                    {
                        id: 'admin-faq',
                        name: 'Covid-19 Faq',
                        image: this.icons.admin.faq,
                        route: 'admin.faq'
                    }
                ];

                if(this.isAdmin()) {

                    if (this.$can('view', 'dashboard')) {
                        this.menu.admin.splice(0, 0, {
                            id: 'admin-dashboard-page',
                            name: 'Dashboard',
                            image: this.icons.admin.dashboard,
                            route: 'admin.dashboard',
                            permission: {action: 'view', object: 'dashboard'},
                            children: []
                        });
                    }
                    else if (this.$can('view', 'report')) {
                        this.menu.admin.splice(0, 0, {
                            id: 'admin-report-page',
                            name: 'Reports',
                            image: this.icons.admin.dashboard,
                            route: 'admin.reports',
                            permission: {action: 'view', object: 'report'},
                            children: []
                        });
                    }

                    if (this.$can('view', 'news-feed')) {
                        this.menu.admin.splice(1, 0, {
                            id: 'admin-communication-page',
                            name: 'Comms',
                            image: this.icons.admin.feeds,
                            route: 'admin.feeds',
                            permission: {action: 'view', object: 'news-feed'},
                        });
                    }
                    else if (this.$can('view', 'message')) {
                        this.menu.admin.splice(1, 0, {
                            id: 'admin-communication-page',
                            name: 'Comms',
                            image: this.icons.admin.feeds,
                            route: 'admin.broadcasts',
                            permission: {action: 'view', object: 'message'},
                        });
                    }

                    if (this.$can('view', 'agent')) {
                        this.menu.admin.splice(2, 0, {
                            id: 'admin-agent-page',
                            name: 'Agents',
                            image: this.icons.admin.agents,
                            route: 'admin.agents',
                            permission: {action: 'view', object: 'agent'},
                        });
                    }
                    else if (this.$can('view', 'user')) {
                        this.menu.admin.splice(2, 0, {
                            id: 'admin-agent-page',
                            name: 'Users',
                            image: this.icons.admin.agents,
                            route: 'admin.staff',
                            permission: {action: 'view', object: 'user'},
                        });
                    }
                }

                if(!this.isMobileDevice() || this.isIOS() || this.isAndroid()) {

                    this.menu.admin.splice(3, 0, {
                        id: 'admin-course-page',
                        name: 'Courses',
                        image: this.icons.admin.courses,
                        route: 'admin.courses',
                        permission: {action: 'view', object: 'course'}
                    });
                }
            },

            generateAgentMenu() {

                this.menu.agent = [

                    {
                        id: 'agent-home-page',
                        name: 'Home',
                        image: '/images/navs/home.png',
                        route: 'agent.home'
                    },

                    /*{
                     id: 'agent-course-page',
                     name: 'Courses',
                     image: '/images/navs/learning.png',
                     route: 'agent.courses'
                     },*/

                    {
                        id: 'agent-news-page',
                        name: 'News',
                        image: '/images/navs/news.png',
                        route: 'agent.feeds'
                    },

                    {
                        id: 'agent-document-page',
                        name: 'Documents',
                        image: '/images/navs/docs.png',
                        route: 'agent.documents'
                    },

                    {
                        id: 'agent-event-page',
                        name: 'Webinars',
                        image: '/images/navs/webinar.png',
                        route: 'agent.events'
                    },

                    {
                        id: 'agent-message-page',
                        name: 'Messages',
                        image: '/images/navs/messages.png',
                        route: 'agent.messages'
                    },
                    {
                        id: 'agent-faq',
                        name: 'Covid-19 Faq',
                        image: '/images/navs/faq.png',
                        route: 'agent.faq'
                    },
                ];

                if(!this.isMobileDevice() || this.isIOS() || this.isAndroid()) {

                    this.menu.agent.splice(1, 0, {
                        id: 'agent-course-page',
                        name: 'Courses',
                        image: '/images/navs/learning.png',
                        route: 'agent.courses'
                    });
                }
            }
        },

        mounted() {
            let self = this;

            self.$root.$on('click-top-hamburger', function(data) {
                self.active = !self.active;
            });

            self.$root.$on('update-unread-message', function(data) {
                self.unread = data;
            });

            self.$root.$on('unread-message', function(data) {
                self.unread = data;
            });
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">



</style>