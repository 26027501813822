<template>
    <div class="">
        <div class="form-group">
            <label for="office-country">Country</label>
            <app-multiple-select-box
                id="country"
                name="country"
                label="name"
                trackBy="id"
                v-validate="{required: true}"
                data-vv-validate-on="select"
                placeholder="Select country"
                :value="countryValue"
                :multiple="false"
                :options="countryList"
                :internal-search="false"
                data-vv-as="country"
                @select="selectCountry($event)"
                :class="{'is-invalid': errors.has('country')}"
                :markup="false">
            </app-multiple-select-box>
        </div>
        <div class="form-group">
            <label for="office-region">Region</label>
            <app-multiple-select-box
                id="region"
                name="region"
                label="regionName"
                trackBy="regionName"
                v-validate="{required: true}"
                data-vv-validate-on="select"
                placeholder="Select region"
                :value="regionValue"
                :multiple="false"
                :options="regionList"
                :internal-search="false"
                data-vv-as="region"
                @select="selectRegion($event)"
                :class="{'is-invalid': errors.has('region')}"
                :markup="false"
                :disabled="!country.iso">
            </app-multiple-select-box>
        </div>
        <div class="form-group">
            <label for="office-city">City</label>
            <app-multiple-select-box
                id="city"
                name="city"
                label="cityName"
                trackBy="cityId"
                v-validate="{required: true}"
                data-vv-validate-on="select"
                placeholder="Select city"
                :value="cityValue"
                :multiple="false"
                :options="cityList"
                :internal-search="true"
                data-vv-as="city"
                @select="selectCity($event)"
                :class="{'is-invalid': errors.has('city')}"
                :markup="false"
                :disabled="!region.regionISO">
            </app-multiple-select-box>
            <small class="form-text">
                <i class="fas fa-info-circle text-main static"></i> Please select or type to search your city.
            </small>
        </div>
    </div>
</template>

<script>
    import {
        loadRegionsByCountry,
        loadCitiesByCountryAndRegion
    } from '@/api/organizations'

    export default {
        props: {},
        data() {
            return {
                country: {},
                region: {},
                city: {},
                countryList: [],
                regionList: [],
                cityList: [],
            }
        },
        inject: ['countries'],
        components: {},
        mounted() {
            this.countryList = this.countries();
        },
        destroyed() {},
        computed: {
            countryValue() {
                return this.country.iso ? this.country : null;
            },
            regionValue() {
                return this.region.regionISO ? this.region : null;
            },
            cityValue() {
                return this.city.cityId ? this.city : null;
            },
        },
        methods: {
            // handle selects
            async selectCountry(country) {
                if(!country || !country.iso) { // de-select country option
                    this.resetCity();
                    this.resetRegion();
                    return this.resetCountry();
                }
                try {
                    const res = await loadRegionsByCountry(country.iso);
                    this.resetCity();
                    this.resetRegion();
                    this.country = country;
                    this.regionList = res.data;
                } catch (error) {
                    this.handleError(error);
                }
            },
            async selectRegion(region) {
                if(!region || !region.regionISO) { // de-select region option
                    this.resetCity();
                    return this.resetRegion();
                }
                try {
                    const res = await loadCitiesByCountryAndRegion(this.country.iso, region.regionISO);
                    this.resetCity();
                    this.region = region;
                    this.cityList = res.data;
                } catch (error) {
                    this.handleError(error);
                }
            },
            selectCity (city) {
                if(!city || !city.cityId) { // de-select city option
                    return this.resetCity();
                }
                this.city = city;
                this.$emit('select', city.cityId);
            },
            // handle resets
            resetCountry() {
                if(this.regionList.length > 0) this.regionList = [];
                if(this.country.iso) this.country = {};
            },
            resetRegion() {
                if(this.cityList.length > 0) this.cityList = [];
                if(this.region.regionISO) this.region = {};
            },
            resetCity() {
                this.$emit('select', null);
                if(this.city.cityId) this.city = {};
            }
        },
    }
</script>

<style rel="stylesheet/scss" lang="scss">


    .country-region-city {
        .dropdown-menu {
            height: 300px;
            width: 100% !important;
            .dropdown-item {

                cursor: pointer;

                &.selected {
                    background: var(--main-color);
                    color: var(--white-color);
                }

                &:hover {
                    background: var(--main-color);
                    color: var(--white-color);
                }
            }
        }
    }
    .multiselect {
        border-radius: 5px;
    }


</style>