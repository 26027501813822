<template>
    <nav class="navbar">
        <div class="navbar-brand" :class="{'expand': sidebar}">

            <div :class="{'is-active': sidebar}" class="hamburger hamburger--collapse d-inline-block d-md-none" @click="toggleSidebar">
                <div class="hamburger-box">
                    <div class="hamburger-inner"></div>
                </div>
            </div>

            <a href="/">
                <img :src="logo" class="img-fluid logo" alt="enz logo">
            </a>
        </div>

        <app-top-nav-bar-menu :limited="limited"></app-top-nav-bar-menu>
    </nav>
</template>

<script>
    import AppTopNavBarMenu  from './AppTopNavBarMenu.vue';
    export default {
        props: {
            logo: {
                type: String,
                default: '/images/svg/enz-logo.svg'
            },
            limited: {
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {
                sidebar: false
            }
        },

        inject: ['auth'],

        components: {
            AppTopNavBarMenu
        },

        created() {

        },

        destroyed() {

        },

        computed: {

        },

        methods: {
            toggleSidebar() {
                this.sidebar = !this.sidebar;
                this.$root.$emit('click-top-hamburger');
            }
        },

        mounted() {
            this.$root.$on('click-side-hamburger', data => {
                this.sidebar = !this.sidebar;
            });
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>