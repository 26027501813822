<template>
    <div class="">
        <div class="row fixed-content-width">
            <div class="col-12">
                <h2 class="mt-4">AgentLab - Terms & Condition</h2>
                <p class="mb-5">This website is owned and maintained by Education New Zealand, a Crown Entity funded by the New Zealand Government.</p>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'AgentTerms',

        data() {
            return {

            }
        },

        inject: [],

        components: {
        },

        created() {

        },

        computed: {

        },

        methods: {

        },

        mounted() {
            this.loaded = true;
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>