<template>
    <div v-if="showReminder" :class="{'col-12': newLine}">
        <div class="alert my-2" :class="reminderClass">
            <slot name="prepend-icon"></slot> {{ searching ? 'Sorry, there were no match with your search.' : message }}
        </div>
    </div>

</template>

<script>
    export default {
        props: {
            ready: {
                type: Boolean,
                default: false
            },

            always: {
                type: Boolean,
                default: false
            },

            newLine: {
                type: Boolean,
                default: false
            },

            reminderClass: {
                type: String,
                default: 'alert-success'
            },

            message: {
                type: String,
                default: null
            },

            result: {
                type: Array,
                default: function(){
                    return [];
                }
            }

        },
        data() {
            return {

            }
        },

        components: {

        },

        created() {

        },

        destroyed() {

        },

        computed: {
            showReminder() {
                return this.always || (this.ready && !this.result.length);
            },

            searching() {
                return this.$parent.$refs.keywords
                    && this.$parent.$refs.keywords.value
                    && this.$parent.$refs.keywords.value.length;
            }
        },

        methods: {

        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>