<template>
    <div class="clearfix col-12">
        <!-- <div v-if="agentList.length > 0" class="row"> -->
            <!-- <div v-if="handleSearch !== null" class="col-12 col-md-8 d-flex" style="padding-left:0px !important">
                <input
                    v-model="searchQuery"
                    type="text"
                    class="form-control search-bar"
                    name="search-agent-table">
                <i class="fas fa-times reset-btn" @click="clear"></i>
                <button @click="handleSearch(searchQuery)" class="jba-btn search-btn"><i class="fas fa-search"></i></button>
            </div> -->
            <paginate v-if="pageCount > 1"
                    :page-count="pageCount"
                    :page-range="5"
                    :click-handler="navigate"
                    :prev-text="'<'"
                    :next-text="'>'"
                    style="padding-right:0px !important"
                    :container-class="'pagination float-right col-12 col-md-4 j-end'"
                    :page-class="'page-item'"
                    v-model="page"
            ></paginate>
        <!-- </div> -->

        <table class="table table-hover">
            <thead>
                <tr class="row">

                    <th class="col-10 col-sm-6 col-md-4 col-lg-4">Name</th>
                    <th class="col-2 d-block d-sm-none"></th>
                    <th class="col-sm-6 col-md-5 col-lg-3 d-none d-sm-inline-block">Email Address</th>
                    <th class="col-md-3 col-lg-3 d-none d-md-inline-block">Immigration Adviser</th>
                    <th class="col-lg-2 d-none d-lg-inline-block">Belong to {{ appName() }} recognised agency</th>
                </tr>
            </thead>
            <tbody v-if="agentList.length > 0">
                <template  v-for="(a, index) in agentList">
                <!--<router-link tag="tr" v-for="(a, index) in agents.data" :key="index" :to="{name:'admin.agents.show', params: {id: a.id}}" class="clickable row">-->
                <tr :key="a.id" class="row">
                    <td class="col-10 col-sm-6 col-md-4 col-lg-4">
                        <label class="custom-control overflow-checkbox d-inline-block mb-0" v-if="$can('create', 'group') || $can('update', 'group')">
                            <input type="checkbox" class="overflow-control-input" v-model="a.selected" @click.stop="toggleAgent(index)">
                            <span class="overflow-control-indicator"></span>
                            <span class="overflow-control-description">{{ a.full_name }}</span>
                        </label>
                        <span v-else>{{ a.full_name }}</span>
                    </td>
                    <td class="col-2 d-block d-sm-none text-center" @click.stop="expandRow($event)"><i class="fas fa-plus"></i></td>
                    <td class="col-sm-6 col-md-5 col-lg-3 d-none d-sm-inline-block">{{ a.email }}</td>
                    <td class="col-md-3 col-lg-3 pl-4 d-none d-md-inline-block"><span v-if="a.immigration_adviser">{{ a.immigration_license }}</span></td>
                    <td class="col-lg-2 d-none d-lg-inline-block pl-4"><i v-if="a.organization_id && a.organization.enzra_agency" class="fas fa-check"></i></td>
                </tr>
                <tr :key="index+'-expand'" class="row expand" style="display: none;">
                    <td class="col-12 d-block d-sm-none" colspan="2">
                        <ul class="list-unstyled">
                            <li class="text-truncate"><i class="far fa-envelope"></i> {{ a.email }}</li>
                            <li v-if="a.immigration_adviser"><i class="fas fa-award"></i> Immigration Licence {{ a.immigration_license }}</li>
                            <li v-if="a.organization_id && a.organization.enzra_agency"><i class="fas fa-award"></i> Recognized agency</li>
                        </ul>
                    </td>
                </tr>

                <!--</router-link>-->
                </template>
            </tbody>
        </table>
        <app-reminder :ready="ready" :message="tableMessage" reminder-class="alert-warning" :result="agentList"></app-reminder>

        <div v-show="pageCount > 1">
            <paginate :page-count="pageCount"
                        :page-range="5"
                        :click-handler="navigate"
                        :prev-text="'<'"
                        :next-text="'>'"
                        :container-class="'pagination float-right'"
                        :page-class="'page-item'"
                        v-model="page"
            ></paginate>
        </div>
    </div>
</template>
<script>
    export default {
        inject: ['appName'],
        props: {
            agentList: {
                type: Array,
                default: () => []
            },
            totalAgents: {
                type: Number,
                default: () => 0
            },
            navigate: {
                type: Function,
                required: true
            },
            toggleAgent: {
                type: Function,
                required: true
            },
            perPage: {
                type: Number,
                default: () => 1
            },
            ready: {
                type: Boolean,
                default: () => true
            },
            handleSearch: {
                type: Function,
            },
            tableMessage: {
                type: String,
                default: () => 'There are no agents in this group'
            }
        },
        data() {
            return {
                page: 1,
                searchQuery: '',
                hasSearched: false
            };
        },
        computed: {
            pageCount(){
                return Math.ceil(this.totalAgents / this.perPage);
            },
        },
        methods: {
            expandRow(e) {
                let expand = $(e.currentTarget).closest('tr').next('.expand');
                expand.toggle();
                if(expand.is(":visible")) {
                    $(e.currentTarget).find('.fa-plus').addClass('fa-minus').removeClass('fa-plus');
                } else {
                    $(e.currentTarget).find('.fa-minus').addClass('fa-plus').removeClass('fa-minus');
                }
            },
            clear () {
                if(!this.searchQuery) return;
                this.searchQuery = '';
                this.handleSearch('');
            },
        },
        mounted() {

        }
    }
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .search-btn {
        margin-top: 15px;
        height: 38px;
        border-radius: 0 0.25rem 0.25rem 0;
        > .fas:hover {
            background: initial;
        }
    }
    .reset-btn {
        position:absolute;
        right: 115px;
        top: 28px;
        cursor: pointer;
        padding: 6px 8px;
        border-radius: 20px;
        color: #b6b6b6;
        &:hover {
            color: white;
            background: #b6b6b6; 
        }
    }
    .search-bar {
        margin-top: 15px;
        border-radius: 0.25rem 0 0 0.25rem;
    }
    .j-end {
        justify-content: flex-end;
    }
</style>