import AgentSetAgency from '../components/agent/AgentSetAgency.vue';

export default [
    {
        name: 'agent.profile.agency',
        path: '/',
        component: AgentSetAgency,
        props: true,
        children: [
        ]
    },
    {
        path: '/*',
        redirect: '/'
    },
];