<template>
    <div class="agent-course">
        <app-page-banner page="agent-course" title="Courses" subtitle="These interactive courses will help you understand the New Zealand education system and what it's like to live and study here"></app-page-banner>

        <div v-if="!loaded" class="page-loading"></div>
        <div v-else :class="{'fixed-content-width': $root.fixedContentWidth }">
            <div class="row sm-p-2">

                <div class="col-12" v-for="(c, index) in courses.data" :key="index">
                    <app-course-module :course="c"></app-course-module>
                </div>

                <app-reminder :ready="loaded" message="There is no course at this moment" :new-line="true" :result="courses.data"></app-reminder>

            </div>
        </div>
        <!--<router-view></router-view>-->
    </div>
</template>

<script>
    export default {
        props: {

        },
        data() {
            return {
                courses: [],
                page: 1
            }
        },

        inject: ['api', 'auth'],

        components: {

        },

        created() {
            this.loadCourses();
        },

        destroyed() {

        },

        computed: {
            pageCount(){
                return Math.ceil(this.courses.total / this.courses.per_page);
            }
        },

        watch: {

        },

        methods: {
            search(keywords) {

                this.loadCourses({q: keywords});
            },

            navigate(page) {

                let params = {page: page};

                if(this.$refs.keywords.value) {
                    params.q = this.$refs.keywords.value;
                }

                this.loadCourses(params);
            },

            loadCourses(params = {}) {

                let self = this;

                axios.get(this.api + 'agent/courses', {params: params})
                    .then(function(response){
                        self.courses = response.data;
                        self.page = params.page || 1;
                        self.loaded = true;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            clear() {
                this.navigate(1);
            },

            play(course) {

                if(!course.id) {
                    return false;
                }

                let self = this;

                let query = {
                    token: self.auth().api_token
                };

                if(course.modules) {
                    course.modules.forEach(function (o) {

                        if (o.agents.length) {
                            query['module' + o.game_module] = o.agents[0].status.score;
                        }
                    });
                }

                self.$router.push({name: 'course.play', params: {id: course.id}, query: query});

            }

        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>