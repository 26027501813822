<template>
    <div id="admin-agency-review" class="agency-review">

        <div class="row sm-p-2" v-show="isCurrentRoute('admin.agencies.pending.review.merge')">
            <div class="col-12">

                <app-breadcrumb></app-breadcrumb>

            </div>

            <div class="col-12">
                <div class="merge-panel">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <h5 class="pb-2 mb-2 border-bottom d-inline-block">{{ $parent.organization.agency_name }}</h5>

                            <ul class="list-unstyled tree" v-if="$parent.organization.locations">
                                <li v-for="(e, index) in $parent.organization.locations"><i class="far fa-building"></i> {{ e.city_name }}, {{ e.country_name }}</li>
                            </ul>
                        </div>

                        <div class="col-12 col-sm-6">
                            <h5 class="pb-2 mb-2 border-bottom d-inline-block">{{ target.agency_name }}</h5>

                            <ul class="list-unstyled tree" v-if="target.locations">
                                <li v-for="(e, index) in target.locations.filter(e => !e.merged)"><i class="far fa-building"></i> {{ e.city_name }}, {{ e.country_name }}</li>
                            </ul>
                        </div>

                        <div class="col-sm-8 col-md-8 col-lg-7">
                            <div class="merge-graph d-none d-sm-block"></div>
                        </div>

                        <div class="col-12 col-sm-4 offset-sm-4 col-lg-6 offset-lg-3">
                            <i class="fas fa-sort-amount-down fa-3x ml-5 static text-main"></i>
                        </div>

                        <div class="col-12 col-sm-6 offset-sm-3">
                            <h5 class="pb-2 mb-2 border-bottom d-inline-block">{{ target.agency_name }}</h5>

                            <ul class="list-unstyled tree" v-if="target.locations">
                                <li v-for="(e, index) in target.locations" :class="{'text-bold': e.merged}"><i class="fas fa-angle-double-left" v-if="e.merged"></i> <i class="far fa-building"></i> {{ e.city_name }}, {{ e.country_name }}</li>
                            </ul>

                            <div class="mt-5">
                                <button class="jba-btn btn-xs">Continue</button>

                                <router-link tag="button" class="jba-btn btn-danger btn-xs" :to="{name: 'admin.agencies.pending.review', params: {sdId: sdId}}">
                                    Cancel
                                </router-link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



        </div>

    </div>
</template>

<script>

    export default {
        props: {
            sdId: {
                type: Number,
                required: true
            },
            index: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                target: {}
            }
        },

        inject: ['api'],

        components: {

        },

        created() {

        },

        destroyed() {

        },

        computed: {

        },

        methods: {

        },

        mounted() {

            let self = this;

            for(let i in self.$parent.organization.similar_agencies) {
                if(i == self.index) {
                    self.target = $.extend({}, self.$parent.organization.similar_agencies[i]);
                    break;
                }
            }

            self.target.locations = self.arrayMerge(self.target.locations, self.$parent.organization.locations, 'city_id', 'merged');

            if(!self.target.id) {
                self.handleError({error: 'No target agency is found!'});
                self.$router.back();
            }
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">


</style>