<template>
    <div id="video">

        <div class="row sm-p-2">
            <div class="col-12">

                <ul class="nav nav-tabs">
                    <li class="nav-item mr-1" v-if="$can('view', 'webinar')">
                        <router-link class="nav-link" :class="{active: isRoutePattern('^admin\.events')}" :to="{name: 'admin.events'}"><span>Scheduled</span></router-link>
                    </li>
                    <li class="nav-item" v-if="$can('create', 'webinar')">
                        <router-link class="nav-link" :class="{active: isRoutePattern('^admin\.videos')}" :to="{name: 'admin.videos'}"><span>Recorded</span></router-link>
                    </li>
                </ul>
            </div>

            <div class="col-12">
                <router-link :to="{name: 'admin.videos.create'}" class="jba-btn btn-info" v-if="$can('create', 'webinar')">Share webinar</router-link>
            </div>
        </div>

        <div v-if="!loaded" class="page-loading"></div>

        <div v-else class="row">

            <div class="col-12 col-sm-4">
                <h5 class="text-main mb-0">Recorded Videos</h5>
            </div>

            <div class="col-12 col-sm-8">
                <app-search-bar placeholder="Search webinars" :search-handler="search" :clear-handler="clear" ref="keywords"></app-search-bar>
            </div>

            <div class="col-12" v-show="pageCount > 1">
                <paginate :page-count="pageCount"
                          :page-range="5"
                          :click-handler="navigate"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :container-class="'pagination float-right'"
                          :page-class="'page-item'"
                          v-model="page"
                ></paginate>
            </div>

            <div class="col-12 mt-3">
                <table class="table table-hover" v-if="videos.total">
                    <thead>
                    <tr class="row">
                        <th class="col-10 col-sm-3 col-md-2 col-lg-2">Name</th>
                        <th class="col-2 d-block d-sm-none"></th>
                        <th class="col-md-4 col-lg-4 d-none d-md-inline-block">Description</th>
                        <th class="col-sm-4 col-md-3 col-lg-3 d-none d-sm-inline-block">Groups</th>
                        <th class="col-sm-2 col-md-1 col-lg-1 d-none d-sm-inline-block">Views</th>
                        <th class="col-sm-3 col-md-2 col-lg-2 d-none d-sm-inline-block text-right"></th>
                    </tr>
                    </thead>

                    <tbody>
                        <template  v-for="(e, index) in videos.data">

                            <tr :key="index" class="row">
                                <td class="col-10 col-sm-3 col-md-2 col-lg-2"><a :href="e.url" class="link" target="_blank" :title="e.url"><i class="fas fa-external-link-alt static p-0"></i> {{ e.name }}</a></td>
                                <td class="col-2 d-block d-sm-none text-center" @click.stop="expandRow($event)"><i class="fas fa-plus"></i></td>
                                <td class="col-md-4 col-lg-4 d-none d-md-inline-block">{{ e.description }}</td>
                                <td class="col-sm-4 col-md-3 col-lg-3 d-none d-sm-inline-block">{{ join(e.groups) }}</td>
                                <td class="col-sm-2 col-md-1 col-lg-1 d-none d-sm-inline-block">{{ e.view_count }}</td>
                                <td class="col-sm-3 col-md-2 col-lg-2 d-none d-sm-inline-block text-right">
                                    <router-link class="jba-btn btn-xs" :to="{name: 'admin.videos.edit', params: {id: e.id}}">
                                        Edit
                                    </router-link>
                                </td>
                            </tr>


                            <tr :key="'tr'+index" class="row expand" style="display: none;">
                                <td class="col-12 d-block d-sm-none" colspan="2">
                                    <ul class="list-unstyled">
                                        <li class="mb-1">Description: <span>{{ e.description }}</span></li>
                                        <li class="mb-1">URL: <a :href="e.url" class="link text-break">{{ e.url }}</a></li>
                                        <li class="mb-1">Groups: {{ join(e.groups) }}</li>
                                        <li class="mb-1">Views: {{ e.view_count }}</li>
                                        <li class="mb-1">
                                            <router-link class="jba-btn btn-xs" :to="{name: 'admin.events.edit', params: {id: e.id}}">
                                                Edit
                                            </router-link>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>

                <app-reminder :ready="loaded" :message="message" :result="videos.data"></app-reminder>
            </div>

            <div class="col-12" v-show="pageCount > 1">
                <paginate :page-count="pageCount"
                          :page-range="5"
                          :click-handler="navigate"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :container-class="'pagination float-right'"
                          :page-class="'page-item'"
                          v-model="page"
                ></paginate>
            </div>
        </div>

        <router-view :video="currentVideo()" @load-videos="navigate(page)"></router-view>
    </div>
</template>

<script>
    import { getVideos } from '@/api/videos'
    export default {

        props: {

        },

        data(){
            return {
                groups: [],
                videos: {
                    data: []
                },
                page: 1,
                submitting: false,
                loaded: false,
                message: 'There is no recorded event at the moment'
            }
        },

        inject: ['api'],

        created() {
            this.loadVideos();
        },

        destroyed() {

        },

        watch: {

        },

        computed: {
            pageCount(){
                return Math.ceil(this.videos.total / this.videos.per_page);
            }
        },

        methods: {

            loadVideos(params = {}) {

                let self = this;

                getVideos(params)
                    .then(function(response){

                        self.videos = response.data;
                        self.loaded = true;
                    })
                    .catch(function(error){
                        self.handleError(error);
                        self.loaded = true;
                    })
            },

            navigate(page) {

                let params = {page: page};

                if(this.$refs.keywords.value) {
                    params.q = this.$refs.keywords.value;
                }

                this.loadVideos(params);
            },

            search(keywords) {

                this.loadVideos({q: keywords});

            },

            clear() {
                this.navigate(1);
            },

            currentVideo() {

                for(let i = 0; i < this.videos.data.length; i++){
                    if(this.videos.data[i].id == this.$route.params.id) {
                        return this.videos.data[i];
                    }
                }

                return {groups:[]};
            }
        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss"  scoped>

</style>