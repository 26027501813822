import Vue from 'vue';

Vue.filter('convertDate', function(date, format = null, timezone = null) {
    // 'Pacific/Auckland'
    if(date) {
        //format = format || 'DD MMM';
     
        if(format) {

            if(timezone) {
                return moment.utc(date).tz(timezone).format(format);
            }

            return moment.utc(date).local().format(format);
        }

        let timestamp = moment.utc(date).unix();

        let now = moment().unix();

        if (now - timestamp > 0 && now - timestamp < 86400) {
            if(timezone) {
                //In fact, we don't need it, as no time difference between different timezone
                return moment.utc(date).tz(timezone).format('HH:mm');
            }

            return moment.utc(date).local().format('HH:mm');

        }
        else {
            if(timezone) {
                return moment.utc(date).tz(timezone).format('DD MMM');
            }
            return moment.utc(date).local().format('DD MMM');
        }

    }

    return date;
});

Vue.filter('convertToUTCDate', function(date, format) {
    if(date && format) {
        return moment(date, format).utc().format(format);
    }
});

Vue.filter('convertToNZDate', function(date, format) {
    if(date && format) {
        return moment(date).tz('Pacific/Auckland').format(format);
    }
});

Vue.filter('convertUTCToNZDate', function(date, format) {
    if(date && format) {
        return moment.utc(date).tz('Pacific/Auckland').format(format);
    }
});


Vue.filter('bytesToSize', function formatBytes(bytes, decimals = 2) {
    if (!bytes) {
        return 'N/A';
    }

    let k = 1024,
        dm = decimals <= 0 ? 0 : decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];

});

/*Vue.filter('advisorBadge', function(num) {
    if (num == 1) {
        return '/images/badges/advisor.png';
    } else {
        return '/images/badges/advisor-grey.png';
    }

});

Vue.filter('recognisedBadge', function(date) {
    if (date == null) {
        return '/images/badges/reconised-grey.png';
    } else {
        return '/images/badges/reconised.png';
    }
});*/

/*Vue.filter('arrayFilter', function(data, filter) {

    let self = this;
    let filtered = null;

    $.each(filter, function(k, v){
        filtered = self.arraySearchAll(data, v, k);
    });

    return filtered;
});*/

