<template>
  <div class="agent-faq p-3 fixed-content-width">
    <div v-if="!loaded" class="page-loading"></div>
    <div class="row sm-p-2" v-else>
      <div class="col-12">
        <h3 class="text-main">Education Agent FAQs</h3>
      </div>
    </div>
    <div class="col-12">
      <div id="accordion">
        <agent-faq-item v-for="faq in faqs" :key="faq.id" :faq="faq" />
      </div>
    </div>
  </div>
</template>

<script>
  import { agentList } from '@/api/faqs'
  import AgentFaqItem from '@/components/agent/AgentFaqItem'

  export default {
    name: 'agent-faq',

    data() {
      return {
        faqs: [],
        loaded: false
      }
    },

    inject: ['api', 'auth', 'appName'],

    components: {
      AgentFaqItem
    },

    created() {

    },

    computed: {

    },

    methods: {

    },

    mounted() {
      agentList().then(response => {
        this.faqs = response.data
        this.loaded = !this.loaded
      })
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>
