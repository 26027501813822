<template>

    <div class="advanced-search">
        <div class="row">
            <div v-if="showField('keywords')" class="col-12" :class="{'col-sm-6 col-md-4': moreOption}">
                <div class="form-group">
                    <label>Keywords</label>
                    <input class="form-control" type="text" name="q" id="keyword"
                           v-model="search.q" />
                </div>
            </div>

            <div v-if="showField('organizations')" class="col-12 col-sm-6 col-md-4">
                <div class="form-group">
                    <label>Organisations</label>

                    <app-multiple-select-box
                            name="organizations"
                            data-vv-name="organizations"
                            v-validate="{required: false}"
                            :value="search.organizations"
                            @select="search.organizations = $event"
                            :multiple="true"
                            :options="confirmedOrganizations"
                            :internal-search="false"
                            :class="{'is-invalid': errors.has('organizations')}">
                    </app-multiple-select-box>

                </div>
            </div>

            <div v-if="showField('countries')" class="col-12 col-sm-6 col-md-4" >
                <div class="form-group">
                    <label>Countries</label>

                    <app-multiple-select-box
                            name="countries"
                            data-vv-name="countries"
                            v-validate="{required: false}"
                            :multiple="true"
                            :options="countries()"
                            :internal-search="false"
                            :value="search.countries"
                            @select="search.countries = $event"
                            :class="{'is-invalid': errors.has('countries')}">
                    </app-multiple-select-box>
                </div>
            </div>

            <div v-if="showField('interests')" class="col-12 col-sm-6 col-md-4">
                <div class="form-group">
                    <label>Interested Sector</label>

                    <app-multiple-select-box
                            name="interests"
                            data-vv-name="interests"
                            v-validate="{required: false}"
                            :multiple="true"
                            :options="providerTypes()"
                            :internal-search="false"
                            :value="search.interests"
                            @select="search.interests = $event"
                            :class="{'is-invalid': errors.has('interests')}">
                    </app-multiple-select-box>
                </div>
            </div>

            <div v-if="showField('groups')" class="col-12 col-sm-6 col-md-4">
                <div class="form-group">
                    <label for="groups">Groups</label>

                    <app-multiple-select-box
                            id="groups"
                            name="groups"
                            data-vv-name="groups"
                            v-validate="{required: false}"
                            :multiple="true"
                            :options="groups"
                            :internal-search="false"
                            :value="search.groups"
                            @select="search.groups = $event"
                            :class="{'is-invalid': errors.has('groups')}">
                    </app-multiple-select-box>
                </div>
            </div>

            <div v-if="showField('modules')" class="col-12 col-sm-6 col-md-4">
                <div class="form-group">
                    <label for="course-modules">Course Modules</label>

                    <app-multiple-select-box
                            id="course-modules"
                            name="modules"
                            data-vv-name="modules"
                            v-validate="{required: false}"
                            data-vv-validate-on="select"
                            :allow-empty="true"
                            :multiple="true"
                            :options="modules"
                            group-values="modules"
                            group-label="name"
                            :group-select="true"
                            track-by="name"
                            label="name"
                            data-vv-as="modules"
                            :value="search.modules"
                            @select="search.modules = $event"
                            :class="{'is-invalid': errors.has('modules')}"
                            :markup="true">
                    </app-multiple-select-box>
                </div>
            </div>

            <div v-if="showField('date_from')" class="col-12 col-sm-6 col-md-4">
                <div class="form-group">
                    <label for="date-from">Date from </label>
                    <date-picker id="date-from" name="date_from" readonly
                                 :class="{'is-invalid': errors.has('date_from')}"
                                 v-model="search.date_from"
                                 :config="datePickerOptions"
                                 v-validate="{required: false}"></date-picker>
                    <small v-show="errors.has('date_from')" class="text-danger">{{ errors.first('date_from') }}</small>
                </div>
            </div>

            <div v-if="showField('date_to')" class="col-12 col-sm-6 col-md-4">
                <div class="form-group">
                    <label for="date-from">Date to </label>
                    <date-picker id="date-to" name="date_to" readonly
                                 :class="{'is-invalid': errors.has('date_to')}"
                                 v-model="search.date_to"
                                 :config="datePickerOptions"
                                 v-validate="{required: false}"></date-picker>
                    <small v-show="errors.has('date_to')" class="text-danger">{{ errors.first('date_to') }}</small>
                </div>
            </div>

            <div v-if="showField('period')" class="col-12 col-sm-6 col-md-4">
                <div class="form-group">
                    <label for="period">Period</label>

                    <div class="form-check form-check-inline pt-2">

                        <div class="custom-control custom-radio half-width">
                            <input type="radio" id="period_monthly" name="period_monthly" class="custom-control-input" value="1" v-model="search.period">
                            <label class="custom-control-label" for="period_monthly">Monthly</label>
                        </div>

                        <div class="custom-control custom-radio half-width">
                            <input type="radio" id="period_yearly" name="period_yearly" class="custom-control-input" value="12" v-model="search.period">
                            <label class="custom-control-label" for="period_yearly">Yearly</label>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="showField('sectors')" class="col-12 col-sm-6 col-md-4">
                <div class="form-group">
                    <label>Education sectors</label>

                    <div id="sector-provider-dropdown" class="dropdown keep-open">
                        <div class="dropdown-toggle" role="button" id="sector-menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                            <input type="text"
                                class="form-control text-left"
                                :value="selectedProviders" placeholder="All"/>

                            <i role="presentation" class="open-indicator"></i>
                            <i v-show="selectedProviders" class="fa fa-times" @click.stop="resetSector"></i>
                        </div>

                        <ul class="dropdown-menu px-2 list-unstyled">
                            <li v-for="(i, index) in providerMenu" :key="index">
                                <label class="custom-control material-checkbox mb-0">
                                    <input type="checkbox" class="material-control-input"
                                           v-model="search.provider_types"
                                           @click.stop="loadProviders($event, index)">
                                    <span class="material-control-indicator"></span>
                                    <span class="material-control-description">{{ i.name }}</span>
                                </label>
                                <ul class="list-unstyled" :id="getProviderListElementId(index)" v-if="showProviderList(index)">
                                    <li class="dropdown-item">
                                        <label class="custom-control material-checkbox mb-0" @click.stop="cleanProvidersInType(i.id)">
                                            <input type="checkbox" class="material-control-input"
                                                   :checked="toggleAllProvidersOption(i.id)">
                                            <span class="material-control-indicator"></span>
                                            <span class="material-control-description">All {{ i.name }}</span>
                                        </label>
                                    </li>

                                    <li class="dropdown-divider mb-2"></li>

                                    <li class="dropdown-item" v-for="(p, idx) in i.children" :key="idx">
                                        <label class="custom-control material-checkbox mb-0" @click.stop="{}">
                                            <input type="checkbox" v-model="search.providers" class="material-control-input">
                                            <span class="material-control-indicator"></span>
                                            <span class="material-control-description">{{ p.trading_name }}</span>
                                        </label>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div v-if="showField('immigration_adviser')" class="col-12 col-sm-6 col-md-4">
                <div class="form-group">
                    <label for="immigration_adviser">Licensed Immigration Adviser?</label>
                    <!--<select class="form-control" id="immigration_adviser" name="immigration_adviser" v-model="search.immigration_adviser">
                        <option v-for="(e, index) in immigrationAdviserStatus" :key="index" :value="e.id">{{ e.name }}</option>
                    </select>-->
                    <div class="form-check form-check-inline pt-2">

                        <div class="custom-control custom-radio">
                            <input type="radio" id="license_no_applicable"
                                name="immigration_adviser"
                                class="custom-control-input"
                                value="0" v-model="search.immigration_adviser">
                            <label class="custom-control-label" for="license_no_applicable">N/A</label>
                        </div>

                        <div class="custom-control custom-radio">
                            <input type="radio" id="immigration_adviser"
                                name="immigration_adviser"
                                class="custom-control-input"
                                value="1" v-model="search.immigration_adviser">
                            <label class="custom-control-label" for="immigration_adviser">Yes</label>
                        </div>

                        <div class="custom-control custom-radio">
                            <input type="radio"
                                id="non_immigration_adviser"
                                name="immigration_adviser"
                                class="custom-control-input"
                                value="2" v-model="search.immigration_adviser">
                            <label class="custom-control-label" for="non_immigration_adviser">No</label>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="showField('recognized_agent')" class="col-12 col-sm-6 col-md-4">
                <div class="form-group">
                    <label for="agent_recognised">ENZ Recognised Agency?</label>

                    <!--<select class="form-control" id="recognized_agent" name="recognized_agent" v-model="search.recognized_agent">
                        <option v-for="(e, index) in recognizedStatus" :key="index" :value="e.id">{{ e.name }}</option>
                    </select>-->
                    <div class="form-check form-check-inline pt-2">

                        <div class="custom-control custom-radio">
                            <input type="radio" id="agent_no_applicable"
                                name="recognized_agent"
                                class="custom-control-input"
                                value="0"
                                v-model="search.recognized_agent">
                            <label class="custom-control-label" for="agent_no_applicable">N/A</label>
                        </div>

                        <div class="custom-control custom-radio">
                            <input type="radio" id="agent_recognised"
                                name="recognized_agent"
                                class="custom-control-input"
                                value="8"
                                v-model="search.recognized_agent">
                            <label class="custom-control-label" for="agent_recognised">Yes</label>
                        </div>

                        <div class="custom-control custom-radio">
                            <input type="radio" id="agent_unrecognised"
                                name="recognized_agent"
                                class="custom-control-input"
                                value="16"
                                v-model="search.recognized_agent">
                            <label class="custom-control-label" for="agent_unrecognised">No</label>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="showField('checkbox_completed_module')" class="col-12">

                <div class="form-group">
                    <label class="hidden">Only completed modules</label>
                    <label class="custom-control overflow-checkbox my-0">
                        <input id="checkbox_completed_module" type="checkbox" class="overflow-control-input"
                               v-model="search.checkbox_completed_module">
                        <span class="overflow-control-indicator"></span>
                        <span class="overflow-control-description">Only completed modules</span>
                    </label>
                </div>
            </div>

            <div class="col-12">
                <div class="text-right">
                    <button @click="handleClear" class="jba-btn reverse">Reset</button>
                    <button @click="handleSearch" class="jba-btn"><i v-show="submitting" class="fas fa-spinner fa-spin"></i> {{ searchButtonText }}</button>
                </div>
                <!--div class="mb-2 mt-1 text-right">
                    <a @click.stop="toggleMoreOptions()" class="mr-2 clickable"><small>{{ moreOption ? 'Less' : 'More' }} options <i class="fas fa-angle-down" :class="{'fa-angle-down': !moreOption, 'fa-angle-up': moreOption}"></i></small></a>
                </div-->
            </div>
        </div>
    </div>

</template>

<script>

    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props:{
            searchHandler: {
                type: Function,
                required: true
            },
            clearHandler: {
                type: Function,
                required: true
            },
            minKeywordLength: {
                type: Number,
                default: 2
            },
            advancedFields: {
                type: Array,
                default: function(){
                    return ['keywords', 'interests', 'recognized_agent', 'immigration_adviser', 'countries', 'organizations']
                }
            },
            searchButtonText: {
                type: String,
                default: 'Search'
            },
            filter: {
                type: Object,
                default: function () {
                    return {};
                }
            },
            submitting: {
                type: Boolean,
                default: false
            }
        },

        data(){
            return {
                search: {},
                moreOption: false,
                providerMenu: [],
                organizationOptions: this.confirmedOrganizations,
                countryOptions: this.countries(),
                groups: [],
                modules: [],
                datePickerOptions: {},
                period: 1
            }
        },

        inject: ['api', 'organizations', 'countries', 'providerTypes'],

        components: {

        },

        created() {

            /*$(document).on({
                'shown.bs.dropdown': function(e) {
                    this.closable = true;
                },
                'click': function(e) {
                    this.closable = false;
                },
                'hide.bs.dropdown': function(e) {
                    let closable = this.closable;
                    this.closable = true;
                    return closable;
                }
            }, '#sector-provider-dropdown');
            */
            this.search = this.initValue();
            this.initFields();
            this.initSearch(this.filter);
            this.datePickerOptions = {
                format: 'YYYY-MM-DD',
                useCurrent: false,
                maxDate: moment().endOf('day'),
                ignoreReadonly: true,
                icons: this.datePickerIcons
            };
        },

        beforeDestroy() {

        },

        computed: {

            value() {
                let data = {};
                let self = this;
                $.each(self.search, function (k, v) {
                    switch(k) {

                        case 'organizations': {
                            data.organizations = [];

                            v.forEach(function(o){
                                data.organizations.push(o.id);
                            });

                            break;
                        }
                        case 'countries': {
                            data.countries = [];

                            v.forEach(function(o) {
                                data.countries.push(o.id);
                            });

                            break;
                        }
                        case 'groups': {
                            data.groups = [];

                            v.forEach(function(o) {
                                data.groups.push(o.id);
                            });
                            break;
                        }
                        case 'modules': {
                            data.modules = [];

                            v.forEach(function(o) {
                                data.modules.push(o.id);
                            });
                            break;
                        }
                        case 'interests': {
                            data.interests = [];
                            v.forEach(function(o){
                                data.interests.push(o.id);
                            });

                            break;
                        }
                        case 'checkbox_completed_module': {
                            data[k] = v ? 1 : 0;
                            break;
                        }
                        case 'provider_types':
                        case 'providers':
                        case 'immigration_adviser':
                        case 'recognized_agent':
                        case 'date_from':
                        case 'date_to':
                        case 'period':
                        case 'q': {
                            data[k] = v;
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                });

                return data;
            },

            recognizedStatus() {
                return [
                    {id: 0, name: 'Any'},
                    {id: 8, name: 'Yes'},
                    {id: 16, name: 'No'}
                ]
            },

            immigrationAdviserStatus() {
                return [
                    {id: 0, name: 'Any'},
                    {id: 1, name: 'Yes'},
                    {id: 2, name: 'No'},
                    {id: 4, name: 'Waiting approval'}
                ]
            },

            selectedProviders() {

                let output = [];
                if(this.search.provider_types.length) {
                    output.push(this.search.provider_types.length + ' sector' + (this.search.provider_types.length > 1 ? 's' : ''));
                }

                if(this.search.providers.length) {
                    output.push(this.search.providers.length + ' provider' + (this.search.providers.length > 1 ? 's' : ''));
                }

                return output.join(', ');
            },

            confirmedOrganizations() {
                return this.organizations().filter(function(e){
                   return e.status == 1;
                });
            }
        },

        watch: {
            'advancedFields': function(val, oldVal) {
                this.initFields();
                this.initSearch(this.filter);
            },
        },

        methods: {
            showProviderList(index) {
                return this.providerMenu[index].children && ($.inArray(this.providerMenu[index].id, this.search.provider_types) !== -1);
            },

            toggleMoreOptions() {
                this.initSearch();
                this.moreOption = !this.moreOption;
            },

            showField(name, always = false) {
                return ($.inArray(name, this.advancedFields) !== -1) && (always || this.moreOption);
            },

            handleSearch() {
                if(this.value) {
                    this.searchHandler(this.value);
                }
            },

            loadGroups() {
                let self = this;

                if(!self.groups.length) {
                    self.loadGroupList().then(function (response) {
                        self.groups = response.data;
                    }, function (error) {
                        self.handleError(error);
                    });
                }
            },

            loadModules() {
                let self = this;

                if(!self.modules.length) {
                    self.loadCourseModules().then(function (response) {
                        self.modules = response.data;
                    }, function (error) {
                        self.handleError(error);
                    });
                }
            },

            initValue() {
                return {
                    interests: [],
                    provider_types: [],
                    providers: [],
                    organizations: [],
                    countries: [],
                    groups: [],
                    modules: [],
                    date_from: '',
                    date_to: '',
                    recognized_agent: 0,
                    immigration_adviser: 0,
                    q: '',
                }
            },

            initFields() {

                if (!$.isArray(this.advancedFields)) {
                    return;
                }

                if($.inArray('groups', this.advancedFields) !== -1) {
                    this.loadGroups();
                }
                if($.inArray('modules', this.advancedFields) !== -1) {
                    this.loadModules();
                }
            },

            initSearch(filter = {}) {

                let self = this;

                self.search = self.initValue();

                if($.isPlainObject(filter)) {

                    $.each(filter, function (k, v) {
                        switch(k) {
                            case 'countries': {
                                filter.countries = self.arraySearchAll(self.countries(), v);
                                break;
                            }
                            case 'interests': {
                                filter.interests = self.arraySearchAll(self.providerTypes(), v);
                                break;
                            }
                            case 'organizations': {
                                filter.organizations = self.arraySearchAll(self.confirmedOrganizations, v);
                                break;
                            }
                            case 'modules': {
                                //TODO test if it works
                                filter.modules = self.arraySearchAll(self.modules, v);
                                break;
                            }
                            case 'groups': {
                                filter.groups = self.arraySearchAll(self.groups, v);
                                break;
                            }
                            case 'provider_types': {

                                $.each(self.providerMenu, function(index, sector){

                                    if($.inArray(sector.id, v) !== -1) {
                                        axios.get(self.api + 'provider-types/' + sector.id + '/providers')
                                            .then(function(response){

                                                let providerType = self.providerMenu[index];
                                                providerType.children = response.data;
                                                self.providerMenu.splice(index, 1, providerType);
                                            })
                                            .catch(function(error){
                                                self.handleError(error);
                                            })
                                    }

                                });

                                break;
                            }
                            default: {
                                break;
                            }
                        }
                    });

                    $.extend(self.search, filter);
                    self.moreOption = true;
                }

            },

            handleClear() {
                this.clearHandler();
            },

            resetSector() {
                let self = this;
                self.search.providers = [];
                self.search.provider_types = [];
                let id = this.getProviderListElementId();
                $('ul[id^="' + id + '"]').hide();
            },

            cleanProvidersInType(typeId) {
                let min = this.calculateLowerLimit(typeId);
                let max = this.calculateUpperLimit(typeId);

                let newValue = [];

                for (let i = 0; i < this.search.providers.length; i++) {
                    if (this.search.providers[i] < min || this.search.providers[i] > max) {
                        newValue.push(this.search.providers[i]);
                    }
                }

                this.search.providers = newValue;
            },

            toggleAllProvidersOption(typeId) {

                let min = this.calculateLowerLimit(typeId);
                let max = this.calculateUpperLimit(typeId);

                for(let i = 0; i < this.search.providers.length; i++) {
                    if(this.search.providers[i] > min && this.search.providers[i] < max) {
                        return false;
                    }
                }

                return true;
            },

            getProviderListElementId(index = '', prefix = '') {
                return prefix + 'sub-list-' + String(index);
            },

            toggleProviderType(e, index) {

                if(index < this.providerMenu.length) {

                    let idx = $.inArray(this.providerMenu[index].id, this.search.provider_types);
                    let providerList = $(this.getProviderListElementId(index, '#'));
                    if (idx !== -1) {
                        let min = this.calculateLowerLimit(this.providerMenu[index].id);
                        let max = this.calculateUpperLimit(this.providerMenu[index].id);
                        let newValue = [];
                        for (let i = 0; i < this.search.providers.length; i++) {
                            if (this.search.providers[i] < min || this.search.providers[i] > max) {
                                newValue.push(this.search.providers[i]);
                            }
                        }

                        this.search.providers = newValue;

                        providerList.hide();
                    }
                    else {
                        providerList.show();
                    }
                }
            },

            loadProviders(e, index) {

                if(index < this.providerMenu.length) {

                    if(this.providerMenu[index].children) {
                        this.toggleProviderType(e, index);
                    }
                    else {
                        let currentTarget = e.currentTarget;

                        $(currentTarget).parent().addClass('loading');

                        let self = this;

                        axios.get(self.api + 'provider-types/' + self.providerMenu[index].id + '/providers')
                            .then(function(response){

                                let providerType = self.providerMenu[index];
                                providerType.children = response.data;
                                self.providerMenu.splice(index, 1, providerType);
                                $(currentTarget).parent().removeClass('loading');
                            })
                            .catch(function(error){
                                $(currentTarget).parent().removeClass('loading');
                                self.handleError(error);
                            })
                    }
                }
            }
/*
            searchOrganizations(query = '') {

                let self = this;
                self.organizationOptions = [];
                if(!query) {
                    self.organizationOptions = self.organizations();
                    return true;
                }

                $.each(self.organizations(), function (idx, item) {
                    if (item.name.toUpperCase().indexOf(query.toUpperCase()) == 0) {
                        self.organizationOptions.push(item);
                    }
                });
            },

            searchCountries(query = '') {

                let self = this;
                self.countryOptions = [];
                if(!query) {
                    self.countryOptions = self.countries();
                    return true;
                }

                $.each(self.countries(), function (idx, item) {
                    if (item.name.toUpperCase().indexOf(query.toUpperCase()) == 0) {
                        self.countryOptions.push(item);
                    }
                });
            }*/
        },

        mounted() {
            this.providerMenu = this.providerTypes();
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" >
    .advanced-search {
        border: 1px solid var(--light-white-color);
        background: var(--bg-light-color);

        .form-check-inline {
            width: 100%;
            .custom-radio {
                width: 30%;

                &.half-width {
                    width: 50%
                }
            }
        }

    }
</style>