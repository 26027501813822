<template>

    <div id="course-play" v-if="course.id">
        <div class="row sm-p-2">
            <div class="col-12">
                <div class="loader-container">
                    <img id="loader" class="loader" src="/images/course/loadingbar.png" alt = "loader"/>
                    <!-- pass token to game client as param on html-src -->
                    <!-- token queryParam causing CORS error in browserconsole, but not preventing game load -->
                    <iframe :src="'/courses/' + id + '/iframe?' + gameParameters" height="640px" width="960px"></iframe>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="page-loading"></div>

</template>

<script>
    //import Unity from './Unity';

    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props:{
            id: {
                type: [Number, String],
                required: true
            },
            sub: {
                type: String,
                default: null,
                required: false
            },
            subId: {
                type: [Number, String],
                required:false,
                default: null
            }
        },

        data(){

            return {
                course: {}
            }
        },

        inject: ['api', 'auth'],

        components: {
        //    Unity
        },
        created() {

        },
        computed: {

            gameParameters() {
                let params = [
                    'course=' + this.id,
                    'token=' + this.$route.query.token
                ];

                if(this.subId) {
                    params.push('module=' + this.subId);
                }

                if(this.$route.query.checkpoint) {
                    params.push('checkpoint=' + this.$route.query.checkpoint);
                }

                return params.join('&');
            }
        },
        mounted() {
            let self = this;
            self.loadCourse(self.id)
                .then(function(response) {
                    self.course = response.data;
                    self.loaded = true;

                    self.$emit('page-loaded');
                })
                .catch(function(error) {
                    self.handleError(error);
                })

        },
        beforeDestroy() {

            if(!this.unload()) {
                // refresh page if webgl2 is not supported or any reason which unload is failed
                window.location.href = '/#' + this.$route.path;
            }



        },

        methods: {

            unload() {
                // Don't use it anymore, we use iframe to display game now
                /*let canvas = $(this.$el).find('canvas').get(0);

                if(canvas) {
                    let gl = canvas.getContext('webgl') || canvas.getContext('webgl2');

                    if(gl && gl.getExtension('WEBGL_lose_context')) {
                        gl.getExtension('WEBGL_lose_context').loseContext();
                        return true;
                    }
                }

                return false;
                */
                return true;
            },

            close() {
                if(this.unload()) {
                    this.$router.go(-1);
                    return true;
                }

                // refresh page if webgl2 is not supported or any reason which unload is failed
                window.location.href = '/#' + this.$route.path;
            }
        }

    }
</script>

<style rel="stylesheet/scss" lang="scss">

    #course-play {
        background: #e4e4e4;
        height: 100%;
    }
    .loader-container{
        width: 960px;
        height:640px;
        position: relative;
        margin: auto;
    }
    .loader{
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        max-width:200px;
        max-height:200px;
        margin:auto;
        width: auto;
        height: auto;
        

        -webkit-animation-name: spin;
        -webkit-animation-duration: 1000ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;

        -moz-animation-name: spin;
        -moz-animation-duration: 1000ms;
        -moz-animation-iteration-count: infinite;
        -moz-animation-timing-function: linear;

        -ms-animation-name: spin;
        -ms-animation-duration: 1000ms;
        -ms-animation-iteration-count: infinite;
        -ms-animation-timing-function: linear;

        animation-name: spin;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        @-ms-keyframes spin { 
            from { 
                -ms-transform: rotate(0deg); 
            } to { 
                -ms-transform: rotate(360deg); 
            }
        }
        @-moz-keyframes spin { 
            from { 
                -moz-transform: rotate(0deg); 
            } to { 
                -moz-transform: rotate(360deg); 
            }
        }
        @-webkit-keyframes spin { 
            from { 
                -webkit-transform: rotate(0deg); 
            } to { 
                -webkit-transform: rotate(360deg); 
            }
        }
        @keyframes spin { 
            from { 
                transform: rotate(0deg); 
            } to { 
                transform: rotate(360deg); 
            }
        }
        z-index: 9999999;
    }
    iframe {
        border: none;
        overflow: hidden;
    }

</style>