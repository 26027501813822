<template>
    <transition name="transition-model">

        <div class="modal fade show" data-backdrop="static" data-keyboard="false" id="show-message-modal" tabindex="-1" role="dialog" aria-labelledby="Show Message">
            <div class="modal-dialog" role="document" @click.stop="{}">
                <div v-if="!loaded" class="page-loading"></div>
                <div v-else class="modal-content">
                    <div class="modal-header">
                        <h3>Message</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </div>

                    <div class="modal-body">

                        <h5 class="my-2">{{ message.subject }}</h5>

                        <div class="message-content" v-html="message.content"></div>
                    </div>
                    <div class="modal-footer">
                        <button @click.stop="close" class="jba-btn reverse">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

    export default {

        props: {
            id: {
                type: [String, Number],
                required: true
            },
            unread: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                message: {}
            }
        },

        inject: ['api'],

        components: {

        },

        created() {
            this.loadMessage();
        },

        computed: {

        },

        methods: {

            loadMessage() {

                let self = this;

                axios.get(self.api + 'agent/messages/' + self.id)
                    .then(function(response) {
                        self.message = response.data;
                        if(self.unread) {
                            self.$emit('read-message', response.data);
                        }
                        self.loaded = true;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            close() {
                this.toggleModal('hide');
                this.$router.push({name: 'agent.messages'});
            },

            toggleModal(status) {
                $(this.$el).modal(status);
            }
        },

        mounted() {
            this.toggleModal('show');
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>