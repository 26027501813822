<template>

    <ul class="nav-item-wrap row align-items-center list-unstyled">

        <li class="nav-item" v-if="isAgent() && !limited">
            <app-inbox-notification :count="false"></app-inbox-notification>
        </li>
        <li class="nav-item">
            <ul class="nav-item-wrap row align-items-center list-unstyled" :class="{maienz: isAgent()}">
                <li v-if="isAgent()" class="nav-item">
                    <a class="link reverse fs-130" :href="maiEnz().url.dashboard" target="_blank">
                        <img class="align-text-bottom" src="/images/svg/widget.svg"/> MaiENZ
                        <img class="align-baseline" src="/images/svg/open-site.svg"/>
                    </a>
                </li>
                <li class="nav-item mr-0" id="header-menu" :class="{agent: isAgent()}">
                    <img v-if="isAdmin()" class="avatar img-circle img-responsive dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :src="miniAvatar"/>

                    <div v-else class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img class="align-text-bottom" :src="headerMenuToggle" >
                        <i class="fas" :class="{'fa-angle-down': !dropdown, 'fa-angle-up': dropdown}"></i>
                    </div>
                    <div class="dropdown-menu dropdown-menu-right p-0 m-0">

                        <!--<div class="fs-100 px-3 pt-2">Hello {{ this.auth().first_name }} </div>-->

                        <div v-for="(m, index) in topBarMenu" :key="index" v-if="!m.permission || $can(m.permission.action, m.permission.object)">
                            <router-link v-if="m.route" class="dropdown-item" tag="button" type="button" :id="m.id" :key="index" :to="{name: m.route}" v-html="m.name"></router-link>
                            <div class="dropdown-item" v-else v-html="m.content"></div>
                            <div class="dropdown-divider my-0" v-if="m.divider"></div>
                        </div>

                        <div class="dropdown-divider my-0" v-if="topBarMenu.length"></div>
                        <button v-if="isAdmin()" class="dropdown-item" type="button" @click.stop="logout">Log out</button>
                        <a v-else class="dropdown-item text-main" href="/auth0/logout">Log out</a>
                    </div>
                </li>
            </ul>
        </li>

    </ul>

</template>

<script>

    export default {
        props: {
            limited: {
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {
                menu: {
                    agent: [
                        /*{
                            id: 'greeting',
                            content: '<span class="d-block fs-90">Hello ' + this.auth().first_name + '</span>'
                        },*/
                        {
                            id: 'manage-maienz-account',
                            content: '<span class="d-block fs-90 py-1">Hello ' + this.auth().first_name + '</span>' + '<a class="d-block text-main" href="' + this.maiEnz().url.profile + '">Manage MaiENZ account</a>',
                            divider: true
                        },
                        {
                            id: 'agent-profile-page',
                            name: 'Profile',
                            image: '',
                            route: 'agent.profile'
                        },
                        {
                            id: 'help-line',
                            content: '<a class="d-block" href="mailto:agent.help@enz.govt.nz">Help</a>'
                        }
                    ],

                    admin: [
                        {
                            id: 'admin-acl-page',
                            name: 'Access Control List',
                            image: '',
                            route: 'admin.acl',
                            permission: {action: 'view', object: 'role'}
                        },
                        {
                            id: 'admin-profile-page',
                            name: 'Profile',
                            image: '',
                            route: 'admin.profile'
                        },
                    ]
                },
                miniAvatar: this.auth().avatar,
                dropdown: false
            }
        },

        inject: ['api', 'auth', 'maiEnz'],

        components: {

        },

        created() {

        },

        destroyed() {

        },

        computed: {

            topBarMenu: {
                get: function(){

                    if(this.isAdmin()) {
                        return this.filterAdminTopMenu(this.menu[this.auth().type]);
                    }
                    else if(this.isAgent()) {
                        return this.filterAgentTopMenu(this.menu[this.auth().type]);
                    }
                    else {
                        this.handleError({message: 'Invalid top menu type'});
                    }
                }
            },

            headerMenuToggle() {
                return this.dropdown ? '/images/svg/menu-open.svg' : '/images/svg/menu.svg';
            }
        },

        methods: {

            filterAdminTopMenu(menu) {

                let self = this;

                let options = [];

                menu.forEach(function(o){

                    if(!o.permission || self.$can(o.permission.action, o.permission.object)) {
                        options.push(o);
                    }
                });

                return options;
            },

            filterAgentTopMenu(menu) {
                if(this.limited) {
                    return menu.filter(i => i.id !== 'agent-profile-page');
                }
                return menu;
            },

            logout() {

                axios.post('/logout')
                    .then(function(response){
                        window.location.replace('/login');
                    })
                    .catch(function(error){
                        // the error is possibly that session is expired
                        window.location.replace('/login');
                    });
            },

            registerEvent() {

                let self = this;

                $('#header-menu')
                    .on('shown.bs.dropdown', function () {
                        self.dropdown = 'open';
                    }).on('hidden.bs.dropdown', function () {
                        self.dropdown = false;
                    });

                self.$root.$on('avatar-updated', function(data){
                    self.miniAvatar = data;
                });
            }

        },

        mounted() {

            this.registerEvent();
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">




</style>