<template>
    <div id="acl">
        <app-page-banner page="acl" title="ACL" subtitle="Assign permission levels by creating a new user role."></app-page-banner>

        <div v-if="!loaded" class="page-loading"></div>
        <div class="row sm-p-2" v-else>

            <div class="col-12 col-sm-8 col-md-6 col-lg-4" v-if="$can('create', 'role')">
                <form novalidate class="create-role">
                    <div class="input-group">
                        <input class="form-control" type="text" name="role" id="new-role"
                               v-model="newRole"
                               placeholder="Create new role"
                               v-validate="{required: true, min: 3, regex: /^[A-Za-z ]+$/}" />
                        <button class="jba-btn input-group-append rounded-left-0" type="button" aria-haspopup="true" aria-expanded="false" @click="createRole">Add</button>
                    </div>
                    <small v-show="errors.has('role')" class="text-danger">{{ errors.first('role') }}</small>
                </form>
            </div>

            <div class="col-12">

                <ul class="nav nav-tabs d-none d-sm-flex">
                    <li class="nav-item mr-1" v-for="(role, index) in acl" :key="index">
                        <a class="nav-link" :class="{active: !index}" :href="'#' + role.slug + '-accordion'" data-toggle="tab"><span>{{ role.name }}</span></a>
                    </li>
                </ul>

                <select id="role-box" class="browser-default custom-select d-block d-sm-none" @change="toggleTabPane($event)">
                    <option v-for="(role, index) in acl" :key="index" :value="'#' + role.slug + '-accordion'">
                        {{ role.name }}
                        <!--<a :href="'#' + role.slug + '-accordion'"  class="nav-link"><span>{{ role.name }}</span></a>-->
                    </option>
                </select>

                <div class="tab-content" v-if="$can('view', 'permission')">

                    <div class="tab-pane fade" v-for="(role, r_k) in acl" :key="r_k" :class="{'show active': !r_k}" :id="role.slug + '-accordion'" role="tabpanel" :aria-labelledby="role.slug + '-accordion'">

                        <div class="my-2" v-if="!role.readonly">
                            <router-link v-if="$can('update', 'role')" class="jba-btn btn-xs" :to="{name: 'admin.acl.roles.rename', params: {id: role.id}}">Rename</router-link>
                            <router-link v-if="$can('delete', 'role')" class="jba-btn btn-xs btn-danger" :to="{name: 'admin.acl.roles.delete', params: {id: role.id}}">Remove</router-link>
                        </div>

                        <div class="card mt-1" v-for="(resource, re_k) in role.acl" :key="re_k">
                            <div class="card-header p-0" :id="role.slug + '-' + resource.slug + '-resource'">
                                <h5 class="mx-2 mb-0 fs-110">
                                    <!--<button class="btn btn-link" data-toggle="" data-target="'#' + role.slug + '-' + resource.slug + '-permission'" aria-expanded="true" :aria-controls="role.slug + '-' + resource.slug + '-permission'">
                                        {{ resource.resource }}
                                    </button>-->
                                    {{ resource.resource }}
                                </h5>
                            </div>

                            <div id="role.slug + '-' + resource.slug + '-permission'" class="collapse show" :aria-labelledby="role.slug + '-' + resource.slug + '-resource'" :data-parent="'#' + role.slug + '-accordion'">
                                <div class="card-body p-1 row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" v-for="(perm, p_k) in resource.permissions">
                                        <label class="custom-control overflow-checkbox my-0 ml-1" v-if="$can('assign', 'permission')">
                                            <input name="dashboard-read" type="checkbox" class="overflow-control-input" :checked="perm.checked" :data-role="role.id" :data-perm="perm.id" @click="togglePermission($event)">
                                            <span class="overflow-control-indicator"></span>
                                            <span class="overflow-control-description">{{ perm.action }}</span>
                                        </label>
                                        <label v-else class="text-main">
                                            {{ perm.action }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div class="alert alert-warning mt-2">You do not have proper permission to access this page</div>
                </div>
            </div>
        </div>

        <router-view :roles="roleList()" :role="currentRole()" @load-acl="loadAcl()"></router-view>
    </div>
</template>

<script>

    export default {
        props: {

        },
        data() {
            return {
                newRole: null,
                acl: []
            }
        },

        inject: ['api', 'auth'],

        components: {

        },

        created() {
            this.loadAcl();
        },

        destroyed() {

        },

        computed: {

        },

        watch: {

        },

        methods: {

            loadAcl() {

                let self = this;

                axios.get(self.api + 'admin/acl')
                    .then(function(response){
                        self.acl = response.data || [];
                        self.loaded = true;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });

            },
            createRole() {

                let self = this;

                self.$validator.validateAll().then(function() {

                    if(!self.errors.any()) {

                        axios.post(self.api + 'admin/acl/roles', {name: self.newRole})
                            .then(function(response){
                                self.acl.push(response.data);
                                self.handleNotice('The role is added');
                                self.newRole = null;
                                self.$validator.reset();
                            })
                            .catch(function(error){
                                self.handleError(error);
                            });
                    }

                });
            },

            togglePermission(event) {

                let self = this;
                let el = $(event.currentTarget);
                let role = el.data('role');
                let permission = el.data('perm');

                axios.put(self.api + 'admin/acl/roles/' + role + '/permissions/' + permission)
                    .then(function(response){
                        //self.handleNotice('The permission is updated');
                    })
                    .catch(function(error){
                        el.prop('checked', !el.is(':checked'));
                        self.handleError(error);
                    });
            },

            toggleTabPane(event) {

                $('.tab-pane').removeClass('show active');
                $(event.target.value).addClass('show active');
            },

            roleList() {

                let roles = [];

                let exclude = this.$route.params.id;

                this.acl.forEach(function(o, k){
                    if(o.id != exclude) {
                        roles.push({
                            id: o.id,
                            name: o.name
                        });
                    }
                });

                return roles;
            },

            currentRole() {

                for(let i = 0; i < this.acl.length; i++){
                    if(this.acl[i].id == this.$route.params.id) {
                        return this.acl[i];
                    }
                }

                return {};
            }

        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

    .scroll-spy {
        .spy-bar {
            width: 100%;
            max-width: 230px;
            font-size: 1.1em;
            list-style-type: none;
            float: left;
            padding: 8px;
            background: #f9f9f9;
            border-radius: 3px;
            li {
                padding: 10px;
                cursor: pointer;
                &.active {
                    background: var(--main-transparent);
                    color: var(--light-white-color);
                    border-left: 3px solid var(--main-hover-color);
                }
            }
        }

        .spy-pane {
            margin-left: 240px;
        }
    }

</style>