<template>
    <div id="profile" class="admin-profile">

        <app-page-banner page="profile" title="My Profile" subtitle=""></app-page-banner>

        <div v-if="!loaded" class="page-loading"></div>
        <div v-else class="row sm-p-2">
            <div class="col-12">
                <div class="pane">

                    <div class="pane-img-top">
                        <app-avatar-update
                                :avatar="staff.avatar"
                                :roles="staff.roles"
                               ></app-avatar-update>
                        <!--<div class="avatar">
                            <img :src="staff.avatar" class="img-circle"/>
                            <span class="badge badge-warning ml-2 p-2">{{ join(staff.roles) }}</span>
                        </div>-->
                    </div>
                    <!--<img class="card-img-top" src="https://seantheme.com/color-admin-v4.3/admin/assets/css/default/images/profile-cover.jpg"/>-->

                    <div class="pane-body">

                        <table class="table mt-4 mb-0">
                            <tbody>
                                <tr>
                                    <td>First name</td>
                                    <td>
                                        <app-single-field-update
                                                ref="singleFirstNameUpdate"
                                                component-class="editable-text mb-0"
                                                :component-value="staff.first_name"
                                                :component-params="staff.id"
                                                :component-handler="updateFirstName"
                                        ></app-single-field-update>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Last name</td>
                                    <td>
                                        <app-single-field-update
                                                ref="singleLastNameUpdate"
                                                component-class="editable-text mb-0"
                                                :component-value="staff.last_name"
                                                :component-params="staff.id"
                                                :component-handler="updateLastName"
                                        ></app-single-field-update>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Email</td>
                                    <td>
                                        <app-single-field-update
                                                ref="singleEmailUpdate"
                                                component-class="editable-text mb-0"
                                                :component-value="staff.email"
                                                :component-params="staff.id"
                                                :component-handler="updateEmail"
                                                :editable="false"
                                        ></app-single-field-update>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Password</td>
                                    <td>
                                        <app-single-field-update
                                                ref="singlePasswordUpdate"
                                                component-class="editable-text mb-0"
                                                :component-value="staff.password || '******'"
                                                :component-params="staff.id"
                                                :component-handler="updatePassword"
                                        ></app-single-field-update>
                                    </td>
                                </tr>
                            </tbody>


                            <!--<div class="form-group">
                                <label for="last-name">Last name</label>
                                <input id="last-name" type="text" class="form-control" v-model="staff.last_name"/>
                            </div>

                            <div class="form-group">
                                <label for="email">Email</label>
                                <input id="email" type="email" class="form-control" v-model="staff.email"/>
                            </div>

                            <div class="form-group">
                                <label for="password">Password</label>
                                <input id="password" type="password" class="form-control" v-model="staff.password"/>
                            </div>

                            <div class="form-group">
                                <p>Your role is <b>{{ join(staff.roles) }}</b></p>
                            </div>

                            <div class="form-group">
                                <button class="jba-btn">Update</button>
                            </div>-->
                        </table>


                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>

    export default {
        props: {

        },
        data() {
            return {
                staff: {}
            }
        },

        inject: ['api', 'auth'],

        components: {

        },

        created() {

        },

        destroyed() {

        },

        computed: {

        },

        methods: {
            updateFirstName(firstName) {
                if(!firstName) {
                    this.handleError({message: 'First name can not be empty'});
                    return;
                }
                this.update('first_name', firstName);
            },

            updateLastName(lastName) {
                if(!lastName) {
                    this.handleError({message: 'Last name can not be empty'});
                    return;
                }
                this.update('last_name', lastName);
            },

            updateEmail(email) {
                if(!email) {
                    this.handleError({message: 'Email can not be empty'});
                    return;
                }
                this.update('email', email);
            },

            updatePassword(password) {
                if(!password) {
                    this.handleError({message: 'Password can not be empty'});
                    return;
                }
                this.update('plain_password', password);
            },

            update(key, value) {

                let self = this;

                let payload = {};
                payload[key] = value;

                axios.put(self.api + 'admin/staff/profile', payload)
                    .then(function(response){

                        switch(key) {
                            case 'first_name': {
                                self.$refs.singleFirstNameUpdate.resetStatus();
                                self.auth().first_name = value;
                                self.auth().full_name = response.data.full_name;
                                break;
                            }

                            case 'last_name': {
                                self.$refs.singleLastNameUpdate.resetStatus();
                                self.auth().last_name = value;
                                self.auth().full_name = response.data.full_name;
                                break;
                            }

                            case 'email': {
                                self.$refs.singleEmailUpdate.resetStatus();
                                self.auth().email = value;
                                break;
                            }

                            case 'plain_password': {
                                self.$refs.singlePasswordUpdate.resetStatus();
                                break;
                            }

                        }

                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            }
        },

        mounted() {
            let self = this;

            self.staff = self.auth();

            self.$root.$on('avatar-updated', function(data) {
            //    self.auth().avatar = data;
                self.staff.avatar = data;
            });

            self.loaded = true;

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">


</style>