<template>

    <transition name="transition-model">

        <div class="modal fade show" data-backdrop="static" data-keyboard="false" id="show-agent-modal" tabindex="-1" role="dialog" aria-labelledby="Show Agent">
            <div class="modal-dialog" role="document" @click.stop="function(){}">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Agent profile</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </div>

                    <div class="modal-body">

                        <div class="text-center avatar">
                            <img :src="agent.avatar" class="img-fluid img-circle"/>
                        </div>

                        <h5 class="text-center my-2">{{ agent.full_name }}</h5>

                        <ul class="list-unstyled m-0">

                            <li>
                                <i class="far fa-envelope"></i> {{ agent.email }}
                            </li>

                            <li v-if="agent.phone">
                                <i class="fas fa-phone"></i> {{ agent.phone }}
                            </li>

                            <li v-if="agent.organization">
                                <i class="far fa-building"></i> {{ agent.organization.name }}
                            </li>

                            <li v-if="agent.website">
                                <i class="fas fa-external-link-alt"></i> <a :href="agent.website" class="link text-truncate" target="_blank">{{ agent.website }}</a>
                            </li>

                            <li v-if="agent.country">
                                <i class="fas fa-globe-asia"></i> {{ agent.country.name }}
                            </li>

                            <li v-if="agent.immigration_adviser">
                                <i class="fas fa-award"></i> Licensed immigration adviser
                            </li>

                            <li v-if="agent.immigration_license">
                                <i class="fas" :class="{'fa-check': agent.immigration_adviser, 'fa-hourglass-half': !agent.immigration_adviser}"></i> License: {{ agent.immigration_license }}
                            </li>

                            <li v-if="agent.recognized_at">
                                <i class="fas fa-award"></i> Belongs to ENZ Recognised Agency
                            </li>

                            <li v-if="agent.created_at && appName()">
                                <i class="far fa-calendar-alt"></i> Registered with {{appName()}} on {{ agent.created_at | convertDate }}
                            </li>

                            <li class="dropdown-divider mb-2"></li>

                            <!--<li class="mb-2">
                                <h6 class="mb-1">Language(s)</h6>
                                <p class="mb-0" v-if="agent.languages.length" v-for="(a, index) in agent.languages" :key="index">{{ a.name }}</p>
                                <p class="mb-0" v-else>N/A</p>
                            </li>-->

                            <li class="mb-2">
                                <h6 class="mb-1">Interested sector</h6>
                                <p class="mb-0" v-for="(a, index) in agent.interests" :key="index">{{ a.name }}</p>
                            </li>

                            <li>
                                <h6 class="mb-1">Belong to group(s)</h6>

                                <p v-if="groups && groups.length">
                                    <span v-for="(a, index) in groups" :key="index" class="mb-0 d-block">{{ a.name }}</span>
                                </p>
                                <p v-else>N/A</p>
                            </li>

                            <!--<li v-if="$can('update', 'staff')">
                                <app-multiple-select-box
                                        name="role"
                                        data-vv-name="role"
                                        v-validate="{required: false}"
                                        :value="role"
                                        @select="updateRole($event)"
                                        :multiple="false"
                                        :options="roles"
                                        :internal-search="false"
                                        :class="{'is-invalid': errors.has('role'), 'autocomplete-loader': submitting}">
                                </app-multiple-select-box>
                            </li>-->

                            <li class="dropdown-divider mb-2"></li>

                            <li>
                                <ul class="list-inline">
                                    <li class="list-inline-item text-center m-2" v-if="agent.recognized_badge">
                                        <img class="img-fluid" :src="agent.recognized_badge">
                                    </li>
                                    <li class="list-inline-item text-center m-2" v-if="agent.immigration_adviser_badge">
                                        <img class="img-fluid" :src="agent.immigration_adviser_badge">
                                    </li>
                                </ul>
                            </li>

                            <li class="text-right hidden">
                                <button v-if="!pretended" class="jba-btn btn-danger" @click="impersonate()">Login as this user</button>
                                <a v-else href="/" class="jba-btn">Access</a>
                            </li>
                        </ul>
                    </div>

                    <!--div class="modal-footer">
                        <button class="jba-btn" @click.stop="toggleRecognizeStatue" v-if="!(agent.recognized_at || agent.recognition_request_token)" ><i v-if="submitting" class="fas fa-spinner fa-spin"></i> Send recognition request</button>
                    </div-->
                </div>
            </div>
        </div>
    </transition>

</template>

<script>
    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props:{
            id: {
                type: [Number, String],
                required: true
            },
            agent: {
                type: Object,
                default: function(){
                    return {};
                }
            }
        },

        data(){
            return {
                submitting: false,
                pretended: false,
                roles: [],
                role: this.agent.roles && this.agent.roles[0] || {},
                groups: []
            }
        },

        inject: ['api', 'appName'],

        created() {
            this.loadAgentGroups();
            this.loadRoles();
        },

        methods: {
            loadRoles() {

                let self = this;

                axios.get(self.api + 'admin/staff/roles')
                    .then(function(response){
                        self.roles = response.data;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            /*loadAgent() {
                let self = this;
                axios.get(this.api + 'admin/agents/' + this.id)
                    .then(function(response){

                        self.agent = response.data;
                        self.$emit('page-loaded');
                        return true;
                    })
                    .catch(function(error) {
                        self.handleError(error);
                    });
            },


            toggleRecognizeStatue() {

                let self = this;
                self.handleConfirm({
                    text: 'Are you sure that you want to send a request to recognise this agent?'
                }, function () {
                    self.submitting = true;

                    axios.put(self.api + 'admin/agents/' + self.id + '/request/recognition')
                        .then(function(response){

                            self.agent.recognition_request_token = response.data.recognition_request_token;
                            self.agent.recognized_badge = response.data.recognized_badge;
                            self.$emit('load-agents');
                            self.submitting = false;
                            return true;
                        })
                        .catch(function(error) {
                            self.handleError(error);
                            self.submitting = false;
                        });
                });
            },

            toggleImmigrationStatue() {

                let self = this;
                self.handleConfirm({
                    text: 'Are you sure that you want to update immigration status?'
                }, function () {
                    self.submittingImmigrationStatus = true;
                    let toggle = !self.agent.immigration_adviser ? 'approve' : 'disapprove';
                    axios.put(self.api + 'admin/agents/' + self.id + '/license/' + toggle)
                        .then(function(response){

                            self.agent.immigration_adviser = !self.agent.immigration_adviser;
                            self.$emit('load-agents');
                            self.submittingImmigrationStatus = false;
                            return true;
                        })
                        .catch(function(error) {
                            self.handleError(error);
                            self.submittingImmigrationStatus = false;
                        });
                });
            },*/

            loadAgentGroups() {

                let self = this;

                if(self.id) {
                    axios.get(self.api + 'admin/agents/' + self.id + '/groups')
                        .then(function(response){
                            self.groups = response.data;
                        })
                        .catch(function(error){
                            self.handleError(error);
                        });
                }
            },

            close() {
                this.toggleModal('hide');
            //    this.$router.push({name: 'admin.agents'});
                this.$router.go(-1);
            },

            impersonate() {

                let self = this;

                self.handleConfirm({
                    text: 'Are you sure that you want to login as this user?',
                    warning: 'You will logout your current account'
                }, function () {

                    axios.put(self.api + 'admin/agents/' + self.id + '/pretend')
                        .then(function(response){

                            self.pretended = true;
                            return true;
                        })
                        .catch(function(error) {
                            self.handleError(error);
                        });
                });

            },

            updateRole(role) {

                if(!role) {
                    return;
                }

                let self = this;

                self.submitting = true;

                axios.put(self.api + 'admin/agents/' + self.id + '/roles/' + role.id)
                    .then(function(response){
                        self.$emit('load-agents');
                        self.submitting = false;
                        self.close();
                    })
                    .catch(function(error){
                        self.handleError(error);
                        self.submitting = false;
                    });
            },

            toggleModal(status) {
                $(this.$el).modal(status);
            }
        },

        computed: {
  
        },

        mounted() {
            this.toggleModal('show');
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" >

    #show-agent-modal {
        .modal-header {
            border-bottom: none;
        }
        .modal-body {
            .list-inline-item {
                img {
                    /*height: 60px;*/
                }
            }
        }
        .modal-footer {
            border-top: none;
        }
        .avatar {
            position: relative;
            overflow: hidden;
            img {
                width: 105px;
                border: 5px solid var(--light-white-color);
                border-radius: 100%;
            }
            &:before {
                background: var(--main-color);
                content: '';
                width: 50%;
                height: 4px;
                position: absolute;
                left: -60px;
                top: 50%;
                margin-top: -2px;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    background: #22a2a2;
                }
            }

            &:after {
                background: var(--main-color);
                content: '';
                width: 50%;
                height: 4px;
                position: absolute;
                right: -60px;
                top: 50%;
                margin-top: -2px;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    background: #22a2a2;
                }
            }
        }
    }
</style>