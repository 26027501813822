<template>

    <div id="staff">
        <app-page-banner page="agent" title="Staff" subtitle="Management staff here"></app-page-banner>

        <div v-if="!loaded" class="page-loading"></div>

        <div v-else>
            <div class="row sm-p-2">
                <div class="col-12">
                    <ul class="nav nav-tabs">
                        <li class="nav-item mr-1" v-if="$can('view', 'agent')">
                            <router-link class="nav-link" :to="{name: 'admin.agents'}"><span>Agents</span></router-link>
                        </li>
                        <li class="nav-item" v-if="$can('view', 'staff')">
                            <router-link class="nav-link" :to="{name: 'admin.staff'}" :class="{'router-link-exact-active': isRoutePattern('^admin\.staff')}"><span>Staff</span></router-link>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="row sm-p-2" v-if="isCurrentRoute('admin.staff')">

                <div class="col-12" v-if="$can('view', 'team')">
                    <router-link class="jba-btn" :to="{name: 'admin.staff.teams'}" >Teams</router-link>
                </div>

                <!--<div class="col-12" v-if="$can('create', 'staff')">
                    <router-link class="jba-btn" :to="{name: 'admin.staff.create'}" >Create Staff</router-link>
                </div>-->


                <div class="col-12">
                    <app-search-bar placeholder="Search Staff" :search-handler="search" :clear-handler="clear" ref="keywords"></app-search-bar>
                </div>

                <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="(a, index) in filterStaff" :key="index">

                    <router-link tag="div" class="card clickable py-2" :to="{name: 'admin.staff.show', params: {id: a.id}}">
                        <app-sticky-flag v-if="a.deleted_at" text="Deleted" component-class="red"></app-sticky-flag>
                        <div class="table mb-0">
                            <div class="table-row">
                                <div class="table-cell">
                                    <img :src="a.avatar" class="img-fluid img-circle"/>
                                </div>
                                <div class="table-cell">
                                    <ul class="list-unstyled mb-0">
                                        <li class="h6">{{ a.full_name }}</li>
                                        <li class="h6">{{ join(a.roles) }}</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>

                <app-reminder :ready="loaded" message="There is no staff at the moment" :new-line="true" :result="staff"></app-reminder>

            </div>

        </div>

        <router-view :staff="currentStaff()" @load-staff="loadStaff"></router-view>
    </div>

</template>

<script>

    import { getAllStaff } from '@/api/staff';

    export default {
        props: {

        },
        data() {
            return {
                staff: [],
                page: 1,
                q: null
            }
        },

        inject: ['api'],

        components: {

        },

        created() {
            this.loadStaff();
        },

        destroyed() {

        },

        computed: {
            pageCount(){
                return Math.ceil(this.staff.total / this.staff.per_page);
            },

            filterStaff() {

                let self = this;

                return this.staff.filter(account => {
                    return !self.q
                        || (account.full_name.toLowerCase().includes(self.q.toLowerCase()))
                        || (account.email.toLowerCase().includes(self.q.toLowerCase()))
                });
            }
        },

        methods: {

            loadStaff(params = {}) {

                let self = this;

                self.submitting = true;

                getAllStaff(params)
                    .then(function(response){
                        self.staff = response.data;
                        self.page = self.staff.current_page;
                        self.loaded = true;
                        self.submitting = false;
                    })
                    .catch(function(error){
                        self.loaded = true;
                        self.submitting = false;
                        self.handleError(error);
                    });
            },

            search(keywords) {

                this.q = keywords;
            },

            clear() {
                this.q = null;
            },

            currentStaff() {

                for(let i = 0; i < this.staff.length; i++){
                    if(this.staff[i].id == this.$route.params.id) {
                        return this.staff[i];
                    }
                }

                return {};
            }

        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" >

    .table {
        display: table;

        .table-row {
            display: table-row;

            .table-cell {
                display: table-cell;
                vertical-align: middle;
                padding: 5px;
                max-width: 100px;

                &:nth-child(1) {
                    width: 80px;
                    vertical-align: top;
                }
                &:nth-child(2) {
                    li {
                        line-height: 1.5em;

                        &.image-badge {
                            min-height: 35px;
                            img {
                                height: 35px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>