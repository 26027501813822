<template>

    <div name="tour-complete-modal">
        <div class="modal fade show" data-backdrop="static" data-keyboard="false" id="tour-complete-modal" tabindex="-1" role="dialog" aria-labelledby="Show Course">
            <div class="modal-dialog" role="document" @click.stop="function(){}">
                <div class="modal-content">

                    <div class="modal-body">

                        <h6>This is just the beginning of our journey with you, we’ll be updating the Agent Hub regularly. Take a look around! </h6>

                        <p class="mb-1">Where do you want to go next?</p>
                        <p class="mb-0">Learn more about: </p>
                        <ul class="clickable pl-4 border" @click="goToCourse()">
                            <li>New Zealand’s education pathways?</li>
                            <li>Your obligations as an education agent?</li>
                            <li>New Zealand’s amazing learning environment?</li>
                        </ul>

                        <button class="jba-btn reverse" @click="goToHome()">Head back to the homepage</button>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {

        props:{

        },

        data(){
            return {
                loaded: false,
            }
        },


        created() {
        },

        methods: {


            close() {
                this.toggleModal('hide');
            },

            toggleModal(status) {
                $(this.$el).find('.modal').modal(status);
            },

            show() {
                this.toggleModal('show');
            },

            goToHome() {
                this.$router.push({name: 'agent.home'});
                this.close();
            },

            goToCourse(){
                this.$router.push({name: 'agent.courses'});
                this.close();
            }

        },

        computed: {

        },

        mounted() {

        }
    }

</script>