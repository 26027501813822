<template>

    <footer :class="{'admin': isAdmin()}">

        <div class="row bg-blue">
            <div class="col-12 bg-main">
                <p class="text-white text-center ma-0 menu">
                    <a :href="maiEnz().url.learn_more" class="px-3 py-2" target="_blank">MaiENZ</a>
                    <!--<router-link :to="{name: 'agent.privacy'}" class="px-3 py-2 d-inline-block">Privacy Policy</router-link>
                    <router-link :to="{name: 'agent.terms'}" class="px-3 py-2 d-inline-block">T&C</router-link>-->
                    <a class="px-3 py-2" :href="maiEnz().url.privacy" target="_blank">Privacy Policy</a>
                    <a class="px-3 py-2" :href="maiEnz().url.terms" target="_blank">Terms and Conditions</a>
                </p>
            </div>
            <div class="col-12">
                <p class="text-white py-2">
                    <span>All content &copy; Education New Zealand {{ currentYear }}</span>
                    <small>Powered by <a href="https://www.joybusinessacademy.com" target="_blank" class="link text-white">Joy Business Academy</a></small>
                </p>
            </div>
        </div>
    </footer>

</template>

<script>

    export default {
        props: {

        },
        data() {
            return {

            }
        },

        components: {

        },

        created() {

        },

        inject: ['maiEnz', 'auth'],

        destroyed() {

        },

        computed: {
            currentYear() {
                return moment().year();
            }
        },

        methods: {

        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">


</style>