<template>

    <div id="group">
        <app-page-banner page="group" title="Groups" subtitle="View and create groups"></app-page-banner>

        <div v-if="isCurrentRoute('admin.groups')">

            <div v-if="!loaded" class="page-loading"></div>
            <div class="row sm-p-2" v-else>

                <div  class="col-12" v-if="$can('create', 'group')">
                    <router-link :to="{name: 'admin.groups.create'}" class="jba-btn btn-info">Create New Group</router-link>
                </div>

                <div class="col-sm-12 col-md-12">
                    <h5 class="text-main mb-0">Group list</h5>
                </div>


                <div class="col-12" v-show="pageCount > 1">
                    <paginate :page-count="pageCount"
                              :page-range="5"
                              :click-handler="navigate"
                              :prev-text="'<'"
                              :next-text="'>'"
                              :container-class="'pagination float-right'"
                              :page-class="'page-item'"
                              v-model="page"
                    ></paginate>
                </div>

                <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="(g, index) in groups.data" :key="index" v-if="groups.total">

                    <router-link tag="div" class="card clickable p-2" :to="{name: 'admin.groups.show', params: {id: g.id}}" :title="g.description">

                        <i v-if="$can('delete', 'group')" class="fas fa-trash clickable close" @click.stop="remove(g.id, g.name)" aria-label="Close"></i>
                        <h6 class="pr-4 mb-0 py-1 text-truncate">{{ g.name }}</h6>
                        <small>{{ g.description }}</small>
                        <div class="dropdown-divider mb-2"></div>
                        <div class="text-grey foot">
                            <div class="pr-5">
                                <p><small>Created: <span>{{g.created_at | convertDate}}</span></small></p>
                                <p><small>By: <span>{{g.user_name}}</span></small></p>
                            </div>
                            <div class="agent-count"><i class="fas fa-users"></i> <span>{{g.agent_count}}</span></div>
                        </div>
                    </router-link>

                </div>

                <app-reminder :ready="loaded" message="There is no group at the moment" :new-line="true" :result="groups.data"></app-reminder>

                <div class="col-12" v-show="pageCount > 1">
                    <paginate :page-count="pageCount"
                              :page-range="5"
                              :click-handler="navigate"
                              :prev-text="'<'"
                              :next-text="'>'"
                              :container-class="'pagination float-right'"
                              :page-class="'page-item'"
                              v-model="page"
                    ></paginate>
                </div>
            </div>
        </div>

        <router-view @load-groups="navigate(page)"></router-view>
    </div>
</template>

<script>
    export default {
        props: {

        },
        data() {
            return {
                submitting: false,
                groups: {},
                page: 1,
                loaded: false
            }
        },

        inject: ['api', 'countries', 'providerTypes'],

        components: {

        },

        created() {
            this.loadGroups();
        },

        destroyed() {

        },

        computed: {
            pageCount(){
                return Math.ceil(this.groups.total / this.groups.per_page);
            }
        },

        watch: {

        },

        methods: {

            search(keywords) {

                this.loadGroups({q: keywords});

            },

            navigate(page) {

                let data = {page: page};
                // search bar is removed in this page
                /*if(this.$refs.keywords.value) {
                    data.q = this.$refs.keywords.value;
                }*/

                this.loadGroups(data);
            },

            loadGroups(params = {}) {

                let self = this;

                axios.get(self.api + 'admin/groups', {params: params})
                    .then(function(response){
                        self.groups = response.data;
                        self.page = self.groups.current_page;
                        self.loaded = true;
                        self.$emit('page-loaded');
                    })
                    .catch(function(error){
                        self.submitting = false;
                    });
            },

            remove(id, name) {

                let self = this;

                self.handleConfirm({
                    text: 'Are you sure that you want to delete "' + name + '"?'
                }, function () {
                    axios.delete(self.api + 'admin/groups/' + id)
                        .then(function (response) {
                            self.navigate(self.goToPage(self.groups.current_page, self.groups.data.length - 1));
                        })
                        .catch(function (error) {
                            self.handleError(error)
                        })
                }.bind(this, id));

            },

            clear() {
                this.navigate(1);
            }

        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" >
    .card {
        .foot {
            position: relative;
            p {
                line-height: 1em;
                margin-bottom: 0;
            }
            .agent-count {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
    }
</style>