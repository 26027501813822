<template>

    <transition name="transition-modal">
        <div class="modal fade show" data-backdrop="static" data-keyboard="false" id="create-feed-modal" tabindex="-1" role="dialog" aria-labelledby="Create Feed">
            <div class="modal-dialog" role="document" @click.stop="function(){}">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Create news feed</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>

                    </div>
                    <div class="modal-body">
                        <form novalidate>
                            <div class="form-group">
                                <label for="feeds_url">New link <span>*</span></label>
                                <input id="feeds_url" name="url" type="url" class="form-control"
                                       :class="{'is-invalid': errors.has('url')}"
                                       v-model="form.url"
                                       v-validate="{required: true, url:'require_protocol'}"/>
                                <small v-show="errors.has('url')" class="text-danger">{{ errors.first('url') }}</small>
                            </div>

                            <!--div class="form-group">
                                <label for="feeds_title">Title</label>
                                <input type="text" id="feeds_title" name="title" class="form-control"
                                          :class="{'is-invalid': errors.has('title')}"
                                          v-model="form.title"
                                          v-validate="{min: 3}" />
                                <small v-show="errors.has('title')" class="text-danger">{{ errors.first('title') }}</small>
                                <small>Leave it empty if you don't want to change the original news title</small>
                            </div-->

                            <div class="form-group">
                                <label for="feeds_message">Message</label>
                                <textarea id="feeds_message" name="message" class="form-control"
                                          :class="{'is-invalid': errors.has('message')}"
                                          v-model="form.content"
                                          v-validate="{min: 3}"></textarea>
                                <small v-show="errors.has('message')" class="text-danger">{{ errors.first('message') }}</small>
                            </div>

                            <div class="form-group clearfix" v-show="!form.countries.length && !form.provider_types.length">
                                <label for="feeds_groups" class="">Groups</label>

                                <!--v-select id="feeds_groups" label="name" data-vv-name="groups" multiple
                                          :class="{'is-invalid': errors.has('groups')}"
                                          :searchable=false
                                          v-model="form.groups"
                                          :options="groups"
                                          v-validate="{required: false}"
                                ></v-select-->
                                <app-multiple-select-box
                                        id="feeds_groups"
                                        name="groups"
                                        data-vv-name="groups"
                                        v-validate="{required: false}"
                                        placeholder="Select groups"
                                        :value="form.groups"
                                        @select="form.groups = $event"
                                        :multiple="true"
                                        :options="groups"
                                        :searchable="false"
                                        :markup="false"
                                        :class="{'is-invalid': errors.has('groups')}">
                                </app-multiple-select-box>
                                <small v-show="errors.has('groups')" class="text-danger">{{ errors.first('groups') }}</small>
                            </div>

                            <div v-show="!form.groups.length">
                                <div class="form-group">
                                    <label for="feeds_countries" class="">Countries</label>

                                    <!--v-select id="feeds_countries" label="name" data-vv-name="countries" multiple
                                              :class="{'is-invalid': errors.has('countries')}"
                                              :searchable=true
                                              v-model="form.countries"
                                              :options="countries()"
                                              v-validate="{required: false}"
                                    ></v-select-->
                                    <app-multiple-select-box
                                            id="feeds_countries"
                                            name="countries"
                                            data-vv-name="countries"
                                            v-validate="{required: false}"
                                            placeholder="Select countries"
                                            :value="form.countries"
                                            @select="form.countries = $event"
                                            :multiple="true"
                                            :options="countries()"
                                            :internal-search="false"
                                            :markup="false"
                                            :class="{'is-invalid': errors.has('countries')}">
                                    </app-multiple-select-box>

                                    <small v-show="errors.has('countries')" class="text-danger">{{ errors.first('countries') }}</small>
                                </div>

                                <div class="form-group">
                                    <label for="feeds_types" class="">Provider types</label>
                                    <!--v-select label="name" data-vv-name="provider_types" multiple
                                              :class="{'is-invalid': errors.has('provider_types')}"
                                              :searchable=false
                                              v-model="form.provider_types"
                                              :options="providerTypes()"
                                              v-validate="{required: false}"
                                    ></v-select-->
                                    <app-multiple-select-box
                                            id="feeds_types"
                                            name="provider_types"
                                            data-vv-name="provider_types"
                                            v-validate="{required: false}"
                                            placeholder="Select provider types"
                                            :value="form.provider_types"
                                            @select="form.provider_types = $event"
                                            :multiple="true"
                                            :options="providerTypes()"
                                            :searchable="false"
                                            :markup="false"
                                            :class="{'is-invalid': errors.has('provider_types')}">
                                    </app-multiple-select-box>

                                    <small v-show="errors.has('types')" class="text-danger">{{ errors.first('provider_types') }}</small>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="hidden">Sticky option</label>
                                <label class="custom-control overflow-checkbox my-0">
                                    <input id="flag" type="checkbox" class="overflow-control-input" v-model="form.flag" value="1">
                                    <span class="overflow-control-indicator"></span>
                                    <span class="overflow-control-description">Sticky option</span>
                                </label>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <!--<button @click.stop="close" class="jba-btn reverse">Cancel</button>-->
                        <button @click.stop="submit" class="jba-btn" :disabled="errors.any() || submitting"><i v-if="submitting" class="fas fa-spinner fa-spin"></i> Send</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>

</template>

<script>
    import DatePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props:{

        },

        data(){
            return {
                form: {
                    countries: [],
                    provider_types: [],
                    groups: []
                },
                submitting: false,
                initialLoad: true,
                keywords: null,
                groups: []
            }
        },

        inject: ['api', 'countries', 'providerTypes'],

        components: {

        },

        created() {
            this.loadGroups();
        },

        destroyed() {

        },

        computed: {

        },

        mounted() {
            this.toggleModal('show');
        },

        methods: {
            loadGroups() {
                let self = this;

                axios.get(self.api + 'admin/groups/list')
                    .then(function(response){
                        self.groups = response.data;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    })
            },

            close() {
                this.toggleModal('hide');
                this.$router.push({name: 'admin.feeds'});
            },

            toggleModal(status) {
                $(this.$el).modal(status);
            },

            submit() {
                let self = this;
                self.initialLoad = false;

                self.$validator.validateAll().then(function(){

                    if(!self.errors.any()) {

                        let data = {
                            countries: [],
                            provider_types: [],
                            groups: [],
                            title: self.form.title,
                            content: self.form.content,
                            url: self.form.url,
                            flag: self.form.flag
                        };

                        self.form.countries.forEach(function(o){
                            data.countries.push(o.id);
                        });

                        self.form.provider_types.forEach(function(o){
                            data.provider_types.push(o.id);
                        });

                        self.form.groups.forEach(function(o){
                            data.groups.push(o.id);
                        });

                        self.submitting = true;

                        axios.post(self.api + 'admin/feeds', data)
                            .then(function(response) {
                                self.submitting = false;
                                self.$emit('load-feeds');
                                self.close();
                                return true;
                            })
                            .catch(function(error){
                                self.submitting = false;
                                self.handleError(error);
                            });
                    }
                });
            }
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" >


    .list-group{
        &:first-child .list-group-control:first-child {
            border-top-left-radius: .25rem;
            border-top-right-radius: .25rem;
        }

        &:last-child .list-group-control:last-child {
            border-bottom-left-radius: .25rem;
            border-bottom-right-radius: .25rem;
        }

        &.list-group-flush{
            &:last-child{
                .list-group-control:last-child {
                    border-bottom: 0;
                    margin-bottom: 0;
                }
            }

            &:first-child{
                .list-group-control:first-child {
                    border-top: 0;
                }
            }

            .list-group-control {
                border-right: 0;
                border-left: 0;
                border-radius: 0;
            }
        }

        .list-group-control{
            position: relative;
            display: block;
            margin-bottom: -1px;
            background-color: #fff;
            border: 1px solid rgba(0,0,0,.125);

            .custom-control{
                margin: 0;
                padding: .75rem 1.25rem;
                padding-left: 3rem;
                display: block;

                .custom-control-indicator, .material-control-indicator, .fill-control-indicator, .overflow-control-indicator{
                    top: 1rem;
                    left: 1.25rem;
                }
            }
        }
    }


</style>