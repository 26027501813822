<template>
    <div class="agent-document">
        <app-page-banner page="agent-document" title="Documents" subtitle="Find the information and resources you need quickly"></app-page-banner>

        <div v-if="!loaded" class="page-loading"></div>
        <div v-else :class="{'fixed-content-width': $root.fixedContentWidth }">
            <div class="row sm-p-2">

                <div class="col-sm-12">
                    <app-search-bar placeholder="Search Documents" :search-handler="search" :clear-handler="clear" ref="keywords"></app-search-bar>
                </div>

                <div class="col-12" v-show="pageCount > 1">
                    <paginate :page-count="pageCount"
                              :page-range="5"
                              :click-handler="navigate"
                              :prev-text="'<'"
                              :next-text="'>'"
                              :container-class="'pagination float-right'"
                              :page-class="'page-item'"
                              v-model="page"
                    ></paginate>
                </div>

                <div class="col-12 col-sm-6 col-md-4" v-for="(m, index) in documents.data" :key="index" v-if="documents.total">

                    <router-link tag="div" class="card clickable p-2" :to="{name:'agent.documents.show', params: {id: m.id}}" :title="m.name">

                        <h6 class="pr-4 mb-0 py-1 text-truncate">{{ m.name }}</h6>
                        <div class="dropdown-divider mb-2"></div>
                        <img :src="m.image" alt="document cover image">
                        <div class="dropdown-divider mb-2"></div>
                        <div class="text-grey foot">
                            <div class="pr-5">
                                <p class="m-0"><small>attachments: <b>{{ m.attachment_count ? m.attachment_count : 'N/A' }}</b></small></p>
                                <p class="m-0"><small>size: <b>{{ m.attachment_size | bytesToSize }}</b></small></p>
                            </div>
                        </div>
                    </router-link>

                </div>

                <app-reminder :ready="loaded" message="There are no documents currently assigned to you." :new-line="true" :result="documents.data"></app-reminder>


                <div class="col-12" v-show="pageCount > 1">
                    <paginate :page-count="pageCount"
                              :page-range="5"
                              :click-handler="navigate"
                              :prev-text="'<'"
                              :next-text="'>'"
                              :container-class="'pagination float-right'"
                              :page-class="'page-item'"
                              v-model="page"
                    ></paginate>
                </div>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        props: {

        },
        data() {
            return {
                documents: [],
                page: 1
            }
        },

        inject: ['api'],

        components: {

        },

        created() {
            this.loadDocuments();
        },

        destroyed() {

        },

        computed: {
            pageCount(){
                return Math.ceil(this.documents.total / this.documents.per_page);
            }
        },

        watch: {

        },

        methods: {
            search(keywords) {
                this.loadDocuments({q: keywords});
            },

            navigate(page) {

                let params = {page: page};

                if(this.$refs.keywords.value) {
                    params.q = this.$refs.keywords.value;
                }

                this.loadDocuments(params);
            },

            loadDocuments(params = {}) {

                let self = this;

                axios.get(this.api + 'agent/documents', {params: params})
                    .then(function(response){
                        self.documents = response.data;
                        self.page = params.page || 1;
                        self.loaded = true;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            clear() {
                this.navigate(1);
            }

        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

    .doc-cover-img {
        object-fit:cover;
        height:200px;
    }

    .default-doc-image {
        font-size: 100px;
        color: grey;
        margin:auto;
    }
</style>