<template>

    <div id="broadcast">
        <app-page-banner page="broadcast" title="Messages" subtitle="Message agents"></app-page-banner>

        <div v-if="!loaded" class="page-loading"></div>
        <div class="row sm-p-2" v-else>

            <div class="col-12">

                <ul class="nav nav-tabs">
                    <li class="nav-item mr-1" v-if="$can('view', 'news-feed')">
                        <router-link class="nav-link" :to="{name: 'admin.feeds'}"><span>News feed</span></router-link>
                    </li>
                    <li class="nav-item" v-if="$can('view', 'message')">
                        <router-link class="nav-link" :to="{name: 'admin.broadcasts'}" :class="{'router-link-exact-active': isRoutePattern('^admin\.broadcasts')}"><span>Message</span></router-link>
                    </li>
                </ul>

                <!-- List Message -->
            </div>

            <div class="col-12" v-if="$can('create', 'message')">
                <router-link :to="{name: 'admin.broadcasts.create'}" class="jba-btn btn-info">Create</router-link>
            </div>

            <div class="col-12 col-sm-6">
                <h5 class="text-main mb-0">Message list</h5>
            </div>

            <div class="col-12 col-sm-6">
                <app-search-bar placeholder="Search Message" :search-handler="search" :clear-handler="clear" ref="keywords"></app-search-bar>
            </div>

            <div class="col-12" v-show="pageCount > 1">
                <paginate :page-count="pageCount"
                          :page-range="5"
                          :click-handler="navigate"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :container-class="'pagination float-right'"
                          :page-class="'page-item'"
                          v-model="page"
                ></paginate>
            </div>

            <div class="col-12 mt-3">
                <table class="table table-hover" v-if="broadcasts.total">
                    <tbody>
                        <tr v-for="(m, index) in broadcasts.data" :key="index">
                            <td class="overlap">
                                <div class="panel">
                                    <button type="button" aria-label="Close" @click="remove(m.id)" v-if="$can('delete', 'message')">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>

                                <span class="posted">sent at {{ m.created_at | convertDate }}</span>

                                <div class="wrap-link-details d-flex">
                                    <span class="left-border"></span>
                                    <div class="w-100">
                                        <h5 class="wr-bk text-bold">{{ m.subject }}</h5>
                                        <p class="pl-3" v-html="m.content"></p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <app-reminder :ready="loaded" message="There is no broadcast message at the moment" :result="broadcasts.data"></app-reminder>
            </div>

            <div class="col-12" v-show="pageCount > 1">
                <paginate :page-count="pageCount"
                          :page-range="5"
                          :click-handler="navigate"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :container-class="'pagination float-right'"
                          :page-class="'page-item'"
                          v-model="page"
                ></paginate>
            </div>

        </div>

        <router-view @load-broadcasts="loadBroadcasts"></router-view>
    </div>
</template>

<script>

    export default {
        props: {

        },
        data() {
            return {
                submitting: false,
                broadcasts: [],
                page: 1
            }
        },

        inject: ['api', 'countries', 'providerTypes'],

        components: {

        },

        created() {
            this.loadBroadcasts();
        },

        destroyed() {

        },

        computed: {
            pageCount(){
                return Math.ceil(this.broadcasts.total / this.broadcasts.per_page);
            }
        },

        watch: {

        },

        methods: {
            search(keywords) {

                this.loadBroadcasts({q: keywords});
            },

            navigate(page) {

                let params = {page: page};

                if(this.$refs.keywords.value) {
                    params.q = this.$refs.keywords.value;
                }

                this.loadBroadcasts(params);
            },

            filterByKeywords(presets) {
                let self = this;
                if(self.keywords.length < 3) {
                    return presets;
                }

                return presets.filter(function(preset) {
                    let regex = new RegExp('(' + self.keywords + ')', 'i');
                    return preset.subject.match(regex) || preset.content.match(regex);
                })
            },

            loadBroadcasts(params = {}) {

                let self = this;

                axios.get(this.api + 'admin/broadcasts', {params: params})
                    .then(function(response){
                        self.broadcasts = response.data;
                        self.page = self.broadcasts.current_page;
                        self.loaded = true;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            remove(id) {

                let self = this;
                self.handleConfirm({
                    text: 'Are you sure that you want to delete this message?'
                }, function () {
                    axios.delete(self.api + 'admin/broadcasts/' + id)
                        .then(function (response) {
                            self.navigate(self.goToPage(self.broadcasts.current_page, self.broadcasts.data.length - 1));
                        })
                        .catch(function (error) {
                            self.handleError(error)
                        })
                }.bind(this, id));

            },

            clear() {
                this.navigate(1);
            }

        },

        mounted() {
            
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>