<template>

    <transition name="transition-modal">
        <div class="modal fade show" data-backdrop="static" data-keyboard="false" id="rename-role-modal" tabindex="-1" role="dialog" aria-labelledby="Rename Role">
            <div class="modal-dialog" role="document" @click.stop="function(){}">
                <div class="modal-content">
                    <div class="modal-header pb-0">
                        <h5>Rename <i class="text-bold fs-110">{{ role.name }}</i> to</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </div>
                    <div class="modal-body pt-1">
                        <form novalidate v-if="!role.readonly">
                            <div class="form-group">
                                <input id="change-to" name="name" type="text" class="form-control"
                                       v-model="name"
                                       :class="{'is-invalid': errors.has('name')}"
                                       v-validate="{required: true, min: 3, regex: /^[A-Za-z ]+$/}"/>
                                <small v-show="errors.has('name')" class="text-danger">{{ errors.first('name') }}</small>
                            </div>

                            <div class="text-right">
                                <!--<button @click.stop="close" class="jba-btn reverse">Cancel</button>-->
                                <button @click.stop="submit" class="jba-btn" :disabled="errors.any() || submitting"><i v-if="submitting" class="fas fa-spinner fa-spin"></i> Rename</button>
                            </div>
                        </form>
                        <p v-else>A readonly role is not allowed to update!</p>
                    </div>

                </div>
            </div>
        </div>
    </transition>

</template>

<script>

    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props:{
            id: {
                type: [Number, String],
                default: null
            },
            role: {
                type: Object,
                default: function() {
                    return {};
                }
            }
        },

        data(){
            return {
                name: null,
                submitting: false,
            }
        },

        inject: ['api'],

        components: {

        },

        created() {

        },

        destroyed() {

        },

        computed: {

        },

        mounted() {
            this.toggleModal('show');
        },

        methods: {
            loadRole() {
                let self = this;

                axios.get(self.api + 'admin/acl/roles/' + self.id + '/permissions')
                    .then(function(response){
                        self.role = response.data;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    })
            },

            close() {
                this.toggleModal('hide');
                this.$router.push({name: 'admin.acl'});
            },

            toggleModal(status) {
                $(this.$el).modal(status);
            },

            submit() {

                let self = this;

                self.$validator.validateAll().then(function(){

                    if(!self.errors.any()) {

                        self.submitting = true;

                        axios.put(self.api + 'admin/acl/roles/' + self.id, {name: self.name})
                            .then(function(response) {
                                self.submitting = false;
                                self.$emit('load-acl');
                                self.close();
                                return true;
                            })
                            .catch(function(error){
                                self.submitting = false;
                                self.handleError(error);
                            });
                    }
                });
            }
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" >


</style>