import request from '@/api/request';

export function getVideos(params = {}) {
    return request({
        url: 'admin/videos',
        method: 'GET',
        params: params
    });
}

export function getVideosForAgent(params = {}) {
    return request({
        url: 'agent/videos',
        method: 'GET',
        params: params
    });
}

export function createVideo(params = {}) {

    return request({
        url: 'admin/videos',
        method: 'POST',
        params: params
    });
}

export function updateVideo(videoId, params = {}) {

    return request({
        url: `admin/videos/${videoId}`,
        method: 'PUT',
        params: params
    });
}

export function deleteVideo(videoId) {

    return request({
        url: `admin/videos/${videoId}`,
        method: 'DELETE'
    });
}