<template>
    <video-player  class="video-player-box"
                   ref="videoPlayer"
                   :options="playerOptions"
                   :playsinline="true"
                   :customEventName="name"

                   @play="onPlayerPlay($event, id)"
                   @pause="onPlayerPause($event)"
                   @ended="onPlayerEnded($event, id)"
                   @waiting="onPlayerWaiting($event)"
                   @playing="onPlayerPlaying($event)"
                   @loadeddata="onPlayerLoadedData($event)"
                   @timeupdate="onPlayerTimeUpdate($event)"
                   @canplay="onPlayerCanplay($event)"
                   @canplaythrough="onPlayerCanplayThrough($event)"

                   @statechanged="playerStateChanged($event)"
                   @ready="playerReady">
    </video-player>
</template>

<script>

    import 'video.js/dist/video-js.css';
    import { videoPlayer } from 'vue-video-player';

    export default {
        props: {
            id: {
                type: Number,
                default: null
            },

            source: {
                type: String,
                required: true
            },

            type: {
                type: String,
                default: 'video/mp4'
            },

            name: {
                type: String,
                default: 'Webinar Archive'
            },

            autoplay: {
                type: Boolean,
                default: false
            },

            muted: {
                type: Boolean,
                default: false
            },

            playsinline: {
                type: Boolean,
                default: false
            },

            language: {
                type: String,
                default: 'en'
            },

            poster: {
                type: String,
                default: null
            },

            height: {
                type: String,
                default: null
            },

            onPlayerPlay: {
                type: Function,
                default: function(){}
            },

            onPlayerEnded: {
                type: Function,
                default: function(){}
            }
        },

        data() {
            return {

            }
        },

        inject: ['api'],

        components: {
            videoPlayer
        },

        computed: {
            player() {
                return this.$refs.videoPlayer.player;
            },

            playerOptions() {
                let options = {
                    autoplay: this.autoplay,
                    muted: this.muted,
                    language: this.language,
                    playbackRates: [0.7, 1.0, 1.5, 2.0],
                    fluid: true,
                    playsinline: this.playsinline,
                    centerBigPlayButton: true,
                    sources: {
                        type: this.type,
                        src: this.source
                    }
                };

                if(this.height) {
                    options.height = this.height;
                }

                if(this.poster) {
                    options.poster = this.poster;
                }

                return options;

            }
        },

        mounted() {
            /*setTimeout(function(){
                console.log('dynamic change options', this.player);

            }, 5000);*/
        },

        methods: {
            /*onPlayerPlay(player) {

                axios.get(this.api + 'events/archives/' + this.id + '/play')
                    .then(function(response) {

                    })
                    .catch(function(error) {

                    })

            },*/

            onPlayerPause(player) {
                //console.log('player Paused', player);
            },

            /*onPlayerEnded(player) {
                console.log('player End', player);
            },*/

            onPlayerLoadedData(player) {
                //console.log('player Loaded Data', player);
            },

            onPlayerWaiting(player) {
                //console.log('player Waiting', player);
            //    $(this.$el).addClass("vjs-custom-waiting");
            },

            onPlayerPlaying(player) {
                //console.log('player playing', player);
            },

            onPlayerTimeUpdate(player) {
                //console.log('player Time Update!', player.currentTime())
            },

            onPlayerCanplay(player) {
                //console.log('player Canplay!', player)
            //    $(this.$el).removeClass("vjs-custom-waiting");
            },

            onPlayerCanplayThrough(player) {
                //console.log('player Canplay Through!', player)
            },

            playerStateChanged(playerCurrentState) {
                //console.log('player current update state', playerCurrentState)
            },

            playerReady(player) {
                //console.log('player is ready', player);
                //player.currentTime(10);
            },

            /*pause() {
                return this.$refs.videoPlayer.$refs.video.pause();
            }*/
        }
    }
</script>
<style rel="stylesheet/scss" lang="scss">
    .video-player {
        .video-js {
            .vjs-big-play-button {
                left: 50%;
                top: 50%;
                margin: -22.5px 0 0 -45px;
            }

            .vjs-poster {
                background-size: cover;
            }

            .vjs-control-bar {
                .vjs-button {
                    .vjs-icon-placeholder {
                        &:before {
                            font-size: 18px;
                        }
                    }
                }
            }

        }

        /*&.vjs-custom-waiting {
            .vjs-loading-spinner
            {
                display: block;
                visibility: visible;

                &:before, &:after {
                    -webkit-animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
                    animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
                }
            }
        }*/
    }
</style>