<template>
    <div id="admin-agency-review" class="agency-review">

        <div class="row sm-p-2" v-show="isCurrentRoute('admin.agencies.pending.review')">
            <div class="col-12">
                <app-breadcrumb></app-breadcrumb>

            </div>

            <div class="col-12">
                <div class="rules">
                    <p class="mb-1"><i class="fas fa-info-circle static text-main"></i> Please read the following rules before committing any action</p>
                    <ul class="pl-4 mb-0">
                        <li>Only unverified agency or location can be approved or declined</li>
                        <li>All unverified locations will be approved automatically when an agency is approved</li>
                        <li>All duplicated locations will be removed when merging two agencies</li>
                    </ul>
                </div>
            </div>

            <div v-if="!loaded" class="page-loading"></div>
            <div class="col-12" v-else>
                <table class="table">

                    <thead>
                        <tr class="row">
                            <th class="col-10 col-sm-11 col-md-4 col-lg-5">Agency</th>
                            <th class="col-2 col-sm-1 d-block d-md-none"></th>
                            <th class="col-md-4 col-lg-4 d-none d-md-inline-block">Location</th>
                            <th class="col-md-4 col-lg-3 d-none d-md-inline-block"></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr class="bg-highlight row">
                            <td class="col-10 col-sm-11 col-md-4 col-lg-5">
                                <app-single-field-update
                                        ref="singleFieldUpdate"
                                        component-class="editable-text mb-0"
                                        :component-value="organization.agency_name"
                                        :component-params="organization.id"
                                        :component-handler="updateAgencyName"
                                        :editable="$can('approve', 'agency')"
                                ></app-single-field-update>
                            </td>
                            <td class="col-2 col-sm-1 d-block d-md-none text-center" @click.stop="expandRow($event)"><i class="fas fa-plus fs-110"></i></td>
                            <td class="col-md-4 col-lg-4 d-none d-md-inline-block">
                                <ul class="list-unstyled mb-0" v-if="organization.locations && organization.locations.length">
                                    <li v-for="(e, index) in organization.locations">
                                        <div class="list-item">
                                            <i class="far fa-building"></i> {{ e.city }}, {{ e.country_name }}
                                            <i v-if="!e.verified && organization.locations.length > 1 && $can('decline', 'agency')" class="fas fa-trash text-red clickable" @click="declineLocation(index)"></i>
                                        </div>
                                    </li>
                                </ul>
                            </td>
                            <td class="col-md-4 col-lg-3 d-none d-md-inline-block">
                                <div class="text-center">
                                    <button v-if="organization.locations.length > 0 && $can('approve', 'agency')" class="jba-btn btn-xs my-1" @click="approve()"><i class="fas fa-spin fa-spinner p-0 fs-110 static hidden"></i> Approve</button>
                                    <button class="jba-btn btn-danger btn-xs my-1" @click="decline()" v-if="$can('decline', 'agency')"><i class="fas fa-spin fa-spinner p-0 fs-110 static hidden"></i> Decline</button>
                                </div>
                            </td>
                        </tr>

                        <tr class="row expand" style="display: none;">
                            <td class="col-12 col-sm-12 d-block d-md-none">
                                <h5 class="mb-0">{{ organization.agency_name }}</h5>
                                <ul class="list-unstyled tree" v-if="organization.locations && organization.locations.length">
                                    <li v-for="(e, index) in organization.locations">
                                        <i class="far fa-building"></i> {{ e.city }}, {{ e.country_name }}
                                        <i v-if="!e.verified && organization.locations.length > 1 && $can('decline', 'agency')" class="fas fa-trash text-red clickable" @click="declineLocation(index)"></i>
                                    </li>
                                </ul>

                                <div class="mt-2">
                                    <button v-if="organization.locations.length > 0 && $can('approve', 'agency')" class="jba-btn btn-xs" @click="approve()"><i class="fas fa-spin fa-spinner p-0 fs-110 static hidden"></i> Approve</button>
                                    <button class="jba-btn btn-danger btn-xs" @click="decline()" v-if="$can('decline', 'agency')"><i class="fas fa-spin fa-spinner p-0 fs-110 static hidden"></i> Decline</button>
                                </div>
                            </td>
                        </tr>

                        <tr class="row" v-if="hasSimilarAgencies">
                            <td colspan="3" class="col-12"><i class="fas fa-info-circle text-main static pl-0"></i> {{ organization.similar_agencies.length == 1 ? 'One similar agency has been found,' : 'Some similar agencies have been found' }}</td>
                        </tr>

                        <!-- similar agencies list -->
                        <template v-for="(e, index) in organization.similar_agencies">
                            <tr class="bg-light-white row mb-1">
                                <td class="col-10 col-sm-11 col-md-4 col-lg-5">
                                    <p class="fs-110 my-1">{{ e.agency_name }}</p>
                                </td>
                                <td class="col-2 col-sm-1 d-block d-md-none text-center" @click.stop="expandRow($event)"><i class="fas fa-plus fs-110"></i></td>
                                <td class="col-md-4 col-lg-4 d-none d-md-inline-block">
                                    <ul class="list-unstyled mb-0" v-if="e.locations && e.locations.length">
                                        <li v-for="(i, idx) in e.locations">
                                            <div class="list-item">
                                                <i class="far fa-building"></i> {{ i.city }}, {{ i.country_name }}
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td class="col-md-4 col-lg-3 d-none d-md-inline-block">
                                    <div class="text-center">
                                        <button class="jba-btn btn-xs my-1" @click="mergeTo(e)" v-if="$can('merge', 'agency')"><i class="fas fa-spin fa-spinner p-0 fs-110 static hidden"></i> Merge</button>
                                    </div>
                                </td>
                            </tr>

                            <tr class="row expand" style="display: none;">
                                <td class="col-12 col-sm-12 d-block d-md-none">
                                    <ul class="list-unstyled tree" v-if="e.locations && e.locations.length">
                                        <li v-for="(i, idx) in e.locations"><i class="far fa-building"></i> {{ i.city }}, {{ i.country_name }}</li>
                                    </ul>
                                    <div class="mt-2">
                                        <button class="jba-btn btn-xs" @click="mergeTo(e)" v-if="$can('merge', 'agency')"><i class="fas fa-spin fa-spinner p-0 fs-110 static hidden"></i> Merge</button>
                                    </div>

                                </td>
                            </tr>
                        </template>

                    </tbody>
                </table>
            </div>

        </div>
    </div>
</template>

<script>

    export default {
        props: {
            sdId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                agencyNameSaved: false,
                organization: {
                }
            }
        },

        inject: ['api'],

        components: {

        },

        created() {
            this.loadUnverifiedOrganization()
        },

        destroyed() {

        },

        computed: {
            hasSimilarAgencies() {
                return  this.organization.similar_agencies && this.organization.similar_agencies.length;
            }
        },

        methods: {

            loadUnverifiedOrganization() {

                let self = this;

                axios.get(self.api + 'admin/organizations/' + self.sdId + '/unverified')
                    .then(function(response){
                        self.organization = response.data;
                        self.loaded = true;
                        self.$emit('page-loaded');
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            updateAgencyName(name, agencyId) {

                let self = this;
                self.agencyNameSaved = false;
                axios.patch(self.api + 'admin/organizations/' + agencyId + '/name', {name: name})
                    .then(function(response){
                        self.handleNotice('The agency name is updated');
                        self.$refs.singleFieldUpdate.resetStatus();
                        self.$emit('load-unverified-organizations');
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });

            },

            expandRow(e) {
                let expand = $(e.currentTarget).closest('tr').next('.expand');

                expand.toggle();

                if(expand.is(":visible")) {
                    $(e.currentTarget).find('.fa-plus').addClass('fa-minus').removeClass('fa-plus');
                }
                else {
                    $(e.currentTarget).find('.fa-minus').addClass('fa-plus').removeClass('fa-minus');
                }

            },

            mergeTo(agency) {

                if(!agency.verified) {
                    this.handleError({message: 'The target agency is not approved'});
                }

                let self = this;

                if(!self.organization.verified){

                    let mergeButton = event.currentTarget;

                    self.handleConfirm({
                        text: 'Are you sure that you want to merge these agencies?'
                    }, function () {

                        self.startSubmitting(mergeButton);

                        axios.post(self.api + 'admin/organizations/' + self.organization.id + '/merge/' + agency.id)
                            .then(function(response){
                                self.$emit('load-unverified-organizations');
                                self.handleNotice('This agency is merged');
                                self.$router.push({name:'admin.agencies.pending'});
                            })
                            .catch(function(error){
                                self.handleError(error);
                                self.endSubmitting(mergeButton);
                            });
                    });

                }
                else {
                    self.handleNotice('This agency has been approved');
                }
            },

            approve() {

                let self = this;

                if(!self.organization.verified){

                    let approveButton = event.currentTarget;

                    self.handleConfirm({
                        text: 'Are you sure that you want to approve this agency?',
                        warning: 'All unverified locations will be approved automatically'
                    }, function () {
                    //    $(approveButton).find('.fa-spin').removeClass('hidden');
                        self.startSubmitting(approveButton);
                        axios.post(self.api + 'admin/organizations/' + self.organization.id + '/approve')
                            .then(function(response){
                                self.$emit('load-unverified-organizations');
                                self.handleNotice('This agency is approved');
                                self.$router.push({name:'admin.agencies.pending'});
                            })
                            .catch(function(error){
                                self.handleError(error);
                            //    $(approveButton).find('.fa-spin').addClass('hidden');
                                self.endSubmitting(approveButton);
                            });
                    });

                }
                else {
                    self.handleNotice('This agency has been approved');
                }
            },

            decline() {
                let self = this;

                if(self.organization.verified) {
                    self.handleNotice('This agency has been approved');
                    return true;
                }

                let declineButton = event.currentTarget;

                self.handleConfirm({
                    text: 'Are you sure that you want to decline this agency?',
                    warning: 'All locations of this agency will be declined as well'
                }, function() {
                    self.startSubmitting(declineButton);

                    axios.delete(self.api + 'admin/organizations/' + self.organization.id + '/decline')
                        .then(function(response){
                            self.$emit('load-unverified-organizations');
                            self.handleNotice('This agency is declined');
                            self.$router.push({name:'admin.agencies.pending'});
                        })
                        .catch(function(error){
                            self.handleError(error);
                            self.endSubmitting(declineButton);
                        });
                });
            },

            declineLocation(index) {

                let self = this;

                if(index >= self.organization.locations.length) {
                    return false;
                }

                let location = self.organization.locations[index];

                let targetElement = event.currentTarget;

                if(!location.verified) {

                    self.handleConfirm({
                        text: 'Are you sure that you want to decline this location?'
                    }, function () {
                        $(targetElement).removeClass('fa-trash').addClass('fa-spin fa-spinner');

                        axios.delete(self.api + 'admin/organizations/' + self.organization.id + '/locations/' + location.location_id + '/decline')
                            .then(function(response){
                                self.organization.locations.splice(index, 1);
                                self.$emit('load-unverified-organizations');
                                self.handleNotice('This location is declined');
                                $(targetElement).addClass('fa-trash').removeClass('fa-spin fa-spinner');
                            })
                            .catch(function(error){
                                $(targetElement).addClass('fa-trash').removeClass('fa-spin fa-spinner');
                                self.handleError(error);
                            });
                    });
                }
                else {
                    self.handleError({message: 'Verified Location is not allowed to decline'});
                }
            }
        },

        mounted() {
            //console.log(this.$router, this.$route);
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">


</style>