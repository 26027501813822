<template>
    <div class="dropbox">
        <input type="file" class="input-file" multiple
               :name="uploadFieldName"
               :disabled="isSaving"
               @change="filesChange($event.target.name, $event.target.files); fileCount= $event.target.files.length"
        >
        <p v-if="isInitial">Drag your file(s) here to begin<br/> or click to browser</p>
        <p v-if="isSaving">Uploading {{ fileCount }} files...</p>
        <p v-if="isSuccess">{{ fileCount }} file(s) are uploaded successfully...</p>
    </div>
</template>

<script>

    const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
    const FILE_SIZE_LIMIT = 8388608;

    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props: {
            uploadFieldName: {
                type: String,
                required: true,
            },
            saveHandler: {
                type: Function,
                required: true
            }
        },

        data(){
            return {
                uploadedFiles: [],
                uploadError: null,
                currentStatus: null,
            }
        },
        computed: {
            isInitial() {
                return this.currentStatus === STATUS_INITIAL;
            },

            isSaving() {
                return this.currentStatus === STATUS_SAVING;
            },

            isSuccess() {
                return this.currentStatus === STATUS_SUCCESS;
            },

            isFailed() {
                return this.currentStatus === STATUS_FAILED;
            }
        },

        methods: {
            reset() {
                this.currentStatus = STATUS_INITIAL;
                this.uploadedFiles = [];
                this.uploadError = null;
            },

            save(formData) {
                let self = this;

                self.currentStatus = STATUS_SAVING;

                self.saveHandler(formData).then(x => {
                    self.uploadedFiles = [].concat(x);
                    self.currentStatus = STATUS_SUCCESS;
                }).catch(error => {
                //    self.uploadError = error;
                //    self.currentStatus = STATUS_FAILED;
                    self.handleError(error);
                    self.reset();
                });
            },

            filesChange(fieldName, fileList) {
                const formData = new FormData();

                if(!fileList.length) {
                    return false;
                }

                let fileTooLarge = false;
                Array.from(Array(fileList.length).keys()).map(x => {
                    if(fileList[x].size >= FILE_SIZE_LIMIT) {
                        fileTooLarge = true;
                    } else {
                        formData.append(fieldName, fileList[x], fileList[x].name);
                    }
                });
                if(fileTooLarge) {
                    this.handleError({
                        message: 'File too large! Attachments cannot exceed 8mb'
                    });
                    this.reset();
                    return;
                }

                this.save(formData);
            }
        },

        mounted() {
            this.reset();
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">
    .dropbox {
        outline: 2px dashed grey; /* the dash box */
        outline-offset: -10px;
        *background: lightcyan;
        background: var(--light-white-color);
        color: dimgray;
        padding: 10px 10px;
        min-height: 200px; /* minimum height */
        position: relative;
        cursor: pointer;

        &:hover {
            *background: lightblue; /* when mouse over to the drop zone, change color */
            background: var(--light-grey-color);
            >p {
                color: var(--main-color);
            }
        }

        p {
            font-size: 1.2em;
            text-align: center;
            padding: 50px 0;
        }

        .input-file {
            opacity: 0; /* invisible but it's there! */
            width: 100%;
            height: 200px;
            position: absolute;
            cursor: pointer;
        }
    }

</style>