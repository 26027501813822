import request from '@/api/request';

export function getGroupAgents (groupId, params = {}) {
  return request({
    url: `admin/groups/${groupId}/users`,
    method: 'GET',
    params: params
  });
}

export function getGroup (groupId) {
  return request({
    url: `admin/groups/${groupId}`,
    method: 'GET',
  });
}

export function getGroupList () {
    return request({
        url: 'admin/groups/list',
        method: 'GET',
    });
}

export function updateGroup (groupId, data = {}) {
  return request({
    url: `admin/groups/${groupId}`,
    method: 'PUT',
    data: data
  });
}

export function updateGroupNameAndDescription (groupId, data = {}) {
  return request({
    url: `admin/groups/${groupId}/name-and-description`,
    method: 'PUT',
    data: data
  });
}

export function toggleGroupAgent (groupId, agentId) {
  return request({
    url: `admin/groups/${groupId}/users/${agentId}`,
    method: 'PUT'
  });
}

export function toggleGroupAgentAddedByManually (groupId, agentId) {
  return request({
    url: `admin/groups/${groupId}/users/${agentId}/added-by-manually`,
    method: 'PUT'
  });
}
export function getGroupAgentsAddedByManually (groupId, params = {}) {
  return request({
    url: `admin/groups/${groupId}/users/added-by-manually`,
    method: 'GET',
    params: params
  });
}

export function createGroup (data = {}) {
  return request({
    url: 'admin/groups',
    method: 'POST',
    data: data
  });
}

export function createGroupWithSingleAgent (data = {}) {
  return request({
    url: 'admin/groups/added-by-manually',
    method: 'POST',
    data: data
  });
}

export function getAllAgentsNotInGroup (groupId) {
  return request({
    url: `admin/groups/${groupId}/users/not-part-of-the-group`,
    method: 'GET',
  });
}