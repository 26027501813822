<template>
    <div id="dashboard" class="admin-dashboard">
        <app-page-banner page="report" title="Report" subtitle="View and download reports"></app-page-banner>

        <div v-if="!loaded" class="page-loading"></div>
        <div class="row sm-p-2" v-else>

            <div class="col-12">

                <ul class="nav nav-tabs">
                    <li class="nav-item mr-1" v-if="$can('view', 'dashboard')">
                        <router-link class="nav-link" :to="{name: 'admin.dashboard'}"><span>Statistic</span></router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{name: 'admin.reports'}"><span>Reports</span></router-link>
                    </li>
                </ul>
            </div>

            <div class="col-12">

                <div class="form-group">

                    <app-multiple-select-box
                            id="report"
                            name="report"
                            data-vv-name="report"
                            v-validate="{required: false}"
                            data-vv-validate-on="select"
                            :allow-empty="false"
                            :multiple="false"
                            :options="reports"
                            placeholder="Select report . . ."
                            group-values="children"
                            group-label="name"
                            :group-select="false"
                            track-by="name"
                            label="name"
                            data-vv-as="report"
                            @select="report = $event"
                            :class="{'is-invalid': errors.has('report')}"
                            :markup="false">
                    </app-multiple-select-box>

                    <small v-show="errors.has('report')" class="text-danger">{{ errors.first('report') }}</small>

                </div>
            </div>

            <div class="col-12" v-if="report.filter">
                <app-advanced-search-bar placeholder="Search..."
                                         :search-handler="search"
                                         :clear-handler="clear"
                                         :submitting="submitting"
                                         :advanced-fields="report.filter"
                                         ref="advancedSearch"></app-advanced-search-bar>
            </div>

            <div class="col-12" v-if="report.id">
                <a v-if="reportData.data && reportData.data.length" class="jba-btn" target="_blank" :href="downloadReport">Download</a>
                <app-report-sheet :report="report" :report-data="reportData" :loading="submitting" @navigate="navigate"></app-report-sheet>
            </div>

        </div>

        <router-view></router-view>
    </div>
</template>

<script>
    import AppReportSheet from '../partials/AppReportSheet.vue';

    export default {
        props: {

        },
        data() {
            return {
                reports: [],
                report: {},
                reportData: {},
                loaded: false,
                submitting: false,
                page: 1
            }
        },

        inject: ['api'],

        components: {
            AppReportSheet: AppReportSheet
        },

        created() {
            /*let self = this;
            self.$on('navigate', function(data){
                self.navigate(data);
            });*/

            this.loadReports();
        },

        destroyed() {

        },

        computed: {
            downloadReport() {
                return this.api + 'admin/reports/' + this.report.id + '/download?' + $.param(this.$refs.advancedSearch.value);
            }
        },

        watch: {
            'report': function(val, oldVal) {
                this.reportData = {};
            }
        },

        methods: {

            loadReports() {

                let self = this;

                axios.get(this.api + 'admin/reports')
                    .then(function(response){
                        self.reports = response.data;
                        self.loaded = true;
                        self.$emit('page-loaded');
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            loadReportData(params = {}) {

                let self = this;

                self.submitting = true;

                axios.get(self.api + 'admin/reports/' + self.report.id, {params: params})
                    .then(function(response){
                        if(response.data.current_page) {
                            self.reportData = response.data;
                            self.page = self.reportData.current_page;
                        }
                        else {

                            self.reportData = {
                                data: response.data,
                                current_page: 1,
                                from: 1,
                                to: 1,
                                total: 1
                            };

                            self.page = 1;
                        }
                        self.loaded = true;
                        self.submitting = false;
                    })
                    .catch(function(error){
                        self.submitting = false;
                        self.handleError(error);
                    });
            },

            search(data) {
                this.loadReportData(data);
            },

            navigate(page) {

                let data = this.$refs.advancedSearch.value;
                data.page = page;

                this.loadReportData(data);
            },

            clear() {
                let data = this.$refs.advancedSearch.value;

                this.loadReportData(data);
            }

        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>