<template>
  <div class="card border-top border-bottom-0 border-right-0 border-left-0">
    <div class="card-header bg-white border-0 px-0">
      <div class="container-fluid px-0">
        <div class="row align-items-center">
          <div class="col-12 px-0">
            <div class="row">
              <div class="col-12 px-2">
                <a
                  ref="toggle"
                  class="text-main toggle d-flex align-items-center justify-content-between"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  :href="'#collapse-'+faq.id"
                  aria-expanded="true"
                  :aria-controls="'#collapse-'+faq.id"
                  @click="toggle = !toggle">
                  <span class="heading-input text-main mb-0">{{ faq.title }}</span>
                  <i class="fas static" :class="{'fa-angle-down': !toggle, 'fa-angle-up': toggle}"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :id="'collapse-' + faq.id" class="collapse row" role="tabpanel" :aria-labelledby="'header-' + faq.id" faq-parent="#accordion">
      <div class="card-body p-0 faq-body">
        <div class="container-fluid px-2 py-3">
          <div class="row">
            <div class="col-12 px-0" v-if="faq.content !== null" v-html="faq.content"></div>
            <div class="col-12 px-0" v-else>
              <p class=""><em>Content Yet to be added</em></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'agent-faq-item',

  data () {
    return {
      toggle: false
    }
  },

  props: {
    faq: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.heading-input {
  font-size: 1em !important;
}
a.toggle {
  font-size: 30px;
  display: block;

  i {
    display: block;
  }

  .fas:hover,
  .fas:active,
  .fas:visited {
    color: var(--main-color)
  }
}
</style>
