<template>

    <div id="agent">
        <app-page-banner page="agent" title="Agents" subtitle="Search and view registered agents"></app-page-banner>

        <div v-if="!loaded" class="page-loading"></div>
        <div class="row sm-p-2" v-else>

            <div class="col-12">

                <ul class="nav nav-tabs">
                    <li class="nav-item mr-1" v-if="$can('view', 'agent')">
                        <router-link class="nav-link" :to="{name: 'admin.agents'}"><span>Agents</span></router-link>
                    </li>
                    <li class="nav-item" v-if="$can('view', 'staff')">
                        <router-link class="nav-link" :to="{name: 'admin.staff'}" ><span>Staff</span></router-link>
                    </li>
                </ul>
            </div>

            <div class="col-12">
                <app-advanced-search-bar placeholder="Search Agents"
                                         :search-handler="search"
                                         :clear-handler="clear"
                                         :submitting="submitting"
                                         ref="advancedSearch"></app-advanced-search-bar>
            </div>

            <div class="col-12" v-show="pageCount > 1">
                <paginate :page-count="pageCount"
                          :page-range="5"
                          :click-handler="navigate"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :container-class="'pagination float-right'"
                          :page-class="'page-item'"
                          v-model="page"
                ></paginate>
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="(a, index) in agents.data" :key="index">

                <router-link tag="div" class="card clickable py-2" :to="{name: 'admin.agents.show', params: {id: a.id}}" :title="a.full_name + ' from ' + a.organization_name + ' in ' + a.country_name">
                    <div class="table mb-0">
                        <div class="table-row">
                            <div class="table-cell">
                                <img :src="a.avatar" class="img-fluid img-circle"/>
                            </div>
                            <div class="table-cell">
                                <ul class="list-unstyled mb-0">
                                    <li class="h6">{{ a.full_name }}</li>
                                    <li><i class="far fa-building text-main"></i> <small>{{ a.organization_name }}</small></li>
                                    <li><i class="fas fa-globe text-main"></i> <small>{{ a.country_name }}</small></li>

                                    <li class="dropdown-divider mb-2"></li>
                                    <li class="image-badge">
                                        <img v-if="a.recognized_badge" :src="a.recognized_badge"/>
                                        <img v-if="a.immigration_adviser_badge" :src="a.immigration_adviser_badge"/>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </router-link>
            </div>

            <app-reminder :ready="loaded" message="There is no agent at the moment" :new-line="true" :result="agents.data"></app-reminder>

            <div class="col-12" v-show="pageCount > 1">
                <paginate :page-count="pageCount"
                          :page-range="5"
                          :click-handler="navigate"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :container-class="'pagination float-right'"
                          :page-class="'page-item'"
                          v-model="page"
                ></paginate>
            </div>

        </div>


        <router-view :agent="currentAgent()" @load-agents="navigate(page)"></router-view>
    </div>

</template>

<script>

    export default {
        props: {

        },
        data() {
            return {
                submitting: false,
                agents: {},
                page: 1
            }
        },

        inject: ['api', 'countries', 'providerTypes'],

        components: {

        },

        created() {
            this.loadAgents();
        },

        destroyed() {

        },

        computed: {
            pageCount(){
                return Math.ceil(this.agents.total / this.agents.per_page);
            }
        },

        watch: {

        },

        methods: {
            toggleRecognizeStatus(param) {
                this.agents.data.forEach(function(o){
                    if(o.id == param.id) {
                        o.recognized_at = param.recognized_at;
                    }
                });
            },

            showRecognizedDate(date) {
                if(date) {
                    return 'Recognized at ' + this.$options.filters.convertDate(date);
                }

                return null;
            },

            search(data) {

                this.loadAgents(data);
                /*let self = this;

                axios.get(this.api + 'admin/agents', {params: data})
                    .then(function(response){
                        self.agents = response.data;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });*/

            },

            navigate(page) {

                let data = this.$refs.advancedSearch.value;
                data.page = page;

                this.loadAgents(data);

                /*axios.get(this.api + 'admin/agents', {params: this.$refs.advancedSearch.value})
                    .then(function(response){
                        self.agents = response.data;
                        self.page = self.agents.current_page;
                    })
                    .catch(function(error){
                        self.submitting = false;
                        self.handleError(error);
                    });*/
            },

            loadAgents(params = {}) {

                let self = this;

                self.submitting = true;

                axios.get(self.api + 'admin/agents', {params: params})
                    .then(function(response){
                        self.agents = response.data;
                        self.page = self.agents.current_page;
                        self.loaded = true;
                        self.submitting = false;
                    })
                    .catch(function(error){
                        self.submitting = false;
                        self.handleError(error);
                    });
            },

            findCountryName(id) {

                for(let i = 0; i < this.countries().length; i++) {
                    if(this.countries()[i].id == id) {
                        return this.countries()[i].name;
                    }
                }

                return null;
            },

            findOrganization(id) {

                for(let i = 0; i < this.organizations().length; i++) {
                    if(this.organizations()[i].id == id) {
                        return this.organizations()[i].name;
                    }
                }

                return null;
            },


            clear() {
                this.navigate(1);
            },

            currentAgent() {

                if(this.agents.data) {

                    for (let i = 0; i < this.agents.data.length; i++) {
                        if (this.agents.data[i].id == this.$route.params.id) {
                            return this.agents.data[i];
                        }
                    }
                }

                return {};
            }

        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" >

    .table {
        display: table;

        .table-row {
            display: table-row;

            .table-cell {
                display: table-cell;
                vertical-align: middle;
                padding: 5px;
                max-width: 100px;

                &:nth-child(1) {
                    width: 80px;
                    vertical-align: top;
                }
                &:nth-child(2) {
                    li {
                        line-height: 1.5em;

                        &.image-badge {
                            min-height: 35px;
                            img {
                                height: 35px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>