<template>
    <transition name="transition-modal">

        <div class="modal fade show" data-backdrop="static" data-keyboard="false" id="organization-modal" tabindex="-1" role="dialog" aria-labelledby="Organization">
            <div class="modal-dialog" role="document" @click.stop="{}">
                <div v-if="!loaded" class="page-loading"></div>
                <div v-else class="modal-content">
                    <div class="modal-header">
                        <h3>Agency</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </div>

                    <div class="modal-body">

                        <div id="organization-panel">
                            <div class="form-group">
                                <label for="organization_name">Name <span>*</span></label>
                                <input id="organization_name" name="organization_name" type="text" class="form-control" autocomplete="off"
                                       :class="{'is-invalid': errors.has('organization_name')}"
                                       v-model="organization.name"
                                       v-validate="{required: true}"/>
                                <small v-show="errors.has('organization_name')" class="text-danger">{{ errors.first('organization_name') }}</small>

                                <small class="form-text"><i class="fas fa-info-circle text-main static"></i> Name of the agency</small>

                            </div>

                            <div class="form-group">
                                <label for="organization_contact_person">Contact Person</label>
                                <input id="organization_contact_person" name="organization_contact_person" type="text" class="form-control" autocomplete="off"
                                       :class="{'is-invalid': errors.has('organization_contact_person')}"
                                       data-vv-as="contact person"
                                       v-model="organization.contact_person"
                                       v-validate="{required: false}"/>
                                <small v-show="errors.has('organization_contact_person')" class="text-danger">{{ errors.first('organization_contact_person') }}</small>
                                <small class="form-text"><i class="fas fa-info-circle text-main static"></i> Name of the contact person of the agency</small>
                            </div>

                            <!--div class="form-group">
                                <label for="main_phone">Main Phone <span>*</span></label>
                                <input id="main_phone" name="main_phone" type="text" class="form-control"
                                       :class="{'is-invalid': errors.has('main_phone')}"
                                       v-model="organization.main_phone"
                                       data-vv-as="main phone"
                                       v-validate="{required: true, regex: /^[0-9\s\-\(\)]{8,}$/}"/>
                                <small v-show="errors.has('main_phone')" class="text-danger">{{ errors.first('main_phone') }}</small>
                            </div-->

                            <div class="form-group">
                                <label for="organization_contact_email">Contact Email address</label>
                                <input id="organization_contact_email" name="organization_contact_email" type="text" class="form-control"
                                       :class="{'is-invalid': errors.has('organization_contact_email')}"
                                       v-model="organization.contact_email"
                                       data-vv-as="contact email"
                                       v-validate="{required: false, email: true}"/>
                                <small v-show="errors.has('organization_contact_email')" class="text-danger">{{ errors.first('organization_contact_email') }}</small>

                                <small class="form-text"><i class="fas fa-info-circle text-main static"></i> Contact email of the agency</small>
                            </div>

                            <!--div class="form-group">
                                <label for="student_contact_email">Student Contact Email address <span>*</span></label>
                                <input id="student_contact_email" name="student_contact_email" type="text" class="form-control"
                                       :class="{'is-invalid': errors.has('student_contact_email')}"
                                       v-model="organization.student_contact_email"
                                       data-vv-as="student contact email"
                                       v-validate="{required: true, email: true}"/>
                                <small v-show="errors.has('student_contact_email')" class="text-danger">{{ errors.first('student_contact_email') }}</small>
                            </div-->

                            <div class="form-group">
                                <label for="organization_website">Website </label>
                                <input id="organization_website" name="organization_website" type="text" class="form-control" placeholder="http(s)://yourdomain.com"
                                       :class="{'is-invalid': errors.has('organization_website')}"
                                       v-model="organization.website"
                                       v-validate="{url: {require_protocol: true }}"/>
                                <small v-show="errors.has('organization_website')" class="text-danger">{{ errors.first('organization_website') }}</small>
                                <small class="form-text"><i class="fas fa-info-circle text-main static"></i> Website of the agency</small>
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button @click.stop="saveOrganization" class="jba-btn" :disabled="errors.any() || submitting"><i v-if="submitting" class="fas fa-spinner fa-spin"></i> Save</button>
                        <button @click.stop="close" class="jba-btn reverse">Close</button>
                    </div>
                </div>
            </div>
        </div>

    </transition>
</template>

<script>
    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props:{

        },

        data(){
            return {
                organization: {
                    offices: []
                },
                submitting: false
            }
        },

        inject: ['api', 'auth', 'countries', 'organizations'],

        components: {

        },

        created() {
        //    this.loadOrganization();
            this.loaded = true;
        },

        beforeDestroy() {

        },

        computed: {
            agencyActionText() {
                return this.organization.id ? 'Update Agency' : 'Create Agency';
            },
            officeActionText() {
                return this.office_id ? 'Update Office' : 'Create Office'
            }
        },

        watch: {

        },

        mounted() {
            this.toggleModal('show');
        },

        methods: {

            loadOrganization() {

                let self = this;

                axios.get(self.api + 'agent/organization')
                    .then(function (response) {

                        self.organization = response.data;
                        self.loaded = true;
                    })
                    .catch(function (error) {
                        self.handleError(error);
                    });

            },

            saveOrganization() {

                let self = this;

                this.$validator.validateAll().then(function() {
                    if(!self.errors.any()) {

                        self.submitting = true;

                        axios.post(self.api + 'agent/organization', self.organization)
                            .then(function (response) {
                                self.submitting = false;
                                self.organization = response.data;

                                self.auth().organization_id = self.organization.id;
                                self.organizations().push({
                                    id: self.organization.id,
                                    name: self.organization.name,
                                    status: self.organization.status,
                                    status_string: self.organization.status_string
                                });
                                self.$emit('load-profile');
                                self.close();
                            })
                            .catch(function (error) {
                                self.submitting = false;
                                self.handleError(error);
                            });

                    }
                });
            },

            saveOffice() {

            },

            close() {
                this.toggleModal('hide');
                this.$router.push({name: 'agent.profile'});
            },

            toggleModal(status) {
                $(this.$el).modal(status);
            }
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>