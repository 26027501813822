<template>

    <div name="course-detail-modal">

        <div class="card clickable p-2" @click="toggleModal('show')">
            <img class="card-img-top" :src="course.image" alt="Course Image">
            <div class="card-body">
                <h6 class="card-title text-truncate">{{ course.name }}</h6>
                <p class="card-text text-truncate" v-if="course.description">{{ course.description }}</p>
            </div>
        </div>

        <div class="modal fade show" data-backdrop="static" data-keyboard="false" :id="'show-course-modal-' + course.id" tabindex="-1" role="dialog" aria-labelledby="Show Course">
            <div class="modal-dialog" role="document" @click.stop="function(){}">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Course</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </div>

                    <div class="modal-body">

                        <div class="text-center avatar">
                            <img :src="course.image" class="img-fluid"/>
                        </div>

                        <h5 class="text-center my-2">{{ course.name }}</h5>

                        <p v-if="course.description">{{ course.description }}</p>

                        <div v-if="course.instruction">
                            <h6>Instruction</h6>
                            <ul class="list-unstyled">
                                <li>1. step 1</li>
                                <li>2. step 2</li>
                                <li>3. step 3</li>
                                <li>...</li>
                            </ul>
                        </div>

                        <div class="row border">
                            <div class="col-12">
                                <button  class="jba-btn btn-block py-2 xlarge-font" @click="play()">Play on Browser</button>
                            </div>
                            <div class="col-12 col-sm-6">
                                <a v-if="course.ios_download"  :href="course.ios_download"><img src="/images/apple-store.png" class="img-fluid"></a>
                            </div>
                            <div class="col-12 col-sm-6">
                                <a v-if="course.android_download"  :href="course.android_download"><img src="/images/google-play.png" class="img-fluid"></a>
                            </div>
                            <div class="col-12">
                                <a  class="jba-btn btn-block py-2 xlarge-font" :href="'/courses/' + course.id + '/package/win' ">Download Windows Package</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {

        props:{
            /*id: {
                type: [Number, String],
                required: true
            },*/
            course: {
                type: Object,
                required: true
            }
        },

        data(){
            return {
            //    course: {},
                loaded: false
            }
        },

        inject: ['api', 'auth'],

        created() {
        },

        methods: {

            close() {
                this.toggleModal('hide');
            //    this.$router.go(-1);
            },

            toggleModal(status) {
                $(this.$el).find('.modal').modal(status);
            },

            play() {

                let self = this;

                if(!self.course.id) {
                    return false;
                }

                let query = {
                    token: self.auth().api_token
                };

                if(self.course.modules) {
                    self.course.modules.forEach(function (o) {

                        if (o.agents.length) {
                            query['module' + o.game_module] = o.agents[0].status.score;
                        }

                    });
                }

                self.close();
                self.$router.push({name: 'course.play', params: {id: self.course.id}, query: query});

            }
        },

        computed: {

        },

        mounted() {

        }
    }

</script>