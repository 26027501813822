<template>
    <div id="dashboard" class="admin-dashboard">
        <app-page-banner page="dashboard" title="Dashboard" subtitle="Overview of AgentLab usage statistics"></app-page-banner>

        <div v-if="!loaded" class="page-loading"></div>
        <div class="row sm-p-2" v-else>

            <div class="col-12">
                <ul class="nav nav-tabs">
                    <li class="nav-item mr-1">
                        <router-link class="nav-link" :to="{name: 'admin.dashboard'}"><span>Statistic</span></router-link>
                    </li>
                    <li class="nav-item" v-if="$can('view', 'report')">
                        <router-link class="nav-link" :to="{name: 'admin.reports'}"><span>Reports</span></router-link>
                    </li>
                </ul>
            </div>


            <div class="col-12">
                <div class="count-section">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-3" v-for="(c, index) in statistic.count">
                            <div class="flex-row align-items-center align-items-stretch border-0">
                                <div class="col-3 col-md-3 col-xl-2 d-flex align-items-center justify-content-center rounded-left">
                                    <img :src="getIcon(c.label)">
                                </div>
                                <div class="col-9 col-md-9 col-xl-10 py-3 rounded-right text-white" style="background: #22a2a2!important">
                                    <div class="h2 mt-0">{{ c.count }}</div>
                                    <div class="text-uppercase" style="font-size: 0.8em;">{{ c.label }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--div class="col-12">
                <router-link class="jba-btn" :to="{name:'admin.agents'}">Card view</router-link>
                <button @click="loadDemo()" class="jba-btn">Randomize</button>
            </div>
            <div class="col-12 col-sm-12 col-md-6">
                <line-chart :chart-data="statistic.demo"></line-chart>
            </div-->
            <div class="col-12 col-sm-12 col-md-6">
                <bar-chart :chart-data="loginHistoryStatistic" :options="chartOptions" style="height: 300px;"></bar-chart>
                <div class="text-center">
                    <small><span @click.stop="loadLoginHistory()" class="text-main clickable">{{ loginHistoryPeriod }} Activated Users</span></small>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6" v-for="(c, index) in courseStatistic">
                <bar-chart :chart-data="c" :options="courseChartOptions" style="height: 300px;"></bar-chart>
                <div class="text-center">
                    <small>{{ c.title }}</small>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6">
                <map-chart :source="agentDistribution" height="300"></map-chart>
            </div>

            <div class="col-12 col-sm-12 col-md-6">
                <div class="distribution">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th colspan="3">Agents by locations</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(o, index) in statistic.distribution">
                                <td>{{ o.name }}</td>
                                <td>{{ o.number }}</td>
                                <td class="text-right">
                                    {{ getDistributionPercentage(o.number) }}%
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

            <!--<div class="col-12 col-sm-12 col-md-6">
                <pie-chart :chart-data="statistic.demo"></pie-chart>

            </div>
            <div class="col-12 col-sm-12 col-md-6">
                <doughnut-chart :chart-data="statistic.demo"></doughnut-chart>

            </div>-->
            <!--div class="col-12 col-sm-12 col-md-6">
                <horizontal-bar-chart :chart-data="statistic"></horizontal-bar-chart>

            </div-->
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
    import LineChart from '../partials/chart/Line.vue';
    import BarChart from '../partials/chart/Bar.vue';
    import PieChart from '../partials/chart/Pie.vue';
    import DoughnutChart from '../partials/chart/Doughnut.vue';
    import HorizontalBarChart from '../partials/chart/HorizontalBar.vue';
    import MapChart from '../partials/chart/Map.vue';

    export default {
        props: {

        },
        data() {
            return {
                statistic: {
                    count: {},
                    login_history: {
                        labels: '',
                        data: []
                    },
                    courses: [],
                    distribution: [],
                    demo: {}
                },
                worldMap: null,
                loaded: false,
                loginPeriod: 1,
                chartOptions: {
                    responsive: true,
                    scaleStartValue: 0,
                    scaleOverride : true,
                    xAxisID: 0,
                    scales: {
                        xAxes: [{
                            //barPercentage: 0.4
                            //maxBarThickness: 30
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    },
                    maintainAspectRatio: false
                },

            }
        },

        inject: ['api'],

        components: {
            LineChart: LineChart,
            BarChart: BarChart,
            PieChart: PieChart,
            HorizontalBarChart: HorizontalBarChart,
            DoughnutChart: DoughnutChart,
            MapChart: MapChart
        },

        created() {
            this.loadStatistic();
        },

        destroyed() {

        },

        computed: {
            loginHistoryPeriod() {
                if(this.loginPeriod == 1) {
                    return 'Monthly';
                }
                else if(this.loginPeriod == 12) {
                    return 'Yearly';
                }
                else {
                    return this.loginPeriod + ' Months';
                }
            },

            loginHistoryStatistic() {

                return {
                    labels: this.arrayValues(this.statistic.login_history.data, 'label'),
                    datasets: [{
                        label: this.statistic.login_history.label,
                        backgroundColor: '#22a2a2',
                        borderWidth: 1,
                        data: this.arrayValues(this.statistic.login_history.data, 'number')
                    }]
                }
            },

            courseChartOptions() {
                return $.extend({},
                    this.chartOptions,
                    {
                        scales: {
                            xAxes: [{
                                stacked: true
                            }],
                            yAxes: [{
                                stacked: true
                            }]
                        }
                    }
                );
            },

            agentDistribution(){
                let distribution = {};
                this.statistic.distribution.forEach(function(o, k){
                    distribution[o.iso] = o.number;
                });

                return distribution;
            },

            courseStatistic() {
                let self = this;
                let courses = [];
                self.statistic.courses.forEach(function(o, k){
                    courses.push({
                        title: o.label,
                        labels: self.arrayValues(o.data, 'label'),
                        datasets: [
                            {
                                label: 'Unstarted',
                                backgroundColor: '#5A4DA5',
                                borderWidth: 1,
                                data: self.arrayValues(o.data, 'unstarted')
                            },
                            {
                                label: 'In Progress',
                                backgroundColor: '#766BBC',
                                borderWidth: 1,
                                data: self.arrayValues(o.data, 'started')
                            },
                            {
                                label: 'Completed',
                                backgroundColor: '#9D95CF',
                                borderWidth: 1,
                                data: self.arrayValues(o.data, 'completed')
                            }
                        ]
                    })
                });

                return courses;
            }
        },

        watch: {

        },

        methods: {

            getIcon(label) {

                switch(label.toLowerCase()) {
                    case 'agents': {

                        return this.icons.admin.agents;
                    }
                    case 'groups': {
                        return this.icons.admin.groups;
                    }
                    case 'course modules':
                    case 'courses': {
                        return this.icons.admin.courses;
                    }
                    case 'events':
                    case 'coming events': {
                        return this.icons.admin.webinars;
                    }
                    case 'news feeds': {
                        return this.icons.admin.feeds;
                    }
                    case 'broadcasts': {
                        return this.icons.admin.broadcasts;
                    }
                    case 'unverified agencies': {
                        return this.icons.admin.agencies;
                    }
                    case 'documents': {
                        return this.icons.admin.documents;
                    }
                    default: {
                        this.handleError({error: 'Could not find icon for ' + label});
                        break;
                    }
                }
            },

            loadLoginHistory() {

                let self = this;

                self.loginPeriod = self.loginPeriod == 1 ? 12 : 1;

                axios.get(this.api + 'admin/dashboard/user/activated', {params: {period: self.loginPeriod}})
                    .then(function(response) {
                        self.statistic.login_history = response.data;
                    });
            },

            loadDemo() {
                let self = this;
                self.statistic.demo = {
                    labels: [self.getRandomInt(), self.getRandomInt()],
                    datasets: [
                        {
                            label: 'Data One',
                            backgroundColor: '#f87979',
                            data: [self.getRandomInt(), self.getRandomInt()]
                        },

                        {
                            label: 'Data Two',
                            backgroundColor: '#1E90FF',
                            data: [self.getRandomInt(), self.getRandomInt()]
                        },

                        {
                            label: 'Data Three',
                            backgroundColor: '#9CC5E0',
                            data: [self.getRandomInt(), self.getRandomInt()]
                        }
                    ]
                };
            },

            loadStatistic() {

                let self = this;

                axios.get(this.api + 'admin/dashboard')
                    .then(function(response){

                        self.statistic = response.data;
                        self.loaded = true;
                        self.$emit('page-loaded');
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            getDistributionPercentage(number) {
                let total = this.arraySearch(this.statistic.count, 'Agents', 'label').count;
                if(total) {
                    return parseFloat(100 * number / total).toFixed(2);
                }
                return 0;
            },

            getRandomInt () {
                return Math.floor(Math.random() * (50 - 5 + 1)) + 5
            }

        },

        mounted() {

        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">
    .admin-agent-statistic-enter {
        opacity: 0;
    }

    .admin-agent-statistic-leave-active {
        opacity: 0;
    }

    .admin-agent-statistic-enter .modal-container,
    .admin-agent-statistic-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .admin-dashboard {
        .count-section {
            border-bottom: 2px solid #22a2a2;
            padding-bottom: 6px;
            .flex-row {
                display: flex;
                & > div {
                    &:nth-child(1) {
                        background: #1b7e7e!important;
                        img {
                            max-width: 40px;
                            width: 100%;
                        }
                    }
                    &:nth-child(2) {
                        background: #22a2a2!important;
                    }
                }
            }
        }
        .distribution {
            overflow: auto;
            height: 300px;
        }
    }

</style>