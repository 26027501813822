import request from '@/api/request';

export function getAgentsAdmin (params = {}) {
    return request({
        url: `admin/agents`,
        method: 'GET',
        params: params
    });
}

export function getAllAgentsAdmin () {
    return request({
        url: `admin/agents/all`,
        method: 'GET',
    });
}

export function getUserProfile () {
    return request({
        url: 'profile',
        method: 'GET'
    })
}

export function updateProfile (data = {}) {
    return request({
        url: 'profile',
        method: 'PUT',
        data: data
    });
}

export function createOffice(data = {}) {
    return request({
        url: 'agent/organizations/offices',
        method: 'POST',
        data: data
    });
}

export function putNotificationSetting(payload) {
    return request({
        url: 'profile/notification',
        method: 'PUT',
        data: payload
    });
}

export function getSettings() {
    return request({
        url: 'agent/settings',
        method: 'GET'
    });
}

export function updateNotification(payload) {
    return request({
        url: 'agent/settings/notification',
        method: 'PUT',
        data: payload
    })
}