<template>
    <transition name="transition-modal">

        <div class="modal fade show" data-backdrop="static" data-keyboard="false" id="share-video-modal" tabindex="-1" role="dialog" aria-labelledby="Share Video">
            <div class="modal-dialog" role="document" @click.stop="function(){}">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Recorded Webinar</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <form novalidate>

                            <div class="form-group">
                                <label for="video_subject">Subject <span>*</span></label>
                                <input id="video_subject" name="subject" type="text" class="form-control"
                                       :class="{'is-invalid': errors.has('subject')}"
                                       v-model="form.name"
                                       v-validate="{required: true, min:3}"/>
                                <small v-show="errors.has('name')" class="text-danger">{{ errors.first('name') }}</small>
                            </div>

                            <div class="form-group">
                                <label for="video_description">Description <span>*</span></label>
                                <textarea id="video_description" name="description" class="form-control"
                                          :class="{'is-invalid': errors.has('description')}"
                                          v-model="form.description"
                                          v-validate="{required: true, min: 10, max: 2000}"></textarea>
                                <small v-show="errors.has('description')" class="text-danger">{{ errors.first('description') }}</small>
                            </div>

                            <div class="form-group">
                                <label for="video_groups" class="">Groups <span>*</span></label>

                                <app-multiple-select-box
                                        id="video_groups"
                                        name="groups"
                                        data-vv-name="groups"
                                        v-validate="{required: true}"
                                        :value="form.groups"
                                        @select="form.groups = $event"
                                        :multiple="true"
                                        :options="groups"
                                        placeholder="Select groups"
                                        :internal-search="false"
                                        :markup="false"
                                        :class="{'is-invalid': errors.has('groups')}">
                                </app-multiple-select-box>

                                <small v-show="errors.has('groups')" class="text-danger">{{ errors.first('groups') }}</small>
                            </div>

                            <div class="form-group">
                                <label for="video_url">Zoom link <span>*</span></label>
                                <input id="video_url" name="url" type="url" class="form-control"
                                       :class="{'is-invalid': errors.has('url')}"
                                       v-model="form.url"
                                       v-validate="{required: true, url:'require_protocol'}"/>
                                <small v-show="errors.has('url')" class="text-danger">{{ errors.first('url') }}</small>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button v-if="video.id" @click.stop="remove" class="jba-btn btn-danger" :disabled="submitting.remove"><i v-if="submitting.remove" class="fas fa-spinner fa-spin"></i> Delete</button>
                        <button @click.stop="submit" class="jba-btn" :disabled="submitting.save"><i v-if="submitting.save" class="fas fa-spinner fa-spin"></i> Save</button>

                    </div>
                </div>
            </div>
        </div>

    </transition>
</template>

<script>
    import { createVideo, updateVideo, deleteVideo } from '@/api/videos';
    import { getGroupList } from '@/api/groups';
    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props:{
            video: {
                type: Object,
                default: function(){
                    return {};
                }
            }
        },

        data(){
            return {
                form: {
                    groups: []
                },
                submitting: {
                    save: false,
                    remove: false
                },
                groups: []
            }
        },

        inject: [],

        components: {

        },

        created() {
            let self = this;
            getGroupList().then(function (response) {
                self.groups = response.data;
            }, function (error) {
                self.handleError(error);
            });
        },

        destroyed() {

        },

        computed: {

        },

        mounted() {
            this.toggleModal('show');
            this.form = this.video;
        },

        methods: {

            close() {
                this.toggleModal('hide');
                this.$router.push({name: 'admin.videos'});
            },

            toggleModal(status) {
                $(this.$el).modal(status);
            },

            remove() {

                let self = this;

                self.handleConfirm(
                    {
                        'text': 'Are you sure you want to delete this item?'
                    },
                    function(){

                        self.submitting.remove = true;

                        deleteVideo(self.video.id)
                            .then(function(response){
                                self.submitting.remove = false;
                                self.$emit('load-videos');
                                self.close();
                                return true;
                            })
                            .catch(function(error){
                                self.handleError(error);
                                self.submitting.remove = false;
                            })
                    })
            },

            submit() {
                let self = this;

                self.$validator.validateAll().then(function(){

                    if(!self.errors.any()) {

                        let data = {
                            name: self.form.name,
                            description: self.form.description,
                            url: self.form.url,
                            groups: []
                        };

                        self.form.groups.forEach(function(o){
                            data.groups.push(o.id);
                        });

                        self.submitting.save = true;

                        if(self.video.id) {
                            updateVideo(self.video.id, data)
                                .then(function(response){
                                    self.submitting.save = false;
                                    self.$emit('load-videos');
                                    self.close();
                                    return true;
                                })
                                .catch(function(error){
                                    self.handleError(error);
                                    self.submitting.save = false;
                                })
                        }
                        else {

                            createVideo(data)
                                .then(function(response){
                                    self.submitting.save = false;
                                    self.$emit('load-videos');
                                    self.close();
                                    return true;
                                })
                                .catch(function(error){
                                    self.handleError(error);
                                    self.submitting.save = false;
                                })
                        }
                    }
                });
            }
        }
    }
</script>