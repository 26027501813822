<template>

    <div id="agent-message">
        <app-page-banner page="agent-message" title="Messages" subtitle="Messages from Education New Zealand"></app-page-banner>

        <div v-if="!loaded" class="page-loading"></div>
        <div v-else :class="{'fixed-content-width': $root.fixedContentWidth }">
            <div class="row">

                <div class="col-12">
                    <app-search-bar placeholder="Search Message" :search-handler="search" :clear-handler="clear" ref="keywords"></app-search-bar>
                </div>

                <div class="col-12" v-if="unread.length">

                    <table class="table table-hover text-bold mt-2" v-if="!hideUnread">
                        <tbody>
                            <!--<tr v-for="(m, index) in unread" :key="index">-->
                            <router-link tag="tr" v-for="(m, index) in unread" :key="index" :to="{name:'agent.messages.show', params: {id: m.id, unread: true}}" class="clickable row">

                                <td class="col-8 col-sm-3">
                                    {{ m.subject }}
                                </td>
                                <td class="col-sm-7 d-none d-sm-inline-block">
                                    <span v-html="m.content"></span>
                                </td>
                                <td class="col-4 col-sm-2 text-right">
                                    {{ m.created_at | convertDate }}
                                </td>
                            </router-link>
                            <!--</tr>-->
                        </tbody>
                    </table>
                </div>

                <app-reminder :ready="loaded && !!messages.total" message="You currently have no new or unread message." :new-line="true" :result="unread"></app-reminder>

                <!--<div class="col-12" v-else-if="loaded && messages.total">
                    <div class="alert alert-success my-2">
                        You've read all the messages in your inbox.
                    </div>

                </div>-->

                <div class="col-12" v-show="pageCount > 1">
                    <paginate :page-count="pageCount"
                              :page-range="5"
                              :click-handler="navigate"
                              :prev-text="'<'"
                              :next-text="'>'"
                              :container-class="'pagination float-right'"
                              :page-class="'page-item'"
                              v-model="page"
                    ></paginate>
                </div>

                <div class="col-12">
                    <table class="table table-hover mt-2" v-if="messages.total">
                        <tbody>
                        <!--<tr v-for="(m, index) in unread" :key="index">-->
                        <router-link tag="tr" v-for="(m, index) in messages.data" :key="index" :to="{name:'agent.messages.show', params: {id: m.id}}" class="clickable row">

                            <td class="col-8 col-sm-3 agent-message-subject">
                                {{ m.subject }}
                            </td>
                            <td class="col-sm-7 d-none d-sm-inline-block">
                                <span v-html="m.content"></span>
                            </td>
                            <td class="col-4 col-sm-2 text-right">
                                {{ m.created_at | convertDate }}
                            </td>
                        </router-link>
                        <!--</tr>-->
                        </tbody>
                    </table>

                    <app-reminder :ready="loaded && !unread.length" message="There is no message at this moment." :result="messages.data"></app-reminder>

                </div>
                <div class="col-12" v-show="pageCount > 1">
                    <paginate :page-count="pageCount"
                              :page-range="5"
                              :click-handler="navigate"
                              :prev-text="'<'"
                              :next-text="'>'"
                              :container-class="'pagination float-right'"
                              :page-class="'page-item'"
                              v-model="page"
                    ></paginate>
                </div>
            </div>
        </div>
        <router-view @read-message="sync"></router-view>
    </div>
</template>

<script>
    export default {

        props: {

        },

        data() {
            return {
                unread: [],
                messages: [],
                page: 1,
                hideUnread: false
            }
        },

        inject: ['api'],

        components: {

        },

        created() {
            this.sync();
        },

        computed: {
            pageCount(){
                return Math.ceil(this.messages.total / this.messages.per_page);
            }
        },

        methods: {

            loadMessages() {

                let self = this;

                axios.get(self.api + 'agent/messages')
                    .then(function(response) {
                        self.messages = response.data;
                        self.page = 1;
                        self.loaded = true;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            search(keywords) {
                let self = this;

                axios.get(this.api + 'agent/messages', {params: {q: keywords}})
                    .then(function(response){
                        self.messages = response.data;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            clear() {
                this.navigate(1);
            },

            navigate(page) {
                let self = this;

                let data = {
                    page: page,
                    q: this.$refs.keywords.value || ''
                };

                axios.get(this.api + 'agent/messages', {params: data})
                    .then(function(response){
                        self.messages = response.data;
                        self.page = self.messages.current_page;
                    })
                    .catch(function(error){
                        self.handleError(error);
                    });
            },

            sync() {
                let self = this;

                self.loadUnreadMessages().then(function(response) {
                    self.unread = response.data;
                    self.$root.$emit('update-unread-message', response.data);
                }, function(error) {
                    self.handleError(error);
                });

                this.loadMessages();
            }
        },

        mounted() {


        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>