<template>
    <transition name="admin-agent-statistic">
        <div class="content-ind">
            <AppPageBanner page="agent" title="Agent Search"></AppPageBanner>
            <div class="container">

                <div class="row mt-4">
                    <div class="col-12">
                        <router-link class="jba-btn" :to="{name:'admin.agents'}">Card view</router-link>
                        <button @click="fillData()" class="jba-btn">Randomize</button>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6">
                        <line-chart :chart-data="statistic"></line-chart>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6">
                        <bar-chart :chart-data="statistic"></bar-chart>

                    </div>
                    <div class="col-12 col-sm-12 col-md-6">
                        <pie-chart :chart-data="statistic"></pie-chart>

                    </div>
                    <div class="col-12 col-sm-12 col-md-6">
                        <doughnut-chart :chart-data="statistic"></doughnut-chart>

                    </div>
                    <div class="col-12 col-sm-12 col-md-6">
                        <horizontal-bar-chart :chart-data="statistic"></horizontal-bar-chart>

                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import LineChart from '../partials/chart/Line.vue';
    import BarChart from '../partials/chart/Bar.vue';
    import PieChart from '../partials/chart/Pie.vue';
    import DoughnutChart from '../partials/chart/Doughnut.vue';
    import HorizontalBarChart from '../partials/chart/HorizontalBar.vue';

    export default {

        props:{

        },

        data(){
            return {
                statistic: {}
            }
        },
        inject: ['api'],

        components: {
            LineChart: LineChart,
            BarChart: BarChart,
            PieChart: PieChart,
            HorizontalBarChart: HorizontalBarChart,
            DoughnutChart: DoughnutChart
        },

        mounted () {
          this.fillData()
        },
        methods: {
            fillData () {
                this.statistic = {
                    labels: [this.getRandomInt(), this.getRandomInt()],
                    datasets: [
                        {
                            label: 'Data One',
                            backgroundColor: '#f87979',
                            data: [this.getRandomInt(), this.getRandomInt()]
                        },

                        {
                            label: 'Data Two',
                            backgroundColor: '#1E90FF',
                            data: [this.getRandomInt(), this.getRandomInt()]
                        }
                    ]
                };

                this.loaded = true;
                this.$emit('page-loaded');
            },
            getRandomInt () {
                return Math.floor(Math.random() * (50 - 5 + 1)) + 5
            }
        }
    }
</script>

<style lang="scss" scoped>

    .admin-agent-statistic-enter {
        opacity: 0;
    }

    .admin-agent-statistic-leave-active {
        opacity: 0;
    }

    .admin-agent-statistic-enter .modal-container,
    .admin-agent-statistic-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
</style>