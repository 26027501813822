<template>
    <transition name="transition-modal">

        <div class="modal fade show" data-backdrop="static" data-keyboard="false" id="show-document-modal" tabindex="-1" role="dialog" aria-labelledby="Show Document">
            <div class="modal-dialog" role="document" @click.stop="{}">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>{{ document.name }}</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="close">
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <p class="">{{ document.description}}</p>

                        <div v-if="document.attachments.length">
                            <h6>Attachment(s)</h6>
                            <table class="table table-hover">
                                <tbody>
                                    <tr v-for="(a, index) in document.attachments" :key="index" class="icon">
                                        <td>{{ a.name }}</td>
                                        <td>{{ a.size | bytesToSize }}</td>
                                        <td class="py-2">
                                            <a target="_blank" :href="a.path" @click="pushDownloadData(document.name, a.name)">
                                                <i class="fas fa-download"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!--<div class="modal-footer">
                        <button @click.stop="close" class="jba-btn reverse">Close</button>
                    </div>-->
                </div>
            </div>
        </div>

    </transition>
</template>

<script>
    export default {
        // you should not attempt to mutate a prop inside a child component!!
        props:{
            id: {
                type: [String, Number],
                required: true
            }
        },

        data(){
            return {
                document: {
                    attachments:[]
                }
            }
        },

        inject: ['api'],

        components: {

        },

        created() {
            this.loadDocument();
        },

        beforeDestroy() {

        },

        computed: {

        },

        watch: {

        },

        mounted() {
            this.toggleModal('show');
        },

        methods: {
            loadDocument() {

                let self = this;

                axios.get(self.api + 'agent/documents/' + self.id)
                    .then(function (response) {

                        self.document = response.data;
                    })
                    .catch(function (error) {
                        self.handleError(error);
                    });

            },

            pushDownloadData(document, attachment) {

                if (window.dataLayer !== undefined) {
                    window.dataLayer.push({ 'event':'documentDownload','folderName': document, 'fileName': attachment });
                }
            },

            close() {
                this.toggleModal('hide');
                this.$router.push({name: 'agent.documents'});
            },

            toggleModal(status) {
                $(this.$el).modal(status);
            }
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>